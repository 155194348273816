import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Col, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const FormSubmitBtns = ({ isLoading = false }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation(["common"]);
  return (
    <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
      <Space
        size="large"
        align="center"
        style={{ margin: "0 auto", display: "flex", flexDirection: "row" }}
        className="cis-form__submitbtns"
      >
        <Button
          icon={<CloseCircleOutlined />}
          onClick={() => navigate(-1)}
          size="large"
        >
          {t("cancel") || "Cancel"}
        </Button>
        <Button icon={<ReloadOutlined />} htmlType="reset" size="large">
          {t("reset") || "Reset"}
        </Button>
        <Button
          type="primary"
          icon={<CheckCircleFilled />}
          htmlType="submit"
          loading={isLoading}
          disabled={isLoading}
          size="large"
        >
          {id ? t("update") : t("submit")}
        </Button>
      </Space>
    </Col>
  );
};

export default FormSubmitBtns;
