import { Button, Col, Form, Row, message } from "antd";
import NumberInput from "components/Input/InputNumber";
import { resetfrontDesk } from "features/frontDesk/frontDeskSlice";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import Axiosinstance from "utils/AxiosInstance";

const ReserFooter = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { bookedRooms, customer } = useSelector((state) => state.frontDesk);
  const totalBill = bookedRooms.reduce((acc, room) => acc + room.total, 0);

  const payableAmount = () => {
    const values = form.getFieldsValue();
    return (
      totalBill -
      ((values.whole_discount || 0) / 100) * totalBill -
      (values.adjust_amount || 0)
    );
  };

  useEffect(() => {
    const values = form.getFieldsValue();

    form.setFieldsValue({
      total_bill: parseInt(totalBill, 10),
      payable_amount: parseInt(payableAmount(), 10),
    });
  }, [totalBill]);

  const grandTotal = () => {
    form.setFieldsValue({
      payable_amount: parseInt(payableAmount(), 10),
    });
  };

  const paidAmount = () => {
    const values = form.getFieldsValue();
    const { payable_amount, paid_amount } = values;
    const total = parseInt(payable_amount, 10) - parseInt(paid_amount, 10);
    form.setFieldsValue({
      due_amount: parseInt(total, 10),
    });
  };

  const totalGuests = bookedRooms.reduce((acc, room) => acc + room.guests, 0);

  const checkIn = async (values) => {
    const { data: response } = await Axiosinstance.post(
      "frontdesk/advancepayment",
      values
    );
    return response;
  };

  const { mutate, isLoading } = useMutation(checkIn, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        queryClient.refetchQueries("frontdesk");
        dispatch(resetfrontDesk());
        form.resetFields();
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    if (!customer) {
      message.warning("Please select a guest");
      return;
    }
    if (!bookedRooms.length) {
      message.warning("Please select a room or service");
      return;
    }
    const data = {
      // hotel_id: 1,
      // employee_id: customer,
      // refrred_by: "A A Sifat",
      guest_id: customer,
      payment_type: 0,
      guests: totalGuests,
      booking_items: bookedRooms.map((room) => {
        if (room.isRoom) {
          return {
            room_id: room.id,
            service_id: 0,
            service_name: null,
            room_type: room.room_type,
            service_type: room.service_type,
            check_in: room.check_in,
            check_out: room.check_out,
            nights: room.nights,
            guests: room.guests,
            price: room.base_price,
            discount_percentage: room.discount,
            tax_percentage: room.vat,
          };
        }
        return {
          room_id: 0,
          service_id: room.id,
          service_name: room.room_name,
          room_type: 0,
          service_type: room.service_type,
          check_in: null,
          check_out: null,
          nights: 0,
          guests: 0,
          price: room.base_price,
          discount_percentage: room.discount,
          tax_percentage: room.vat,
        };
      }),
      whole_discount: values.whole_discount,
      adjust_amount: values.adjust_amount,
      paid: values.paid_amount,
      due: values.due_amount,
      status: 1,
    };
    mutate(data);
  };
  return (
    <Form
      layout="virtical"
      className="cis__form"
      // style={{ marginTop: "20px" }}
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[24, 24]}>
        <Col span={3}>
          <NumberInput
            name="total_bill"
            label="Total Bill"
            placeholder="Total Bill"
            onChange={grandTotal}
          />
        </Col>
        <Col span={3}>
          <NumberInput
            name="whole_discount"
            label="Whole Discount"
            placeholder="Whole Discount"
            onChange={grandTotal}
          />
        </Col>
        <Col span={3}>
          <NumberInput
            name="adjust_amount"
            label="Adjust Amount"
            placeholder="Adjust Amount"
            onChange={grandTotal}
          />
        </Col>
        <Col span={3}>
          <NumberInput
            name="payable_amount"
            label="Payable Amount"
            placeholder="Payable Amount"
            onChange={grandTotal}
          />
        </Col>
        <Col span={3}>
          <NumberInput
            name="paid_amount"
            label="Paid Amount"
            placeholder="Paid Amount"
            onChange={paidAmount}
          />
        </Col>
        <Col span={5}>
          <NumberInput
            name="due_amount"
            label="Due Balance"
            placeholder="Due Balance"
            disabled
          />
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            style={{ marginTop: "35px" }}
            loading={isLoading}
          >
            Advance Payment
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ReserFooter;
