/* eslint-disable no-unsafe-optional-chaining */
import { Card, Form, message, Row } from "antd";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import GetModuleFields from "utils/GetModuleFields";
import DATA from "./data.json";

const NewGuest = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);
  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    // console.log("values, values?.logo[0]?.uid", values);
    const data = {
      ...values,
      image: values?.image
        ? values.image.map((item) => {
          return item?.response ? item?.response : item;
        })
        : [],
    };
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        image: data?.image !== null ? data?.image : [],
      };

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  if (DATA === null) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <GetModuleFields data={DATA?.input_fields} />
          <Suspense fallback={<Loader />}>
            <FormSubmitBtns isLoading={isLoading} />
          </Suspense>
        </Row>
      </Form>
    </Card>
  );
};

export default NewGuest;
