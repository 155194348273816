/* eslint-disable no-mixed-operators */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import { Card, Col, Divider, Form, Input, InputNumber, message, Row, Select } from "antd";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import GetModuleFields from "utils/GetModuleFields";
import InputNumber2 from "components/Input/InputNumber2";
import SelectRequest from "components/Input/SelectRequest";
import { SelectStatus } from "utils/CISUI";
import DATA from "./data.json";

const AddItem = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [basePrice, setBasePrice] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [salePrice, setSalePrice] = useState(0);
  const [itemType, setItemType] = useState(2);
  const [showCategorySubcategory, setShowCategorySubcategory] = useState(true);

  useEffect(() => {
    // Calculate the Sale Price whenever the inputs change.
    const calculatedSalePrice = basePrice - (basePrice * discountPercentage / 100) + (basePrice * taxPercentage / 100);
    setSalePrice(calculatedSalePrice);
    setDiscountPercentage(discountPercentage);
    setTaxPercentage(taxPercentage);
    form.setFieldsValue({ sale_price: calculatedSalePrice });
    form.setFieldsValue({ base_price: basePrice });
    form.setFieldsValue({ discount_percentage: discountPercentage });
    form.setFieldsValue({ tax_percentage: taxPercentage });
  }, [basePrice, discountPercentage, taxPercentage]);
  // console.log(salePrice, "salePrice");

  useEffect(() => {
    setShowCategorySubcategory(itemType !== 1);
  }, [itemType]);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  // const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
  //   onSuccess: (data) => {
  //     if (data.status === 1) {
  //       message.success(data.msg);
  //       queryClient.refetchQueries([DATA.urls.list]);
  //       if (!id) {
  //         form.resetFields();
  //         queryClient.refetchQueries([DATA?.urls?.details + id]);
  //         navigate(DATA.urls.list);
  //       }
  //     } else if (data.status === 0) {
  //       message.warning(data.msg);
  //     } else {
  //       message.error(data.msg);
  //     }
  //   },
  //   onError: () => {
  //     message.error("Server Error");
  //   },
  // });

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    // console.log("values, values?.logo[0]?.uid", values);
    const data = {
      ...values,
    };
    console.log("data", data);
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
      };
      console.log("values", values);
      form.setFieldsValue(values);
      setItemType(values.item_type);
      setDiscountPercentage(values.discount_percentage);
      setTaxPercentage(values.tax_percentage);
      setBasePrice(values.base_price);
      setSalePrice(values.sale_price);
      setShowCategorySubcategory(values.item_type !== 1);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  if (DATA === null) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Divider
            orientation="left"
            orientationMargin="0"
            className="input__devider"
          >
            Item Details
          </Divider>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Item Name"
              name="name"
              rules={[
                { required: true, message: "Item name is required" },
              ]}
            >
              <Input placeholder="Enter item name" size="large" />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Barcode"
              name="barcode"
              rules={[
                { required: true, message: "Barcode is required" },
              ]}
            >
              <Input placeholder="Enter barcode" size="large" />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Tax Paid By"
              name="tax_paid_by"
              rules={[
                { required: true, message: "Tax payer is required" },
              ]}
            >
              <Select
                placeholder="Select tax payer"
                size="large"
              >
                <Select.Option value={1}>Owner</Select.Option>
                <Select.Option value={2}>Supplier</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Item Type"
              name="item_type"
              rules={[
                { required: true, message: "Item type is required" },
              ]}
            >
              <Select
                placeholder="Select item type"
                size="large"
                value={itemType}
                onChange={(value) => setItemType(value)}
              >
                <Select.Option value={1}>Non Saleable Goods</Select.Option>
                <Select.Option value={2}>Saleable Goods</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>

            <SelectRequest
              name="unit_title"
              label="Unit Title"
              required
              path="restaurant/unittitlelist?is_dep=1"
              placeholder="Select unit title"
            />

          </Col>
          { showCategorySubcategory && (
          <>
            <Col lg={8} md={8} span={24}>
              <SelectRequest
                name="category"
                label="Category"
                required={false}
                path="restaurant/menucategorylist?is_dep=1"
                placeholder="Select category"
              />
            </Col>
            <Col lg={8} md={8} span={24}>
              <SelectRequest
                name="subcategory"
                label="Sub Category"
                required={false}
                path="restaurant/menusubcatlist?is_dep=1"
                placeholder="Select sub category"
              />
            </Col>
          </>
          )}
          <Col lg={8} md={8} span={24}>
            <SelectStatus />
          </Col>
          <Col lg={24} md={8} span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: false }
              ]}
            >
              <Input.TextArea placeholder="Enter description" size="large" rows={3} />
            </Form.Item>
          </Col>
          {/* <GetModuleFields data={DATA?.input_fields} /> */}
          <Divider
            orientation="left"
            orientationMargin="0"
            className="input__devider"
          >
            Pricing Details
          </Divider>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Base Price"
              name="base_price"
              rules={[
                { required: true, message: "Base price is required" },
              ]}
            >
              <InputNumber
                placeholder="Enter base price"
                value={basePrice}
                onChange={(value) => setBasePrice(value)}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Discount(%)"
              name="discount_percentage"
              rules={[
                { required: true, message: "Discount is required" },
              ]}
              initialValue={0}
            >
              <InputNumber
                placeholder="Enter discount"
                value={discountPercentage}
                onChange={(value) => setDiscountPercentage(value)}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Tax(%)"
              name="tax_percentage"
              rules={[
                { required: true, message: "Tax is required" },
              ]}
              initialValue={0}
            >
              <InputNumber
                placeholder="Enter Tax"
                value={taxPercentage}
                onChange={(value) => setTaxPercentage(value)}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>
            <Form.Item
              label="Sale Price"
              name="sale_price"
              rules={[
                { required: false, message: "Sale price is required" },
              ]}
            >
              <InputNumber
                placeholder="Calculated sale price"
                value={salePrice}
                readOnly
                size="large"
              />
            </Form.Item>
          </Col>
          <Col lg={8} md={8} span={24}>
            <SelectRequest
              name="origin_country"
              label="Origin Country"
              path="sys/countries"
              placeholder="Select Origin Country"
            />
          </Col>
        </Row>
        <Suspense fallback={<Loader />}>
          <FormSubmitBtns isLoading={isLoading} />
        </Suspense>
      </Form>
    </Card>
  );
};

export default AddItem;
