/* eslint-disable max-len */
import React from "react";
import { Card, Col, Row, Statistic } from "antd";

const BalanceInfo = ({ generalInfo }) => {
  // console.log("generalInfo--------------->", generalInfo);
  const cardStyle = {
    backgroundColor: "#fafafa",
    borderRadius: "5px",
    padding: "2px 10px",
    boxShadow:
    "0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02)",
  };
  const feeAmount = {
    fontWeight: "500",
    fontSize: "18px",
    color: "#0e4535"
  };
  return (
    <Card className="card__paddingless card__border__radiusless card__box__shadow card__custom__bg for__scroll">
      <Row gutter={16}>
        <Col span={8}>
          <Card bordered={false} style={{ backgroundColor: "#fafafa" }}>
            <Statistic
              title="Amount"
              value={`$ ${generalInfo.total_bill}`}
              precision={2}
              valueStyle={{
                color: "rgb(14, 69, 53)",
                fontSize: "16px",
                fontWeight: "600"
              }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ backgroundColor: "#fafafa" }}>
            <Statistic
              title="Paid"
              value={`$ ${generalInfo.paid_amount}`}
              precision={2}
              valueStyle={{
                color: "rgb(14, 69, 53)",
                fontSize: "16px",
                fontWeight: "600"
              }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false} style={{ backgroundColor: "#fafafa" }}>
            <Statistic
              title=" Due"
              value={`$ ${generalInfo.due_amount}`}
              precision={2}
              valueStyle={{
                color: "rgb(14, 69, 53)",
                fontSize: "16px",
                fontWeight: "600"
              }}
            />
          </Card>
        </Col>
      </Row>
      <div className="included__title">Included</div>

      <div className="included__info_">
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <div className="single__included" style={cardStyle}>
              <div className="fee__title" style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>Service Charge</div>
              <div className="fee__amount" style={feeAmount}> $ {generalInfo.total_service_charge ? generalInfo.total_service_charge.toFixed(2) : "00"}</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="single__included" style={cardStyle}>
              <div className="fee__title" style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>Security Deposit</div>
              <div className="fee__amount" style={feeAmount}> $ {generalInfo.security_deposit?.amount ? generalInfo.security_deposit?.amount.toFixed(2) : "00"}</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="single__included" style={cardStyle}>
              <div className="fee__title" style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>Sales Amount</div>
              <div className="fee__amount" style={feeAmount}> $ {generalInfo.total_sales_amount ? generalInfo.total_sales_amount.toFixed(2) : "00" }</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="single__included" style={cardStyle}>
              <div className="fee__title" style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}> Booking Amount</div>
              <div className="fee__amount" style={feeAmount}> $ {generalInfo.total_booking_amount ? generalInfo.total_booking_amount.toFixed(2) : "00" }</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="single__included" style={cardStyle}>
              <div className="fee__title" style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>Tax Amount</div>
              <div className="fee__amount" style={feeAmount}> $ {generalInfo.total_tax ? generalInfo.total_tax.toFixed(2) : "00" }</div>
            </div>
          </Col>
          <Col span={8}>
            <div className="single__included" style={cardStyle}>
              <div className="fee__title" style={{ color: "rgba(0, 0, 0, 0.45)", fontSize: "12px" }}>Discount Amount</div>
              <div className="fee__amount" style={feeAmount}>$ {generalInfo.whole_discount_amount ? generalInfo.whole_discount_amount.toFixed(2) : " 00" }</div>
            </div>
          </Col>
        </Row>
        {/* <div className="single__included">
          <div className="fee__title"> Service Charge
          </div>
          <div className="fee__amount"> ${generalInfo.total_service_charge ? generalInfo.total_service_charge.toFixed(2) : "00"}</div>
        </div>
        c
        <div className="single__included">
          <div className="fee__title">Total Sales Amount
          </div>
          <div className="fee__amount"> ${generalInfo.total_sales_amount}</div>
        </div>
        <div className="single__included">
          <div className="fee__title">Total Sales Amount
          </div>
          <div className="fee__amount"> ${generalInfo.total_sales_amount}</div>
        </div>
        <div className="single__included">
          <div className="fee__title">Total Sales Amount
          </div>
          <div className="fee__amount"> ${generalInfo.total_sales_amount}</div>
        </div> */}
      </div>

      {/* <div className="blance__footer__title">

        { generalInfo.payment_status === 1 ?
          <Button style={buttonStyle} block onClick={handleAddItem}>
            <AppstoreAddOutlined /> Add Payment
          </Button>
          : generalInfo.payment_status === 2 ?
            <Button style={buttonStyleSuccess} block onClick={handleCheckOut}>
              <CheckCircleOutlined /> Checkout & invoice
            </Button>
            :
            <Link
              to={`
            ${baseUrl}booking/invoice/bookingdetails/${generalInfo.booking_id}`}
              target="_blank"
            >
              <Button style={buttonStyleSuccess} block>
                <CheckCircleOutlined /> Invoice
              </Button>
            </Link>
          }
      </div> */}
      {/* <BookingPaymentDrawer
        openPaymentDrawer={openPaymentDrawer}
        setOpenPaymentDrawer={setOpenPaymentDrawer}
        payable_amount={generalInfo.due_amount}
        generalInfo={generalInfo}
        setGeneralInfo={setGeneralInfo}
        setPayInfo={setPayInfo}
        booking_id={booking_id}
      /> */}
    </Card>
  );
};

export default BalanceInfo;
