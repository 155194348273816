/* eslint-disable max-len */
import React, { useState } from "react";
import {
  Col,
  Drawer,
  Row,
  Button,
  Input,
  Space,
  Select,
  Table,
  message,
} from "antd";
import { useMutation } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const BookingPaymentDrawer = ({
  openPaymentDrawer,
  setOpenPaymentDrawer,
  payable_amount,
  generalInfo,
  setGeneralInfo,
  resetHandler,
  setPayInfo,
  booking_id,
}) => {
  const paymentOptions = [
    {
      label: "Cash",
      value: 1,
    },
    {
      label: "Card",
      value: 2,
    },
    {
      label: "Wallet",
      value: 3,
    },
  ];
  const initialPaymentRow = [
    {
      type: "",
      tranx_id: "",
      total: "",
    },
  ];
  const [paymentRow, setPaymentRow] = useState(initialPaymentRow);
  const [options, setOptions] = useState(paymentOptions);
  const [payingAmount, setPayingAmount] = useState(0);
  const handleCancel = () => {
    setOpenPaymentDrawer(false);
  };
  const handleReset = () => {
    setPaymentRow(initialPaymentRow);
    setOptions(paymentOptions);
  };
  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(
      `booking/addpayment/${generalInfo.booking_id}`,
      values
    );
    return response;
  };

  const { mutate, isLoading } = useMutation(addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
          .then((res) => res.data)
          .then((data) => {
            setPayInfo(data?.data?.payment_items);
            setGeneralInfo(data?.data);
          });
        handleCancel();
        setPaymentRow([
          {
            type: "",
            tranx_id: "",
            total: "",
          },
        ]);
        resetHandler();
        // navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
  });
  const handleOk = () => {
    let totalAmount = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; paymentRow.length > i; i++) {
      if (!paymentRow[i].type) {
        message.error("Payment Method is Required");
        return;
      }
      if (paymentRow[i].type !== 1 && !paymentRow[i].tranx_id) {
        message.error("Transaction ID is Required");
        return;
      }
      if (!paymentRow[i].total) {
        message.error("Amount is Required");
        return;
      }
      totalAmount += +paymentRow[i].total;
    }
    if (totalAmount > payable_amount) {
      message.error("Total payment amount exceeds the payable amount");
      return;
    }

    const finalData = {
      payment_object: paymentRow,
    };
    mutate(finalData);
    setPaymentRow(initialPaymentRow);
    setOptions(paymentOptions);
  };
  const addMoreBtnHandler = () => {
    const remainingAmount = payable_amount - payingAmount;
    if (remainingAmount <= 0) {
      message.warning(
        "Remaining amount is zero or less. No more payments can be added."
      );
    } else {
      setPaymentRow((prevState) => {
        return [
          ...prevState,
          {
            type: "",
            tranx_id: "",
            total: "",
          },
        ];
      });
    }
  };
  const removeHandler = (idx) => {
    if (paymentRow.length > 1) {
      const newPaymentRow = [...paymentRow];
      const deletedMethod = newPaymentRow.splice(idx, 1);
      if (deletedMethod[0].type) {
        const findDeletedOption = paymentOptions.find(
          (item) => item.value === deletedMethod[0].type
        );
        setOptions((prevState) => [...prevState, findDeletedOption]);
      }
      if (deletedMethod[0].total) {
        const totalPayingAmount = newPaymentRow.reduce(
          (prevValue, currValue) =>
            prevValue + parseFloat(currValue.total || 0),
          0
        );
        setPayingAmount(totalPayingAmount);
      }
      setPaymentRow(newPaymentRow);
    }
  };
  const handleChange = (e, idx) => {
    const newPaymentRow = [...paymentRow];
    newPaymentRow[idx][e.target.name] = e.target.value;

    if (e.target.name === "total") {
      const totalPayingAmount = newPaymentRow.reduce(
        (prevValue, currValue) => prevValue + parseFloat(currValue.total || 0),
        0
      );
      if (totalPayingAmount > payable_amount) {
        message.warning("Total amount exceeds the paying amount");
      } else { /* empty */ }
      setPayingAmount(totalPayingAmount);
    }
    if (
      e.target.name === "tranx_id" &&
      (newPaymentRow[idx].type === 2 || newPaymentRow[idx].type === 3)
    ) {
      const maxFourDigits = e.target.value.slice(0, 4);
      newPaymentRow[idx].tranx_id = maxFourDigits;
    }

    setPaymentRow(newPaymentRow);
  };

  const handleSelect = (value, v, idx) => {
    const newPaymentRow = [...paymentRow];
    newPaymentRow[idx].type = value;

    const newPaymentOptions = [...paymentOptions];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; newPaymentRow.length > i; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; newPaymentOptions.length > j; j++) {
        if (newPaymentRow[i].type === newPaymentOptions[j].value) {
          newPaymentOptions.splice(j, 1);
        }
      }
    }
    setOptions(newPaymentOptions);
    setPaymentRow(newPaymentRow);
  };
  const remainAmount = (payable_amount - payingAmount).toFixed(2);
  console.log("remainAmount--------------", remainAmount);
  const columns = [
    {
      title: <span className="table__title"> Payment Method</span>,
      dataIndex: "type",
      render: (value, obj, idx) => {
        let methodName;
        if (value === 1) {
          methodName = "Cash";
        } else if (value === 2) {
          methodName = "Card";
        } else if (value === 3) {
          methodName = "Wallet";
        } else {
          methodName = null;
        }
        return (
          <Select
            placeholder="Select a option"
            style={{ width: "100%" }}
            options={options}
            onChange={(changeValue, changeAllValue) =>
              handleSelect(changeValue, changeAllValue, idx)
            }
            value={methodName || null}
          />
        );
      },
    },
    {
      title: <span className="table__title"> ref</span>,
      dataIndex: "tranx_id",
      render: (value, v, idx) => {
        let placeholderMsg = "";
        if (v.type == 1) {
          placeholderMsg = "payed by cash";
        } else if (v.type == 2) {
          placeholderMsg = "Last 4 digit";
        } else if (v.type == 3) {
          placeholderMsg = "Transaction ID";
        } else {
          placeholderMsg = "";
        }

        return (
          <Input
            placeholder={placeholderMsg}
            name="tranx_id"
            onChange={(e) => handleChange(e, idx)}
            value={value || null}
            style={{ width: "100%" }}
            disabled={v.type === 1}
          />
        );
      },
    },
    {
      title: <span className="table__title"> Amount ($)</span>,
      dataIndex: "total",
      align: "right",
      render: (value, v, idx) => {
        return (
          <Input
            placeholder="Total"
            name="total"
            onChange={(e) => handleChange(e, idx)}
            type="number"
            value={value || null}
            style={{ width: "100%", textAlign: "right" }}
          />
        );
      },
    },

    {
      title: <span className="table__title"> Action</span>,
      align: "center",
      render: (value, val, idx) => {
        return (
          <Button type="primary" onClick={() => removeHandler(idx)}>
            <CloseOutlined />
          </Button>
        );
      },
    },
  ];

  const buttonStyle = {
    marginBottom: "10px",
  };
  const submitButtonStyle = {
    marginBottom: "10px",
  };
  const resetButtonStyle = {
    marginBottom: "10px",
  };
  return (
    <Drawer
      title="Please complete your payment"
      width="50%"
      placement="right"
      closable={false}
      onClose={() => setOpenPaymentDrawer(false)}
      open={openPaymentDrawer}
      bodyStyle={{
        paddingBottom: 80,
      }}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            <Button
              type="default"
              onClick={() => setOpenPaymentDrawer(false)}
              icon={<CloseCircleOutlined />}
              style={{ marginTop: "20px", width: "250px" }}
            >
              Close
            </Button>
            <Button
              type="default"
              icon={<SyncOutlined />}
              style={{ marginTop: "20px", width: "250px" }}
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={handleOk}
              icon={<CheckCircleOutlined />}
              style={{ marginTop: "20px", width: "250px" }}
              loading={isLoading}
            >
              Submit
            </Button>
          </Space>
        </div>
      }
      style={{ zIndex: "2001" }}
    >
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                margin: options.length ? "0" : "0 0 8px 0",
                display: "flex",
                columnGap: "5px",
                color: "#23253c",
                alignItems: "baseline",
                marginBottom: "10px",
              }}
            >
              Remain:
              <span
                style={{
                  color: "#23253c",
                  background: "#faad14",
                  fontWeight: "600",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                {" "}
                ${remainAmount}
              </span>
            </p>
            {options.length ? (
              <Button
                type="primary"
                onClick={addMoreBtnHandler}
                style={buttonStyle}
              >
                <PlusOutlined /> Add More
              </Button>
            ) : null}
          </div>
          <Table
            columns={columns}
            dataSource={paymentRow}
            bordered
            className="booking__payment__table"
            pagination={false}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default BookingPaymentDrawer;
