import { Affix, Button, Card, Col, Form, Row, Tree, message } from "antd";
import Loader from "components/Loader";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { jsonData } from "features/data/dataSlice";
import GlobalLoader from "components/Loader/GlobalLoader";
import { InputField } from "utils/InputField";
import Axiosinstance from "utils/AxiosInstance";
import InputSelect from "components/Input/InputSelect";
import { AVD_INPUT, ROUTES } from "./Data";
import json_data from "./data.json";

const NewRoles = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(jsonData(json_data));
  }, [json_data]);

  const { data, isLoading } = useQuery(["moduletree"], () =>
    Axiosinstance.get("moduletree").then((res) => res.data.data)
  );
  console.log("🚀 ~ file: New.js:35 ~ NewRoles ~ data:", data);

  const actionsData = [
    {
      id: 1,
      act_name: "Add",
    },
    {
      id: 2,
      act_name: "Edit",
    },
    {
      id: 3,
      act_name: "View",
    },
    {
      id: 4,
      act_name: "Delete",
    },
    {
      id: 5,
      act_name: "Export",
    },
    {
      id: 6,
      act_name: "Import",
    },
    {
      id: 7,
      act_name: "Report",
    },
    {
      id: 8,
      act_name: "File Attachment",
    },
  ];

  // const treeData = data?.map((item) => {
  //   return {
  //     title: item.title,
  //     key: item.key,
  //     children: item.children?.map((subitem) => {
  //       return {
  //         title: subitem.title,
  //         key: `${item.key}_${subitem.key}`,
  //         children: actionsData?.map((action) => {
  //           return {
  //             title: action.act_name,
  //             key: `act_${item.key}_${subitem.key}_${action.id}`,
  //           };
  //         }),
  //       };
  //     }),
  //   };
  // });

  const treeData = data?.map((item) => {
    return {
      title: item.title,
      key: item.key,
      children: item?.children?.map((subitem) => {
        return {
          title: subitem.title,
          key: `${item.key}_${subitem.key}`,
          children: actionsData?.map((action) => {
            return {
              title: action.act_name,
              key: `act_md_${item.key}_sb_${subitem.key}_${action.id}`,
            };
          }),
        };
      }),
    };
  });

  const { data: roleData, isLoading: isDataLoading } = useQuery(
    [`${ROUTES.DETAILS}_${id}`],
    () =>
      Axiosinstance.get(`${ROUTES.DETAILS + id}`).then((res) => res.data.data),
    { enabled: !!id }
  );

  const addRole = async (values) => {
    const { data: response } = await Axiosinstance.post(ROUTES.NEW, values);
    return response;
  };

  const editRole = async (values) => {
    const { data: response } = await Axiosinstance.patch(
      ROUTES.UPDATE + id,
      values
    );
    return response;
  };

  const { mutate, isLoading: isAddLoading } = useMutation(
    id ? editRole : addRole,
    {
      onSuccess: (data) => {
        if (data.status === 1) {
          message.success(data.msg);
          form.resetFields();
          // queryClient.refetchQueries([COMMONURL.USERROLES]);
          queryClient.refetchQueries(json_data.urls.list);
          // queryClient.invalidateQueries("user/allroles?is_dep=1");
          queryClient.removeQueries("user/allroles?is_dep=1");
          navigate(ROUTES.LIST);
        } else if (data.status === 0) {
          message.warning(data.msg);
        } else {
          message.error(data.msg);
        }
      },
      onError: () => {
        message.error("Server Error");
      },
    }
  );

  useEffect(() => {
    if (id !== undefined) {
      form.setFieldsValue({
        ...roleData,
      });
    } else {
      form.resetFields();
    }
  }, [id, roleData]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries(`${ROUTES.DETAILS}_${id}`);
    };
  }, []);

  const onFinish = (values) => {
    mutate(values);
  };
  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  if (isLoading || isDataLoading) {
    return <GlobalLoader />;
  }

  return (
    <Card
      // title={<IntlMessages id="Add New User Role" />}
      // extra={<ListIcon url={ROUTES.LIST} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        layout="vertical"
        form={form}
      >
        <Row gutter={[16, 0]}>
          {user.area === 1 && (
            <Col span={24} lg={6}>
              <InputSelect
                placeholder="Use For"
                items={[
                  { id: 2, name: "System User" },
                  { id: 3, name: "Hotel Admin" },
                  { id: 4, name: "Hotel User" },
                ]}
                name="usefor"
                label="Use For"
              />
            </Col>
          )}

          {user.area === 2 && (
            <Col span={24} lg={6}>
              <InputSelect
                placeholder="Use For"
                items={[
                  { id: 3, name: "Hotel Admin" },
                  { id: 4, name: "Hotel User" },
                ]}
                name="usefor"
                label="Use For"
              />
            </Col>
          )}

          <InputField input={AVD_INPUT} />
          <Col span={24}>
            <Form.Item name="roles" valuePropName="checked" trigger="onCheck">
              <Tree
                checkable
                treeData={treeData}
                defaultExpandAll
                defaultCheckedKeys={id ? roleData.roles : null}
                // checkedKeys={id ? roleData?.roles : null}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Affix offsetBottom={40}>
              <div className="submit__btn roles">
                <Button
                  size="large"
                  icon={<CloseCircleOutlined />}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button size="large" icon={<ReloadOutlined />} htmlType="reset">
                  Reset
                </Button>
                <Button
                  type="primary"
                  size="large"
                  icon={<CheckCircleFilled />}
                  htmlType="submit"
                  loading={isAddLoading}
                  disabled={isAddLoading}
                >
                  Save
                </Button>
              </div>
            </Affix>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default NewRoles;
