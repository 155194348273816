/* eslint-disable max-len */
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import Logo from "../../assets/images/logo.svg";
import { items } from "../../data/data";

const unsplashimg = {
  src: "https://source.unsplash.com/1600x900?hotel",
  alt: "random unsplash image",
};
const image = `url(${unsplashimg.src})`;

const ForgotPassword = () => {
  const [quotes, setQuotes] = useState("");
  const navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const sentOtp = async (values) => {
    const { data: response } = await Axiosinstance.post("/auth/forgotpassword", values);
    return response;
  };

  const { mutate, isLoading } = useMutation(sentOtp, {
    onSuccess: (data) => {
      if (data.status === 1) {
        // message.success(data.msg);
        // navigate("/login");
        // console.log("email=====", email);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSendOtp = () => {
    setOtpSent(true);
    mutate({ email });
    message.success(`OTP sent to ${email}`);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmNewPasswordChange = (e) => {
    setConfirmNewPassword(e.target.value);
  };

  useEffect(() => {
    const randomNum = Math.floor(Math.random() * items.length);
    const randomQuote = items[randomNum];
    setQuotes(randomQuote);
  }, []);

  const resetPass = async (values) => {
    const { data: response } = await Axiosinstance.post("/auth/passwordreset", values);
    return response;
  };

  const { mutate: mutate1, isLoading: isLoading1 } = useMutation(resetPass, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        navigate("/login");
        // console.log("email=====", email);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    const data1 = {
      email: values.email,
      code: values.code,
      new_password: values.new_password,
    };
    // setEmail(values.email);
    console.log("email=====", email);
    mutate1(data1);
  };

  return (
    <main className="main__loginpage">
      <section className="home__login">
        <img
          src={unsplashimg.src}
          alt="home shape"
          className="login-shape-image"
        />
        <div className="login__container container">
          <div className="login__info">
            <div className="login__leftside__info">
              <div className="login__left__title">
                Our high-quality services are trusted by hotels worldwide.
              </div>
              {/* <div className="login__left__des">
                A quick interactive session with our expert, to help you find
                out how to:
              </div> */}
              <div className="login__left__point">
                <ul>
                  <li>Connect more guests and revenue streams with our software and monitor the entire system efficiently from the front desk.</li>
                  <li>
                    Surveil your room rates during peak seasons or high-demand periods and suggest offers based on previous customer experience.
                  </li>
                  <li>Manage restaurants and bars for the availability of items and stock management along with room services and management from the front desk.</li>
                </ul>
              </div>
              <div className="owner__quote__area">
                <div className="ownwr__img">
                  <img src={Logo} alt="" />
                </div>
                <div className="owner__info">
                  <div className="owner__quote">
                    <i className="icon icon-quote-left" />
                    <span>{quotes.quote}</span>
                    <i className="icon icon-quote-right" />
                  </div>
                  <div className="owner__name">{quotes.name}</div>
                  {/* <div className="owner__address"> Mt. Jefferson View, USA</div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="login__form__area">
            <div className=" login___form">
              <div className="login__right">
                <img src={Logo} alt="brand logo" className="avatar" />
                <h3>Cosmo Hotel</h3>
              </div>
              {/* <p> Please enter your details</p> */}
              {!otpSent && (
                <Form
                  className="login-form cis__form"
                  onFinish={onFinish}
                  name="basic"
                  style={{ maxWidth: 400 }}
                  layout="vertical"
                >
                  <Form.Item
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your E-mail!",
                      },
                    ]}
                    name="email"
                    autoFocus={false}
                    className="form__item__element"
                  >
                    <Input
                      value={email}
                      onChange={handleEmailChange}
                      prefix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Email"
                      size="large"
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      onClick={handleSendOtp}
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                      size="large"
                      loading={isLoading}
                      disabled={isLoading}
                    >
                      Send OTP
                    </Button>
                    {/* <p className="login-form-signin">
                      Didn&apos;t receive a code ?
                      <Link to="/signup"> Resend OTP</Link>
                    </p> */}
                  </Form.Item>
                </Form>
              )}
              {otpSent && (
              <Form
                className="login-form cis__form"
                onFinish={onFinish}
                name="basic"
                style={{ maxWidth: 400 }}
                layout="vertical"
              >
                <Form.Item
                  label="Email"
                  hidden
                  initialValue={email}
                  rules={[
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                  name="email"
                  autoFocus={false}
                  className="form__item__element"
                >
                  {/* <Input
                    type="hidden"
                    defaultValue={email}
                    onChange={handleEmailChange}
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    size="large"
                  /> */}
                </Form.Item>

                <Form.Item
                  label="OTP"
                  rules={[
                    {
                      required: true,
                      message: "Please input your otp!",
                    },
                  ]}
                  name="code"
                  // autoFocus={false}
                  className="form__item__element"
                >
                  <Input
                    value={otp}
                    onChange={handleOtpChange}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="OTP"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label="New Password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="new_password"
                  // autoFocus={false}
                  className="form__item__element"
                >
                  <Input.Password
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    // type="password"
                    placeholder="Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label="Retype Password"
                  rules={[
                    { required: true, message: "Please input your password again!" },
                  ]}
                  name="password"
                  autoFocus={false}
                  className="form__item__element"
                >
                  <Input.Password
                    value={confirmNewPassword}
                    onChange={handleConfirmNewPasswordChange}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    // type="password"
                    placeholder="Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    size="large"
                    loading={isLoading1}
                    disabled={isLoading1}
                  >
                    Reset Password
                  </Button>
                  {/* <p className="login-form-signin">
                    Didn&apos;t receive a code ?
                    <Link to="/signup"> Resend OTP</Link>
                  </p> */}
                </Form.Item>
              </Form>
              )}
            </div>
          </div>
        </div>
      </section>
      <p className="footer__area">Product of CIS Tech Ltd.</p>
    </main>
  );
};

export default ForgotPassword;
