import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Button, Popover, message } from "antd";
import { vi } from "faker/lib/locales";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Axios from "utils/AxiosInstance";

/**
 *
 * @param {string} urls - all urls from data.json file
 * @param {string} recordID - id of record to be deleted
 *
 */

const ActionMenu = ({ recordID = null, urls = null }) => {
  const [visible, setVisible] = useState(false);
  const [deletePopover, setDeletePopover] = useState(false);
  const queryClient = useQueryClient();

  const deleteItem = async (id) => {
    const { data: response } = await Axios.delete(urls.remove + id);
    return response;
  };

  const { mutate: deleteMutate, isLoading } = useMutation(deleteItem, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        setVisible(false);
        setDeletePopover(false);
        queryClient.refetchQueries([urls.list]);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const handleDelete = (id) => {
    deleteMutate(id);
    setDeletePopover(false);
  };

  const cancelDelete = () => {
    setTimeout(() => {
      setVisible(false);
      setDeletePopover(false);
    }, 100);
    console.log("cancel delete");
  };

  console.log(visible, deletePopover, "visible, deletePopover");

  return (
    <Popover
      open={visible}
      onOpenChange={(open) => setVisible(open)}
      overlayClassName="action__popover"
      content={
        <>
          <Link to={urls.edit + recordID}>
            <Button
              size="small"
              type="primary"
              block
              className="action__popover__btn"
              icon={<EditOutlined />}
            >
              Edit
            </Button>
          </Link>
          <Popover
            open={deletePopover}
            onOpenChange={(open) => setDeletePopover(open)}
            placement="topLeft"
            trigger="click"
            content={
              <div className="action__popover__content">
                <p>Are you sure you want to delete this record?</p>
                <div className="action__popover__content__btns">
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => handleDelete(recordID)}
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Yes
                  </Button>
                  <Button size="small" onClick={cancelDelete}>
                    No
                  </Button>
                </div>
              </div>
            }
          >
            <Button
              size="small"
              danger
              type="primary"
              block
              icon={<DeleteOutlined />}
              className="action__popover__btn"
              onClick={() => setDeletePopover(true)}
            >
              Delete
            </Button>
          </Popover>
        </>
      }
      title="Actions"
      trigger="click"
      placement="left"
    >
      <Button
        onClick={() => setVisible(!visible)}
        size="small"
        style={{ marginBottom: 0 }}
      >
        <EllipsisOutlined />
      </Button>
    </Popover>
  );
};

export default ActionMenu;
