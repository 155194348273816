import { UnorderedListOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const ListIcon = ({ url }) => {
  return (
    <div className="form__print__list">
      <Link to={url}>
        <UnorderedListOutlined className="form__print__list__icon" />
      </Link>
    </div>
  );
};

export default ListIcon;
