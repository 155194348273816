import Error404 from "containers/Error404";
import ForgotPassword from "containers/ResetPassword/ForgotPassword";
import SignIn from "containers/SignIn";
import Signup from "containers/Signup";
import useAuthCheck from "hooks/useAuthCheck";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppRoutes from "routes";
import PrivateRoute from "routes/PrivateRoute";
import PublicRoute from "routes/PublicRoute";
import GlobalLoader from "components/Loader/GlobalLoader";
import MainConfig from "containers/App/MainConfig";
import NewV2 from "containers/Restaurant/POS/NewV2";
import PrivateRouteBlank from "routes/PrivateRouteBlank";

const App = () => {
  const authChecked = useAuthCheck();

  if (!authChecked) return <GlobalLoader />;

  return (
    <BrowserRouter>
      <MainConfig>
        <Routes>
          <Route
            path="/signin"
            element={
              <PublicRoute>
                <SignIn />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <Signup />
              </PublicRoute>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />

          <Route
            path="/*"
            element={
              <PrivateRoute>
                <AppRoutes />
              </PrivateRoute>
            }
          />
          <Route
            path="/restaurant/addnewposv2"
            element={
              <PrivateRouteBlank>
                <NewV2 />
              </PrivateRouteBlank>
            }
          />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </MainConfig>
    </BrowserRouter>
  );
};

export default App;
