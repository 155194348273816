import React, { useEffect, useState } from "react";
import { Button, Card, Space, Table, Drawer, message, Popconfirm } from "antd";
import CardTitle from "components/Cards/CardTitle";
import Axiosinstance from "utils/AxiosInstance";
import { DeleteOutlined } from "@ant-design/icons";
import AddRoomService from "./AddRoomService";

const RoomService = ({
  roomService,
  booking_id,
  onUpdateRoomService,
  setGeneralInfo,
  bookingStatus }) => {
  const [service, setService] = useState();
  const [addRoomService, setAddRoomService] = useState(false);
  // console.log("roomService---------------roomService------now1222>", service);
  const handleClick = () => {
    setAddRoomService(true);
  };
  useEffect(() => {
    const infos = roomService?.map((item) => {
      return {
        ...item,
        service_name: item.service_name,
        service_type: item.service_type,
        sub_total: `$${item.sub_total}`,
        price: `$${item.price}`,
        quantity: item.quantity,
        discount_percentage: item.discount_percentage,
        discount_percentage_amount: `$${item.discount_percentage_amount}`,
        tax_percentage: item.tax_percentage,
        tax_percentage_amount: `$${item.tax_percentage_amount}`,
        total: `$${item.total}`,
      };
    });
    setService(infos);
  }, [roomService]);
  const handleDelete = async (record) => {
    try {
      // Send the API request to delete the row
      const response = await Axiosinstance.delete(`booking/deleteserviceitem/${record}`);
      if (response.data.status === 1) {
        // Update the roomService state by removing the deleted row
        const updatedService = service.filter((item) => item.id !== record);
        setService(updatedService);
        message.success(response.data.msg);
        Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
          .then((res) => res.data)
          .then((data) => {
            setGeneralInfo(data?.data);
          });
      } else if (response.data.status === 0) {
        message.error(response.data.msg);
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      console.error("Error while deleting row:", error);
      message.error("Failed to delete row");
    }
  };
  const columns = [
    {
      title: <span className="table__title"> service name</span>,
      dataIndex: "service_name",
      key: "service_name",
    },
    {
      title: <span className="table__title"> service type</span>,
      dataIndex: "service_type",
      key: "service_type",
    },

    {
      title: <span className="table__title"> QUANTITY</span>,
      dataIndex: "quantity",
      align: "right",
      key: "quantity",
    },
    {
      title: <span className="table__title"> price</span>,
      dataIndex: "price",
      align: "right",
      key: "price",
    },
    {
      title: <span className="table__title"> subtotal</span>,
      dataIndex: "sub_total",
      align: "right",
      key: "sub_total",
    },
    {
      title: <span className="table__title"> discount</span>,
      dataIndex: "discount_percentage_amount",
      align: "right",
      key: "discount_percentage_amount",
      render: (_, record) => (
        <span>
          {record.discount_percentage_amount} ({record.discount_percentage} %)
        </span>
      ),
    },
    {
      title: <span className="table__title"> tax</span>,
      dataIndex: "discount_percentage_amount",
      align: "right",
      key: "discount_percentage_amount",
      render: (_, record) => (
        <span>
          {record.tax_percentage_amount} ({record.tax_percentage} %)
        </span>
      ),
    },
    {
      title: <span className="table__title"> total</span>,
      dataIndex: "total",
      align: "right",
      key: "total",
    },
    {
      title: <span className="table__title"> actions</span>,
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        return (
          bookingStatus !== 3 ? (
            <Popconfirm
              title="Are you sure you want to delete this row?"
              onConfirm={() => handleDelete(_)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          )
            :
            <Button type="link" danger disabled>
              <DeleteOutlined />
            </Button>
        );
      },
    },

  ];
  return (
    <>
      <div className="newDiv">
        <AddRoomService
          addRoomService={addRoomService}
          setAddRoomService={setAddRoomService}
          booking_id={booking_id}
          onUpdateRoomService={onUpdateRoomService}
          setGeneralInfo={setGeneralInfo}
        />
      </div>
      <Card
        className="card__paddingless card__border__radiusless card__box__shadow card__custom__bg"
        title="ROOM SERVICE"
        extra={
          bookingStatus !== 3 ? (
            <Button
              type="primary"
              onClick={handleClick}
            >
              ADD ROOM SERVICE
            </Button>
          )
            :
            <Button
              type="primary"
              onClick={handleClick}
              disabled
            >
              ADD ROOM SERVICE
            </Button>
        }
      >
        <Table
          pagination={false}
          columns={columns}
          dataSource={service}
          bordered
          scroll={{
            y: 240,
          }}
        />
      </Card>
    </>
  );
};

export default RoomService;
