const { createSlice } = require("@reduxjs/toolkit");

const dataSlice = createSlice({
  name: "jsonData",
  initialState: {
    data: null,
  },
  reducers: {
    jsonData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { jsonData } = dataSlice.actions;

export default dataSlice.reducer;
