/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-sequences */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unsafe-optional-chaining */
// import { Card, Row, Col, Image, message, Divider, Typography, PageHeader } from "antd";
import { Image, Divider, Card, Tag, Space, message, Row, Col, Spin, Empty } from "antd";
import { EnvironmentOutlined, PhoneOutlined, MailOutlined, GlobalOutlined, HomeOutlined } from "@ant-design/icons";
import dataSlice, { jsonData } from "features/data/dataSlice";
import { useEffect, useState } from "react";
import ListIcon from "shared/ListIcon";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import Banner from "assets/images/banner_hotel.jpg";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "utils/AxiosInstance";
import DATA from "./data.json";

const { Meta } = Card;

const ProductDetails = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [roomtype, setRoomtype] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  const tagColors = ["#ff0000", "#00ff00", "#0000ff"];

  useEffect(() => {
    Axios.get(DATA?.urls?.hotelwiseroom + id)
      .then((response) => {
        console.log("type", response.data.data);
        setRoomtype(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    Axios.get(DATA?.urls?.policies)
      .then((response) => {
        console.log("policies", response.data.data);
        setPolicies(response.data.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    Axios.get(DATA?.urls?.amenities)
      .then((response) => {
        console.log("amenities", response.data.data);
        setAmenities(response.data.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const detailsRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.details + id, values);
    console.log("responsedetails", values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? detailsRecord : detailsRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        queryClient.refetchQueries([DATA.urls.list]);
        if (!id) {
          queryClient.refetchQueries([DATA?.urls?.details + id]);
          navigate(DATA.urls.list);
        }
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );
  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        logo: data?.logo !== null ? data?.logo : [],
        cover_photo: data?.cover_photo !== null ? data?.cover_photo : [],
      };
    } else {
      // form.resetFields();
    }
  }, [id, data]);
  console.log("datass", data?.cover_photo[0].url);

  return (
    <Card
      title={DATA?.detailstitle}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Spin spinning={isLoading || DATA == null}>
        {data &&
          <div>
            {/* <div style={{ height: "500px", width: "100%", overflow: "hidden", padding: "15px", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}> */}
            <Image
              preview={false}
              style={{ width: "100vw", height: "450px", objectFit: "cover", borderRadius: "10px", alignmentBaseline: "center" }}
              src={data?.cover_photo[0].url}
            />
            {/* </div> */}
            <Divider orientation="left" orientationMargin={0} plain style={{ fontWeight: "bold", fontSize: 24 }}>
              {data?.hotel_legal_name}
            </Divider>

            <Card bordered={false} style={{ width: "100%", marginBottom: "20px", boxShadow: "none" }}>
              <Row>
                <Col span={8}>
                  <Meta
                    avatar={<EnvironmentOutlined />}
                    title="Location"
                    description={`${data?.address}, ${data?.city}, ${data?.country}`}
                    style={{ marginTop: "10px" }}
                  />
                </Col>
                <Col span={8}> <Meta
                  avatar={<PhoneOutlined />}
                  title="Phone Number"
                  description={data?.phone}
                  style={{ marginTop: "10px" }}
                />
                </Col>
                <Col span={8}> <Meta
                  avatar={<MailOutlined />}
                  title="Email"
                  description={data?.email}
                  style={{ marginTop: "20px" }}
                />
                </Col>
                <Col span={8}> <Meta
                  avatar={<GlobalOutlined />}
                  title="Website"
                  description={data?.website}
                  style={{ marginTop: "20px" }}
                />
                </Col>
                <Col span={8}>
                  <Meta
                    avatar={<HomeOutlined />}
                    title="Number of Rooms"
                    description={data?.number_of_rooms}
                    style={{ marginTop: "10px" }}
                  />
                </Col>
              </Row>
            </Card>

            <Divider orientation="left" orientationMargin={0} style={{ fontWeight: "bold", fontSize: 24 }}>Room Types</Divider>

            <Card>
              <Row>
                <Col span={24}>
                  {roomtype?.length > 0 ? (
                    <>

                      <Space size={[16, 16]} wrap>
                        {roomtype?.map((item) => (
                          <Tag style={{ fontSize: 14 }} color="blue">{item.name}</Tag>
                        ))}
                      </Space>

                    </>
                  ) : (
                    <Empty />
                  )}
                </Col>
              </Row>
            </Card>

            <Divider orientation="left" orientationMargin={0} style={{ fontWeight: "bold", fontSize: 24 }}>Policies</Divider>

            <Card>
              <Row>
                <Col span={24}>
                  {policies?.length > 0 ? (
                    <>

                      <Space size={[16, 16]} wrap>
                        {policies?.map((item) => (<Tag style={{ fontSize: 14 }} color="blue">{item.name}</Tag>
                        ))}
                      </Space>

                    </>
                  ) : (
                    <Empty />
                  )}
                </Col>
              </Row>
            </Card>

            <Divider orientation="left" orientationMargin={0} style={{ fontWeight: "bold", fontSize: 24 }}>Amenities</Divider>

            <Card>
              <Row>
                <Col span={24}>
                  {amenities?.length > 0 ? (
                    <>

                      <Space size={[16, 16]} wrap>
                        {amenities?.map((item) => (<Tag style={{ fontSize: 14 }} color="blue">{item.name}</Tag>
                        ))}
                      </Space>

                    </>
                  ) : (
                    <Empty />
                  )}
                </Col>
              </Row>
            </Card>
          </div>
      }
      </Spin>
    </Card>
  );
};

export default ProductDetails;
