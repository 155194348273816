/* eslint-disable max-len */
import React from "react";
import { LockOutlined, UserOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { useLoginMutation } from "features/auth/authApi";
import bagImage from "../assets/images/signup.jpg";
import Logo from "../assets/images/logo.svg";
import Quote from "../assets/images/quote.png";

const Signup = () => {
  const [login, { data, isLoading, isSuccess, isError }] = useLoginMutation();

  const onFinish = (values) => {
    console.log(values);
  };
  return (
    <main className="main__loginpage">
      <section className="home__login">
        <img src={bagImage} alt="home shape" className="login-shape-image" />
        <div className="login__container container">

          <div className="login__info">
            <div className="login__leftside__info">
              <div className="login__left__title">
                Our high-quality services are trusted by hotels worldwide.
              </div>
              {/* <div className="login__left__des">
                A quick interactive session with our expert, to help you find
                out how to:
              </div> */}
              <div className="login__left__point">
                <ul>
                  <li>Connect more guests and revenue streams with our software and monitor the entire system efficiently from the front desk.</li>
                  <li>
                    Surveil your room rates during peak seasons or high-demand periods and suggest offers based on previous customer experience.
                  </li>
                  <li>Manage restaurants and bars for the availability of items and stock management along with room services and management from the front desk.</li>
                </ul>
              </div>
              <div className="owner__quote__area">
                <div className="ownwr__img">
                  <img src={Quote} alt="" />
                </div>
                <div className="owner__info">
                  <div className="owner__quote">
                    <i className="icon icon-quote-left" />
                    <span>
                      For ease-of-use, cost effectiveness and great customer
                      support, Hotelogix is a company that will help grow your
                      business
                    </span>
                    <i className="icon icon-quote-right" />
                  </div>
                  <div className="owner__name">Dewald Steinmann</div>
                  <div className="owner__address"> Mt. Jefferson View, USA</div>
                </div>
              </div>
            </div>
          </div>

          <div className="login__form__area">
            <div className=" login___form">
              <img src={Logo} alt="brand logo" className="avatar" />
              <h3>Create Account</h3>
              <p>Please enter your details</p>

              <Form
                className="login-form cis__form"
                onFinish={onFinish}
                name="basic"
                style={{ maxWidth: 400 }}
                layout="vertical"
              >
                <Form.Item
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "The input is not Empty!",
                    },
                  ]}
                  name="user_name"
                  autoFocus={false}
                  className="form__item__element"
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Your Name"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "The input is not valid E-mail!",
                    },
                  ]}
                  name="useremail"
                  autoFocus={false}
                  className="form__item__element"
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="user_password"
                  autoFocus={false}
                  className="form__item__element"
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>I agree to the Terms & Conditions </Checkbox>
                  </Form.Item>

                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    size="large"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Sign up
                  </Button>

                  <p className="login-form-signin">
                    Already have an account ?
                    <Link to="/sign-in"> Sign in</Link>
                  </p>
                </Form.Item>
              </Form>
            </div>
          </div>

        </div>
      </section>
    </main>
  );
};

export default Signup;
