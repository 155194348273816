import { useState } from "react";
import { useQuery } from "react-query";
import { Select, Input, Button } from "antd";
import AxiosInstance from "utils/AxiosInstance";

const { Option } = Select;

const SelectAddInput = ({
  error_msg = "",
  name = "",
  label = "",
  initialValue = null,
  placeholder = "",
  required = false,
  path = "",
  showSearch = true,
  addOn = "",
  mode = null,
  ...arg
}) => {
  const { isLoading, isError, data } = useQuery([path], () =>
    AxiosInstance.get(path).then((res) => res.data.data)
  );

  const [inputVisible, setInputVisible] = useState(false);
  const [newItem, setNewItem] = useState("");
  const options = data || [];

  const handleSelectChange = (value) => {
    if (value === "addNew") {
      setInputVisible(true);
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setNewItem(value);
  };

  const handleInputConfirm = async () => {
    try {
      const trimmedValue = newItem.trim();
      if (trimmedValue === "") return;

      // Call your API endpoint to add the new item
      await AxiosInstance.post(path, { item: trimmedValue });

      setNewItem("");
      setInputVisible(false);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <>
      <Select
        onChange={handleSelectChange}
        value={initialValue}
        showSearch={showSearch}
        size="large"
        mode={mode}
        placeholder={placeholder}
        {...arg}
      >
        {options.map((option) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
        {inputVisible && (
          <Option key="addNew" value="addNew">
            Add New Item
          </Option>
        )}
      </Select>
      {inputVisible && (
        <>
          <Input value={newItem} onChange={handleInputChange} />
          <Button onClick={handleInputConfirm}>Add</Button>
        </>
      )}
    </>
  );
};

export default SelectAddInput;
