import { Form, Input } from "antd";

const InputUrl = ({ name, label, required }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message: "Please input your URL!",
        },
        {
          type: "url",
          message: "The input is not valid URL",
        },
      ]}
    >
      <Input type="url" placeholder="Ex: www.demo.com" size="large" />
    </Form.Item>
  );
};

export default InputUrl;
