import { DatePicker, Form } from "antd";
import { dateFormat } from "utils/CISUI";

const InputDate = ({
  error_msg = "",
  required = true,
  placeholder = "",
  name = "",
  label = "",
  ...arg
}) => {
  return (
    <Form.Item
      rules={[{ required, message: error_msg }]}
      name={name}
      label={label}
    >
      <DatePicker
        format={dateFormat}
        size="large"
        placeholder={placeholder}
        {...arg}
      />
    </Form.Item>
  );
};

export default InputDate;
