/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unsafe-optional-chaining */
import { Card, Form, message, Row, Button, Col, Input, TreeSelect, Select, Popconfirm, InputNumber } from "antd";
import Loader from "components/Loader";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import { PlusOutlined, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import GetModuleFields from "utils/GetModuleFields";
import GetDataFromAPI from "utils/GetDataFromAPI";
// import FileAttachment from "components/Input/InputExceptions/FileAttachment2";
import DATA from "./data.json";

const NewVendorBill = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [remainProduct, setRemainProduct] = useState([]);

  const [state, setState] = useState([
    {
      account_id: undefined,
      item_id: undefined,
      quantity: undefined,
      amount: undefined,
      note: "",
      attachment: [],
    },
  ]);
  const [subTotal, setSubTotal] = useState(0.0);

  const handleAddRows = () => {
    const items = {
      account_id: undefined,
      item_id: undefined,
      quantity: undefined,
      amount: undefined,
      unit_price: undefined,
      note: "",
      attachment: [],
    };
    const errors = {
      account_id: "",
      item_id: "",
      quantity: "",
      unit_price: "",
      amount: "",
    };
    setState([...state, items]);
  };

  const handleChange = (e, id, field_name) => {
    const list = [...state];
    if (field_name === "note") {
      const { name, value } = e.target;
      list[id][name] = value;
    } else if (field_name === "attachment") {
      if (e.file.status === undefined) {
        if (e.file.status !== "removed") {
          const fileData = [];
          const { file } = e;
          const reader = new FileReader();
          reader.onload = (ene) => {
            list[id][field_name] = [
              ...list[id].attachment,
              ene.target.result,
            ];
            fileData.push(ene.target.result);
          };
          reader.readAsDataURL(file);
          console.log(fileData);
        }
      }
    } else {
      list[id][field_name] = e;
    }

    let sub_total = 0.0;
    // eslint-disable-next-line no-return-assign
    list.map((item, index) => (sub_total += item.amount));
    setSubTotal(sub_total);
    setState(list);
  };

  const handleRowDelete = (id) => {
    if (state.length === 1) {
      message.warning("You can not delete this item");
    } else {
      const newArr = state.filter((item, index) => index !== id);
      let sub_total = 0.0;
      // eslint-disable-next-line no-return-assign
      newArr.map((item, index) => (sub_total += item.amount));
      setSubTotal(sub_total);
      setState(newArr);
    }
  };

  const accounts = GetDataFromAPI("accounts/chartofacclist");
  const AccData = [];
  const products = GetDataFromAPI("purchase/purchaseitemlist?is_dep=1");

  useEffect(() => {
    if (products !== undefined) {
      // setRemainProduct([...products])
      const newProductAry = [...products];

      if (state[0]?.item_id !== "") {
        for (let i = 0; i < state.length; i++) {
          if (state[i].item_id !== "") {
            for (let j = 0; j < newProductAry.length; j++) {
              if (newProductAry[j].id === state[i].item_id) {
                newProductAry.splice(j, 1);
              }
            }
          }
        }
      }

      setRemainProduct([...newProductAry]);
    }
  }, [products, state]);

  accounts?.map((acc, index) => {
    AccData.push({
      value: acc.id,
      title: acc.name,
      children: acc?.child?.map((child, ind) => {
        return {
          value: child.id,
          title: child.name,
        };
      }),
    });
  });

  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    // for (let i = 0; i < state.length; i++) {
    //   if (state[i].account_id === undefined) {
    //     message.error("Account is required");
    //     return;
    //   }
    //   if (state[i].item_id === undefined) {
    //     message.error("Item is required.");
    //     return;
    //   }
    //   if (state[i].quantity === undefined) {
    //     message.error("Quantity is required.");
    //     return;
    //   }
    //   if (state[i].amount === undefined) {
    //     message.error("Amount is required.");
    //     return;
    //   }
    // }

    const newData = (values);
    const data = {
      ...newData,
      date: newData.date.format("YYYY-MM-DD"),
      due_date: newData.due_date.format("YYYY-MM-DD"),
      bill_items: state,
    };
    console.log(data);
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
      };
      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  if (DATA === null) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <GetModuleFields data={DATA?.input_fields} />

          <Col lg={24} span={24}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <p style={{ fontWeight: "450", fontSize: "16px" }}>
                Bill Items
              </p>
              <hr style={{ flexGrow: "1", margin: "0 20px" }} />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ margin: 0 }}
                onClick={() => handleAddRows()}
              >
                Add More
              </Button>
            </div>
          </Col>

          <Col lg={24} span={24}>
            <table className="tranx_table">
              <thead>
                <tr>
                  <th style={{ width: "2%" }} />
                  <th style={{ width: "16%" }}>Accounts</th>
                  <th style={{ width: "16%" }}>Item</th>
                  <th style={{ width: "8%", textAlign: "right" }}>
                    Quantity
                  </th>
                  <th style={{ width: "8%", textAlign: "right" }}>Unit Price</th>
                  <th style={{ width: "8%", textAlign: "right" }}>Amount</th>
                  <th style={{ width: "14%" }}>Note</th>
                  <th style={{ width: "8%" }}>Attachment</th>
                </tr>
              </thead>
              <tbody>
                {state?.map((item, idx) => (
                  <tr id="addr0" key={idx}>
                    <td style={{ width: "30px" }}>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => handleRowDelete(idx)}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </td>
                    <td>
                      <TreeSelect
                        showSearch
                        style={{ width: "100%" }}
                        value={item.account_id}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder="Select account"
                        allowClear
                        treeDefaultExpandAll
                        onChange={(e) => handleChange(e, idx, "account_id")}
                        treeData={AccData}
                      />
                    </td>
                    <td>
                      <Select
                        showSearch
                        placeholder="Select an item"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.child
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                          }
                        allowClear
                        onChange={(e) => handleChange(e, idx, "item_id")}
                        value={item.item_id}
                        loading={!(products?.length > 0)}
                      >
                        {remainProduct?.map((item, index) => (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                      {/* <Select
                        name={`item_id_${idx}`}
                        // label="Running Booking"
                        path="purchase/purchaseitemlist?is_dep=1"
                        placeholder="Select an option"
                      /> */}
                    </td>
                    <td>
                      <InputNumber
                        name="quantity"
                        className="gx-w-100 tranx_input_number"
                        value={item.quantity}
                        placeholder="Quantity"
                        onChange={(e) => handleChange(e, idx, "quantity")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="unit_price"
                        className="gx-w-100 tranx_input_number"
                        value={item.unit_price}
                        placeholder="Unit price"
                        onChange={(e) => handleChange(e, idx, "unit_price")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="amount"
                        className="gx-w-100 tranx_input_number"
                        value={item.quantity * item.unit_price}
                        placeholder="Amount"
                        onChange={(e) => handleChange(e, idx, "amount")}
                      />
                    </td>
                    <td>
                      <Input.TextArea
                        name="note"
                        rows={1}
                        className="gx-w-100"
                        value={item.note}
                        placeholder="Enter note"
                        onChange={(e) => handleChange(e, idx, "note")}
                      />
                    </td>
                    <td>
                      {/* <FileAttachment
                        name={`attachment_${idx}`}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{ margin: "0" }}
                        >
                          Select File
                        </Button>
                      </FileAttachment> */}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={5}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Total
                  </td>
                  <td style={{ textAlign: "right", fontWeight: "bold" }}>
                    {(subTotal)}
                  </td>
                  <td colSpan={2} />
                </tr>
              </tfoot>
            </table>
          </Col>

          <Suspense fallback={<Loader />}>
            <FormSubmitBtns isLoading={isLoading} />
          </Suspense>
        </Row>
      </Form>
    </Card>
  );
};

export default NewVendorBill;
