/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import GetModuleFields from "utils/GetModuleFields";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Select,
  TreeSelect,
  Upload,
  message
} from "antd";
import React, { useEffect, useState, Suspense } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListPrintBtns from "shared/ListPrintBtns";
import Error404 from "containers/Error404";
import Axiosinstance from "utils/AxiosInstance";
import { addCompanyIfNotExist } from "utils/CISUI";
import GetDataFromAPI from "utils/GetDataFromAPI";
import Loader from "../../../components/Loader";
import DATA from "./data.json";

const NewTransaction = () => {
  const [input, setInput] = useState(0);
  const [form] = Form.useForm();
  const history = useNavigate();
  const [subTotal, setSubTotal] = useState(0.0);
  const [checkClearHide, setCheckClearHide] = useState(true);
  const [beneficiaryAccounts, setBeneficiaryAccounts] = useState([]);
  const { id } = useParams();

  const [state, setState] = useState([
    {
      account_id: undefined,
      beneficiary_type: undefined,
      beneficiary_account: undefined,
      item_description: "",
      amount: 0.0,
      attachment: [],
    },
  ]);

  const handleAddRows = () => {
    const transaction_items = {
      account_id: undefined,
      beneficiary_type: undefined,
      beneficiary_account: undefined,
      item_description: "",
      amount: 0.0,
      attachment: [],
    };
    setState([...state, transaction_items]);
  };

  const handleChange = (e, id, field_name) => {
    const list = [...state];

    if (field_name === "item_description") {
      const { name, value } = e.target;
      list[id][name] = value;
    } else if (field_name === "beneficiary_type") {
      list[id][field_name] = e;
      Axiosinstance
        .get(`accounts/beneficiaries/${e}`)
        .then((res) => {
          if (res.data.status === 1) {
            setBeneficiaryAccounts(res.data.data);
          } else {
            message.error(res.data.msg);
          }
        });
    } else if (field_name === "attachment") {
      if (e.file.status === undefined) {
        if (e.file.status !== "removed") {
          const fileData = [];
          const { file } = e;
          const reader = new FileReader();
          reader.onload = (ene) => {
            list[id][field_name] = [
              ...list[id].attachment,
              ene.target.result,
            ];
            fileData.push(ene.target.result);
          };
          reader.readAsDataURL(file);
        }
      }
    } else {
      list[id][field_name] = e;
    }

    let total = 0;
    list.map((item, index) => (total += item.amount));
    setSubTotal(total);
    setState(list);
  };

  const handleRowDelete = (id) => {
    if (state.length === 1) {
      message.warning("You can not delete this item");
    } else {
      const newArr = state.filter((item, index) => index !== id);
      setState(newArr);
    }
  };

  const accounts = GetDataFromAPI("accounts/chartofacclist?is_dep=1");
  const cashAccounts = GetDataFromAPI("accounts/chartofacclist?type_id=14");
  const bankAccounts = GetDataFromAPI("accounts/chartofacclist?type_id=2");
  const beneficiary = GetDataFromAPI("accounts/beneficiaries");
  const AccData = [];
  const CashAccData = [];

  accounts?.map((acc, index) => {
    AccData.push({
      value: acc.id,
      title: acc.name,
      children: acc?.child?.map((child, ind) => {
        return {
          value: child.id,
          title: child.name,
        };
      }),
    });
  });
  cashAccounts?.map((acc, index) => {
    CashAccData.push({
      value: acc.id,
      title: acc.name,
      children: acc?.child?.map((child, ind) => {
        return {
          value: child.id,
          title: child.name,
        };
      }),
    });
  });

  const HandelPaymentMethod = (value) => {
    setInput(value);
    if (value === 2) {
      setCheckClearHide(false);
    } else {
      setCheckClearHide(true);
    }
  };

  const addType = async (values) => {
    const { data: response } = await Axiosinstance.post(DATA.urls.add, values);
    return response;
  };

  const { mutate, isLoading: isLoader } = useMutation(addType, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        history(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    for (let i = 0; i < state.length; i++) {
      if (state[i].account_id === undefined) {
        message.error("Account is required");
        return;
      }
      if (state[i].beneficiary_type === undefined) {
        message.error("Beneficiary source is required.");
        return;
      }
      if (state[i].beneficiary_account === undefined) {
        message.error("Beneficiary account is required.");
        return;
      }
    }

    const newData = addCompanyIfNotExist(values);
    const data = {
      ...newData,
      date: newData.date.format("YYYY-MM-DD"),
      bank_id: values.bank_id === undefined ? 0 : newData.bank_id,
      transaction_items: state,
      status: 1
    };
    console.log(data);
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const {
    data: payData,
    isError: isPayError,
    isLoading: isPayLoading,
  } = useQuery(
    [`${DATA.urls.details}_${id}`],
    () =>
      Axiosinstance.get(DATA.urls.details + id).then((res) => res.data.data),
    { enabled: !!id }
  );

  useEffect(() => {
    if (id !== undefined) {
      form.setFieldsValue({
        ...payData,
      });
    } else {
      form.resetFields();
    }
  }, [id, payData]);

  if (isPayLoading) {
    return <Loader />;
  }
  if (isPayError) {
    return <Error404 />;
  }

  const beforeUpload = (e, idx) => {
    e.preventDefault();
  };
  const deleteImage = (e, idx) => {
    const list = [...state];
    let index = "";
    list[idx].attachment.find((item, i) => {
      if (item.uid === e.uid) {
        index = i;
      }
    });
    list[idx].attachment.splice(index, 1);
    setState(list);
  };

  const HandleTranxType = (e) => {
    Axiosinstance
      .get(`accounts/createtranxno?tranx_type=${e.target.value}`)
      .then((res) => {
        form.setFieldsValue({ tranx_no: res?.data?.tranx_no });
      })
      .catch((error) => console.log(error));
  };

  return (
    <Card
      title="New Transaction"
      extra={<ListPrintBtns url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          {/* <GetModuleFields data={DATA.input_fields} /> */}
          <Col lg={6} md={8} span={24}>
            <Form.Item
              label="Transaction Type"
              name="transaction_type"
              rules={[
                { required: true, message: "Transaction type is required" },
              ]}
              onChange={HandleTranxType}
            >
              <Radio.Group>
                <Radio value={1}>Payment</Radio>
                <Radio value={2}>Receipt</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={6} md={8} span={24}>
            <Form.Item
              label="Date"
              name="date"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col lg={6} md={8} span={24}>
            <Form.Item
              label="Payment Type"
              name="payment_method"
              rules={[{ required: true, message: "Payment type is required" }]}
              initialValue={1}
            >
              <Select
                placeholder="Select a Payment Type"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toString().toLowerCase()) >= 0
                                  }
                allowClear
                onSelect={HandelPaymentMethod}
              >
                <Select.Option value={1}>Cash</Select.Option>
                <Select.Option value={2}>Bank/Virtual Account</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {input === 2 ? (
            <Col lg={6} md={8} span={24}>
              <Form.Item
                label="Bank Account/Cash Account"
                name="bank_id"
                rules={[
                  {
                    required: true,
                    message: "Bank/Cash Account is required",
                  },
                ]}
              >
                <Select
                  placeholder="Select a Account"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toString().toLowerCase()) >= 0
                                      }
                  allowClear
                >
                  {bankAccounts?.length > 0 &&
                                          bankAccounts?.map((item, index) => (
                                            <Select.Option value={item.id}>
                                              {item.account_name}({item.last_4_digit})
                                            </Select.Option>
                                          ))}
                </Select>
              </Form.Item>
            </Col>
          ) : (
            <Col lg={6} md={8} span={24}>
              <Form.Item
                label="Cash Account"
                name="cash_id"
                rules={[
                  { required: true, message: "Cash Account is required" },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Select an account"
                  allowClear
                  treeDefaultExpandAll
                  treeData={CashAccData}
                  loading={!(cashAccounts?.length > 0)}
                />
              </Form.Item>
            </Col>
          )}
          <Col lg={6} md={8} span={24}>
            <Form.Item name="check_no" label="Check No#">
              <Input />
            </Form.Item>
          </Col>
          <Col lg={6} md={8} span={24}>
            <Form.Item name="remarks" label="Remark/Memo">
              <Input.TextArea rows={1} />
            </Form.Item>
          </Col>
          {/* {!checkClearHide && ( */}
          <Col lg={6} md={8} span={24}>
            <Form.Item
              name="is_cleared"
              label="Cheque Cleared?"
              valuePropName="checked"
            >
              <Checkbox>Yes</Checkbox>
            </Form.Item>
          </Col>

          <Col lg={24} span={24}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <p style={{ fontWeight: "450", fontSize: "16px" }}>Items</p>
              <hr style={{ flexGrow: "1", margin: "0 20px" }} />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ margin: 0 }}
                onClick={() => handleAddRows()}
              >
                Add More
              </Button>
            </div>
          </Col>

          <Col lg={24} span={24}>
            <table className="tranx_table">
              <thead>
                <tr>
                  <th style={{ width: "2%" }} />
                  <th style={{ width: "22.5%" }}>Account</th>
                  <th style={{ width: "22.5%" }}>Beneficiary</th>
                  <th style={{ width: "22.5%" }}>Item Description</th>
                  <th style={{ width: "15%", textAlign: "right" }}>
                    Payment
                  </th>
                  <th style={{ width: "10%" }}>Attachment</th>
                </tr>
              </thead>
              <tbody>
                {state?.map((item, idx) => (
                  <tr id="addr0" key={idx}>
                    <td style={{ width: "30px" }}>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => handleRowDelete(idx)}
                      >
                        {/* <Button type="primary" style={{ margin: 0 }} > */}
                        <DeleteOutlined />
                        {/* </Button> */}
                      </Popconfirm>
                    </td>
                    <td>
                      <TreeSelect
                        showSearch
                        style={{ width: "100%" }}
                        value={item.account_id}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder="Select account"
                        allowClear
                        treeDefaultExpandAll
                        onChange={(e) => handleChange(e, idx, "account_id")}
                        treeData={AccData}
                        loading={!(AccData?.length > 0)}
                      />
                    </td>
                    <td>
                      <Select
                        placeholder="Select a beneficiary"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                                                }
                        allowClear
                        onChange={(e) =>
                          handleChange(e, idx, "beneficiary_type")
                                                }
                        value={item.beneficiary_type}
                      >
                        {beneficiary?.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                      {
                        state[idx].beneficiary_type !== 5 ?
                          <Select
                            placeholder="Select a beneficiary account"
                            style={{ width: "100%", marginTop: "10px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toString().toLowerCase()) >= 0
                                                        }
                            allowClear
                            onChange={(e) =>
                              handleChange(e, idx, "beneficiary_account")
                                                        }
                            value={item.beneficiary_account}
                          >
                            {beneficiaryAccounts?.map((item, index) => (
                              <Select.Option key={index} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                          :
                          <TreeSelect
                            showSearch
                            style={{ width: "100%", marginTop: "10px" }}
                            value={item.beneficiary_account}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder="Select account"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(e) => handleChange(e, idx, "beneficiary_account")}
                            treeData={AccData}
                            loading={!(AccData?.length > 0)}
                          />
                                            }
                    </td>
                    <td>
                      <Input.TextArea
                        name="item_description"
                        rows={3}
                        value={item.item_description}
                        placeholder="Enter item description"
                        onChange={(e) => handleChange(e, idx, "item_description")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        className="gx-w-100 tranx_input_number"
                        value={item.amount}
                        placeholder="Enter amount"
                        onChange={(e) => handleChange(e, idx, "amount")}
                      />
                    </td>
                    <td>
                      <Upload
                        beforeUpload={(e) => beforeUpload(e, idx)}
                        onRemove={(e) => deleteImage(e, idx)}
                        onChange={(e) => handleChange(e, idx, "attachment")}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          style={{ margin: "0" }}
                        >
                          Select File
                        </Button>
                      </Upload>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={4}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Total
                  </td>
                  <td style={{ textAlign: "right", fontWeight: "bold" }}>
                    {(subTotal)}
                  </td>
                  <td colSpan={2} />
                </tr>
              </tfoot>
            </table>
          </Col>

          <Col span={24}>
            <FormSubmitBtns form={form} isLoading={isLoader} />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default NewTransaction;
