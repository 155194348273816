import { Form, InputNumber } from "antd";
import { useState } from "react";

const NumberInput = ({
  name,
  label,
  placeholder = "",
  required,
  disabled = false,
  min = 0,
  ...arg
}) => {
  const [cleanedValue, setCleanedValue] = useState("");

  const handleInputChange = (value) => {
    const cleanedInput = value.toString().replace(/^0+/, "");
    setCleanedValue(cleanedInput);
  };
  return (
    <Form.Item
      rules={[
        {
          required,
          message: `Please input your ${label}!`,
        },
        {
          type: "number",
          message: "The input is not valid number",
          min: { min },
        },
      ]}
      name={name}
      label={label}
      {...arg}
    >
      <InputNumber
        placeholder={placeholder}
        type="number"
        size="large"
        min={min}
        disabled={disabled}
        onChange={handleInputChange}
        value={cleanedValue}
      />
    </Form.Item>
  );
};

export default NumberInput;
