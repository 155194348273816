import { Button, Col, Form, Row, message } from "antd";
import InputGroup from "components/Input/InputGroup";
import SelectRequest from "components/Input/SelectRequest";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Axiosinstance from "utils/AxiosInstance";

const AddBooking = () => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [selectedBooking, setSelectedBooking] = useState(null);

  const checkIn = async (values) => {
    const { data: response } = await Axiosinstance.post(
      "frontdesk/checkedin",
      values
    );
    return response;
  };

  const { mutate, isLoading } = useMutation(checkIn, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        // queryClient.refetchQueries([DATA.urls.list]);
        form.resetFields();
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    console.log("🚀 ~ file: AddBooking.js:17 ~ onFinish ~ values", values);
    const data = {
      ...values,
      booking_id: selectedBooking.booking_id,
      // guest_id:
    };
    mutate(data);
  };
  const onFieldsChange = (changedFields) => {
    const currentBookings = queryClient.getQueryData(
      "frontdesk/currentbookings"
    );

    if (changedFields[0].name[0] === "guest_id") {
      const currentBooking = currentBookings.find(
        (item) => item.id === changedFields[0].value
      );
      setSelectedBooking(currentBooking);

      Axiosinstance.get(
        `frontdesk/bookingidwithguest/${currentBooking.booking_id}`
      ).then((res) => {
        form.setFieldsValue({
          name: res.data.data.name,
          contact_no: res.data.data.contact_no,
          address: res.data.data.address,
          nid_passport: res.data.data.nid,
        });
      });
    }
  };
  return (
    <Form
      layout="vertical"
      className="cis__form"
      // style={{ marginTop: "24px" }}
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      form={form}
    >
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <SelectRequest
            name="guest_id"
            label="Running Booking"
            path="frontdesk/currentbookings"
            placeholder="Select Running Booking"
          />
        </Col>
        <Col span={24}>
          <InputGroup name="name" label="Name" placeholder="Guest Name" />
        </Col>
        <Col span={24}>
          <InputGroup
            name="contact_no"
            label="Contact No"
            placeholder="Contact Phone"
          />
        </Col>
        <Col span={24}>
          <InputGroup name="address" label="Address" placeholder="Address" />
        </Col>
        <Col span={24}>
          <InputGroup
            name="nid_passport"
            label="NID/Passport"
            placeholder="NID/Passport"
          />
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            loading={isLoading}
          >
            Checked In
          </Button>
        </Col>
        <Col span={12}>
          <Button type="primary" block size="large">
            Print Bill
          </Button>
        </Col>
        <Col span={12}>
          <Button type="primary" block size="large">
            Explore Voucher
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddBooking;
