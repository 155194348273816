import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Form,
  Space,
  Modal,
  Col,
  Row,
  Select,
  message,
  AutoComplete,
} from "antd";
import {
  UserOutlined,
  UserAddOutlined,
  CloseCircleOutlined,
  UndoOutlined,
  CheckCircleFilled,
  CaretLeftOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { customerList, selectedCustomer } from "features/pos/posSlice";
import CountrySelect from "components/Input/CountrySelect";

const { Option } = Select;

const { Search } = Input;

const PosAddSearchCustomer = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [customerSearchValue, setCustomerSearchValue] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { customers, activeCustomer } = useSelector((state) => state.posData);

  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const onChange = (data) => {
    setCustomerSearchValue(data);
  };

  const onSelectCustomer = (data, fullData) => {
    dispatch(selectedCustomer(fullData));
  };

  useEffect(() => {
    if (activeCustomer) {
      setCustomerSearchValue(activeCustomer.value);
    } else {
      setCustomerSearchValue(activeCustomer);
    }
  }, [activeCustomer]);

  const { data, isLoading: customerLoading } = useQuery(
    ["/guests/guestlist_1"],
    () =>
      Axiosinstance.get("/guests/restaurantguests?is_dep=1").then((res) => {
        const allCustomers = res.data.data.map((item) => {
          return {
            ...item,
            value: `${item.name} (${item.phone})`,
            label: `${item.name} (${item.phone})`,
          };
        });
        dispatch(customerList(allCustomers));
        return allCustomers;
      })
  );

  const onCustomerSearch = (searchText) => {
    if (searchText) {
      const newIncludesAry = [...data]?.filter((item) =>
        item?.label?.toLowerCase().includes(searchText.toLowerCase())
      );
      dispatch(customerList(newIncludesAry));
    } else {
      console.log("aa1", data);
      dispatch(customerList(data));
    }
  };

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(
      "/guests/addnewguest",
      values
    );
    return response;
  };

  const { mutate, isLoading } = useMutation(addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        handleCancel();
        queryClient.refetchQueries(["/guests/guestlist_1"]).then(() => {
          const allData = queryClient.getQueryData("/guests/guestlist_1");
          const selectedCust = allData.filter((itm) => itm.id === data.id);
          const newCustData = {
            ...selectedCust[0],
            value: `${selectedCust[0].name} (${selectedCust[0].phone})`,
            label: `${selectedCust[0].name} (${selectedCust[0].phone})`,
          };
          dispatch(selectedCustomer(newCustData));
        });
        // navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const finishHandler = (values) => {
    // console.log("values, values?.logo[0]?.uid", values);
    const data = {
      ...values,
      status: 6
    };

    mutate(data);
  };

  return (
    <>
      <Form className="cart__search__form">
        <Space.Compact
          size="large"
          style={{
            width: "100%",
          }}
        >
          <AutoComplete
            value={customerSearchValue || ""}
            options={customers}
            style={{
              width: "100%",
            }}
            className="pos__add__customer__autocomplete"
            name="customer"
            onSelect={onSelectCustomer}
            onSearch={onCustomerSearch}
            onChange={onChange}
            placeholder="Search customer"
          />

          <Button
            type="primary"
            icon={<UserAddOutlined />}
            size="large"
            style={{
              width: "80px",
              boxShadow: "none",
            }}
            onClick={showModal}
          />
        </Space.Compact>
      </Form>

      <Modal
        open={open}
        title="New Customer"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form layout="vertical" form={form} onFinish={finishHandler}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter full name",
                  },
                ]}
              >
                <Input placeholder="Please enter full name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    message: " enter email ",
                  },
                ]}
              >
                <Input placeholder=" enter  email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: " enter phone number",
                  },
                ]}
              >
                <Input placeholder=" enter phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    // required: true,
                    message: " enter your city",
                  },
                ]}
              >
                <Input placeholder=" enter your city" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="zip_code"
                label="ZipCode"
                rules={[
                  {
                    // required: true,
                    message: "enter your zipCode",
                  },
                ]}
              >
                <Input placeholder="enter your zipCode" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    // required: true,
                    message: "enter your state",
                  },
                ]}
              >
                <Input placeholder=" enter your state" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <CountrySelect
                name="country"
                label="Country"
                placeholder="select country"
                size="default"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    // required: true,
                    message: "enter your address",
                  },
                ]}
              >
                <Input placeholder="enter your address" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="passport"
                label="Passport"
                rules={[
                  {
                    // required: true,
                    message: "enter your passport number",
                  },
                ]}
              >
                <Input placeholder="enter your passport number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="nid"
                label="Nid"
                rules={[
                  {
                    // required: true,
                    message: " enter your nid number",
                  },
                ]}
              >
                <Input placeholder="enter your nid number" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: " select activity",
                  },
                ]}
              >
                <Select placeholder=" select activity">
                  <Option value="1">Active</Option>
                  <Option value="2">Inactive </Option>
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="special_note" label="Special Notes">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="submit__btn">
                <Space>
                  <Button icon={<CaretLeftOutlined />} onClick={handleCancel}>
                    Back
                  </Button>
                  <Button icon={<ReloadOutlined />} htmlType="reset">
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    icon={<CheckCircleFilled />}
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default PosAddSearchCustomer;
