import { TreeSelect, Form } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
import Axios from "utils/AxiosInstance";

// const treeData = [
//   {
//     value: "parent 1",
//     title: "parent 1",
//     children: [
//       {
//         value: "parent 1-0",
//         title: "parent 1-0",
//         children: [
//           {
//             value: "leaf1",
//             title: "leaf1",
//           },
//           {
//             value: "leaf2",
//             title: "leaf2",
//           },
//         ],
//       },
//       {
//         value: "parent 1-1",
//         title: "parent 1-1",
//         children: [
//           {
//             value: "leaf3",
//             title: (
//               <b
//                 style={{
//                   color: "#08c",
//                 }}
//               >
//                 leaf3
//               </b>
//             ),
//           },
//         ],
//       },
//     ],
//   },
// ];
const SelectTree = ({
  error_msg = "",
  name = "",
  label = "",
  initialValue = null,
  placeholder = "",
  required = false,
  path = "",
  showSearch = true,
  addOn = "",
  mode = null,
  ...arg
}) => {
  const { isLoading, isError, data } = useQuery([path], () =>
    Axios.get(path).then((res) => res.data.data)
  );
  const [value, setValue] = useState(initialValue);

  if (isError) {
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: error_msg }]}
        extra={addOn === "" ? "" : addOn}
      >
        <TreeSelect
          showSearch
          size="large"
          style={{
            width: "100%",
          }}
          value={value}
          dropdownStyle={{
            maxHeight: 400,
            overflow: "auto",
          }}
          placeholder="Please select"
          allowClear
          treeDefaultExpandAll
          // treeData={treeData}
        />
      </Form.Item>
    );
  }

  return initialValue === null ? (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      extra={addOn === "" ? "" : addOn}
    >
      <TreeSelect
        showSearch
        size="large"
        style={{
          width: "100%",
        }}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: "auto",
        }}
        placeholder="Please select"
        allowClear
        treeDefaultExpandAll
        treeData={data?.map((item, index) => ({
          key: index,
          value: item.id,
          label: item.name,
        }))}
      />
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      extra={addOn === "" ? "" : addOn}
    >
      <TreeSelect
        showSearch
        size="large"
        style={{
          width: "100%",
        }}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: "auto",
        }}
        placeholder="Please select"
        allowClear
        treeDefaultExpandAll
        treeData={data?.map((item, index) => ({
          key: index,
          value: item.id,
          label: item.name,
        }))}
      />
    </Form.Item>
  );
};

export default SelectTree;
