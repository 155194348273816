import { Card, Col, Row, Table } from "antd";
import { useQuery } from "react-query";

import Loader from "components/Loader";
import ListPrintBtns from "shared/ListPrintBtns";
import ActionMenu from "./ActionMenu";
import Axios from "./AxiosInstance";
import { GetStatusTag } from "./CISUI";

const ListField = ({ DATA }) => {
  const { data, isLoading } = useQuery(
    [DATA?.urls.list],
    Axios.get(DATA?.urls.list).then((res) => res.data.data)
  );

  const columns = [];

  DATA?.list_view?.forEach((item) => {
    if (item.title === "Action") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        align: "center",
        render: (id) => {
          return <ActionMenu recordID={id} urls={DATA?.urls} />;
        },
      });
    } else if (item.key === "status") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        render: (text) => {
          return <GetStatusTag status={text} />;
        },
      });
    } else {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
      });
    }
  });

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Card
          title={DATA?.listtitle ? DATA?.listtitle : "List"}
          extra={<ListPrintBtns URL={DATA} />}
          headStyle={{
            backgroundColor: "#23253c",
            color: "#fcfafc",
            height: "44px",
          }}
          className="cis__table"
        >
          <Table
            size="small"
            className="table"
            scroll={{ x: true }}
            columns={columns}
            dataSource={data}
            bordered
            pagination={{ position: ["bottomCenter"] }}
            loading={{
              spinning: isLoading,
              size: "large",
              indicator: <Loader />,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ListField;
