/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import { CaretLeftOutlined, PrinterOutlined } from "@ant-design/icons";
import { Button, Card, Form, Modal, Space, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Axiosinstance from "utils/AxiosInstance";
import dayjs from "dayjs";
import { Axios } from "axios";
import GlobalLoader from "components/Loader/GlobalLoader";
// import { getCurrencyFormatted, getCurrencyFormatted1 } from "../../../util/CISUI";
import ListIcon from "shared/ListIcon";
import DATA from "./data2.json";
import DATA2 from "./data.json";

const Details = () => {
  const { order_id } = useParams();
  const queryClient = useQueryClient();
  const history = useNavigate();
  const [purchaseData, setPurchaseData] = useState({});
  const [isPDFOpen, setIsPDFOpen] = useState(false);

  const handlePrintClick = () => {
    setIsPDFOpen(true);
  };

  const handleCloseModal = () => {
    setIsPDFOpen(false);
  };

  const {
    data: typeData,
    isError: isTypeError,
    isLoading: isTypeLoading,
  } = useQuery(
    [`${"/restaurant/salesitemdetails/"}_${order_id}`],
    () =>
      Axiosinstance
        .get(`/restaurant/salesitemdetails/${order_id}`)
        .then((res) => res.data.data),
    { enabled: !!order_id }
  );

  useEffect(() => {
    if (typeData !== undefined) {
      setPurchaseData(typeData);
    }
  }, [order_id, typeData]);

  // useEffect(() => {
  //   Axiosinstance.get(purchaseData?.payment_object).then((res) => res.data.data, [purchaseData?.payment_object]);
  //   console.log(purchaseData?.payment_object, "purchaseData?.payment_object");
  // }, [order_id, typeData1]);

  useEffect(() => {
    return () =>
      queryClient.removeQueries([
        `${"/restaurant/salesitemdetails/"}_${order_id}`,
      ]);
  }, []);

  if (isTypeLoading) {
    return <GlobalLoader />;
  }

  console.log(purchaseData?.payment_object, "purchaseData?.payment_object");

  return (
    <Card
      title="Sales Details"
      extra={<ListIcon url={DATA2?.urls?.list} />}
        // extra={<ListPrintBtn URL={DATA?.urls} />}
      className="chms__table"
    >
      <h3>
        Order Number <span> #{purchaseData?.order_id} </span>
      </h3>

      <div className="product__details__container">
        <div className="first__container">
          <div className="vendor__info">
            <p>
              <b>Customer Details</b>
            </p>
            <hr color="#f1f1f1" />
            <div>
              <p>Name</p>
              <p> {purchaseData?.guest_info?.name} </p>
            </div>
            <hr color="#f1f1f1" />
            <div>
              <p>Address</p>
              <p> {purchaseData?.guest_info?.address} </p>
            </div>
            <hr color="#f1f1f1" />
            <div>
              <p>City</p>
              <p> {purchaseData?.guest_info?.city} </p>
            </div>
            <hr color="#f1f1f1" />
            <div>
              <p>State</p>
              <p> {purchaseData?.guest_info?.state} </p>
            </div>
            <hr color="#f1f1f1" />
            <div>
              <p>Zipcode</p>
              <p> {purchaseData?.guest_info?.zip_code} </p>
            </div>
          </div>
        </div>

        <div className="second__container">
          <div className="order__summary">
            <p>
              <b>Order Summary</b>
            </p>
            <hr color="#f1f1f1" />
            <div>
              <p>Date</p>
              <p> {dayjs(purchaseData?.date).format("MMMM Do, YYYY")} </p>
            </div>
            <div>
              <p>Tax Amount</p>
              <p>$ {parseFloat(purchaseData?.total_tax_amount).toFixed(2)}</p>
            </div>
            <div>
              <p>Discount Amount</p>
              <p>$ {parseFloat(purchaseData?.whole_discount_amount).toFixed(2)}</p>
            </div>
            <div>
              <p>Total</p>
              <p>$ {parseFloat(purchaseData?.total).toFixed(2)} </p>
            </div>
            <hr color="#f1f1f1" />
            <div>
              <p>
                <b>Payable Amount</b>
              </p>
              <p>
                <b>
                  $ {parseFloat(purchaseData?.payable_amount).toFixed(2)}{" "}
                </b>
              </p>
            </div>
            {/* <div>
              <p>
                <b>Direct Purchase</b>
              </p>
              <p>
                <b>{typeData?.is_direct_purchase ? "Yes" : "No"} </b>
              </p>
            </div> */}
          </div>

          <table className="payment__object">
            <thead>
              <tr>
                <th>Type</th>
                <th>Transaction ID</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {purchaseData?.payment_object?.map((item, index) => {
                return (
                  <tr>
                    <td style={{ fontWeight: "lighter", textAlign: "center" }}>{item?.type}</td>
                    <td style={{ fontWeight: "lighter", textAlign: "center" }}>{item?.tranx_id}</td>
                    <td style={{ fontWeight: "lighter", textAlign: "center" }}>{item?.total}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <table className="purchase_details-table">
        <tr>
          <th style={{ width: "3%" }}>#</th>
          <th
            style={{ width: "20%", textAlign: "left", paddingLeft: "10px" }}
          >
            Item
          </th>
          <th
            style={{ width: "12%", textAlign: "left", paddingLeft: "10px" }}
          >
            Barcode
          </th>
          <th
            style={{ width: "5%", textAlign: "right", paddingRight: "10px" }}
          >
            Quantity
          </th>
          <th
            style={{ textAlign: "right", paddingRight: "10px", width: "8%" }}
          >
            Unit Price
          </th>
          <th
            style={{ textAlign: "right", paddingRight: "10px", width: "8%" }}
          >
            Total
          </th>
        </tr>
        {purchaseData?.sales_items?.map((item, index) => {
          return (
            <tr key={index}>
              <td>{index + 1}</td>
              <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                {item?.name}
              </td>
              <td style={{ textAlign: "left", paddingLeft: "10px" }}>
                {item?.barcode}
              </td>
              <td
                style={{
                  textAlign: "right",
                  paddingRight: "10px",
                  color: "#2f4d67",
                  fontWeight: "450",
                }}
              >
                {item?.quantity}
              </td>

              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                ${(item?.unit_price)}
              </td>
              <td style={{ textAlign: "right", paddingRight: "10px" }}>
                ${(item?.unit_price * item?.quantity)}
              </td>
            </tr>
          );
        })}
        <tr>
          <td style={{ textAlign: "right", }} colSpan="5">Total</td>
          <td style={{ textAlign: "right" }}>${(purchaseData?.total)}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right", }} colSpan="5">Tax Amount</td>
          <td style={{ textAlign: "right" }}>${(purchaseData?.total_tax_amount)}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right", }} colSpan="5">Discount (%)</td>
          <td style={{ textAlign: "right" }}>${(purchaseData?.whole_discount_amount)} ({(purchaseData?.whole_discount_percentage)}%)</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right", }} colSpan="5">Adjust Amount</td>
          <td style={{ textAlign: "right" }}>${(purchaseData?.adjust_amount)}</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right", fontWeight: 500 }} colSpan="5">Payable Amount</td>
          <td style={{ textAlign: "right" }}>${(purchaseData.payable_amount)}</td>
        </tr>
      </table>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Space>
          <Button type="primary" onClick={() => history(DATA2.urls.list)} icon={<CaretLeftOutlined />}>
            Back
          </Button>
          <Button type="primary" onClick={handlePrintClick} icon={<PrinterOutlined />}>
            Print
          </Button>
        </Space>
        {isPDFOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <span className="close" onClick={handleCloseModal}>
              &times;
            </span>
            <iframe
              src={`https://hotelmgtapi.cisstaging.com/api/restaurant/invoice/salesitemdetails/${order_id}`}
              title="PDF"
              className="pdf-iframe"
            />
          </div>
        </div>
        )}
      </div>
    </Card>
  );
};

export default Details;
