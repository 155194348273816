/* eslint-disable max-len */
import { Button, Input, Modal, Select, Table, message } from "antd";
import { useState } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import Axiosinstance from "utils/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Config from "utils/Config";
// import Invoice from "./Invoice";
const PaymentModal = ({
  isOpenPaymentModal,
  setIsOpenPaymentModal,
  payable_amount,
  discountPercentage,
  serviceCharge,
  adjustAmount,
  resetHandler,
}) => {
  const baseUrl = Config.API_URL;
  const paymentOptions = [
    {
      label: "Cash",
      value: 1,
    },
    {
      label: "Card",
      value: 2,
    },
    {
      label: "Wallet",
      value: 3,
    },
  ];
  const [orderId, setOrderId] = useState("");
  console.log("orderid====", orderId);
  const [paymentRow, setPaymentRow] = useState([
    {
      type: "",
      tranx_id: "",
      total: "",
    },
  ]);

  const [options, setOptions] = useState(paymentOptions);
  const [payingAmount, setPayingAmount] = useState(0);
  const { cartData, activeCustomer } = useSelector((state) => state.posData);
  console.log("cartData===========================>", cartData);
  console.log("activeCustomer===========================>", activeCustomer);
  const handleCancel = () => {
    setIsOpenPaymentModal(false);
  };

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(
      "/restaurant/addnewpos",
      values
    );
    return response;
  };

  const { mutate, isLoading } = useMutation(addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        handleCancel();
        setPaymentRow([
          {
            type: "",
            tranx_id: "",
            total: "",
          },
        ]);
        resetHandler();
        // navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
      setOrderId(data.order_id);
      window.open(`${baseUrl}restaurant/invoice/salesitemdetails/${data.order_id}`, "_blank");
    },
    onError: () => {
      message.error("Server Error");
    },
  });
  const handleOk = () => {
    let totalAmount = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; paymentRow.length > i; i++) {
      if (!paymentRow[i].type) {
        message.error("Payment Method is Required");
        return;
      }
      // if (!paymentRow[i].tranx_id) {
      //   message.error("Transaction ID is Required");
      //   return;
      // }
      if (paymentRow[i].type !== 1 && !paymentRow[i].tranx_id) {
        message.error("Transaction ID is Required");
        return;
      }
      if (!paymentRow[i].total) {
        message.error("Amount is Required");
        return;
      }
      totalAmount += +paymentRow[i].total;
    }
    if (totalAmount > payable_amount) {
      message.error("Total payment amount exceeds the payable amount");
      return;
    }

    if (!activeCustomer) {
      message.error("Customer is required");
      return;
    }
    const finalData = {
      guest_id: activeCustomer.id,
      payment_object: paymentRow,
      sales_items: cartData,
      whole_tax: cartData.reduce((a, c) => a + c.tax_amount, 0),
      whole_discount: discountPercentage,
      service_charge: serviceCharge,
      adjust_amount: adjustAmount,
      payable_amount,
      paid_amount: totalAmount,
      status: 1,
    };
    mutate(finalData);
    setOrderId(activeCustomer.id);
    // setIsInvoiceModal(true);
    setOrderId(activeCustomer.order_id);
  };
  // const addMoreBtnHandler = () => {
  //   setPaymentRow((prevState) => {
  //     return [
  //       ...prevState,
  //       {
  //         type: "",
  //         tranx_id: "",
  //         total: "",
  //       },
  //     ];
  //   });
  // };
  const addMoreBtnHandler = () => {
    const remainingAmount = payable_amount - payingAmount;

    if (remainingAmount <= 0) {
      message.warning("Remaining amount is zero or less. No more payments can be added.");
    } else {
      // Add a new payment row
      setPaymentRow((prevState) => {
        return [
          ...prevState,
          {
            type: "",
            tranx_id: "",
            total: "",
          },
        ];
      });
    }
  };
  const removeHandler = (idx) => {
    if (paymentRow.length > 1) {
      const newPaymentRow = [...paymentRow];
      const deletedMethod = newPaymentRow.splice(idx, 1);

      if (deletedMethod[0].type) {
        const findDeletedOption = paymentOptions.find(
          (item) => item.value === deletedMethod[0].type
        );
        setOptions((prevState) => [...prevState, findDeletedOption]);
      }

      if (deletedMethod[0].total) {
        const totalPayingAmount = paymentRow.reduce(
          // eslint-disable-next-line no-unsafe-optional-chaining
          (prevValue, currValue) => prevValue + +currValue?.total,
          0
        );
        setPayingAmount(totalPayingAmount);
      }
      setPaymentRow(newPaymentRow);
    }
  };

  const handleChange = (e, idx) => {
    const newPaymentRow = [...paymentRow];
    if (e.target.name === "total") {
      newPaymentRow[idx][e.target.name] = +e.target.value;
    } else {
      newPaymentRow[idx][e.target.name] = e.target.value;
    }
    setPaymentRow(newPaymentRow);

    // if (e.target.name === "total") {
    //   const totalPayingAmount = paymentRow.reduce(
    //     // eslint-disable-next-line no-unsafe-optional-chaining
    //     (prevValue, currValue) => prevValue + +currValue?.total,
    //     0
    //   );
    //   setPayingAmount(totalPayingAmount);
    // }
    if (e.target.name === "total") {
      const totalPayingAmount = newPaymentRow.reduce(
        (prevValue, currValue) => prevValue + parseFloat(currValue.total || 0),
        0
      );
      console.log("totalPayingAmount----------", totalPayingAmount);
      console.log("payable_amount----------", payable_amount);
      // Check if totalPayingAmount exceeds payingAmount
      if (totalPayingAmount > payable_amount) {
        // Display an error message or set an error flag here
        // For example, you can set a state variable for error message and display it in your component
        // setErrorMsg("Total amount exceeds the paying amount");
        message.warning("Total amount exceeds the paying amount");
      } else {
        // message.warning("");
      }

      setPayingAmount(totalPayingAmount);
    }

    if (e.target.name === "tranx_id" && (newPaymentRow[idx].type === 2 || newPaymentRow[idx].type === 3)) {
      const maxFourDigits = e.target.value.slice(0, 4);
      newPaymentRow[idx].tranx_id = maxFourDigits;
    }
  };

  const handleSelect = (value, v, idx) => {
    const newPaymentRow = [...paymentRow];
    newPaymentRow[idx].type = value;

    const newPaymentOptions = [...paymentOptions];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; newPaymentRow.length > i; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; newPaymentOptions.length > j; j++) {
        if (newPaymentRow[i].type === newPaymentOptions[j].value) {
          newPaymentOptions.splice(j, 1);
        }
      }
    }
    setOptions(newPaymentOptions);
    setPaymentRow(newPaymentRow);
  };

  const columns = [
    {
      title: "Payment Method",
      dataIndex: "type",
      render: (value, obj, idx) => {
        let methodName;
        if (value === 1) {
          methodName = "Cash";
        } else if (value === 2) {
          methodName = "Card";
        } else if (value === 3) {
          methodName = "Wallet";
        } else {
          methodName = null;
        }
        return (
          <Select
            placeholder="Select a option"
            style={{ width: "100%" }}
            options={options}
            onChange={(changeValue, changeAllValue) =>
              handleSelect(changeValue, changeAllValue, idx)
            }
            value={methodName || null}
          />
        );
      },
    },
    {
      title: "Reference",
      dataIndex: "tranx_id",
      render: (value, v, idx) => {
        let placeholderMsg = "";
        if (v.type == 1) {
          placeholderMsg = "payed by cash";
        } else if (v.type == 2) {
          placeholderMsg = "Last 4 digit";
        } else if (v.type == 3) {
          placeholderMsg = "Transaction ID";
        } else {
          placeholderMsg = "";
        }

        return (
          <Input
            placeholder={placeholderMsg}
            name="tranx_id"
            onChange={(e) => handleChange(e, idx)}
            value={value || null}
            style={{ width: "100%" }}
            disabled={v.type === 1}
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "total",
      render: (value, v, idx) => {
        return (
          <Input
            placeholder="Total"
            name="total"
            onChange={(e) => handleChange(e, idx)}
            type="number"
            value={value || null}
            style={{ width: "100%" }}
          />
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (value, val, idx) => {
        return (
          <Button
            type="danger"
            size="small"
            onClick={() => removeHandler(idx)}
            style={{ margin: "0" }}
          >
            Remove
          </Button>
        );
      },
    },
  ];

  return (
    <Modal
      title={`Payable Amount - $ ${payable_amount.toFixed(2)}`}
      width="800px"
      open={isOpenPaymentModal}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      maskClosable={false}
      okText="Submit"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            margin: options.length ? "0" : "0 0 8px 0",
            display: "flex",
            columnGap: "5px",
            color: "#23253c",
            alignItems: "baseline",
            marginBottom: "10px"
          }}
        >
          {/* Remain: ${(payable_amount - payingAmount).toFixed(2)} */}
          Remain:<span style={{ color: "#23253c", background: "#faad14", fontWeight: "600", padding: "5px 10px", borderRadius: "5px" }}> ${(payable_amount - payingAmount).toFixed(2)}</span>
        </p>
        {options.length ? (
          <Button type="primary" onClick={addMoreBtnHandler}>
            Add More
          </Button>
        ) : null}
      </div>
      <Table
        columns={columns}
        dataSource={paymentRow}
        bordered
        className="payment__method-table"
        pagination={false}
      />
    </Modal>
  );
};

export default PaymentModal;
