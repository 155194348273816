import { Card, Form, Table } from "antd";
// import ServerError from "components/Result/ServerError";
import { useQuery } from "react-query";
// import { AdvSearch, AvdWrapper } from "util/AdvSearch";
// import axiosinstance from "util/Api";
// import IntlMessages from "util/IntlMessages";
import Axiosinstance from "utils/AxiosInstance";
import { AVD_INPUT, ROUTES } from "./Data";

const UserActivity = () => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: "SL NO",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "IP Address",
      dataIndex: "ip",
      key: "ip",
    },
  ];

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const { data, isLoading } = useQuery([ROUTES.UserActivity], () =>
    Axiosinstance.get(ROUTES.UserActivity).then((res) => res.data.data)
  );

  // if (isError) {
  //   return <ServerError />;
  // }

  return (
    <>
      {/* <AvdWrapper>
        <Form form={form} onFinish={onFinish} className="chms_form">
          {AdvSearch(AVD_INPUT)}
        </Form>
      </AvdWrapper> */}

      {/* <Card title={<IntlMessages id="User Activity" />} className="chms__table"> */}
      <Table
        size="small"
        className="table"
        scroll={{ x: true }}
        columns={columns}
        dataSource={data}
        bordered
        pagination={{ position: ["bottomCenter"], pageSize: 50 }}
        loading={isLoading}
      />
      {/* </Card> */}
    </>
  );
};
export default UserActivity;
