/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Row, Table, Tag } from "antd";
import dayjs from "dayjs";

const PaymentInfo = ({ payInfo }) => {
  const successCallback = (position) => {
    console.log(position);
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  const id = navigator.geolocation.watchPosition(successCallback, errorCallback);
  console.log(id, "currLocation");

  const [pay, setPay] = useState();
  useEffect(() => {
    const infos = payInfo?.map((item) => {
      return {
        ...item,
        date: item.date ? dayjs(item.date).toLocaleString() : "",
        payment_method: item.payment_method,
        reference: item.reference,
        amount: `$${item.amount}`,
      };
    });
    setPay(infos);
  }, [payInfo]);
  const columns = [
    {
      title: <span className="table__title">Date</span>,
      dataIndex: "date",
      key: "date",
    },
    {
      title: <span className="table__title">method</span>,
      dataIndex: "payment_method",
      key: "payment_method",
      render: (text, record) => {
        return record.payment_method === 1 ? <Tag color="blue">Cash</Tag> : record.payment_method === 2 ? <Tag color="geekblue"> Card</Tag> : <Tag color="purple">Wallet</Tag>;
      },
    },
    {
      title: <span className="table__title">reference</span>,
      dataIndex: "reference",
      key: "reference",
    },
    {
      title: <span className="table__title">amount</span>,
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => {
        return <p style={{ textAlign: "right" }}>{record.amount}</p>;
      },
    },

  ];

  return (
    <Card className="card__paddingless card__border__radiusless card__box__shadow card__custom__bg for__scroll">
      {pay && pay.length > 0 ? (
        <Table
          pagination={false}
          columns={columns}
          dataSource={pay}
          bordered
          scroll={{
            y: 240,
          }}
          footer={() => {
            return (
              <Row justify="end">
                <Col span={12} style={{ textAlign: "right", fontWeight: "bolder", fontSize: 16 }}>Total</Col>
                <Col span={12} style={{ textAlign: "right", fontWeight: "bold", fontSize: 14 }}>
                  ${payInfo?.reduce((acc, item) => {
                  return acc + item.amount;
                }, 0)}
                </Col>
              </Row>
            );
          }}
        />
      ) : (
        <Empty style={{ alignContent: "center" }} />
      )}
    </Card>
  );
};

export default PaymentInfo;
