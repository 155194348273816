import React from "react";

const BlockCard = (props) => {
  const { count, color, status, icon, total } = props;
  return (
    <div className="block__card__area">
      <div className="block__card__count">{count}</div>
      <div className="block__card__status" style={{ color: `${color}` }}>
        {status}
        <i className={`icon icon-${icon}`} />
      </div>
      <div className="block__card__total">
        crs / total:
        <span>{total}%</span>
      </div>
    </div>
  );
};

export default BlockCard;
