import { Form, Input, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

const SearchBoxArea = ({ searchBoxChangeHandler }) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };
  const suffixSelector = (
    <Form.Item name="suffix" noStyle>
      <Select
        defaultValue="Name"
        size="large"
        // style={{
        //   width: 100,
        // }}
        className="product__search__select"
      >
        <Option value="name">Name</Option>
        <Option value="barcode">Barcode</Option>
      </Select>
    </Form.Item>
  );
  return (
    <Form form={form} name="search" onFinish={onFinish} style={{ width: "80%" }}>
      <Form.Item name="search">
        <Input
          size="large"
          addonAfter={suffixSelector}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          // style={{
          //   width: 100,
          // }}
          placeholder="search"
          onChange={searchBoxChangeHandler}
        />
      </Form.Item>
    </Form>
  );
};

export default SearchBoxArea;
