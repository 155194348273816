import { Card, Col, Radio, Row } from "antd";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import { useSelector } from "react-redux";
import Loader from "components/Loader";
import GlobalLoader from "components/Loader/GlobalLoader";
import BookForm from "./BookForm";
import Reservation from "./Reservation";
import SelectRoomInfoBlock from "./RoomCount";
import RoomItem from "./RoomItem";
import SelectRoomInfoSection from "./SelectRoomInfoSection";
import Sidebar from "./Sidebar";

const Booking = () => {
  const { selectedRoom, customer } = useSelector((state) => state.frontDesk);
  const { data, isLoading, isError } = useQuery("frontdesk", () =>
    Axiosinstance.get("/frontdesk/frontdesk").then((res) => res.data)
  );

  const rooms = data?.room_list?.data;

  if (isLoading) return <GlobalLoader />;

  if (isError) return <div>Error...</div>;

  return (
    <div title="Frontdesk" className="frontdesk">
      {/** Div to Card */}
      <Row gutter={[24, 24]}>
        <Col span={18} xs={24} md={24} lg={12} xl={14} xxl={18}>
          <Radio.Group
            defaultValue="a"
            buttonStyle="solid"
            size="large"
            style={{ width: "100%" }}
            className="frontdesk__rooms"
          >
            <Row
              gutter={[24, 24]}
              style={{ marginBottom: 24 }}
              className="fontdesk__info__block"
            >
              <SelectRoomInfoSection style={{ marginBottm: "10px" }} />
              <Col span={24}>
                <Card title="Room Status" className="frontdesk__sidebar">
                  <Row gutter={[24, 24]}>
                    <SelectRoomInfoBlock data={data} />
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Card title="Rooms" className="frontdesk__sidebar">
                  <Row gutter={[24, 24]}>
                    {rooms?.map((item) => (
                      <RoomItem item={item} key={item.id} />
                    ))}
                  </Row>
                </Card>
              </Col>
            </Row>
            {selectedRoom && (
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Card title="Book Form">
                    <BookForm />
                  </Card>
                </Col>
              </Row>
            )}
          </Radio.Group>
          <br />
          {selectedRoom ? <Reservation /> : null}
          {/* <Reservation /> */}
        </Col>
        <Col span={6} xs={24} md={24} lg={12} xl={10} xxl={6}>
          <Sidebar data={data} />
        </Col>
      </Row>
    </div>
  );
};

export default Booking;
