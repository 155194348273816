import { Button, Drawer, Space } from "antd";
import NewBooking2 from "containers/Booking2/New";

const AddBookingDrawer = ({ addBookingDrawer, setAddBookingDrawer }) => {
  return (
    <Drawer
      title="ADD BOOKING"
      width="80%"
      placement="right"
      open={addBookingDrawer}
      onClose={() => setAddBookingDrawer(false)}
      extra={
        <Space>
          <Button onClick={() => setAddBookingDrawer(false)}>Cancel</Button>
          <Button type="primary" onClick={() => setAddBookingDrawer(false)}>
            OK
          </Button>
        </Space>
            }
      style={{ zIndex: "2001" }}
    >
      <NewBooking2 />
    </Drawer>
  );
};

export default AddBookingDrawer;
