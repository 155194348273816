import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, Col, Row, Table } from "antd";
import GlobalLoader from "components/Loader/GlobalLoader";
import { jsonData } from "features/data/dataSlice";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ListPrintBtns from "shared/ListPrintBtns";
import ActionMenu from "utils/ActionMenu";
import Axios from "utils/AxiosInstance";
import { GetStatusTag } from "utils/CISUI";
import DATA from "./data.json";

const VacationList = () => {
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  // const json = useSelector((state) => state.json.data);

  const { data, isLoading } = useQuery([DATA?.urls.list, page], () =>
    Axios.get(`${DATA?.urls.list}?page=${page}`).then((res) => res.data.data)
  );

  const columns = [];

  DATA?.list_view?.forEach((item) => {
    if (item.title === "Action") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: `${item.key}action`,
        align: "center",
        render: (id) => {
          return <ActionMenu recordID={id} urls={DATA?.urls} />;
        },
      });
    } else if (item.key === "id") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        align: "center",
        render: (text, record, index) => {
          // return `${index + 1 * page} `;
          return `${index + 1 + (page - 1) * 10}`;
        },
      });
    } else if (item.key === "status") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        align: "center",
        render: (text) => {
          return <GetStatusTag status={text} />;
        },
      });
    } else {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        align: "center",
      });
    }
  });

  if (isLoading) return <GlobalLoader />;

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Card
          extra={
            <Breadcrumb
              // className="cis__table__breadcrumb"
              separator={
                <i className="icon-dot" style={{ fontSize: "10px" }} />
              }
            >
              <Breadcrumb.Item>
                <Link to="/dashboard">
                  <HomeOutlined /> <span>Dashboard</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>{DATA?.listtitle ? DATA?.listtitle : "List"}</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          className="cis__table__breadcrumb"
          bordered={false}
        />
        {/* <Breadcrumb
          className="cis__table__breadcrumb"
          separator={
            <i className="icon-dot" style={{ fontSize: "10px" }} />
                }
        >
          <Breadcrumb.Item>
            <Link to="/dashboard">
              <HomeOutlined /> <span>Dashboard</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span>{DATA?.listtitle ? DATA?.listtitle : "List"}</span>
          </Breadcrumb.Item>
        </Breadcrumb> */}
        <Card
          title={DATA?.listtitle ? DATA?.listtitle : "List"}
          extra={<ListPrintBtns />}
          headStyle={{
            backgroundColor: "#23253c",
            color: "#fcfafc",
            minHeight: "48px",
          }}
          className="cis__table"
        >
          <Table
            size="small"
            scroll={{ x: true }}
            columns={columns}
            dataSource={data.data}
            // bordered
            loading={{
              spinning: isLoading,
              size: "large",
              indicator: <GlobalLoader />,
            }}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
              defaultCurrent: page,
              total: data.total,
              onChange: (page) => {
                setPage(page);
              },
            }}
          />
        </Card>
      </Col>
    </Row>
    // </ConfigProvider>
  );
};

export default VacationList;
