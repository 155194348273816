const { createSlice } = require("@reduxjs/toolkit");

const customizerSlice = createSlice({
  name: "customizer",
  initialState: {
    language: localStorage.getItem("language") || "en_US",
    layout: localStorage.getItem("layout") || "vertical",
    theme: localStorage.getItem("theme") || "light",
    direction: localStorage.getItem("direction") || "ltr",
  },
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setLayout: (state, action) => {
      state.layout = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setDirection: (state, action) => {
      state.direction = action.payload;
    },
  },
});

export default customizerSlice.reducer;
