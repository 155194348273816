/* eslint-disable max-len */
import { Button, Col, Drawer, Row, Space, Spin } from "antd";
import NewBooking2 from "containers/Booking2/New";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import { Link } from "react-router-dom";
import { CloseOutlined, FieldTimeOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import CardTitle from "components/Cards/CardTitle";
import GuestInfo from "../BookingDetails/GuestInfo";
import BalanceInfo from "../BookingDetails/BalanceInfo";
import GuestStayInfo from "../BookingDetails/GuestStayInfo";
import Accommodation from "../BookingDetails/Accommodation";
import GuestStatus from "../BookingDetails/GuestStatus";
import PaymentInfo from "../BookingDetails/PaymentInfo";
import CheckIn from "../BookingDetails/TopActionButton";
import DetailsTop from "../BookingDetails/DetailsTop";

const RoomDetailsDrawer = ({ roomDetailsDrawer, setRoomdetailsDrawer, selectedEvent }) => {
  const [generalInfo, setGeneralInfo] = useState("");
  const [guestInfo, setGuestInfo] = useState("");
  const [payInfo, setPayInfo] = useState([]);
  const [hotelInfo, setHotelInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const response = await Axiosinstance.get(`booking/bookingdetails/${selectedEvent.booking_id}`);
        console.log("booking detailssss====", response?.data?.data);
        setGeneralInfo(response?.data.data);
        setGuestInfo(response?.data?.data?.guest_info);
        setPayInfo(response?.data?.data?.payment_items);
        setHotelInfo(response?.data?.data?.hotel_info.cover_photo);
        setIsLoading(false);
      } catch (error) {
        // Handle any error that occurs during the request
        console.error("Error fetching room details:", error);
        setIsLoading(false);
      }
    };

    fetchRoomDetails();
  }, [selectedEvent.booking_id]);

  // useEffect(() => {
  //   setIsLoading(true); // Set loading state to true initially
  //   Axiosinstance.get(`booking/bookingdetails/${selectedEvent.booking_id}`)
  //     .then((res) => {
  //       setGeneralInfo(res?.data.data);
  //       setGuestInfo(res?.data?.data?.guest_info);
  //       setPayInfo(res?.data?.data?.payment_items);
  //       setHotelInfo(res?.data?.data?.hotel_info.cover_photo);
  //       setIsLoading(false);
  //     });
  // }, []);

  const booking_time = generalInfo.booked_time;
  const bookingTime = moment(booking_time, "YYYY-MM-DD HH:mm").format("DD MMM Y h:mm a");

  return (
    <Drawer
      title={
        <span>
          <DetailsTop generalInfo={generalInfo} booking_id={generalInfo.booking_id} />
        </span>
      }
      width="60%"
      placement="right"
      closable={false}
      open={roomDetailsDrawer}
      onClose={() => setRoomdetailsDrawer(false)}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            <Link to={`/booking/bookingdetails/${selectedEvent.booking_id}`}>
              <Button type="default" icon={<InfoCircleOutlined />}>
                Booking Details
              </Button>
            </Link>
            <Button type="primary" onClick={() => setRoomdetailsDrawer(false)} icon={<CloseOutlined />}>
              Close
            </Button>
          </Space>
        </div>
      }
      style={{ zIndex: "2001" }}
    >
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div className="booking__details">
          <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
            <Col span={7}>
              <CardTitle title="general info " />
              <GuestInfo guestInfo={guestInfo} hotelInfo={hotelInfo} />
            </Col>
            <Col span={17}>
              <CardTitle title="payment overview" />
              <BalanceInfo generalInfo={generalInfo} />
            </Col>
            <Col span={24}>
              <CardTitle title="payment history" />
              <PaymentInfo payInfo={payInfo} />
            </Col>
          </Row>
        </div>
      )}
    </Drawer>
  );
};

export default RoomDetailsDrawer;
