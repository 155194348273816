import dayjs from "dayjs";

const { createSlice } = require("@reduxjs/toolkit");

const frontDesk = createSlice({
  name: "frontDesk",
  initialState: {
    selectedRoom: null,
    bookedRooms: [],
    changeRoom: false,
    customer: null,
  },
  reducers: {
    selectedRoom: (state, action) => {
      state.selectedRoom = action.payload;
    },
    bookedRoom: (state, action) => {
      const {
        id,
        room_type,
        room_name,
        base_price,
        discount,
        vat,
        maximum_guests,
      } = state.selectedRoom;

      const check_in = dayjs(action.payload.check_in);
      const check_out = dayjs(action.payload.check_out);
      const dayCount = check_out.diff(check_in, "days");
      const total = dayCount * (base_price - 1 - discount / 100) + vat;
      const index = state.bookedRooms.findIndex((room) => room.id === id);

      if (index === -1) {
        state.bookedRooms.push({
          ...action.payload,
          id,
          room_type,
          room_name,
          base_price,
          discount,
          vat,
          total,
          isRoom: true,
          guests: maximum_guests,
        });
      } else {
        state.bookedRooms[index] = {
          ...action.payload,
          id,
          room_type,
          room_name,
          base_price,
          discount,
          vat,
          total,
          isRoom: true,
          guests: maximum_guests,
        };
      }
    },
    addService: (state, action) => {
      // Service Object
      //   {
      //     "id": 12,
      //     "name": "Tarif Sadman",
      //     "type": "Test Type",
      //     "charge": 22,
      //     "discount": null,
      //     "details": null,
      //     "status": 1,
      //     "created_at": "2023-03-01 08:18:32",
      //     "updated_at": null,
      //     "created_by": 2,
      //     "updated_by": 0
      // }

      // Room Object
      // {
      //   "room": "181",
      //   "rent": 800,
      //   "discount": 29,
      //   "tax_gst": 87,
      //   "check_in": "2023-04-04",
      //   "check_out": "2023-04-06",
      //   "id": 3,
      //   "room_type": "Byron Price",
      //   "room_name": "Rana Carrillo",
      //   "base_price": 800,
      //   "vat": 87,
      //   "total": 1684.42
      // }
      const { id, name, type, charge, discount, details } = action.payload;
      state.bookedRooms.push({
        id,
        room_name: name,
        room_type: type,
        base_price: charge,
        total: charge - (discount / 100) * charge,
        discount,
        details,
        isRoom: false,
        guests: 0,
        vat: 0,
      });
    },
    removeRoom: (state, action) => {
      state.bookedRooms = state.bookedRooms.filter(
        (room) => room.id !== action.payload
      );
    },
    openChangeRoomModal: (state) => {
      state.changeRoom = true;
    },
    closeChangeRoomModal: (state) => {
      state.changeRoom = false;
    },
    selectCustomer: (state, action) => {
      state.customer = action.payload;
    },
    resetfrontDesk: (state) => {
      state.selectedRoom = null;
      state.bookedRooms = [];
      state.changeRoom = false;
      state.customer = null;
    },
  },
});

export const {
  selectedRoom,
  bookedRoom,
  removeRoom,
  openChangeRoomModal,
  closeChangeRoomModal,
  addService,
  selectCustomer,
  resetfrontDesk,
} = frontDesk.actions;

export default frontDesk.reducer;
