import { Checkbox, Form } from "antd";
import React from "react";

const InputCheckbox = ({
  error_msg = "",
  required = true,
  label = "",
  checked = false,
  ...arg
}) => {
  return (
    <Form.Item
      valuePropName="checked"
      rules={[{ required, message: error_msg }]}
      {...arg}
    >
      <Checkbox checked={checked}>
        {label === "" ? "" : label}
      </Checkbox>
    </Form.Item>
  );
};

export default InputCheckbox;
