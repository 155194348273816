/* eslint-disable linebreak-style */
/* eslint-disable array-callback-return */
/* eslint-disable linebreak-style */
import {
  CaretDownOutlined,
  CaretRightOutlined,
  EyeOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { Button, Card, Form, Table } from "antd";
import React, { useState, useEffect } from "react";
// import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import ListPrintBtns from "shared/ListPrintBtns";
import ActionMenu from "./ActionMenu";
import Loader from "../components/Loader";
// import ServerError from "../components/Result/ServerError";
// import { AdvSearch, AvdWrapper } from "./AdvSearch";
import Axiosinstance from "./AxiosInstance";
import { GetStatusTag, getQueryStrings } from "./CISUI";
// import IntlMessages from "./IntlMessages";

const ListField2 = ({
  DATA,
  query,
  detailsUrl = "",
  isExcelPdfPrintWordShow = true,
  setIsModalOpen = null,
  onlyDetail = false,
}) => {
  // console.log("DATA", DATA);
  const [reqURL, setURL] = useState(DATA?.urls?.list);
  // console.log("reqURL", reqURL);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // const intl = useIntl();
  const getList = async () => {
    const { data: res } = await Axiosinstance
      .get(reqURL)
      .then((res) => res.data);

    if (query !== undefined) {
      const filterData = query(res);
      return filterData;
    }

    return res;
  };

  const { data, isLoading } = useQuery([DATA?.urls?.list, reqURL], getList, {
    enabled: !!reqURL,
    refetchOnMount: true,
  });

  console.log("Data", data);

  const onFinish = (values) => {
    const qsup = getQueryStrings(values);
    navigate.push(`?${qsup}`);
    const newURL = `${DATA?.urls.list}?${qsup}`;
    setURL(newURL);
  };

  // Advance Method

  const advanceInput = [];

  DATA?.input_fields.forEach((item) => {
    if (item?.input) {
      const data = item.input.filter((i) => {
        return i.isFilterable;
      });

      return advanceInput.push(...data);
    }
  });

  const columns = [];

  DATA?.list_view?.forEach((item, index) => {
    if (item.title === "Action") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        align: "center",
        render: (id) => {
          // console.log(all)
          if (detailsUrl && onlyDetail) {
            return (
              <Link to={detailsUrl + id}>
                <Button
                  size="small"
                  type="primary"
                  block
                  className="chms__action__popover__btn"
                  style={{ width: "50%" }}
                  icon={<EyeOutlined />}
                />
              </Link>
            );
          }

          return (
            <ActionMenu
              urls={DATA?.urls}
              recordID={id}
              // deleteApiUrl={DATA?.urls?.remove}
              // detailsUrl={detailsUrl}
              // refetchQuerie={DATA?.urls?.list}
              // approveUrl={approveUrl}
              // setIsModalOpen={setIsModalOpen}
              // setId={setId}
            />
          );
        },
      });
    } else if (item.key === "status") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        render: (text) => <GetStatusTag status={text} />,
      });
    } else if (item.title === "SN") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        render: (text, all, index) => index + 1,
        is_hide: 1
      });
    } else if (item?.type === "boolean") {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        render: (text, all, index) => (text === 1 ? "Yes" : "No"),
      });
    } else {
      columns.push({
        title: item.title,
        dataIndex: item.key,
        key: item.key,
        // render: item?.render,
        is_hide: item?.is_hide,
      });
    }
  });

  const filterColumns = columns?.filter((item) => !item?.is_hide);

  const data1 = [];
  data?.map((item, index) => {
    if (item.child !== undefined) {
      if (item.child.length > 0) {
        data1.push({
          key: item.id,
          name: <span style={{ color: "#2f4d67" }}>{item.name}</span>,
          account_name: item.account_name,
          account_no: item.account_no,
          account_type: item.account_type,
          reference_code: item.reference_code,
          is_adjustable: item.is_adjustable,
          status: item.status,
          child: item.child,
        });
      }
    } else {
      data1.push({
        key: item.id,
        name: <span style={{ color: "#2f4d67" }}>{item.name}</span>,
        account_name: item.account_name,
        account_no: item.account_no,
        account_type: item.account_type,
        reference_code: item.reference_code,
        is_adjustable: item.is_adjustable,
        status: item.status,
      });
    }
  });

  // console.log("Hello",data1)

  const ShowIcon = (expanded, data, onExpand) => {
    let icon = "";
    if (data?.child !== undefined && data?.child.length > 0) {
      // console.log(data)
      if (expanded === true) {
        icon = (
          <Button
            style={{
              border: "none",
              margin: "0",
              padding: "0 6px 0 6px",
              background: "none",
            }}
          >
            <CaretDownOutlined onClick={(e) => onExpand(data, e)} />
          </Button>
        );
      } else {
        icon = (
          <Button
            style={{
              border: "none",
              margin: "0",
              padding: "0 6px 0 6px",
              background: "none",
            }}
          >
            <CaretRightOutlined
              onClick={(e) => onExpand(data, e)}
            />
          </Button>
        );
      }
    } else {
      icon = (
        <Button
          style={{
            border: "none",
            margin: "0",
            padding: "0 6px 0 6px",
            background: "none",
            opacity: "0",
          }}
        >
          <PlusCircleTwoTone onClick={(e) => onExpand(data, e)} />
        </Button>
      );
    }
    return icon;
  };
  const [isShow, setIsShow] = useState(true);
  const showExpand = () => {
    return setIsShow(!isShow);
  };
  useEffect(() => {
    showExpand();
  }, []);
  console.log("show", isShow);

  return (
    <Card
      title={DATA?.listtitle ? DATA?.listtitle : "List"}
      extra={<ListPrintBtns URL={DATA} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
        height: "44px",
      }}
      setIsModalOpen={setIsModalOpen}
      className="cis__table"
    >

      {data1?.length !== 0 && (
      <Table
        size="small"
        className="table"
        scroll={{ x: true }}
        columns={columns}
        bordered
        pagination={{ position: ["bottomCenter"] }}
        loading={{
          spinning: isLoading,
          size: "large",
          indicator: <Loader />,
        }}
        expandable={{
          // expandedRowRender,
          expandRowByClick: true,
          defaultExpandAllRows: true,
          // expandRowByClick: true,
          defaultExpandedRowKeys6px: ["0"],
          expandIcon: ({ expanded, onExpand, record }) =>
            ShowIcon(expanded, record, onExpand),
        }}
        dataSource={data1}
      />
      )}
    </Card>
  );
};

export default ListField2;
