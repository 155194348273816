/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unsafe-optional-chaining */
import { useEffect } from "react";
import { CloseCircleOutlined, ShoppingOutlined } from "@ant-design/icons";
import { PiShoppingCartSimpleThin } from "react-icons/pi";
import { Col, Typography } from "antd";
import {
  quantityDecrement,
  quantityIncrement,
  removeFromCart,
} from "features/pos/posSlice";
import { useDispatch, useSelector } from "react-redux";
import localforage from "localforage";

const CartItem = (props) => {
  const { cartData } = useSelector((state) => state.posData);
  const dispatch = useDispatch();

  useEffect(() => {
    localforage.setItem("cart--add--new", cartData);
  }, [cartData]);

  return (
    <>
      {cartData?.map((item, key) => (
        <Col span={24} key={key}>
          <div className="cart__item">
            <div className="delete__icon">
              <CloseCircleOutlined
                onClick={() => dispatch(removeFromCart(item.id))}
              />
            </div>
            <div className="counter__area">
              <button
                className="plus"
                onClick={() => dispatch(quantityIncrement(item.id))}
              >
                +
              </button>
              <span>{item.quantity}</span>
              <button
                className="minus"
                onClick={() => dispatch(quantityDecrement(item.id))}
              >
                -
              </button>
            </div>
            <div className="cart__info">
              <div className="cart__title">{item.name}</div>
              <div className="cart__quantity"> {item.quantity} X 1 Item</div>
            </div>
            <div className=" main__price">
              $ {(item.sale_price * item.quantity).toFixed(2)}
            </div>
          </div>
        </Col>
      ))}
      <h1>
        {cartData.length === 0 && (
          <div
            style={{
              fontFamily: " 'Satisfy', cursive",
              color: "#23253c",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column"
            }}
          >
            {/* <ShoppingOutlined style={{ fontSize: "30px" }} /> */}
            Cart is empty
            <PiShoppingCartSimpleThin style={{ fontSize: "70px" }} />
          </div>
        )}
      </h1>
    </>
  );
};

export default CartItem;

{ /* <span> No Order Found</span>  */ }
