export const AVD_INPUT = [
  {
    label: "User Name",
    name: "user",
    placeholder: "Enter user Name",
    type: "text",
    size: 6,
    required: false,
  },
  {
    label: "Status",
    name: "status",
    placeholder: "Select an status",
    type: "status",
    size: 6,
    required: false,
  }
];

export const ROUTES = {
  UserActivity: "/user/useractivity",
  ChangeLog: "/user/userchangelogs",
  LoginHistory: "/user/loginhistory",
  DETAILS: "/company/companydetails/",
  DeletdLog: "/user/deleteddata",
};
