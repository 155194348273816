import { Form, Input } from "antd";
import React from "react";

const InputPassword = ({
  name = "password",
  label = "Password",
  placeholder = "Enter Password",
  required = false,
  ...arg
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        { required, message: `Please input your ${label}!` },
        {
          min: 6,
          message: "Password must be at least 6 characters",
        },
      ]}
      {...arg}
    >
      <Input.Password placeholder={placeholder} size="large" />
    </Form.Item>
  );
};

export default InputPassword;
