import React from "react";
import { Card, Col, Divider } from "antd";

const summaryData = [
  {
    title: "occupancy",
    iconOne: "icon-notebook-streamline",
    percentage: "88.5 %",
    iconTwo: "icon-angle-down",
  },
  {
    title: "guests in house",
    iconOne: "icon-man-people-streamline-user",
    percentage: "88.5",
    iconTwo: "icon-angle-down",
  },
  {
    title: "available rooms",
    iconOne: "icon-frame-picture-streamline",
    percentage: "88.5",
    iconTwo: "icon-angle-down",
  },
  {
    title: "available clean rooms",
    iconOne: "icon-notebook-streamline",
    percentage: "88.5",
    iconTwo: "icon-angle-down",
  },
];

const SummaryCard = () => {
  return (
    <>
      {summaryData.map((item, index) => {
        return (
          <Col span={12} lg={6} className="summery__card" key={index}>
            <Card
              bordered={false}
              style={{ boxShadow: "none" }}
              className="card__paddingless"
            >
              <div className="summery__card__area">
                <div className="summary__card__title">{item.title} </div>
                <div className="summary__card__info">
                  <i
                    className={`icon icon__color ${item.iconOne}`}
                    style={{ fontSize: "20px" }}
                  />
                  <div className="summery__percentage">{item.percentage}</div>
                  <i className={`icon  icon__color ${item.iconTwo}`} />
                </div>
              </div>
            </Card>
          </Col>
        );
      })}

      <Divider style={{ margin: "0px" }} />
    </>
  );
};

export default SummaryCard;
