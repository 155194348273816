// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { API_URL } from "utils/Config";

// export const apiSlice = createApi({
//   reducerPath: "api",
//   baseQuery: fetchBaseQuery({
//     baseUrl: API_URL,
//     headers: {
//       //   "Content-Type": "application/json",
//       Authorization: "c3JkdGRzc3VwZXJhZG1pbjoxMjM0NTY",
//     },
//   }),
//   tagTypes: ["Rooms", "RoomStatus"],
//   endpoints: (builder) => ({
//     getRooms: builder.query({
//       query: () => "frontdesk/roomlist?is_dep=1",
//       providesTags: ["Rooms"],
//     }),
//     getRoom: builder.query({
//       query: (id) => `frontdesk/roomlist/${id}`,
//     }),
//     getRoomStatus: builder.query({
//       query: () => "frontdesk/roomstatus",
//       providesTags: ["RoomStatus"],
//     }),
//   }),
// });

// export const { useGetRoomsQuery, useGetRoomQuery, useGetRoomStatusQuery } =
//   apiSlice;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userLoggedOut } from "../auth/authSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: async (headers, { getState, endpoint }) => {
    const token = getState()?.auth?.accessToken;
    if (token) {
      headers.set("Authorization", `${token}`);
    }

    return headers;
  },
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result?.error?.status === 401) {
      api.dispatch(userLoggedOut());
      localStorage.clear();
    }
    return result;
  },
  tagTypes: [],
  endpoints: (builder) => ({}),
});
