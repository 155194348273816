import { Button, Col, DatePicker, Form, Row, message } from "antd";
import InputGroup from "components/Input/InputGroup";
import { bookedRoom } from "features/frontDesk/frontDeskSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const { RangePicker } = DatePicker;

const BookForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //   [
  //     {
  //         "id": 0,
  //         "status_name": "Close",
  //         "status_color": "#F24636"
  //     },
  //     {
  //         "id": 1,
  //         "status_name": "Available",
  //         "status_color": "#8BC34A"
  //     },
  //     {
  //         "id": 2,
  //         "status_name": "Reserved",
  //         "status_color": "#9E9E9E"
  //     },
  //     {
  //         "id": 3,
  //         "status_name": "Check Out",
  //         "status_color": "#9F52B0"
  //     },
  //     {
  //         "id": 4,
  //         "status_name": "Unprepared",
  //         "status_color": "#3F51B5"
  //     },
  //     {
  //         "id": 5,
  //         "status_name": "Dirty",
  //         "status_color": "#FFEB3C"
  //     },
  //     {
  //         "id": 6,
  //         "status_name": "Occupied",
  //         "status_color": "#F39D2D"
  //     }
  // ]
  const checkRoomAvailability = (room_status) => {
    switch (room_status) {
      case 0:
        message.error("Room is closed");
        return false;
      case 1:
        return true;
      case 2:
        message.error("Room is reserved");
        return false;
      case 3:
        message.error("Room is checked out");
        return false;
      case 4:
        message.error("Room is unprepared");
        return false;
      case 5:
        message.error("Room is dirty");
        return false;
      case 6:
        message.error("Room is occupied");
        return false;
      default:
        return false;
    }
  };

  const { selectedRoom } = useSelector((state) => state.frontDesk);

  const onFinish = (values) => {
    if (!values.check_in_out) {
      message.error("Please select check in and check out date");
      return;
    }
    const isAvailable = checkRoomAvailability(selectedRoom?.status);
    if (isAvailable) {
      const data = {
        ...values,
        check_in_out: undefined,
        check_in: values.check_in_out[0].format("YYYY-MM-DD"),
        check_out: values.check_in_out[1].format("YYYY-MM-DD"),
      };
      dispatch(bookedRoom(data));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      room: selectedRoom?.room_number,
      rent: selectedRoom?.base_price,
      discount: selectedRoom?.discount,
      tax_gst: selectedRoom?.vat,
    });
  }, [selectedRoom]);

  return (
    <Form
      layout="vertical"
      className="cis__form space__m__top"
      form={form}
      onFinish={onFinish}
      // style={{ marginTop: "20px" }}
    >
      <Row gutter={[24, 24]}>
        <Col span={24} md={12} lg={12} xl={8}>
          <Form.Item name="check_in_out" label="Check In/Out">
            <RangePicker size="large" />
          </Form.Item>
        </Col>
        <Col span={24} md={12} lg={12} xl={3}>
          <InputGroup name="room" label="Room" placeholder="Room" />
        </Col>
        <Col span={24} md={12} lg={12} xl={4}>
          <InputGroup name="rent" label="Rent" placeholder="Rent" />
        </Col>
        <Col span={24} md={12} lg={12} xl={3}>
          <InputGroup name="discount" label="Discount" placeholder="Discount" />
        </Col>
        <Col span={24} md={12} lg={12} xl={3}>
          <InputGroup name="tax_gst" label="Tax" placeholder="Tax" />
        </Col>

        <Col span={3}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            block
            style={{ marginTop: "26px" }}
          >
            Book
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BookForm;
