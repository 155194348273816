import countries from "world-countries";

// Assuming you have the object saved in a variable called "currencyData"
// const randomKey = Object.keys(currencyData)[0];
// const symbol = currencyData[randomKey].symbol;

// console.log(symbol); // Output: "؋"

// const formattedCountries = countries.map((country) => ({
//   value: country.cca2,
//   label: country.name.common,
//   flag: country.flag,
//   latlng: country.latlng,
//   region: country.region,
//   currencies: country.currencies,
//   format: country.cca2,
//   currencieSymbol: country.currencies[0].symbol,
// }));
const formattedCountries = countries.map((country) => {
  const currencyKey = Object.keys(country.currencies)[0];
  const currency = country.currencies[currencyKey];
  return {
    value: country.cca2,
    label: country.name.common,
    flag: country.flag,
    latlng: country.latlng,
    region: country.region,
    currencies: country.currencies,
    format: country.cca2,
    currencieSymbol: currency?.symbol,
  };
});

const useCountries = () => {
  const getAll = () => formattedCountries;

  const getByValue = (value) => {
    return formattedCountries.find((item) => item.value === value);
  };

  return {
    getAll,
    getByValue,
  };
};

export default useCountries;
