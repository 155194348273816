import { Breadcrumb, Card, Col, Form, Row, Table } from "antd";
import { useQuery } from "react-query";
import Loader from "components/Loader";
import Axiosinstance from "utils/AxiosInstance";
import ActionMenu from "utils/ActionMenu";
import ListPrintBtns from "shared/ListPrintBtns";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { jsonData } from "features/data/dataSlice";
import { Link, useLocation } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import GlobalLoader from "components/Loader/GlobalLoader";
import { GetStatusTag, dateFormat } from "utils/CISUI";
import dayjs from "dayjs";
import jsonFile from "./data.json";

const UserRoles = () => {
  const location = useLocation();
  const queryParams = location.search;
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Hotel Name",
      dataIndex: "hotel_name",
      key: "hotel_name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => dayjs(item).format(dateFormat),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
    },
    {
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (item) => dayjs(item).format(dateFormat),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (item) => <GetStatusTag status={item} />,
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id) => <ActionMenu recordID={id} urls={jsonFile?.urls} />,
    },
  ];
  useEffect(() => {
    dispatch(jsonData(jsonFile));
  }, []);

  const { data: userData, isLoading } = useQuery(
    [jsonFile?.urls?.list, page, queryParams],
    () =>
      Axiosinstance.get(
        `${jsonFile?.urls?.list}${queryParams}${
          queryParams === "" ? "?" : "&"
        }page=${page}`
      ).then((res) => res.data.data)
  );

  console.log("🚀 ~ file: List.js:76 ~ UserRoles ~ userData:", userData);

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Card
          extra={
            <Breadcrumb
              // className="cis__table__breadcrumb"
              separator={
                <i className="icon-dot" style={{ fontSize: "10px" }} />
              }
            >
              <Breadcrumb.Item>
                <Link to="/dashboard">
                  <HomeOutlined /> <span>Dashboard</span>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <span>
                  {jsonFile?.listtitle ? jsonFile?.listtitle : "List"}
                </span>
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          className="cis__table__breadcrumb"
          bordered={false}
        />
        <Card
          title="User Role list"
          extra={<ListPrintBtns />}
          className="chms__table"
          headStyle={{
            backgroundColor: "#23253c",
            color: "#fcfafc",
            minHeight: "48px",
          }}
        >
          <Table
            size="small"
            scroll={{ x: true }}
            columns={columns}
            dataSource={userData?.data}
            bordered
            // pagination={{ position: ["bottomCenter"], pageSize: 50 }}
            loading={{
              spinning: isLoading,
              size: "large",
              indicator: <GlobalLoader />,
            }}
            pagination={{
              position: ["bottomCenter"],
              pageSize: 10,
              defaultCurrent: page,
              total: userData?.total,
              onChange: (page) => {
                setPage(page);
              },
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};
export default UserRoles;
