import React from "react";
import { SearchOutlined } from "@ant-design/icons";

const SearchItem = () => {
  return (
    <div className="notification__item item__three">
      <SearchOutlined style={{ fontSize: "20px" }} />
    </div>
  );
};

export default SearchItem;
