import { Form, Input } from "antd";
/**
 *
 * @param {boolean} required
 * @param {boolean} disable
 * @param {string} label
 * @param {string} placeholder
 * @param {string} addOn
 * @param {object} arg
 * @returns Email Input with validation
 */

const InputEmail = ({
  name,
  required = true,
  disabled = false,
  label = "",
  placeholder = "",
  addOn = "",
  ...arg
}) => {
  return (
    <Form.Item
      rules={[
        {
          type: "email",
          message: "The input is not valid E-mail!",
        },
        {
          required,
          message: "Please input your E-mail!",
        },
      ]}
      extra={addOn === "" ? "" : addOn}
      name={name}
      label={label}
      {...arg}
    >
      <Input disabled={disabled} placeholder={placeholder} size="large" />
    </Form.Item>
  );
};

export default InputEmail;
