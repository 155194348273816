import { Form, TimePicker } from "antd";
import { timeFormat } from "utils/CISUI";

const InputTime = ({
  name = "",
  label = "",
  required = false,
  index,
  ...arg
}) => {
  return (
    <Form.Item
      rules={[{ required, message: `Please input your ${label}!` }]}
      label={label}
      name={name}
    >
      <TimePicker use12Hours size="large" format={timeFormat} {...arg} />
    </Form.Item>
  );
};

export default InputTime;
