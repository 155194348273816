/* eslint-disable max-len */
import { Button, Menu, Drawer, Modal, Popconfirm, Space, message, Dropdown } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Axiosinstance from "utils/AxiosInstance";
import Config from "utils/Config";
import { AppstoreAddOutlined, CheckCircleOutlined, DashOutlined } from "@ant-design/icons";
import BookingPaymentDrawer from "./BookingPaymentDrawer";
import CheckInModal from "./NewGuestModal";
import SecDepositModal from "./SecurityDeposit";

// eslint-disable-next-line max-len
const TopActionButton = ({ generalInfo, setGeneralInfo, booking_id, setPayInfo, onUpdateRoomInfo }) => {
  const [openPaymentDrawer, setOpenPaymentDrawer] = useState(false);
  const baseUrl = Config.API_URL;
  const [checkInModalVisible, setCheckInModalVisible] = useState(false);
  const [popVisible, setPopVisible] = useState(false);
  const [secModalVisible, setSecModalVisible] = useState(false);

  const handlePopConfirm = () => {
    if (generalInfo?.check_in === null) {
      message.error("Please Check In First");
    } else {
      setPopVisible(true);
    }
  };

  const handlePopCancel = () => {
    setPopVisible(false);
  };

  const handleCheckInModal = () => {
    Axiosinstance.post(`booking/checkin/${booking_id}`)
      .then((response) => {
        const { data } = response;
        if (data.status === 0) {
          message.error(data.msg);
        } else if (data.status === 1) {
          message.success(data.msg);
        }
      });
  };

  const handleCheckInModalCancel = () => {
    setCheckInModalVisible(false);
  };

  const handlePaymentDrawer = () => {
    setOpenPaymentDrawer(true);
  };
  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1000);
  };
  const handleCheckOut = () => {
    const checkoutDateTime = moment().format("YYYY-MM-DD HH:mm");

    const patchData = {
      check_out: checkoutDateTime
    };
    Axiosinstance.patch(`booking/checkout/${generalInfo.booking_id}`, patchData)
      .then((response) => {
        const { data } = response;
        if (data.status === 0) {
          message.error(data.msg);
        } else if (data.status === 1) {
          message.success(data.msg);
          window.open(data.url, "_blank");
          Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
            .then((res) => res.data)
            .then((data) => {
              setGeneralInfo(data?.data);
              onUpdateRoomInfo(data?.data?.booking_items);
            });
        }
      })
      .catch((error) => {
        // Handle error
        console.log("PATCH request failed:", error);
      });
  };

  const handleSecurityDeposit = () => {
    setSecModalVisible(true);
  };

  const handleSecurityDepositClose = () => {
    setSecModalVisible(false);
  };

  const handleCheckIn = () => {
    const currentDateTime = moment().format("YYYY-MM-DD HH:mm");

    const requestBody = {
      check_in: currentDateTime,
    };
    Axiosinstance.post(`booking/checkin/${generalInfo.booking_id}`, requestBody)
      .then((response) => {
        // console.log(response.data);
        const { data } = response;
        if (data.status === 0) {
          message.error(data.msg);
        } else if (data.status === 1) {
          message.success(data.msg);
          Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
            .then((res) => res.data)
            .then((data) => {
              setGeneralInfo(data?.data);
            });
        }
      })
      .catch((error) => {
        console.log("PATCH request failed:", error);
      });
  };

  const handleCancellation = () => {
    Axiosinstance.post(`booking/cancelbooking/${generalInfo.booking_id}`)
      .then((response) => {
        const { data } = response;
        if (data.status === 0) {
          message.error(data.msg);
        } else if (data.status === 1) {
          message.success(data.msg);
          Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
            .then((res) => res.data)
            .then((data) => {
              setGeneralInfo(data?.data);
            });
        }
      });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Button
          type="text"
          onClick={handleCheckIn}
          disabled={generalInfo?.booking_status === 5 || generalInfo?.booking_status === 2}
        >
          Check In
        </Button>
      </Menu.Item>
      <Menu.Item key="2">
        <a href={`${baseUrl}booking/invoice/bookingdetails/${booking_id}`} target="_blank" rel="noreferrer">
          <Button type="text">
            Invoice
          </Button>
        </a>
      </Menu.Item>
      <Menu.Item key="3">
        <a href={`${baseUrl}booking/pos/paymentreceipt/${booking_id}`} target="_blank" rel="noreferrer">
          <Button type="text">
            Payment Receipt
          </Button>
        </a>
      </Menu.Item>
      <Menu.Item key="4">
        <Button
          type="text"
          onClick={handlePaymentDrawer}
          disabled={generalInfo?.booking_status === 5}
        >
          Add Payment
        </Button>
      </Menu.Item>
      <Menu.Item key="5">
        <Button
          type="text"
          onClick={handleSecurityDeposit}
          disabled={generalInfo?.booking_status === 5}
        >
          Security Deposit
        </Button>
      </Menu.Item>
      <Menu.Item key="6">
        <Button
          type="text"
          onClick={handleCancellation}
          disabled={generalInfo?.booking_status === 5}
        >
          Cancel
        </Button>
      </Menu.Item>
      <Menu.Item key="7">
        <Button
          type="text"
          onClick={handlePopConfirm}
          disabled={generalInfo?.booking_status === 5}
        >
          Check Out
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Dropdown overlay={menu}>
          <Button type="primary"><DashOutlined /></Button>
        </Dropdown>
      </span>

      <Popconfirm
        title="Are you sure to check out?"
        open={popVisible}
        onConfirm={handleCheckOut}
        onCancel={handlePopCancel}
        okButtonProps={{ loading: loadings[1] }}
        cancelButtonProps={{ disabled: loadings[1] }}
        placement="bottomLeft"
      />

      <SecDepositModal
        visible={secModalVisible}
        inVisible={handleSecurityDepositClose}
      />

      <BookingPaymentDrawer
        openPaymentDrawer={openPaymentDrawer}
        setOpenPaymentDrawer={setOpenPaymentDrawer}
        payable_amount={generalInfo.due_amount}
        generalInfo={generalInfo}
        setGeneralInfo={setGeneralInfo}
        setPayInfo={setPayInfo}
        booking_id={booking_id}
      />

      <CheckInModal
        visible={checkInModalVisible}
        onCancel={handleCheckInModalCancel}
        // onSubmit={handleCheckInModal}
      />
    </>
  );
};

export default TopActionButton;
