import {
  FileExcelFilled,
  FilePdfFilled,
  FileWordFilled,
  FilterFilled,
  PlusOutlined,
  PrinterFilled,
} from "@ant-design/icons";
import { Tooltip, message } from "antd";
import { openDrawer } from "features/avdSearch/avdSearchSlice";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
// import { openDrawer } from "redux/advSearchSlice";

const ListBtns = () => {
  const dispatch = useDispatch();
  const json = useSelector((state) => state.json?.data);
  const { t } = useTranslation();
  return (
    <div className="form__print__list">
      <Tooltip title="Advance Search">
        <FilterFilled
          className="form__print__list__icon"
          onClick={() => dispatch(openDrawer())}
        />
      </Tooltip>
    </div>
  );
};

export default ListBtns;
