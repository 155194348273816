import { Card, Form, Table } from "antd";
// import ServerError from "components/Result/ServerError";
import { useQuery } from "react-query";
// import { AdvSearch, AvdWrapper } from "util/AdvSearch";
// import axiosinstance from "util/Api";
import { GetStatusTag } from "utils/CISUI";
// import IntlMessages from "util/IntlMessages";
import Axiosinstance from "utils/AxiosInstance";
import { AVD_INPUT, ROUTES } from "./Data";

const LoginHistory = () => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: "SL NO",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Login Source",
      dataIndex: "login_source",
      key: "login_source",
    },
    {
      title: "Login Time",
      dataIndex: "login_time",
      key: "login_time",
    },
    {
      title: "Hit Time",
      dataIndex: "hit_time",
      key: "hit_time",
    },
    {
      title: "IP Address",
      dataIndex: "ip",
      key: "ip",
    },
    {
      title: "Logout Time",
      dataIndex: "logout_time",
      key: "logout_time",
    },
    // {
    //     title: <IntlMessages id="Website" />,
    //     dataIndex: 'com_website',
    //     key: 'com_website',
    // },
    // {
    //     title: <IntlMessages id='Address' />,
    //     dataIndex: 'address',
    //     key: 'address',
    //     render: (text, record) => (
    //         <div>
    //             {record.address_line1}
    //         </div>
    //     )
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => <GetStatusTag status={text} />,
    },
  ];

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const { data, isError, isLoading } = useQuery([ROUTES.LoginHistory], () =>
    Axiosinstance.get(ROUTES.LoginHistory).then((res) => res.data.data)
  );

  // if (isError) {
  //   return <ServerError />;
  // }

  return (
    <>
      {/* <AvdWrapper>
        <Form form={form} onFinish={onFinish} className="chms_form">
          {AdvSearch(AVD_INPUT)}
        </Form>
      </AvdWrapper> */}

      {/* <Card
        title="Login History"
        // extra={<ListPrintBtn URL={ROUTES.NEW} />}
        className="chms__table"
      > */}
      <Table
        size="small"
        className="table"
        scroll={{ x: true }}
        columns={columns}
        dataSource={data}
        bordered
        pagination={{ position: ["bottomCenter"], pageSize: 50 }}
        loading={isLoading}
      />
      {/* </Card> */}
    </>
  );
};
export default LoginHistory;
