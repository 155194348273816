import { useEffect, useState } from "react";
import Axios from "utils/AxiosInstance";

const GetDataFromAPI = (url) => {
  const [data, setData] = useState();

  const getdata = () => {
    Axios
      .get(url)
      .then((res) => {
        if (res.data.status === 1) {
          setData(res.data.data);
        } else {
          console.log("error", res.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getdata();
  }, []);

  return data;
};

export default GetDataFromAPI;
