import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spin } from "antd";
import CardTitle from "components/Cards/CardTitle";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import { useParams, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import GuestInfo from "./GuestInfo";
import CardInnerTitle from "./CardInnerTitle";
import BalanceInfo from "./BalanceInfo";
import Orders from "./Orders";
import Invoice from "./Invoice";
import Accommodation from "./Accommodation";
import GuestStatus from "./GuestStatus";
import PaymentInfo from "./PaymentInfo";
import RoomService from "./RoomService";
import ResturentService from "./ResturentService";
import CheckIn from "./TopActionButton";
import DetailsTop from "./DetailsTop";
import RoomServices from "./RoomServices";
import GuestsList from "./GuestList";

const Index = () => {
  const { id } = useParams();
  const [generalInfo, setGeneralInfo] = useState("");
  const [guestInfo, setGuestInfo] = useState("");
  const [payInfo, setPayInfo] = useState([]);
  const [roomInfo, setRoomInfo] = useState([]);
  const [allGuests, setAllGuests] = useState([]);
  const [roomService, setRoomService] = useState([]);
  const [resService, setResService] = useState([]);
  const [hotelInfo, setHotelInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("generalInf9090999", generalInfo.security_deposit);
  useEffect(() => {
    Axiosinstance.get(`booking/bookingdetails/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setGeneralInfo(data?.data);
        setGuestInfo(data?.data?.guest_info);
        setPayInfo(data?.data?.payment_items);
        setRoomInfo(data?.data?.booking_items);
        setRoomService(data?.data?.service_items);
        setResService(data?.data?.sales_items);
        setAllGuests(data?.data?.multiple_guests);
        setHotelInfo(data?.data?.hotel_info.cover_photo);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
        setLoading(false);
      });
  }, [id]);
  const handleUpdateRoomService = (updatedRoomService) => {
    setRoomService(updatedRoomService);
  };
  const handleUpdateRoomInfo = (updatedRoomInfo) => {
    setRoomInfo(updatedRoomInfo);
  };
  return (
    <div className="booking__details">
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <DetailsTop
            generalInfo={generalInfo}
            setGeneralInfo={setGeneralInfo}
            booking_id={id}
            setPayInfo={setPayInfo}
            onUpdateRoomInfo={handleUpdateRoomInfo}
          />
          <Row gutter={[16, 16]}>
            <Col xs={24} md={5} lg={5} xl={5} xxl={5}>
              <CardTitle title="general info " />
              <GuestInfo guestInfo={guestInfo} hotelInfo={hotelInfo} generalInfo={generalInfo} />
            </Col>
            <Col xs={24} md={24} lg={11} xl={11} xxl={11}>
              <CardTitle title="payment overview" />
              <BalanceInfo
                generalInfo={generalInfo}
                setGeneralInfo={setGeneralInfo}
                setPayInfo={setPayInfo}
                onUpdateRoomInfo={handleUpdateRoomInfo}
                booking_id={id}
              />
            </Col>
            <Col xs={24} md={8} lg={8} xl={8} xxl={8}>
              <CardTitle title="payment history" />
              <PaymentInfo payInfo={payInfo} />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 50 }}>
              {/* <CardTitle title="booking overview" /> */}
              <Accommodation
                roomInfo={roomInfo}
                booking_id={id}
                onUpdateRoomInfo={handleUpdateRoomInfo}
                setGeneralInfo={setGeneralInfo}
                bookingStatus={generalInfo.booking_status}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 50 }}>
              <RoomService
                roomService={roomService}
                booking_id={id}
                onUpdateRoomService={handleUpdateRoomService}
                setGeneralInfo={setGeneralInfo}
                bookingStatus={generalInfo.booking_status}
              />

            </Col>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 50 }}>
              <ResturentService resService={resService} />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={24} lg={24} xl={24} xxl={24} style={{ marginTop: 50 }}>
              <GuestsList />
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Index;
