import { Form, Input } from "antd";

const InputGroup = ({
  required = false,
  disabled = false,
  initialValue = null,
  placeholder = "",
  addOn = "",
  label = "",
  tooltip = "",
  ...arg
}) => {
  return (
    <Form.Item
      rules={[{ required, message: `Please input your ${label}!` }]}
      {...arg}
      va
      extra={addOn === "" ? "" : addOn}
      initialValue={initialValue}
      label={label}
      tooltip={tooltip}
    >
      <Input disabled={disabled} placeholder={placeholder} size="large" />
    </Form.Item>
  );
};

export default InputGroup;
