/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-const-assign */
import { Button, Input, Modal, Select, Spin, Table, message } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Axiosinstance from "utils/AxiosInstance";
import DATA from "./data.json";

const BookingModal = ({
  isOpenPaymentModal,
  setIsOpenPaymentModal,
  prev_data,
  payableAmount,
  booking_id
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const paymentOptions = [
    {
      label: "Cash",
      value: 1,
    },
    {
      label: "Card",
      value: 2,
    },
    {
      label: "Wallet",
      value: 3,
    },
  ];
  const [orderId, setOrderId] = useState("");
  // console.log("orderid====", orderId);
  // const [isInvoiceModal, setIsInvoiceModal] = useState(false);
  const [paymentRow, setPaymentRow] = useState([
    {
      type: "",
      tranx_id: "",
      total: "",
    },
  ]);

  const [options, setOptions] = useState(paymentOptions);
  const [payingAmount, setPayingAmount] = useState(0);
  // const [isDisabled, setIsDisabled] = useState(true);
  // const bookingData = useSelector((state) => state.);
  // const navigate = useNavigate();
  const handleCancel = () => {
    setIsOpenPaymentModal(false);
  };

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(
      "/booking/addnewbooking",
      values
    );
    console.log(values, "values++00-__((()**&*^&^&");
    return response;
  };

  const handleChange = (e, idx) => {
    const newPaymentRow = [...paymentRow];
    if (e.target.name === "total") {
      newPaymentRow[idx][e.target.name] = +e.target.value;
    } else {
      newPaymentRow[idx][e.target.name] = e.target.value;
    }
    setPaymentRow(newPaymentRow);

    if (e.target.name === "total") {
      const totalPayingAmount = paymentRow.reduce(
        // eslint-disable-next-line no-unsafe-optional-chaining
        (prevValue, currValue) => prevValue + +currValue?.total,
        0
      );
      setPayingAmount(totalPayingAmount);
    }
  };

  const handleSelect = (value, v, idx) => {
    const newPaymentRow = [...paymentRow];
    newPaymentRow[idx].type = value;

    const newPaymentOptions = [...paymentOptions];
    // console.log("newPaymentRow====", newPaymentRow);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; newPaymentRow.length > i; i++) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; newPaymentOptions.length > j; j++) {
        if (newPaymentRow[i].type === newPaymentOptions[j].value) {
          newPaymentOptions.splice(j, 1);
        }
      }
    }
    setOptions(newPaymentOptions);
    // console.log("newPaymentOptions====", newPaymentOptions);
    setPaymentRow(newPaymentRow);
  };

  const removeHandler = (idx) => {
    if (paymentRow.length > 1) {
      const newPaymentRow = [...paymentRow];
      const deletedMethod = newPaymentRow.splice(idx, 1);

      if (deletedMethod[0].type) {
        const findDeletedOption = paymentOptions.find(
          (item) => item.value === deletedMethod[0].type
        );
        setOptions((prevState) => [...prevState, findDeletedOption]);
      }

      if (deletedMethod[0].total) {
        const totalPayingAmount = paymentRow.reduce(
          // eslint-disable-next-line no-unsafe-optional-chaining
          (prevValue, currValue) => prevValue + +currValue?.total,
          0
        );
        setPayingAmount(totalPayingAmount);
      }
      setPaymentRow(newPaymentRow);
    }
  };

  const columns = [
    {
      title: "Payment Method",
      dataIndex: "type",
      render: (value, obj, idx) => {
        let methodName;
        if (value === 1) {
          methodName = "Cash";
        } else if (value === 2) {
          methodName = "Card";
        } else if (value === 3) {
          methodName = "Wallet";
        } else {
          methodName = null;
        }
        return (
          <Select
            placeholder="Select a option"
            style={{ width: "100%" }}
            options={options}
            onChange={(changeValue, changeAllValue) =>
              handleSelect(changeValue, changeAllValue, idx)
            }
            value={methodName || null}
          />
        );
      },
    },
    {
      title: "Reference",
      dataIndex: "tranx_id",
      render: (value, v, idx) => {
        // console.log("payment type====", v.type);
        let placeholderMsg = "";
        let isFieldRequired = true;
        if (v.type == 1) {
          placeholderMsg = "Note";
          isFieldRequired = false;
          // console.log(isFieldRequired, "isFieldRequired");
        } else if (v.type == 2) {
          placeholderMsg = "Last 4 digit";
        } else if (v.type == 3) {
          placeholderMsg = "Transaction ID";
        } else {
          placeholderMsg = "";
        }

        return (
          <Input
            placeholder={placeholderMsg}
            name="tranx_id"
            required={isFieldRequired}
            onChange={(e) => handleChange(e, idx)}
            value={value || null}
            style={{ width: "100%" }}
          />
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "total",
      render: (value, v, idx) => {
        return (
          <Input
            placeholder="Total"
            name="total"
            onChange={(e) => handleChange(e, idx)}
            type="number"
            value={value || null}
            style={{ width: "100%" }}
          />
        );
      },
    },
    {
      title: "Action",
      align: "center",
      render: (value, val, idx) => {
        return (
          <Button
            type="danger"
            size="small"
            onClick={() => removeHandler(idx)}
            style={{ margin: "0" }}
          >
            Remove
          </Button>
        );
      },
    },
  ];

  // console.log(booking_id, "lolBooking");

  // const editRecord = async (values) => {
  //   const { data: response } = await Axiosinstance.patch(
  //     DATA?.urls?.edit + prev_data?.id,
  //     values
  //   );
  //   return response;
  // };

  const { mutate, isLoading } = useMutation(addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        // console.log("data====", data);
        message.success(data.msg);
        navigate(DATA.urls.details + data?.booking_id);
        handleCancel();
        setPaymentRow([
          {
            type: undefined,
            tranx_id: "",
            total: undefined,
          },
        ]);
      } else if (data.status === 0) {
        message.warning(data.msg);
        // console.log("data====", data);
      } else {
        message.error(data.msg);
      }
      setOrderId(data.order_id);
    },
    onError: () => {
      message.error("Server Error");
    },
  });
  const handleOk = () => {
    // console.log("paymentRow====", paymentRow);
    let totalAmount = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; paymentRow.length > i; i++) {
      if (!paymentRow[i].type) {
        message.error("Payment Method is Required");
        return;
      }
      if (paymentRow[i].type == !1) {
        if (!paymentRow[i].tranx_id) {
          message.error("Transaction ID is Required");
          return;
        }
      }
      if (!paymentRow[i].total) {
        message.error("Amount is Required");
        return;
      }
      totalAmount += +paymentRow[i].total;
    }

    const final_obj = {
      ...prev_data,
      // referred_by: prev_data?.refrred_by?.id,
      payment_object: paymentRow,
    };
    console.log(prev_data, "prev_data");

    console.log("finalData====", final_obj);
    mutate(final_obj);
  };

  const addMoreBtnHandler = () => {
    setPaymentRow((prevState) => {
      return [
        ...prevState,
        {
          type: "",
          tranx_id: "",
          total: "",
        },
      ];
    });
  };

  return (
    <>
      {/* <Invoice
        isInvoiceModal={isInvoiceModal}
        setIsInvoiceModal={setIsInvoiceModal}
        id={orderId}
      /> */}
      <Modal
        title={`Due - ${(payableAmount - payingAmount).toFixed(2)}`}
        // title="Payment Amount"
        width="800px"
        open={isOpenPaymentModal}
        onOk={handleOk}
        isLoading={isLoading}
        onCancel={handleCancel}
        cancelButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        booking_id={booking_id}
        okText="Submit"
      >
        <Spin spinning={isLoading}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                margin: options.length ? "0" : "0 0 8px 0",
                display: "flex",
                columnGap: "5px",
              }}
            >
              {/* Remain: ${(payable_amount - payingAmount).toFixed(2)} */}
            </p>
            {options.length ? (
              <Button type="primary" onClick={addMoreBtnHandler} style={{ marginBottom: 8 }}>
                Add More
              </Button>
            ) : null}
          </div>
          <Table
            columns={columns}
            dataSource={paymentRow}
            bordered
            className="payment__method-table"
            pagination={false}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default BookingModal;
