import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userLoggedIn } from "../features/auth/authSlice";

export default function useAuthCheck() {
  const dispatch = useDispatch();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userStr = localStorage.getItem("user");
    const user = JSON.parse(userStr);

    if (accessToken && user) {
      dispatch(
        userLoggedIn({
          accessToken,
          user,
        })
      );
    }
    setAuthChecked(true);
  }, [setAuthChecked]);

  return authChecked;
}
