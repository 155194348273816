import { useQuery } from "react-query";
import { Form, Select } from "antd";
import Axios from "utils/AxiosInstance";
import GlobalLoader from "components/Loader/GlobalLoader";

const SelectRequestMultiple = ({
  error_msg = "",
  name = "",
  label = "",
  initialValue = null,
  placeholder = "",
  required = false,
  path = "",
  // eslint-disable-next-line no-unused-vars
  showSearch = true,
  addOn = "",
  mode = null,
}) => {
  const { isLoading, isError, data } = useQuery([path], () =>
    Axios.get(path).then((res) => res.data.data)
  );

  // const options = [];
  // // eslint-disable-next-line no-plusplus
  // for (let i = 10; i < 36; i++) {
  //   options.push({
  //     value: i.toString(36) + i,
  //     label: i.toString(36) + i,
  //   });
  // }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  if (isError) {
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: error_msg }]}
        extra={addOn === "" ? "" : addOn}
      >
        <Select
          size="large"
          placeholder={placeholder}
          mode="tags"
          style={{
            width: "100%",
          }}
          onChange={handleChange}
          tokenSeparators={[","]}
        />
      </Form.Item>
    );
  }

  return initialValue === null ? (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      extra={addOn === "" ? "" : addOn}
    >
      <Select
        size="large"
        placeholder={placeholder}
        mode="tags"
        style={{
          width: "100%",
        }}
        onChange={handleChange}
        tokenSeparators={[","]}
        // options={options}
      >
        {isLoading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          data?.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))
        )}
      </Select>
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      initialValue={initialValue}
      extra={addOn === "" ? "" : addOn}
      mode={mode}
    >
      <Select
        size="large"
        placeholder={placeholder}
        mode="tags"
        style={{
          width: "100%",
        }}
        onChange={handleChange}
        tokenSeparators={[","]}
        // options={options}
      >
        {isLoading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          data?.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))
        )}
      </Select>
    </Form.Item>
  );
};

export default SelectRequestMultiple;
