/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable max-len */
import React, { useState } from "react";
import { Avatar, List, Card, Button, Image } from "antd";
import { InfoCircleOutlined, MailOutlined, PhoneOutlined, PlusOutlined, UserOutlined, EditOutlined, EllipsisOutlined, SettingOutlined } from "@ant-design/icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsPersonVcard } from "react-icons/bs";
import { SiGmail } from "react-icons/si";
import { Link } from "react-router-dom";
import CardInnerTitle from "./CardInnerTitle";
import FallbackImage from "../../../assets/images/j.webp";
import generalInfo from "./BalanceInfo";
import profileImage from "../../../assets/images/profile.jpeg";
import CheckedInGuests from "./GuestLists";

const { Meta } = Card;

const GuestInfo = ({ guestInfo, hotelInfo, generalInfo }) => {
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleDrawer = () => {
    setDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  // console.log("guestInfo---------guestInfo>>", hotelInfo);
  const data = [
    {
      title: "Ant Design Title 1",
    },
  ];
  return (
    <Card
      className="card__border__radiusless card__box__shadow card__paddingless for__scroll"
      cover={
        hotelInfo ?
          <img alt="example" src={hotelInfo} style={{ height: "140px", objectFit: "cover" }} />
          :
          <img style={{ height: "140px", objectFit: "cover" }} src="https://www.mfkh.hu/public/images/image-not-found.jpg" alt="" />
      }
    >
      <div className="description__list">
        <div className="" style={{ textTransform: "capitalize", color: "#0e4535", fontSize: "16px", fontWeight: "500" }}>
          {guestInfo.name}
        </div>
        <div className="phone__number">
          <PhoneOutlined style={{ color: "#23253c" }} /> {guestInfo.phone}
        </div>
        <div className="email__number">
          <BsPersonVcard style={{ color: "#23253c" }} /> {guestInfo.passport}
        </div>
        <div className="email__number">
          <SiGmail style={{ color: "#23253c" }} /> {guestInfo.email}
        </div>
        <div className="email__number">
          <FaMapMarkerAlt style={{ color: "#23253c" }} /> {guestInfo.address}
        </div>
        {/* <div style={{ marginTop: 7 }}>
          <Button onClick={handleDrawer}>Guests</Button>
        </div> */}
        {/* <CheckedInGuests visible={drawerVisible} onClose={handleDrawerClose} /> */}
      </div>
    </Card>
  // <Card className="card__paddingless card__border__radiusless card__box__shadow card__custom__bg for__scroll guest_info">
  //   <div className="main">
  //     <div className="container">
  // {generalInfo.image ? <img className="profile-img" src={generalInfo.image} alt="" />
  //   : <img className="profile-img" src={profileImage} alt="" /> }
  //       {hotelInfo ? <img className="background-img" src={hotelInfo} alt="" />
  //         : <img className="profile-img" src="https://www.mfkh.hu/public/images/image-not-found.jpg" alt="" />
  //     }

  // <div className="description__list" style={{ padding: "10px 26px" }}>
  //   <div className="" style={{ textTransform: "capitalize", color: "#0e4535", fontSize: "16px", fontWeight: "500" }}>
  //     {guestInfo.name}
  //   </div>
  //   <div className="phone__number">
  //     <PhoneOutlined style={{ color: "#23253c" }} /> {guestInfo.phone}
  //   </div>
  //   <div className="email__number">
  //     <BsPersonVcard style={{ color: "#23253c" }} /> {guestInfo.passport}
  //   </div>
  //   <div className="email__number">
  //     <SiGmail style={{ color: "#23253c" }} /> {guestInfo.email}
  //   </div>
  //   <div className="email__number">
  //     <FaMapMarkerAlt style={{ color: "#23253c" }} /> {guestInfo.address}
  //   </div>
  // </div>
  //     </div>
  //   </div>
  // </Card>
  );
};

export default GuestInfo;
