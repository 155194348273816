import { Form, Table } from "antd";
// import ServerError from "components/Result/ServerError";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import { AVD_INPUT, ROUTES } from "./Data";

const ChangeLog = () => {
  const [form] = Form.useForm();

  const columns = [
    {
      title: "SL NO",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
    },
    {
      title: "Old Data",
      dataIndex: "old_data",
      key: "old_data",
    },
    {
      title: "New Data",
      dataIndex: "new_data",
      key: "new_data",
    },
    {
      title: "Changed By",
      dataIndex: "changed_by",
      key: "changed_by",
    },
    {
      title: "Is Restore",
      dataIndex: "is_restore",
      key: "is_restore",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
    },
  ];

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  const { data, isError, isLoading } = useQuery([ROUTES.ChangeLog], () =>
    Axiosinstance.get(ROUTES.ChangeLog).then((res) => res.data.data)
  );

  return (
    <Table
      size="small"
      className="table"
      scroll={{ x: true }}
      columns={columns}
      dataSource={data}
      bordered
      pagination={{ position: ["bottomCenter"], pageSize: 50 }}
      loading={isLoading}
    />
  );
};
export default ChangeLog;
