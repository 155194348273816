import { ReloadOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
import GlobalLoader from "components/Loader/GlobalLoader";

const InputSelect = ({
  name = "",
  label = "",
  initialValue = null,
  placeholder = "",
  rules = [{ required: true }],
  showSearch = true,
  items = [],
  loading = false,
  error = false,
  disabled = false,
  ...arg
}) => {
  if (error) {
    return (
      <Form.Item label={label} name={name} rules={rules}>
        <Select
          placeholder={placeholder}
          style={{ width: "100%" }}
          status="error"
          size="large"
          disabled={disabled}
        >
          <Select.Option style={{ textAlign: "center" }}>
            <Button
              type="link"
              icon={<ReloadOutlined />}
              onClick={() => {
                return window.location.reload();
              }}
              className="reload_page"
            >
              Something is wrong please reload the page
            </Button>
          </Select.Option>
        </Select>
      </Form.Item>
    );
  }

  return initialValue === null ? (
    <Form.Item label={label} name={name} rules={rules}>
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        filterOption={(input, option) => {
          return (
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        size="large"
        loading={loading}
        {...arg}
        disabled={disabled}
      >
        {loading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          items?.map((item, index) => {
            return (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })
        )}
      </Select>
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      size="large"
      initialValue={initialValue}
    >
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        filterOption={(input, option) => {
          return (
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        loading={loading}
        {...arg}
        disabled={disabled}
      >
        {loading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          items?.map((item, index) => {
            return (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            );
          })
        )}
      </Select>
    </Form.Item>
  );
};

export default InputSelect;
