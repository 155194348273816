import {
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Popover, message } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Axios from "utils/AxiosInstance";

const DeleteItem = ({ recordID = null, urls = null }) => {
  const [deletePopover, setDeletePopover] = useState(false);
  const queryClient = useQueryClient();

  const deleteItem = async (id) => {
    const { data: response } = await Axios.delete(urls.remove + id);
    return response;
  };

  const { mutate: deleteMutate, isLoading } = useMutation(deleteItem, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        setDeletePopover(false);
        queryClient.refetchQueries([urls.list]);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const handleDelete = (id) => {
    deleteMutate(id);
    setDeletePopover(false);
  };

  const cancelDelete = () => {
    setDeletePopover(false);
  };

  return (
    <Popover
      overlayClassName="action__popover"
      content={
        <div className="action__popover__content">
          <p>Are you sure you want to delete this record?</p>
          <div className="action__popover__content__btns">
            <Button
              type="primary"
              size="small"
              onClick={() => handleDelete(recordID)}
              loading={isLoading}
              disabled={isLoading}
            >
              Yes
            </Button>
            <Button size="small" onClick={cancelDelete}>
              No
            </Button>
          </div>
        </div>
      }
      open={deletePopover}
      onOpenChange={(visible) => setDeletePopover(visible)}
      trigger="click"
    >
      <Button
        size="small"
        icon={<DeleteOutlined style={{ color: "#cf1322" }} />}
        style={{ marginBottom: 0, border: "none" }}
      />
    </Popover>
  );
};

export default DeleteItem;
