/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-closing-tag-location */
import {
  CheckCircleFilled,
  ContainerOutlined,
  DollarCircleFilled,
  PlusCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from "antd";
import {
  resetCart,
  selectedCustomer,
  setDraftItems,
} from "features/pos/posSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import localforage from "localforage";
import PaymentModal from "./PaymentModal";

const { Option } = Select;

const PosCalculationBlock = ({
  discountPercentage,
  setDiscountPercentage,
  serviceCharge,
  setServiceCharge,
  adjustAmount,
  setAdjustAmount,
}) => {
  const { cartData, activeCustomer } = useSelector((state) => state.posData);
  const dispatch = useDispatch();

  const [totalAmount, setTotalAmount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);

  const itemsPrice = cartData.reduce((a, c) => a + c.quantity * c.price, 0);
  const taxPrice = itemsPrice * 0.14;
  const shippingPrice = itemsPrice > 2000 ? 0 : 20;
  const totalPrice = itemsPrice + taxPrice + shippingPrice;
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const sum = cartData.reduce((acc, c) => acc + (c.tax_amount * c.quantity), 0);
  const texSum = sum.toFixed(2);

  const showModal = () => {
    if (!activeCustomer) {
      message.error("Customer is required");
      return;
    }
    setIsOpenPaymentModal(true);
  };

  useEffect(() => {
    if (cartData.length) {
      const total = cartData.reduce((a, c) => a + c.quantity * c.sale_price, 0);
      setTotalAmount(total);
    }
  }, [cartData]);

  useEffect(() => {
    const grandTotal =
      totalAmount -
      totalAmount * (discountPercentage / 100) +
      serviceCharge -
      adjustAmount;
    setGrandTotal(grandTotal);
  }, [totalAmount, discountPercentage, serviceCharge, adjustAmount]);

  const resetHandler = () => {
    dispatch(selectedCustomer(null));
    dispatch(resetCart());
    setDiscountPercentage(0);
    setAdjustAmount(0);
    setServiceCharge(0);
    setTotalAmount(0);
  };

  const draftHandler = () => {
    if (!activeCustomer) {
      message.error("Customer is required");
      return;
    }
    const draftData = {
      cartData,
      activeCustomer,
      discountPercentage,
      serviceCharge,
      adjustAmount,
    };

    localforage.getItem("pos_draft").then((res) => {
      if (!res) {
        localforage.setItem("pos_draft", [draftData]).then((res) => {
          dispatch(setDraftItems(draftData));
          resetHandler();
        });
      } else {
        const isExist = res.filter(
          (item) => item.activeCustomer.id === activeCustomer.id
        );
        if (isExist.length) {
          message.error("User already in draft");
        } else {
          const newData = [...res, draftData];
          localforage.setItem("pos_draft", newData).then((res) => {
            dispatch(setDraftItems(newData));
            resetHandler();
          });
        }
      }
    });
  };

  // const handleOk = () => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //     setOpen(false);
  //   }, 3000);
  // };
  // const handleCancel = () => {
  //   setOpen(false);
  // };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log(values);
  };
  return (
    <div className="pos__accounting__area">
      <div className="pos__accounting__wrapper">
        <div className="pos__total__input_area">
          <Form layout="vertical">
            <Row>
              <Col span={12} lg={6}>
                <Form.Item label="Tax Amount">
                  <Input
                    size="small"
                    style={{
                      width: 90,
                      margin: "0 0px",
                      color: "#23253C",
                      fontWeight: "bold"
                    }}
                    // value={cartData.reduce((a, c) => a + (c.tax_amount * c.quantity), 0)}
                    value={texSum}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={12} lg={5}>
                <Form.Item label="Discount(%)">
                  <Input
                    size="small"
                    style={{
                      width: 80,
                      margin: "0 0px",
                    }}
                    onChange={(e) => setDiscountPercentage(+e.target.value)}
                  />
                </Form.Item>
              </Col>

              <Col span={12} lg={7}>
                <Form.Item label="Service Charge">
                  <Input
                    size="small"
                    style={{
                      width: 100,
                      margin: "0 0px",
                    }}
                    onChange={(e) => setServiceCharge(+e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} lg={6}>
                <Form.Item label="Adjustment">
                  <Input
                    size="small"
                    style={{
                      width: 90,
                      margin: "0 0px",
                    }}
                    onChange={(e) => setAdjustAmount(+e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="pos__total__calculation__area">
          <div className="pos__item__number">
            Total Item :<span> {cartData.length} </span>
          </div>
          <div className="pos__subtotal__cost">
            SubTotal : <span>${parseFloat(totalAmount).toFixed(2)}</span>
          </div>
          <div className="pos__total__cost">
            Total :<span> ${parseFloat(grandTotal).toFixed(2)}</span>
          </div>
        </div>
      </div>
      <div className="pos__action__button__area">
        <div className="pos__calculation__btn">
          <Button icon={<RedoOutlined />} size="small" onClick={resetHandler} style={{ width: "30%" }}>
            Reset
          </Button>
          <Button
            type="primary"
            icon={<ContainerOutlined />}
            size="small"
            onClick={draftHandler}
            style={{ width: "30%" }}
          >
            Draft
          </Button>

          <Button
            type="primary"
            icon={<DollarCircleFilled />}
            size="small"
            onClick={showModal}
            style={{ width: "30%" }}
          >
            Pay Now
          </Button>
        </div>
        <PaymentModal
          payable_amount={grandTotal}
          isOpenPaymentModal={isOpenPaymentModal}
          setIsOpenPaymentModal={setIsOpenPaymentModal}
          discountPercentage={discountPercentage}
          serviceCharge={serviceCharge}
          adjustAmount={adjustAmount}
          resetHandler={resetHandler}
        />
      </div>
    </div>
  );
};

export default PosCalculationBlock;
