/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Image, Layout, Menu, Space } from "antd";
import { useState, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import { PieChartOutlined } from "@ant-design/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Scrollbars from "react-custom-scrollbars-2";
// import { items } from "../../data/Dummy";
// import LogoutBtn from "./LogoutBtn";
import { useSelector } from "react-redux";
import logo from "../../assets/images/cis_logo.svg";
import SidebarLoading from "./SidebarLoader";

const { Sider } = Layout;
const Sidebar = () => {
  const history = useNavigate();
  const { user } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: index.js:19 ~ Sidebar ~ user:", user);
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState("/overview/dashboard");
  const forceRerenderRef = useRef(false);

  const [userMenus, setUserMenus] = useState([]);

  const { data, isLoading } = useQuery(
    ["getusermenuv2?username=superadmin"],
    () =>
      Axiosinstance.get(`getusermenuv2?username=${user.user_name}`).then(
        (res) => res.data.allmenu
      )
  );

  useEffect(() => {
    if (data) {
      const userMenu = data?.map((item) => {
        return {
          label: (
            <span>
              <i className={item.icon} style={{ paddingRight: "5px" }} />
              {item.name}
            </span>
          ),
          key: item.id,
          type: "group",
          children: item.submodule.map((child) => {
            if (`/${item.urlkey}/${child.urlkey}` === location.pathname) {
              setSelectedKey([`/${item.urlkey}/${child.urlkey}`]);
            }
            return {
              label: (
                <Link to={`/${item.urlkey}/${child.urlkey}`}>{child.name}</Link>
              ),
              key: `/${item.urlkey}/${child.urlkey}`,
              icon: <PieChartOutlined style={{ fontSize: "23px" }} />,
            };
          }),
        };
      });
      setUserMenus(userMenu);
    }
  }, [data]);

  // useEffect(() => {
  //   // console.log("Selected Key:", selectedKey);
  //   // Perform any action or trigger the reload here
  //   // history.push(selectedKey); // Reflect changes in the address bar
  // }, [selectedKey]);

  const handleMenuItemClick = (e) => {
    const clickedKey = e.key;
    if (clickedKey === selectedKey) {
      setSelectedKey("");
      history(clickedKey);
      forceRerenderRef.current = true; // Set forceRerender using useRef
      // setTimeout(() => {
      //   forceRerenderRef.current = false; // Reset forceRerender after a delay
      // }, 50);
    } else {
      setSelectedKey(clickedKey);
    }
    console.log("Clicked Key:", clickedKey);
  };

  if (isLoading) {
    return <SidebarLoading />;
  }

  return (
    <Sider
      // trigger={<LogoutBtn />}
      collapsible
      collapsed={collapsed}
      width={250}
      className="sidebar__area"
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <div className="sidebar__menu__area">
        <i
          className={`icon ${collapsed ? "icon-menu-ex" : "icon-menu"}`}
          onClick={() => setCollapsed(!collapsed)}
        />
        <Link to="/dashboard">
          {/* <div className="logo__area">
            <Space>
              <i className="icon icon-brand-logo" />
              <div className="brand__name">Company </div>
            </Space>
          </div> */}
          <Image src={logo} preview={false} width={180} height={64} />
        </Link>
      </div>

      {/* <main className="menu"> */}
      <Scrollbars className="menu">
        <Menu
          theme="light"
          mode="inline"
          onClick={handleMenuItemClick}
          // onClick={(e) => setSelectedKey(e.key)}
          // defaultSelectedKeys={1.1}
          selectedKeys={selectedKey}
          items={userMenus}
          forceSubMenuRender={forceRerenderRef.current} // Access forceRerender using .current
        />
        {/* </main> */}
      </Scrollbars>
    </Sider>
  );
};

export default Sidebar;
