import React, { useEffect, useState } from "react";
import { Button, Card, Space, Table, Tag } from "antd";

const ResturentService = ({ resService }) => {
  const [res, setres] = useState();

  useEffect(() => {
    const infos = resService?.map((item) => {
      return {
        ...item,
        item_name: item.item_name,
        barcode: item.barcode,
        description: item.description,
        quantity: item.quantity,
        amount: `$${item.amount}`,
        unit_price: `$${item.unit_price}`,
        discount_percentage_amount: `$${item.discount_percentage_amount}`,
        tax_percentage_amount: `$${item.tax_percentage_amount}`,
        total: `$${item.total}`,
      };
    });
    setres(infos);
  }, [resService]);
  const columns = [
    {
      title: <span className="table__title"> item name</span>,
      dataIndex: "item_name",
      key: "item_name",
    },
    {
      title: <span className="table__title"> barcode</span>,
      dataIndex: "barcode",
      key: "barcode",
    },
    {
      title: <span className="table__title"> quantity</span>,
      dataIndex: "quantity",
      align: "right",
      key: "quantity",
    },
    {
      title: <span className="table__title"> price</span>,
      dataIndex: "unit_price",
      align: "right",
      key: "unit_price",
    },
    {
      title: <span className="table__title"> discount</span>,
      dataIndex: "discount_percentage_amount",
      align: "right",
      key: "discount_percentage_amount",
    },
    {
      title: <span className="table__title"> tax</span>,
      dataIndex: "tax_percentage_amount",
      align: "right",
      key: "tax_percentage_amount",
    },
    {
      title: <span className="table__title"> total</span>,
      dataIndex: "total",
      align: "right",
      key: "total",
    },

  ];
    // date: `${new Date(item?.date).
    // toLocaleDateString()}-${new Date(item?.check_out).toLocaleDateString()}`,
  return (
    <Card
      className="card__paddingless card__border__radiusless card__box__shadow card__custom__bg for__scroll"
      title="RESTAURANT BILL & SERVICE"
      extra={
        <Button
          type="primary"
          href="/restaurant/addnewposv2"
          target="_blank"
        >
          ADD ITEM
        </Button>
    }
    >
      <Table
        pagination={false}
        columns={columns}
        dataSource={res}
        bordered
        scroll={{
          y: 240,
        }}
      />
    </Card>
  );
};

export default ResturentService;
