/* eslint-disable no-unsafe-optional-chaining */
import { Card, Col, Divider, Form, message, Row } from "antd";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import GetModuleFields from "utils/GetModuleFields";
import GlobalLoader from "components/Loader/GlobalLoader";
import SelectRequest from "components/Input/SelectRequest";
import InputSelect from "components/Input/InputSelect";
import Axiosinstance from "utils/AxiosInstance";
import DATA_TEMP from "./data.json";

const NewUser = () => {
  const [DATA, setDATA] = useState(DATA_TEMP);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [role, setRole] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [hotel, setHotel] = useState(null);

  // useEffect(() => {
  //   const handleHotelRole = DATA.input_fields.find((field) => field.name === "hotel_id");
  //   const handleInputChange = (event) => {
  //     const newValue = event.target.value;
  //     console.log("Input field value changed:", newValue);
  //   };

  //   useEffect(() => {
  //     const inputField = document.getElementById(handleHotelRole.name);
  //     inputField.addEventListener("change", handleInputChange);

  //     return () => {
  //       inputField.removeEventListener("change", handleInputChange);
  //     };
  //   }, []);
  // });

  useEffect(() => {
    if (id) {
      const data = {
        ...DATA,
        input_fields: DATA_TEMP.input_fields.map((item) => {
          return {
            ...item,
            input: item.input.map((input) => {
              if (
                input.type === "password" ||
                input.type === "retype_password"
              ) {
                return {
                  ...input,
                  required: false,
                };
              }
              return input;
            }),
          };
        }),
      };
      setDATA(data);
    }
    return () => {
      const data = {
        ...DATA,
        input_fields: DATA_TEMP.input_fields.map((item) => {
          console.log(item);
          return {
            ...item,
            input: item.input.map((input) => {
              if (
                input.type === "password" ||
                input.type === "retype_password"
              ) {
                return {
                  ...input,
                  required: true,
                };
              }
              return input;
            }),
          };
        }),
      };
      setDATA(data);
    };
  }, []);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, [DATA]);

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axiosinstance.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg.password);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    const image = (image) => {
      if (image) {
        if (image[0]?.response) {
          return [image[0]?.response];
        }
        return image;
      }
      return [];
    };
    const data = {
      ...values,
      image: image(values.image),
    };

    // if (!data.password) {
    //   message.warning("Please enter a password");
    //   return;
    // }

    // if (!data.retype_password) {
    //   message.warning("Please retype the password");
    //   return;
    // }

    // if (data.password !== data.retype_password) {
    //   message.warning("Password does not match");
    //   return;
    // }

    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axiosinstance.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );
  console.log(data, "data");

  // useEffect(() => {
  //   const handleHotelRoles = (value) => {
  //     console.log(value, "value");
  //     Axiosinstance.get(`user/allroles/${value}`)
  //       .then((res) => {
  //         // setRoomtype(res.data);
  //         console.log(res.data.data, "roomtype");
  //         setRole(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  // }, [id, data]);

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        image: data?.image !== null ? data?.image : [],
      };
      // Remove a field from object

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  const handleRoomChange = (value) => {
    console.log(value, "value");
    Axiosinstance.get(`user/hotelwiserole/${value}`)
      .then((res) => {
        // setRoomtype(res.data);
        console.log(res.data.data, "roomtype");
        setHotel(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFieldsChange = (changedFields) => {
    // if (changedFields.length > 1) return;
    // if (changedFields[0]?.name[0] === "password") {
    //   form.setFieldsValue({
    //     retype_password: changedFields[0]?.value,
    //   });
    // }
  };

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
        onFieldsChange={onFieldsChange}
      >
        <Row gutter={[16, 0]}>
          <Divider
            orientation="left"
            orientationMargin="0"
            className="input__devider"
          >
            Hotel & Role
          </Divider>
          <Col span={12}>
            <SelectRequest
              path="overview/hotellist?is_dep=1"
              name="hotel_id"
              onChange={handleRoomChange}
              placeholder="Select hotel"
              label="Hotel"
              required
            />
          </Col>
          <Col span={12}>
            <InputSelect
              items={hotel}
              name="user_roles"
              placeholder="Select a role"
              // disabled={hotel.length === 0}
              label="User Role"
              displayKey="user_roles"
              valueKey="id"
            />
          </Col>
          <GetModuleFields data={DATA?.input_fields} />
          <Suspense fallback={<Loader />}>
            <FormSubmitBtns isLoading={isLoading} />
          </Suspense>
        </Row>
      </Form>
    </Card>
  );
};

export default NewUser;
