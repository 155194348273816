/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, Suspense, useEffect } from "react";
import { Input, Button, Col, Space, Drawer, message, Popconfirm, DatePicker, Select } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import FileAttachment from "components/Input/FileAttachment";
import { useParams } from "react-router-dom";
import Axiosinstance from "utils/AxiosInstance";
import { useMutation } from "react-query";

const CheckInModal = ({ visible, onCancel }) => {
  const { id } = useParams();
  const [generalInfo, setGeneralInfo] = useState("");
  console.log("🚀 ~ file: NewGuestModal.jsx:14 ~ CheckInModal ~ generalInfo:", generalInfo.multiple_guests);
  const [allGuest, setAllGuest] = useState([]);

  useEffect(() => {
    Axiosinstance.get(`booking/bookingdetails/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setGeneralInfo(data?.data);
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  }, [id]);

  const [state, setState] = useState([
    {
      name: "",
      phone: "",
      email: "",
      check_in: "",
      address: "",
      emergency_contact: "",
      photo_identification: "",
      status: 1,
      attachment: "",
    },
  ]);

  const handleAddRows = () => {
    const guest_Items = {
      name: "",
      phone: "",
      email: "",
      check_in: "",
      address: "",
      emergency_contact: "",
      photo_identification: "",
      status: "",
      attachment: "",
    };
    setState([...state, guest_Items]);
  };

  const addGuest = async (values) => {
    const { data: response } = await Axiosinstance.post(`https://hotelmgtapi.cisstaging.com/api/booking/newguests/${generalInfo.booking_id}`, values);
    console.log(values, "values");
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axiosinstance.patch(`https://hotelmgtapi.cisstaging.com/api/booking/updateguest/${generalInfo.booking_id}`, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(generalInfo.booking_id ? editRecord : addGuest, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
      setAllGuest(data?.data?.multiple_guests);
      onCancel();
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  useEffect(() => {
    if (generalInfo) {
      setState(generalInfo?.multiple_guests);
      // console.log(editingRow, "editingRow");
    } else {
      setState([
        {
          name: "",
          phone: "",
          email: "",
          check_in: "",
          address: "",
          emergency_contact: "",
          photo_identification: "",
          status: 1,
          attachment: "",
        },
      ]);
    }
  }, [generalInfo]);

  const onFinish = () => {
    const data = {
      guest_info: state,
    };
    if (generalInfo?.multiple_guests.id) {
      mutate(generalInfo?.multiple_guests.id, data)
        .then((response) => {
          if (response.status === 200) {
            message.success("Guest updated successfully");
            onCancel();
          } else {
            message.error("Failed to update the guest");
          }
        });
    } else {
      mutate(data);
    }
  };

  const handleChange = (e, id, field_name) => {
    const list = [...state];
    list[id][field_name] = e;
    setState(list);
  };

  const handleRowDelete = (id) => {
    if (state.length === 1) {
      message.warning("You can not delete this item");
    } else {
      const newArr = state.filter((item, index) => index !== id);
      setState(newArr);
    }
  };

  return (
    <Drawer title="New Guest" width={1400} onClose={onCancel} open={visible} bodyStyle={{ paddingBottom: 80 }}>

      <Col lg={24} span={24}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          <p style={{ fontWeight: "450", fontSize: "16px" }}>
            Guests Information
          </p>
          <hr style={{ flexGrow: "1", margin: "0 20px" }} />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            style={{ margin: 0 }}
            disabled={state.length >= generalInfo.total_guest}
            onClick={() => handleAddRows()}
          >
            Add More
          </Button>
        </div>
      </Col>
      <Col lg={24} span={24}>
        <table className="tranx_table">
          <thead>
            <tr>
              <th style={{ width: "3%", textAlign: "center" }} />
              <th style={{ width: "10%", textAlign: "center" }}>Name</th>
              <th style={{ width: "11%", textAlign: "center" }}>Phone</th>
              <th style={{ width: "11%", textAlign: "center" }}>Email</th>
              <th style={{ width: "10%", textAlign: "center" }}>Check In</th>
              <th style={{ width: "12%", textAlign: "center" }}>Address</th>
              <th style={{ width: "10%", textAlign: "center" }}>Emergency Contact</th>
              <th style={{ width: "11%", textAlign: "center" }}>Photo ID</th>
              <th style={{ width: "10%", textAlign: "center" }}>Status</th>
              <th style={{ width: "11%", textAlign: "center" }}>Attachment</th>
            </tr>
          </thead>
          <tbody>
            {state?.map((item, idx) => (
              <tr id="addr0" key={idx}>
                <td style={{ textAlign: "center" }}>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => handleRowDelete(idx)}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </td>
                <td>
                  <Input
                    name="name"
                    className="gx-w-100 tranx_input_number"
                    placeholder="Name"
                    value={item.name}
                    onChange={(e) => handleChange(e.target.value, idx, "name")}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    name="phone"
                    className="gx-w-100 tranx_input_number"
                    placeholder="Conatct No."
                    value={item.phone}
                    onChange={(e) => handleChange(e.target.value, idx, "phone")}
                  />
                </td>
                <td>
                  <Input
                    name="email"
                    className="gx-w-100 tranx_input_number"
                    placeholder="Email"
                    value={item.email}
                    onChange={(e) => handleChange(e.target.value, idx, "email")}
                  />
                </td>
                <td>
                  <DatePicker
                    name="check_in"
                    className="gx-w-100 tranx_input_number"
                    placeholder="Check In"
                    value={item.check_in}
                    onChange={(e) => handleChange(e, idx, "check_in")}
                  />
                </td>
                <td>
                  <Input
                    name="Address"
                    className="gx-w-100 tranx_input_number"
                    rows={1}
                    value={item.address}
                    placeholder="Full Address"
                    onChange={(e) => handleChange(e.target.value, idx, "address")}
                  />
                </td>
                <td>
                  <Input
                    type="number"
                    name="emergency_contact"
                    className="gx-w-100 tranx_input_number"
                    value={item.emergency_contact}
                    placeholder="Emergency Contact"
                    onChange={(e) => handleChange(e.target.value, idx, "emergency_contact")}
                  />
                </td>
                <td>
                  <Input
                    type="text"
                    name="photo_identification"
                    className="gx-w-100 tranx_input_number"
                    value={item.photo_identification}
                    placeholder="Photo ID"
                    onChange={(e) => handleChange(e.target.value, idx, "photo_identification")}
                  />
                </td>
                <td>
                  <Select
                    name="status"
                    className="gx-w-100"
                    placeholder="Status"
                    value={item.status}
                    onChange={(e) => handleChange(e, idx, "status")}
                  >
                    <Select.Option value={1}>Check In Now</Select.Option>
                    <Select.Option value={2}>Check In Later</Select.Option>
                  </Select>
                </td>
                <td>
                  <FileAttachment
                    name="attachment"
                    className="gx-w-100 tranx_input_number"
                    value={item.attachment}
                    placeholder="Attachment"
                    onChange={(e) => handleChange(e, idx, "attachment")}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Space style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}>
          <Button
            type="primary"
            className="gx-mb-0"
            icon={<CheckCircleOutlined />}
            onClick={onFinish}
            loading={isLoading}
          >
            Confirm Check In
          </Button>
          <Button
            type="primary"
            className="gx-mb-0"
            icon={<CloseCircleOutlined />}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Space>
      </Col>
    </Drawer>
  );
};

export default CheckInModal;
