import { Avatar, Button, Image } from "antd";
import { userLoggedOut } from "features/auth/authSlice";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const UserContent = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const handleSignOut = () => {
    dispatch(userLoggedOut());
    queryClient.clear();
    navigate("/signin");
  };
  return (
    <div className="user-dropdwon">
      <figure className="user-dropdwon__info">
        <Avatar src={user?.image?.url} alt={user?.name} preview={false} size={35} />
        <figcaption>
          <h4 className="user-dropdown__name">{user?.name}</h4>
          <span style={{ fontSize: "12px", color: "#464545" }}>{user?.user_name}</span>
        </figcaption>
      </figure>
      <ul className="user-dropdwon__links">
        <li>
          <Link to="/">Profile</Link>
        </li>
        <li>
          <Link to="/"> Settings</Link>
        </li>
        <li>
          <Link to="/">Billing</Link>
        </li>
        <li>
          <Link to="/"> Activity</Link>
        </li>
        <li>
          <Link to="/">Help</Link>
        </li>
      </ul>
      <Button
        block
        type="dashed"
        className="user-dropdwon__bottomAction"
        onClick={handleSignOut}
      >
        Sign Out
      </Button>
    </div>
  );
};

export default UserContent;
