import { Form, Input } from "antd";
import React from "react";

const ReTypePassword = ({
  name = "retype_password",
  label = "Retype Password",
  placeholder = "Enter your password again",
  required = false,
  ...arg
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      //   rules={[{ required, message: `Please input your ${label}!` }]}
      {...arg}
      dependencies={["password"]}
      hasFeedback
      rules={[
        {
          required,
          message: `Please input your ${label}!`,
        },
        {
          min: 6,
          message: "Password must be at least 6 characters",
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("The two passwords that you entered do not match!")
            );
          },
        }),
      ]}
    >
      <Input.Password placeholder={placeholder} size="large" />
    </Form.Item>
  );
};

export default ReTypePassword;
