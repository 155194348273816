import { Card, Tabs } from "antd";
import React from "react";
import LoginHistory from "./LoginHistory";
import UserActivity from "./UserActivityLog";
import ChangeLog from "./UserChangeLog";
import DeletdLog from "./DeletedData";

const Audit = () => {
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "Login History",
      children: <LoginHistory />,
    },
    {
      key: "2",
      label: "User Activity",
      children: <UserActivity />
    },
    {
      key: "3",
      label: "Change Log",
      children: <ChangeLog />,
    },
    {
      key: "4",
      label: "Deleted Log",
      children: <DeletdLog />,
    },
  ];
  return (
    <Card>
      <Tabs size="large" defaultActiveKey="1" items={items} onChange={onChange} />
    </Card>
  );
};

export default Audit;
