/* eslint-disable radix */
import React, { Component, useState } from "react";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";
import { Button, message, Popover, Tag, Input, Radio, Space } from "antd";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline";
import RoomStatus from "./RoomStatus";

import generateFakeData from "./generate-fake-data";

const content = (
  <div className="room__status__button">
    <RoomStatus />
  </div>
);

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title"
};

export default class App extends Component {
  constructor(props) {
    super(props);

    const { groups, items } = generateFakeData(150);
    const defaultTimeStart = moment()
      .startOf("day")
      .toDate();
    const defaultTimeEnd = moment()
      .startOf("day")
      .add(1, "day")
      .toDate();

    // convert every 2 groups out of 3 to nodes, leaving the first as the root
    const newGroups = groups.map((group) => {
      const isRoot = (parseInt(group.id) - 1) % 3 === 0;
      const parent = isRoot ? null : Math.floor((parseInt(group.id) - 1) / 3) * 3 + 1;

      return { ...group,
        root: isRoot,
        parent };
    });

    this.state = {
      groups: newGroups,
      items,
      defaultTimeStart,
      defaultTimeEnd,
      openGroups: {}
    };
  }

  toggleGroup = (id) => {
    const { openGroups } = this.state;
    this.setState({
      openGroups: {
        ...openGroups,
        [id]: !openGroups[id]
      }
    });
  };

  render() {
    const { groups, items, defaultTimeStart, defaultTimeEnd, openGroups } = this.state;

    const newGroups = groups
      .filter((g) => g.root || openGroups[g.parent])
      .map((group) => {
        return { ...group,
          title: group.root ? (
            // eslint-disable-next-line radix, react/jsx-no-comment-textnodes
            <div role="presentation" onClick={() => this.toggleGroup(parseInt(group.id))} style={{ cursor: "pointer", color: "red" }}>
              {openGroups[parseInt(group.id)] ? "[-]" : "[+]"} {group.title}
            </div>
          ) : (
          // <div style={{ paddingLeft: 20, color: "green" }}>{group.title}</div>
            <div className="hotel__name__status" style={{ paddingLeft: 10, color: "green" }}>
              <div className="hotel__status__area">
                <span className="status__box" />
                <span>{group.title}</span>
              </div>
              <span className="room__status">
                <Popover placement="topLeft" content={content} trigger="click">
                  <MoreOutlined />
                </Popover>
              </span>
            </div>
          ) };
      });
    console.log("groups=====", groups);
    return (
      <Timeline
        groups={newGroups}
        items={items}
        keys={keys}
        sidebarWidth={150}
        canSelect
        sidebarContent={<div>Above The Left</div>}
        itemsSorted
        itemTouchSendsClick={false}
        stackItems
        itemHeightRatio={0.75}
        showCursorLine
        canMove
        canResize="right"
        defaultTimeStart={defaultTimeStart}
        defaultTimeEnd={defaultTimeEnd}
      >

        <TimelineHeaders className="timeline_calender_sticky">
          <SidebarHeader style={{ width: "250px" }}>
            {({ getRootProps }) => {
              return <div {...getRootProps()}>Left</div>;
            }}
          </SidebarHeader>
          <DateHeader unit="primaryHeader" className="timeline__calender__top__header" />
          <DateHeader className="timeline__calender__top__datebar" />
        </TimelineHeaders>
      </Timeline>
    );
  }
}
