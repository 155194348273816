/* eslint-disable max-len */
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, PlusCircleOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Drawer, Select, Table, notification, DatePicker, message, Space } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import "moment/locale/en-gb";
import { useEffect, useState } from "react";
import { MdOutlineRoomService } from "react-icons/md";
import Axiosinstance from "utils/AxiosInstance";

const AddRoomService = ({
  addRoomService,
  setAddRoomService,
  booking_id,
  onUpdateRoomService,
  setGeneralInfo }) => {
  const [tableData, setTableData] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  // console.log("selectOptions===================", selectOptions);
  const [selectedName, setSelectedName] = useState("");
  const dateFormat = "YYYY-MM-DD";
  moment.locale("en-gb"); // Set the locale if needed
  const fetchData = async () => {
    try {
      const response = await Axiosinstance.get("rooms_ratings/roomotherserviceslist?is_dep=1");
      const apiData = response.data.data;
      // console.log("apiData----->", apiData);
      const options = apiData.map((item) => ({
        id: item.id,
        value: item.id.toString(), // Use the ID as the value for Select
        name: item.name,
        type: item.type,
        price: item.charge,
        discount: item.discount,
        tax: item.tax,
        is_day_wise: item.is_day_wise,
      }));
      setSelectOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchData()
      .catch((error) => {
        console.error("Error fetching options:", error);
      });

    setTableData([
      {
        id: "",
        name: "",
        type: "",
        price: 0,
        discount: 0,
        tax: 0,
        total: 0,
        dateStart: null,
        dateEnd: null,
      }
    ]);
  }, []);

  const handleAddRow = () => {
    const newRow = {
      id: "",
      name: "",
      type: "",
      price: 0,
      discount: 0,
      tax: 0,
      total: 0,
      dateStart: null,
      dateEnd: null,
    };
    setTableData([...tableData, newRow]);
  };

  const handleCancel = (record) => {
    const updatedData = tableData.filter((item) => item !== record);
    const canceledItem = tableData.find((item) => item.id === record.id);
    // console.log("canceledItem-------------------->>", canceledItem);
    const updatedOptions = [...selectOptions, canceledItem];
    setTableData(updatedData);
    setSelectOptions(updatedOptions);
  };

  const handleSave = async () => {
    // Check if any 'name' field is empty in tableData
    const hasEmptyNameField = tableData.some((item) => !item.name);
    const hasEmptyDateField = tableData.some((item) => !item.dateStart || !item.dateEnd);

    if (hasEmptyNameField) {
      message.error("Please fill in all 'name' fields before saving.");
      return; // Exit the function early if there are empty 'name' fields
    }

    if (hasEmptyDateField) {
      message.error("Please fill in all date fields before saving.");
      return; // Exit the function early if there are empty date fields
    }

    try {
      // Continue with saving data if all fields are filled
      const postData = tableData.map((item) => ({
        service_id: item.id,
        service_name: item.name,
        service_from: item.dateStart,
        service_to: item.dateEnd,
        quantity: 1,
        charge: item.price,
        total_charge: item.price - item.discount + item.tax,
        discount_percentage: item.discount,
        tax_percentage: item.tax,
      }));

      const response = await Axiosinstance.post(`booking/addserviceitem/${booking_id}`, {
        service_items: postData,
      });

      if (response.data.status === 1) {
        // Fetch all room service names and set the table data
        const roomServiceResponse = await Axiosinstance.get("rooms_ratings/roomotherserviceslist?is_dep=1");
        const roomServiceData = roomServiceResponse.data.data;

        const newRowData = roomServiceData.map((item) => ({
          id: item.id,
          value: item.id.toString(), // Use the ID as the value for Select
          name: item.name,
          type: item.type,
          price: item.charge,
          discount: item.discount,
          tax: item.tax,
          is_day_wise: item.is_day_wise,
        }));

        // Set the table data with the room service names
        setSelectOptions(newRowData);
        setTableData([
          {
            id: "",
            name: "",
            type: "",
            price: 0,
            discount: 0,
            tax: 0,
            total: 0,
            dateStart: null,
            dateEnd: null,
          }
        ]);

        Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
          .then((res) => res.data)
          .then((data) => {
            onUpdateRoomService(data?.data?.service_items);
            setGeneralInfo(data?.data);
          });

        setAddRoomService(false);
        message.success(response.data.msg);
      } else if (response.data.status === 0) {
        message.error(response.data.msg);
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      console.error("Error while saving:", error);
      message.error({ message: "Failed to save data" });
    }
  };

  const handleNameChange = (value, record) => {
    const selectedOption = selectOptions.find(
      (option) => option.id.toString() === value
    );

    if (selectedOption) {
      const updatedRecord = {
        ...record,
        id: selectedOption.id,
        name: selectedOption.name,
        type: selectedOption.type,
        price: selectedOption.price,
        discount: selectedOption.discount,
        tax: selectedOption.tax,
        total:
          selectedOption.price -
          selectedOption.discount +
          selectedOption.tax,
        is_day_wise: selectedOption.is_day_wise,
      };

      const updatedData = tableData.map((item) =>
        (item === record ? updatedRecord : item)
      );

      setSelectedName(selectedOption.name); // Set the selected name
      const updatedSelectOptions = selectOptions.filter(
        (option) => option.id !== selectedOption.id
      );

      setTableData(updatedData);
      setSelectOptions(updatedSelectOptions);
    } else {
      const updatedRecord = {
        ...record,
        id: "", // Reset ID to empty
        name: "",
        type: "",
        price: 0,
        discount: 0,
        tax: 0,
        total: 0,
        dateStart: null, // Reset date fields
        dateEnd: null,
      };

      const updatedData = tableData.map((item) =>
        (item === record ? updatedRecord : item)
      );

      setTableData(updatedData);
      setSelectedName(""); // Reset the selected name
    }
  };

  const handleDateChange = (field, value, record) => {
    const updatedRecord = {
      ...record,
      [field]: value,
    };
    const updatedData = tableData.map((item) => (item === record ? updatedRecord : item));
    setTableData(updatedData);
  };

  const columns = [
    {
      title: <span className="table__title"> service name</span>,
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Select
          value={record.name || undefined}
          style={{ width: "200px" }}
          onChange={(value) => handleNameChange(value, record)}
          placeholder="Select Service"
        >
          {selectOptions.map((option) => (
            <Select.Option key={option.id.toString()} value={option.id.toString()}>
              {option.name}
            </Select.Option>
          ))}
        </Select>

      ),
    },
    { title: <span className="table__title"> type</span>, dataIndex: "type", key: "type" },
    { title: <span className="table__title"> price</span>, dataIndex: "price", align: "right", key: "price" },
    { title: <span className="table__title"> discount(%)</span>, dataIndex: "discount", align: "right", key: "discount" },
    { title: <span className="table__title"> tax(%)</span>, dataIndex: "tax", align: "right", key: "tax" },
    { title: <span className="table__title"> total</span>, dataIndex: "total", align: "right", key: "total" },
    ...(selectOptions.map((option) => option.is_day_wise === 1)
      ? [
        {
          title: <span className="table__title"> date Start</span>,
          dataIndex: "dateStart",
          key: "dateStart",
          render: (text, record) =>
            (record.is_day_wise === 1 ? (
              <DatePicker
                value={text ? dayjs(text, dateFormat) : null}
                onChange={(value) => handleDateChange("dateStart", value ? value.format(dateFormat) : null, record)}
                format={dateFormat}
                style={{ width: "100px" }}
                rules={[
                  {
                    required: true,
                    message: "Please select the start date",
                  },
                ]}
              />
            ) : null),
        },

        {
          title: <span className="table__title"> date End</span>,
          dataIndex: "dateEnd",
          key: "dateEnd",
          render: (text, record) =>
            (record.is_day_wise === 1 ? (
              <DatePicker
                value={text ? dayjs(text, dateFormat) : null}
                onChange={(value) => handleDateChange("dateEnd", value ? value.format(dateFormat) : null, record)}
                format={dateFormat}
                style={{ width: "500px" }}
                rules={[
                  {
                    required: true,
                    message: "Please select the end date",
                  },
                ]}
              />
            ) : null),
        },
      ]
      : []),
    {
      title: <span className="table__title"> actions</span>,
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button type="primary" onClick={() => handleCancel(record)}>
            <CloseOutlined />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Drawer
      title={
        <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
          ADD ROOM SERVICE
        </span>}
      width="70%"
      closable={false}
      placement="right"
      open={addRoomService}
      onClose={() => setAddRoomService(false)}
      style={{ zIndex: "2001" }}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            <Button type="default" onClick={() => setAddRoomService(false)} icon={<CloseCircleOutlined />} style={{ marginTop: "20px", width: "250px" }}>
              Close
            </Button>
            <Button type="primary" onClick={handleSave} icon={<CheckCircleOutlined />} style={{ marginTop: "20px", width: "250px" }}>
              Submit
            </Button>
          </Space>
        </div>
      }
    >
      <div>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={handleAddRow}
          style={{ marginBottom: 16, float: "right" }}
          disabled={selectOptions.length === 0}
        >
          Add Service item
        </Button>
        <Table columns={columns} dataSource={tableData} pagination={false} />
      </div>
    </Drawer>
  );
};

export default AddRoomService;
