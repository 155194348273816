import { configureStore } from "@reduxjs/toolkit";
// import advSearchReducer from "redux/advSearchSlice";
// import dataSlice from "features/data/dataSlice";
import avdSearchSliceReducer from "features/avdSearch/avdSearchSlice";
import customizerSliceReducer from "features/customizer/customizerSlice";
import dataSliceReducer from "features/data/dataSlice";
import frontDeskReducer from "features/frontDesk/frontDeskSlice";
import posSliceReducer from "features/pos/posSlice";
import { apiSlice } from "../features/api/apiSlice";
import authSliceReducer from "../features/auth/authSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSliceReducer,
    avdSearch: avdSearchSliceReducer,
    json: dataSliceReducer,
    customizer: customizerSliceReducer,
    frontDesk: frontDeskReducer,
    posData: posSliceReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddlewares) =>
    getDefaultMiddlewares().concat(apiSlice.middleware),
});
