import App from "App";
import GlobalLoader from "components/Loader/GlobalLoader";
import useAuthCheck from "hooks/useAuthCheck";
import React from "react";

const Auth = () => {
  const authChecked = useAuthCheck();

  if (!authChecked) return <GlobalLoader />;
  return <App />;
};

export default Auth;
