import { Col, Radio, Row, message } from "antd";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import Axiosinstance from "utils/AxiosInstance";

const Status = ({ data }) => {
  const { room_status } = data;
  const queryClient = useQueryClient();
  const { selectedRoom } = useSelector((state) => state.frontDesk);

  const handleStatusChange = (e) => {
    console.log(e.target.value);
    if (selectedRoom) {
      Axiosinstance.patch(`frontdesk/updateroomstatus/${selectedRoom.id}`, {
        status: e.target.value,
      })
        .then((res) => {
          console.log("res------------------>>>", res);
          if (res.data.status === 1) {
            message.success(res.data.msg);
            queryClient.refetchQueries("frontdesk");
          }
        })
        .catch((err) => {
          console.log(err);
          message.error("Something Went Wrong");
        });
    } else {
      message.warning("Please Select Room First");
    }
  };

  return (
    <Radio.Group
      defaultValue="a"
      buttonStyle="solid"
      size="large"
      style={{ width: "100%" }}
      className="frontdesk__rooms"
      onChange={handleStatusChange}
    >
      <Row gutter={[24, 24]}>
        {room_status?.map((item) => (
          <Col span={24} lg={12}>
            <Radio.Button
              value={item.id}
              // size="large"
              className="frontdesk__blockitem"
              style={{
                // background: item.status_color,
                borderBottom: `5px solid ${item.status_color}`,
              }}
            >
              <p className="">{item.status_name}</p>
            </Radio.Button>
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
};

export default Status;
