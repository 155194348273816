import { Form, Rate } from "antd";

const InputRating = ({ name, label, required, count = 7 }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required, message: `Please input your ${label}!`
      }]}
    >
      <Rate
        count={count}
        defaultValue={0}
        onChange={(value) => console.log(value)}
      />
    </Form.Item>
  );
};

export default InputRating;
