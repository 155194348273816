/* eslint-disable no-unsafe-optional-chaining */
import { Card, Form, message, Row, Spin } from "antd";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import GetModuleFields from "utils/GetModuleFields";
import DATA from "./data.json";

const NewLeaveType = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [jsonData1, setJsonData1] = useState(DATA);
  // const [showInputs, setShowInputs] = useState(false);
  // const [inputFields1, setInputFields1] = useState([]);
  // const [inputFields2, setInputFields2] = useState([]);
  const dispatch = useDispatch();

  const removeAryInput = () => {
    const newJsonData = { ...DATA };
    const a = [...newJsonData?.input_fields[0]?.input];
    a.splice(5, 1);
    a.splice(5, 1);
    a.splice(5, 1);
    const newData = {
      ...DATA,
      input_fields: [
        {
          ...DATA.input_fields[0],
          input: a,
        },
      ],
    };
    setJsonData1(newData);
  };

  useEffect(() => {
    if (DATA.with_pay == 0) {
      dispatch(jsonData(DATA));
    } else {
      dispatch(jsonData(DATA));
    }
    removeAryInput();
    return () => {
      setJsonData1(DATA);
    };
  }, []);
  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    // console.log("values, values?.logo[0]?.uid", values);
    const data = {
      ...values,
    };
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
      };

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  const fieldChangeHandler = (changedFields, allFields) => {
    // console.log("changedFields, allFields", changedFields, allFields);

    if (changedFields[0]?.name[0] === "with_pay") {
      if (changedFields[0]?.value) {
        setJsonData1(DATA);
      } else {
        removeAryInput();
      }
    }
  };

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Spin spinning={isLoading || loading || DATA == null}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="cis__form"
          form={form}
          layout="vertical"
          onFieldsChange={fieldChangeHandler}
        >
          <Row gutter={[16, 0]}>
            <GetModuleFields data={jsonData1?.input_fields} />
            <Suspense fallback={<Loader />}>
              <FormSubmitBtns isLoading={isLoading} />
            </Suspense>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
};

export default NewLeaveType;
