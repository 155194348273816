/* eslint-disable no-unsafe-optional-chaining */
import { Card, Form, message, Row, Spin } from "antd";
import Loader from "components/Loader";
import dayjs from "dayjs";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import { dateFormat } from "utils/CISUI";
import GetModuleFields from "utils/GetModuleFields";
import DATA from "./data.json";

const NewEmp = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);
  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    // console.log("values, values?.logo[0]?.uid", values);
    const data = {
      ...values,
      dob: values?.dob?.format(dateFormat),
      joining_date: values?.joining_date?.format(dateFormat),

      // room_image: values?.room_image
      //   ? values?.room_image[0]?.response
      //     ? values?.room_image[0]?.response
      //     : values?.room_image
      //   : null,
      image: values?.image
        ? values.image.map((item) => {
          return item?.response ? item?.response : item;
        })
        : null,
      signature: values?.signature
        ? values.signature.map((item) => {
          return item?.response ? item?.response : item;
        })
        : null,
      cv: values?.cv
        ? values.cv.map((item) => {
          return item?.response ? item?.response : item;
        })
        : null,
    };
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        dob: dayjs(data.dob, dateFormat),
        joining_date: dayjs(data.joining_date, dateFormat),
        image: data?.image !== null ? data?.image : [],
        // image: data?.image !== null ? [data?.image] : [],
        cv: data?.cv !== null ? data?.cv : [],
        signature: data?.signature !== null ? data?.signature : [],
      };

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Spin spinning={isLoading || loading || DATA == null}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="cis__form"
          form={form}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <GetModuleFields data={DATA?.input_fields} />
            <Suspense fallback={<Loader />}>
              <FormSubmitBtns isLoading={isLoading} />
            </Suspense>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
};

export default NewEmp;
