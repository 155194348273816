import { useState } from "react";
import { Modal, DatePicker, Button } from "antd";
import moment from "moment";

const CheckoutModal = ({
  checkIn,
  checkOut,
  id,
  onSave,
  showCheckoutModal,
  setShowCheckoutModal
}) => {
  const [checkoutDate, setCheckoutDate] = useState(checkOut);

  const handleSave = () => {
    onSave(id, checkoutDate);
  };

  const handleCancel = () => {
    setShowCheckoutModal(false);
  };

  const handleDateChange = (date) => {
    setCheckoutDate(date);
  };

  const disabledDate = (current) => {
    const checkInDate = moment(checkIn);
    const checkOutDate = moment(checkOut);

    // Disable dates before check-in or after checkout
    return (
      (checkInDate && current && current < checkInDate.startOf("day")) ||
      (checkOutDate && current && current > checkOutDate.endOf("day"))
    );
  };
  //   const dateRender = (current) => {
  //     const isSelectedDate = current.isSame(checkoutDate, "day");
  //     const isCheckInDate = current.isSame(checkIn, "day");
  //     const isCheckOutDate = current.isSame(checkOut, "day");
  //     const isInRange =
  //       moment(current).isBetween(checkIn, checkOut, "day", "[]");

  //     let cellClass = "";

  //     if (isSelectedDate) {
  //       cellClass = "highlighted-date";
  //     } else if (isCheckInDate || isCheckOutDate) {
  //       cellClass = "check-in-out-date";
  //     } else if (isInRange) {
  //       cellClass = "range-date";
  //     }

  //     return (
  //       <div className={cellClass}>
  //         <div className="ant-picker-cell-inner">{current.date()}</div>
  //       </div>
  //     );
  //   };
  const dateRender = (current) => {
    const isSelectedDate = current.isSame(checkoutDate, "day");
    const isCheckInDate = current.isSame(checkIn, "day");
    const isCheckOutDate = current.isSame(checkOut, "day");
    const isInRange =
    moment(current).isBetween(checkIn, checkOut, "day", "[]") ||
      current.isSame(checkIn, "day") ||
      current.isSame(checkOut, "day");

    let cellClass = "";

    if (isSelectedDate) {
      cellClass = "highlighted-date";
    } else if (isCheckInDate || isCheckOutDate) {
      cellClass = "check-in-out-date";
    } else if (isInRange) {
      cellClass = "range-date";
    }

    return (
      <div className={cellClass}>
        <div className="ant-picker-cell-inner">{current.date()}</div>
      </div>
    );
  };

  return (
    <Modal
      title={
        <span style={{ fontSize: "18px", fontWeight: "bold", color: "#ff4d4f" }}>
          !!! Checkout
        </span>
      }
      open={showCheckoutModal}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={() => setShowCheckoutModal(false)}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>
      ]}
    >
      <p>
        Check-in Date:{" "}
        <span style={{ color: "#2a899d", fontWeight: "700" }}>{checkIn}</span>
      </p>
      <p>Check-out Date:</p>
      <DatePicker
        value={checkoutDate}
        onChange={handleDateChange}
        disabledDate={disabledDate}
        dateRender={dateRender}
        picker="date"
      />
      <style>{`
            .highlighted-date {
              background-color: #494d7c;
              color: #fff;
              border-radius: 4px;
            }
    
            .check-in-out-date {
              background-color: #494d7c;
              color: #fff;
              border-radius: 4px;
            }
      `}
      </style>
    </Modal>
  );
};

export default CheckoutModal;
