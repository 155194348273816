import { Button } from "antd";
import React from "react";

const CardTitle = ({ title, btnTitle }) => {
  return (
    <div className="card__title details__title">
      <span> {title}</span>
      {btnTitle ?
        <Button type="primary">
          {btnTitle}
        </Button> : ""
      }
    </div>
  );
};

export default CardTitle;
