import { Form, Checkbox } from "antd";

const options = [
  {
    label: "Monday",
    value: 1
  },
  {
    label: "Tuesday",
    value: 2
  },
  {
    label: "Wednesday",
    value: 3
  },
  {
    label: "Thursday",
    value: 4
  },
  {
    label: "Friday",
    value: 5
  },
  {
    label: "Saturday",
    value: 6
  },
  {
    label: "Sunday",
    value: 7
  }
];

const Weekdays = ({
  required = false,
  initialValue = null,
  label = "",
  name = "",
  checked = false,
  ...arg
}) => {
  return (
    <Form.Item
      rules={[{ required, message: `Please input your ${label}!` }]}
      {...arg}
      initialValue={initialValue}
      label={label}
      name={name}
    >
      <Checkbox.Group
        checked={checked}
        options={options}
      />
    </Form.Item>
  );
};

export default Weekdays;
