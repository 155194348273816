/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, InputNumber, Popconfirm, Radio, Row, Select, TreeSelect, Upload, message, } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Error404 from "containers/Error404";
import Axiosinstance from "utils/AxiosInstance";
import { addCompanyIfNotExist, dateFormat } from "utils/CISUI";
import GetDataFromAPI from "utils/GetDataFromAPI";
import IntlMessages from "utils/IntlMessages";
import ListPrintBtns from "shared/ListPrintBtns";
import Loader from "../../../components/Loader";
import DATA from "./data.json";

const NewJournal = () => {
  const [input, setInput] = useState(0);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [creditTotal, setCreditTotal] = useState(0.0);
  const [debitTotal, setDebitTotal] = useState(0.0);
  const [checkClearHide, setCheckClearHide] = useState(true);
  const [beneficiarySource, setBeneficiarySource] = useState(0);
  const [beneficiaryAccounts, setBeneficiaryAccounts] = useState([]);
  const { journal_id } = useParams();
  const queryClient = useQueryClient();

  const [state, setState] = useState([
    {
      account_id: undefined,
      beneficiary_type: undefined,
      beneficiary_account: undefined,
      item_description: "",
      credit: 0.0,
      debit: 0.0,
    },
  ]);

  const handleAddRows = () => {
    const items = {
      account_id: undefined,
      beneficiary_type: undefined,
      beneficiary_account: undefined,
      item_description: "",
      credit: 0.0,
      debit: 0.0,
    };
    setState([...state, items]);
  };

  const handleChange = (e, id, field_name) => {
    const list = [...state];

    if (field_name === "item_description") {
      const { name, value } = e.target;
      list[id][name] = value;
    } else if (field_name === "beneficiary_type") {
      list[id][field_name] = e;
      if (e === 5) {
        setBeneficiarySource(e);
      }
      Axiosinstance
        .get(`accounts/beneficiaries/${e}`)
        .then((res) => {
          if (res.data.status === 1) {
            setBeneficiaryAccounts(res.data.data);
          } else {
            message.error(res.data.msg);
          }
        });
    } else {
      list[id][field_name] = e;
    }

    let totalCredit = 0;
    list.map((item, index) => (totalCredit += item.credit));
    setCreditTotal(totalCredit);

    let totalDebit = 0;
    list.map((item, index) => (totalDebit += item.debit));
    setDebitTotal(totalDebit);
    setState(list);
  };

  const handleRowDelete = (id) => {
    if (state.length === 1) {
      message.warning("You can not delete this item");
    } else {
      const newArr = state.filter((item, index) => index !== id);
      setState(newArr);
    }
  };

  const accounts = GetDataFromAPI("accounts/chartofacclist?is_dep=1");
  const cashAccounts = GetDataFromAPI("accounts/chartofacclist?type_id=14");
  const beneficiary = GetDataFromAPI("accounts/beneficiaries");
  const AccData = [];
  const CashAccData = [];

  accounts?.map((acc, index) => {
    AccData.push({
      value: acc.id,
      title: acc.name,
      children: acc?.child?.map((child, ind) => {
        return {
          value: child.id,
          title: child.name,
        };
      }),
    });
  });
  cashAccounts?.map((acc, index) => {
    CashAccData.push({
      value: acc.id,
      title: acc.name,
      children: acc?.child?.map((child, ind) => {
        return {
          value: child.id,
          title: child.name,
        };
      }),
    });
  });

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(DATA.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axiosinstance.patch(DATA.urls.edit + journal_id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(journal_id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    const data = {
      ...values
    };
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const {
    data: payData,
    isError: isPayError,
    isLoading: isPayLoading,
  } = useQuery(
    [`${DATA.urls.details}_${journal_id}`],
    () =>
      Axiosinstance.get(DATA.urls.details + journal_id).then((res) => res.data.data),
    { enabled: !!journal_id }
  );

  // useEffect(() => {
  //   if (id !== undefined && data) {
  //     const values = {
  //       ...data,
  //       dob: dayjs(data.dob, dateFormat),
  //       joining_date: dayjs(data.joining_date, dateFormat),
  //       image: data?.image !== null ? data?.image : [],
  //       // image: data?.image !== null ? [data?.image] : [],
  //       cv: data?.cv !== null ? data?.cv : [],
  //       signature: data?.signature !== null ? data?.signature : [],
  //     };

  //     form.setFieldsValue(values);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [id, data]);

  useEffect(() => {
    if (journal_id !== undefined) {
      form.setFieldsValue({
        ...payData,
        date: dayjs(payData?.date, dateFormat),
      });
    } else {
      form.resetFields();
    }
  }, [journal_id, payData]);

  if (isPayLoading) {
    return <Loader />;
  }
  if (isPayError) {
    return <Error404 />;
  }

  return (
    <Card
      title="New Transaction"
      extra={<ListPrintBtns url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col lg={6} md={8} span={24}>
            <Form.Item name="date" label="Date" rules={[{ required: true, message: "Date is required" }]}>
              <DatePicker />
            </Form.Item>
          </Col>

          <Col lg={24} span={24}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <p style={{ fontWeight: "450", fontSize: "16px" }}>Items</p>
              <hr style={{ flexGrow: "1", margin: "0 20px" }} />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ margin: 0 }}
                onClick={() => handleAddRows()}
              >
                Add More
              </Button>
            </div>
          </Col>

          <Col lg={24} span={24}>
            <table className="tranx_table">
              <thead>
                <tr>
                  <th style={{ width: "2%" }} />
                  <th style={{ width: "22.5%" }}>Account</th>
                  <th style={{ width: "22.5%" }}>Description</th>
                  <th style={{ width: "22.5%" }}>Beneficiary</th>
                  <th style={{ width: "15%", textAlign: "right" }}>
                    Debit ($)
                  </th>
                  <th style={{ width: "15%", textAlign: "right" }}>
                    Credit ($)
                  </th>
                </tr>
              </thead>
              <tbody>
                {state?.map((item, idx) => (
                  <tr id="addr0" key={idx}>
                    <td style={{ width: "30px" }}>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => handleRowDelete(idx)}
                      >
                        {/* <Button type="primary" style={{ margin: 0 }} > */}
                        <DeleteOutlined />
                        {/* </Button> */}
                      </Popconfirm>
                    </td>
                    <td>
                      <TreeSelect
                        showSearch
                        style={{ width: "100%" }}
                        value={item.account_id}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        placeholder="Select account"
                        allowClear
                        treeDefaultExpandAll
                        onChange={(e) => handleChange(e, idx, "account_id")}
                        treeData={AccData}
                        loading={!(AccData?.length > 0)}
                      />
                    </td>
                    <td>
                      <Input.TextArea
                        name="item_description"
                        rows={3}
                        value={item.item_description}
                        placeholder="Enter description"
                        onChange={(e) => handleChange(e, idx, "item_description")}
                      />
                    </td>
                    <td>
                      <Select
                        placeholder="Select a beneficiary"
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                          option.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                                                }
                        allowClear
                        onChange={(e) =>
                          handleChange(e, idx, "beneficiary_type")
                                                }
                        value={item.beneficiary_type}
                      >
                        {beneficiary?.map((item, index) => (
                          <Select.Option key={index} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                      {
                        state[idx].beneficiary_type !== 5 ?
                          <Select
                            placeholder="Select a beneficiary account"
                            style={{ width: "100%", marginTop: "10px" }}
                            filterOption={(input, option) =>
                              option.children
                                .toString()
                                .toLowerCase()
                                .indexOf(input.toString().toLowerCase()) >= 0
                                                        }
                            allowClear
                            onChange={(e) =>
                              handleChange(e, idx, "beneficiary_account")
                                                        }
                            value={item.beneficiary_account}
                          >
                            {beneficiaryAccounts?.map((item, index) => (
                              <Select.Option key={index} value={item.id}>
                                {item.name}
                              </Select.Option>
                            ))}
                          </Select>
                          :
                          <TreeSelect
                            showSearch
                            style={{ width: "100%", marginTop: "10px" }}
                            value={item.beneficiary_account}
                            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                            placeholder="Select account"
                            allowClear
                            treeDefaultExpandAll
                            onChange={(e) => handleChange(e, idx, "beneficiary_account")}
                            treeData={AccData}
                            loading={!(AccData?.length > 0)}
                          />
                                            }
                    </td>
                    <td>
                      <InputNumber
                        className="gx-w-100 tranx_input_number"
                        value={item.debit}
                        placeholder="Enter amount"
                        onChange={(e) => handleChange(e, idx, "debit")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        className="gx-w-100 tranx_input_number"
                        value={item.credit}
                        placeholder="Enter amount"
                        onChange={(e) => handleChange(e, idx, "credit")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={4}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    Total
                  </td>
                  <td style={{ textAlign: "right", fontWeight: "bold" }}>
                    {(debitTotal)}
                  </td>
                  <td style={{ textAlign: "right", fontWeight: "bold" }}>
                    {(creditTotal)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </Col>

          <Col span={24}>
            <FormSubmitBtns form={form} isLoading={isLoading} />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default NewJournal;
