import { createSlice } from "@reduxjs/toolkit";

export const posSlice = createSlice({
  name: "posData",
  initialState: {
    cartData: [],
    categoryList: [],
    menuList: [],
    activeCustomer: null,
    customers: [],
    draftItems: [],
  },
  reducers: {
    addToCart: (state, action) => {
      const isCart = state.cartData.findIndex(
        (item) => item.id === action.payload.id
      );

      if (isCart > -1) {
        state.cartData[isCart].quantity += 1;
        return;
      }

      state.cartData.push({ ...action.payload, quantity: 1 });
    },
    resetCart: (state, action) => {
      state.cartData = [];
    },
    categoryList: (state, action) => {
      state.categoryList = action.payload;
    },
    menuList: (state, action) => {
      state.menuList = action.payload;
    },
    quantityIncrement: (state, action) => {
      const cartIndex = state.cartData.findIndex(
        (item) => item.id === action.payload
      );
      console.log("cis", cartIndex);
      state.cartData[cartIndex].quantity += 1;
    },
    quantityDecrement: (state, action) => {
      const cartIndex = state.cartData.findIndex(
        (item) => item.id === action.payload
      );

      if (state.cartData[cartIndex].quantity === 1) {
        state.cartData.splice(cartIndex, 1);
      } else {
        state.cartData[cartIndex].quantity -= 1;
      }
    },
    removeFromCart: (state, action) => {
      const cartIndex = state.cartData.findIndex(
        (item) => item.id === action.payload
      );
      state.cartData.splice(cartIndex, 1);
    },
    selectedCustomer: (state, action) => {
      state.activeCustomer = action.payload;
    },
    customerList: (state, action) => {
      state.customers = action.payload;
    },
    setDraftItems: (state, action) => {
      state.draftItems = [...action.payload];
    },
    addToCartFromDraft: (state, action) => {
      state.cartData = action.payload;
    },
  },
});

export const {
  addToCart,
  categoryList,
  menuList,
  quantityIncrement,
  quantityDecrement,
  removeFromCart,
  selectedCustomer,
  customerList,
  resetCart,
  setDraftItems,
  addToCartFromDraft,
} = posSlice.actions;
export default posSlice.reducer;
