import React, { useEffect, useState } from "react";
import { Menu, Drawer } from "antd";
// import { useNavigate } from "react-router-dom";
import { MenuOutlined, PieChartOutlined } from "@ant-design/icons";
import SidebarLoading from "components/Sidebar/SidebarLoader";
import { Link, useLocation } from "react-router-dom";
import Axiosinstance from "utils/AxiosInstance";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import GlobalLoader from "components/Loader/GlobalLoader";
// import { items } from "../../data/Dummy";

const TogleMenu = () => {
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);

  // const navigator = useNavigate();

  const [selectedKey, setSelectedKey] = useState("/overview/dashboard");

  const [userMenus, setUserMenus] = useState([]);

  const { data, isLoading } = useQuery(
    ["getusermenuv2?username=superadmin"],
    () =>
      Axiosinstance.get(`getusermenuv2?username=${user.user_name}`).then(
        (res) => res.data.allmenu
      )
  );

  useEffect(() => {
    if (data) {
      const userMenu = data?.map((item) => {
        return {
          label: (
            <span>
              <i className={item.icon} style={{ paddingRight: "5px" }} />
              {item.name}
            </span>
          ),
          key: item.id,
          type: "group",
          children: item.submodule.map((child) => {
            if (`/${item.urlkey}/${child.urlkey}` === location.pathname) {
              setSelectedKey([`/${item.urlkey}/${child.urlkey}`]);
            }
            return {
              label: (
                <Link to={`/${item.urlkey}/${child.urlkey}`}>{child.name}</Link>
              ),
              key: `/${item.urlkey}/${child.urlkey}`,
              icon: <PieChartOutlined style={{ fontSize: "23px" }} />,
            };
          }),
        };
      });
      setUserMenus(userMenu);
    }
  }, [data]);

  // const MenuClickHandle = (e) => {
  //   console.log("🚀 ~ file: index.jsx:10 ~ MenuClickHandle ~ e", e);
  //   // navigator(e.key);
  // };
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  if (isLoading) return <GlobalLoader />;

  return (
    <div className="topbar__responsive__menu__btn">
      <MenuOutlined style={{ fontSize: "25px" }} onClick={showDrawer} />
      <Drawer
        title="CIS "
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        width={250}
      >
        <main className="menu">
          <Menu
            // onClick={(e) => MenuClickHandle(e)}
            onClick={(e) => setSelectedKey(e.key)}
            selectedKeys={selectedKey}
            mode="inline"
            defaultSelectedKeys={["dashboard"]}
            items={userMenus}
            className=""
          />
        </main>
      </Drawer>
    </div>
  );
};

export default TogleMenu;
