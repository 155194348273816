/* eslint-disable no-sequences */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Input,
  Popconfirm,
  Space,
  Table,
  message,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { MdOutlineEditOff } from "react-icons/md";
import Axiosinstance from "utils/AxiosInstance";
import AddAccommodation from "./AddAccommodation";
import CheckoutModal from "./CheckoutModal";

const Accommodation = ({ roomInfo, booking_id, onUpdateRoomInfo, setGeneralInfo, bookingStatus }) => {
  // console.log("roomInfo---------------------123", roomInfo);
  const [info, setInfo] = useState([]);
  const [addRoomDrawer, setAddRoomDrawer] = useState(false);
  const [editedValues, setEditedValues] = useState({});
  const [editingRow, setEditingRow] = useState(null);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [checkoutData, setCheckoutData] = useState({
    checkIn: null,
    checkOut: null,
    id: null,
  });
  console.log("roomInfo-------------", roomInfo);
  const handleClick = () => {
    setAddRoomDrawer(true);
  };

  useEffect(() => {
    const infos = roomInfo?.map((item) => (
      console.log(roomInfo, "roomInfo"), {
        ...item,
        room_number: item.room_number,
        room_type: item.room_type_name,
        check_in: item.check_in,
        check_out: item.check_out,
        nights: item.nights,
        price: item.price,
        discount_percentage: item.discount_percentage,
        discount_percentage_amount: item.discount_percentage_amount,
        tax_percentage: item.tax_percentage,
        tax_percentage_amount: item.tax_percentage_amount,
        sub_total: item.sub_total,
        total: item.total,
      }));
    setInfo(infos);
  }, [roomInfo]);

  const handleDelete = async (record) => {
    try {
      const response = await Axiosinstance.delete(
        `booking/deletebookingitem/${record.id}`
      );
      if (response.data.status === 1) {
        const updatedInfo = info.filter((item) => item.id !== record.id);
        setInfo(updatedInfo);
        message.success(response.data.msg);
        Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
          .then((res) => res.data)
          .then((data) => {
            setGeneralInfo(data?.data);
          });
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      console.error("Error while deleting row:", error);
      message.error(error.message);
    }
  };

  const handleFieldEdit = (record, field, value) => {
    let updatedValues;
    if (
      field === "price" ||
      field === "discount_percentage" ||
      field === "tax_percentage"
    ) {
      const updatedValue = value.trim() === "" ? null : value;
      updatedValues = {
        ...editedValues,
        [record.id]: {
          ...editedValues[record.id],
          [field]: updatedValue,
        },
      };

      const price = parseFloat(
        updatedValues[record.id]?.price || record.price || 0
      );
      const discount = parseFloat(
        updatedValues[record.id]?.discount_percentage ||
          record.discount_percentage ||
          0
      );
      const tax = parseFloat(
        updatedValues[record.id]?.tax_percentage || record.tax_percentage || 0
      );
      const nights = parseFloat(record.nights || 0);
      const subtotal = price * nights;
      const afterDiscount = subtotal - (subtotal * discount) / 100;
      const total = afterDiscount + (afterDiscount * tax) / 100;
      updatedValues[record.id].sub_total = subtotal.toFixed(2);
      updatedValues[record.id].total = total.toFixed(2);
    } else if (field === "check_out") {
      const updatedValue = value.trim() === "" ? null : value;
      updatedValues = {
        ...editedValues,
        [record.id]: {
          ...editedValues[record.id],
          [field]: updatedValue,
        },
      };

      const editedCheckOut =
        updatedValues[record.id]?.check_out || record.check_out;
      const checkInDate = dayjs(record.check_in, "YYYY-MM-DD");
      const updatedCheckOutDate = dayjs(editedCheckOut, "YYYY-MM-DD");
      const nights = updatedCheckOutDate.diff(checkInDate, "days");
      updatedValues[record.id].nights = nights;

      const price = parseFloat(
        updatedValues[record.id]?.price || record.price || 0
      );
      const discount = parseFloat(
        updatedValues[record.id]?.discount_percentage ||
          record.discount_percentage ||
          0
      );
      const tax = parseFloat(
        updatedValues[record.id]?.tax_percentage || record.tax_percentage || 0
      );
      const subtotal = price * nights;
      const afterDiscount = subtotal - (subtotal * discount) / 100;
      const total = afterDiscount + (afterDiscount * tax) / 100;
      updatedValues[record.id].sub_total = subtotal.toFixed(2);
      updatedValues[record.id].total = total.toFixed(2);
    } else {
      updatedValues = {
        ...editedValues,
        [record.id]: {
          ...editedValues[record.id],
          [field]: value,
        },
      };
    }

    const updatedInfo = info.map((item) =>
      item.id === record.id ? { ...item, ...updatedValues[record.id] } : item
    );
    setInfo(updatedInfo);
    setEditedValues(updatedValues);
  };

  const handleSave = async (record) => {
    try {
      const updatedFields = {
        ...editedValues[record.id],
        check_out: editedValues[record.id]?.check_out || record.check_out,
        price: editedValues[record.id]?.price || record.price,
        discount_percentage:
          editedValues[record.id]?.discount_percentage ||
          record.discount_percentage,
        tax_percentage:
          editedValues[record.id]?.tax_percentage || record.tax_percentage,
      };

      const response = await Axiosinstance.patch(
        `booking/updatebookingitem/${record.id}`,
        updatedFields
      );

      if (response.data.status === 1) {
        const updatedItem = {
          ...record,
          check_out: updatedFields.check_out,
          price: updatedFields.price,
          discount_percentage: updatedFields.discount_percentage,
          tax_percentage: updatedFields.tax_percentage,
        };

        const updatedInfo = info.map((item) =>
          item.id === record.id ? updatedItem : item
        );
        setInfo(updatedInfo);
        setEditingRow(null);
        message.success(response.data.msg); //= ============================= working area
        Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
          .then((res) => res.data)
          .then((data) => {
            onUpdateRoomInfo(data?.data?.booking_items);
            setGeneralInfo(data?.data);
          });
      } else if (response.data.status === 0) {
        message.error(response.data.msg);
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      console.error("Error while updating item:", error);
      message.error(error.message);
    }
  };

  const handleEdit = (record) => {
    if (editingRow === record.id) {
      handleSave(record);
    } else {
      setEditingRow(record.id);
    }
  };

  const handleCheckout = (record) => {
    const { check_in, check_out, id } = record;
    setCheckoutData({ checkIn: check_in, checkOut: check_out, id });
    setShowCheckoutModal(true);
  };

  const handleSaveCheckout = async (id, checkoutDate) => {
    try {
      const response = await Axiosinstance.post(`booking/roomcheckout/${id}`, {
        check_out: checkoutDate,
      });
      if (response.data.status === 1) {
        message.success(response.data.msg); /// ==================check out
        Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
          .then((res) => res.data)
          .then((data) => {
            onUpdateRoomInfo(data?.data?.booking_items);
            setGeneralInfo(data?.data);
          });
        // Close the modal
        setShowCheckoutModal(false);
      } else if (response.data.status === 0) {
        message.error(response.data.msg);
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      console.error("Error while updating item:", error);
      message.error(error.message);
    }
  };

  const disabledCheckOutDate = (record, current) => {
    const checkInDate = dayjs(record.check_in, "YYYY-MM-DD");
    return checkInDate.isValid() && dayjs(current).isBefore(checkInDate, "day");
  };

  const columns = [
    {
      title: <span className="table__title">Room Type</span>,
      dataIndex: "room_type_name",
      key: "room_type_name",
    },
    {
      title: <span className="table__title">Room Number</span>,
      dataIndex: "room_number",
      key: "room_number",
    },
    {
      title: <span className="table__title">Check In</span>,
      dataIndex: "check_in",
      key: "check_in",
    },
    {
      title: <span className="table__title">Check Out</span>,
      dataIndex: "check_out",
      key: "check_out",
      render: (text, record) => {
        const editedCheckOut = editedValues[record.id]?.check_out;
        const displayValue = editedCheckOut
          ? dayjs(editedCheckOut, "YYYY-MM-DD")
          : dayjs(text, "YYYY-MM-DD");

        const handleChange = (date, dateString) => {
          const formattedDate = dateString || null;
          handleFieldEdit(record, "check_out", formattedDate);
        };

        const disabledDate = (current) => {
          const checkInDate = dayjs(record.check_in, "YYYY-MM-DD");
          return (
            checkInDate.isValid() && dayjs(current).isBefore(checkInDate, "day")
          );
        };

        return (
          <>
            {editingRow === record.id ? (
              <DatePicker
                value={displayValue.isValid() ? displayValue : null}
                onChange={handleChange}
                disabledDate={disabledDate}
              />
            ) : (
              text
            )}
            <style>
              {`
                .highlighted-date {
                  background-color: #494d7c;
                  color: #fff;
                  border-radius: 4px;
                }
    
                .check-in-out-date {
                  background-color: #494d7c;
                  color: #fff;
                  border-radius: 4px;
                }
              `}
            </style>
          </>
        );
      },
    },

    {
      title: <span className="table__title"> nights</span>,
      dataIndex: "nights",
      align: "center",
      key: "nights",
      render: (text, record) => {
        if (editingRow === record.id) {
          return editedValues[record.id]?.nights || text;
        }
        return text;
      },
    },
    {
      title: <span className="table__title">unit Price</span>,
      dataIndex: "price",
      align: "right",
      key: "price",
      render: (text, record) => {
        const editedValue = editedValues[record.id]?.price;
        const displayValue = editedValue !== undefined ? editedValue : text;
        const formattedPrice = `$${displayValue}`; // Add dollar sign to the price

        return editingRow === record.id ? (
          <Input
            value={displayValue}
            onChange={(e) => handleFieldEdit(record, "price", e.target.value)}
          />
        ) : (
          formattedPrice // Display the price with a dollar sign
        );
      },
    },
    {
      title: <span className="table__title"> subtotal</span>,
      dataIndex: "sub_total",
      align: "right",
      key: "sub_total",
      render: (text, record) => {
        const formattedSubtotal = `$${text}`; // Add dollar sign to the price
        if (editingRow === record.id) {
          return editedValues[record.id]?.sub_total || text;
        }
        return formattedSubtotal;
      },
    },
    {
      title: <span className="table__title">Discount</span>,
      dataIndex: "discount_percentage",
      align: "right",
      key: "discount_percentage",
      render: (text, record) => {
        const discountAmount = parseFloat(record.discount_percentage_amount); // Retrieve discount_percentage_amount from the record
        const editedValue = editedValues[record.id]?.discount_percentage;
        const displayValue = editedValue !== undefined ? editedValue : text;
        return editingRow === record.id ? (
          <Input
            value={displayValue}
            onChange={(e) =>
              handleFieldEdit(record, "discount_percentage", e.target.value)
            }
          />
        ) : (
          <>
            ${discountAmount} ({ displayValue} %) {/* Display both percentage and amount */}
          </>
        );
      },
    },
    {
      title: <span className="table__title"> tax </span>,
      dataIndex: "tax_percentage",
      align: "right",
      key: "tax_percentage",
      render: (text, record) => {
        const textAmount = parseFloat(record.tax_percentage_amount);
        const editedValue = editedValues[record.id]?.tax_percentage;
        const displayValue = editedValue !== undefined ? editedValue : text;
        return editingRow === record.id ? (
          <Input
            value={displayValue}
            onChange={(e) =>
              handleFieldEdit(record, "tax_percentage", e.target.value)
            }
          />
        ) : (
          <>
            ${textAmount} ({ displayValue} %)
          </>
        );
      },
    },
    {
      title: <span className="table__title"> total</span>,
      dataIndex: "total",
      align: "right",
      key: "total",
      render: (text, record) => {
        const formattedTotal = `$${text}`; // Add dollar sign to the price
        if (editingRow === record.id) {
          return editedValues[record.id]?.total || text;
        }
        return formattedTotal;
      },
    },

    {
      title: <span className="table__title"> actions</span>,
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        if (record.booking_status === 3 || bookingStatus === 3) {
          // Disable actions when booking_status is 3
          return (
            <Space>
              <Button type="link" disabled>
                <SaveOutlined />
              </Button>
              <Button type="link" disabled danger>
                <DeleteOutlined />
              </Button>
              <Button type="link" disabled>
                <CheckCircleOutlined />
              </Button>
            </Space>
          );
        }

        return (
          <Space>
            {editingRow === record.id ? (
              <>
                <Button type="link" onClick={() => handleSave(record)}>
                  <SaveOutlined />
                </Button>
                <Button type="link" onClick={() => setEditingRow(null)}>
                  <MdOutlineEditOff style={{ color: "#ff4d4f" }} />
                </Button>
              </>
            ) : (
              <Button type="link" onClick={() => handleEdit(record)} className="pap">
                <EditOutlined />
              </Button>
            )}
            <Popconfirm
              title="Are you sure you want to delete this row?"
              onConfirm={() => handleDelete(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger className="pap">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
            <Button type="link" onClick={() => handleCheckout(record)} className="pap">
              <CheckCircleOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <CheckoutModal
        checkIn={checkoutData.checkIn}
        checkOut={checkoutData.checkOut}
        id={checkoutData.id}
        onSave={handleSaveCheckout}
        showCheckoutModal={showCheckoutModal}
        setShowCheckoutModal={setShowCheckoutModal}
      />
      <AddAccommodation
        addRoomDrawer={addRoomDrawer}
        setAddRoomDrawer={setAddRoomDrawer}
        booking_id={booking_id}
        onUpdateRoomInfo={onUpdateRoomInfo}
        setGeneralInfo={setGeneralInfo}
        info={info}
      />
      <Card
        className="card__paddingless card__border__radiusless card__box__shadow card__custom__bg "
        title="BOOKING OVERVIEW"
        extra={
          <div className="fixed-extra">
            {bookingStatus !== 3 ? (
              <Button type="primary" onClick={handleClick}>
                ADD NEW ROOM
              </Button>
            ) : (
              <Button type="primary" onClick={handleClick} disabled>
                ADD NEW ROOM
              </Button>
            )}
          </div>

        }
      >
        <Table
          pagination={false}
          columns={columns}
          dataSource={info}
          bordered
          scroll={{
            y: 240,
          }}
        />
      </Card>
    </>
  );
};

export default Accommodation;
