/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import { HomeOutlined, EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, EllipsisOutlined, SettingOutlined, UserOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, Col, Row, Spin, Empty, Space, Image, message, Avatar, Button, Skeleton, Pagination } from "antd";
import { jsonData } from "features/data/dataSlice";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Axios from "utils/AxiosInstance";
import DeleteItem from "utils/DeleteItem";
import DUMMY from "../../../assets/images/dummy.jpg";
import ADDBUTTON2 from "../../../assets/images/add.svg";

import DATA from "./data.json";

const { Meta } = Card;

const HotelList = () => {
  console.log("DUMMY---------", DUMMY);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [visible, setVisible] = useState(false);
  const [deletePopover, setDeletePopover] = useState(false);
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  const { data: hotels, isLoading } = useQuery([DATA?.urls.list], () =>
    Axios.get(`${DATA?.urls.list}`).then((res) => res.data.data)
  );
  console.log("hotels---------", hotels);
  const deleteItem = async (id) => {
    const { data: response } = await Axios.delete(DATA?.urls.remove + id);
    return response;
  };

  const { mutate: deleteMutate, isLoading: loading } = useMutation(deleteItem, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        setVisible(false);
        setDeletePopover(false);
        queryClient.refetchQueries([DATA?.urls.list]);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const handleView = (id) => {
    nav(DATA?.urls.details + id);
    console.log(id, "id");
  };

  const handleEdit = (id) => {
    nav(DATA?.urls.edit + id);
    console.log(id, "id");
  };

  const handleDelete = (id) => {
    deleteMutate(id);
    console.log(id, "id");
  };
  // Assuming you have a way to retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem("user"));
  console.log("userData----------", userData);

  // Check if the user's role is equal to 1
  const isUserRole1 = userData?.user_roles === 1;
  return (
    <>
      <Row gutter={[24, 24]} justify="center">
        <Col span={24}>
          <Card
            extra={
              <Breadcrumb
                separator={<i className="icon-dot" style={{ fontSize: "10px" }} />}
              >
                <Breadcrumb.Item>
                  <Link to="/dashboard">
                    <HomeOutlined style={{ color: "#23253C" }} /> <span>Dashboard</span>
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <span>{DATA?.listtitle ? DATA?.listtitle : "List"}</span>
                </Breadcrumb.Item>
              </Breadcrumb>
            }
            className="cis__table__breadcrumb"
            bordered={false}
            style={{ boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)" }}
          />
        </Col>
      </Row>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card
            title={DATA?.listtitle ? DATA?.listtitle : "List"}
            style={{ border: "hidden", minHeight: "100vh" }}
            extra={
              <Link to={DATA?.urls?.add}>
                <PlusOutlined className="form__print__list__icon" />
              </Link>
            }
            headStyle={{
              backgroundColor: "#23253c",
              color: "#fcfafc",
              minHeight: "48px",
            }}
            className="cis__table"
          >
            <Spin spinning={isLoading || DATA == null}>
              {hotels?.data?.length > 0 && (
              <Row gutter={[16, 24]}>
                {hotels.data.map((item) => (
                  <Col key={item.id}>
                    <Card
                      style={{ width: 300, boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" }}
                      cover={
                          JSON.parse(item.cover_photo).length > 0 ?
                            <img
                              alt="example"
                              src={JSON.parse(item.cover_photo)[0]?.url}
                              style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            />
                            :
                            <Skeleton.Image
                              active
                              style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            />
                        }
                      actions={[
                        <EditOutlined
                          onClick={() => handleEdit(item.id)}
                          key="ellipsis"
                        />,
                        <EyeOutlined
                          onClick={() => handleView(item.id)}
                          key="eye"
                        />,
                        <DeleteItem
                          onClick={() => handleDelete(item.id)}
                          key="delete"
                        />,
                      ]}
                    >
                      <Meta
                        avatar={
                            Array.isArray(JSON.parse(item.logo)) &&
                            JSON.parse(item.logo).length > 0 ?
                              <Avatar src={JSON.parse(item.logo)[0].url} />
                              :
                              <Avatar icon={<UserOutlined />} />
                        }
                        title={item.name}
                        description={`${item.address.slice(0, 25)} ...`}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
              )}
            </Spin>
            <div style={{ marginTop: "40px", textAlign: "center" }}>
              {isUserRole1 && (

              <Pagination
                // pageSize={hotels?.per_page}
                defaultCurrent={page}
                current={page}
                total={hotels?.total}
                onChange={(page) => setPage(page)}
              />
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default HotelList;
