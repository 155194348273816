/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import { Card, Divider, Form, message, Row, Spin, Col, Input, Button, Select } from "antd";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, Fragment, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import GetModuleFields from "utils/GetModuleFields";
import SelectRequest from "components/Input/SelectRequest";
import InputGroup from "components/Input/InputGroup";
import { SelectStatus } from "utils/CISUI";
import InputTextArea from "components/Input/InputTextArea";
import InputFile from "components/Input/InputFile";
import DATA from "./data.json";
import CustomizedSelect from "../UnitTitle/AddSelectCustomizedUnitTitles";
import SelectRequestSubCat from "../MenuCategory/AddSelectCustomizedCat";

const AddMenu = (name) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [room, setRoom] = useState([]);
  const [cat, setCat] = useState([]);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  const { data: categories } = useQuery(("restaurant/menucategorylist"), () =>
    Axios.get("restaurant/menucategorylist?is_dep=1").then((res) => res.data.data)
  );

  console.log(categories, "cat");

  const handleSubCatChange = (value) => {
    Axios.get(`/restaurant/catwisesubcatlist/${value}`)
      .then((res) => {
        const rooms = res.data.data;
        console.log(rooms);
        const roomOptions = rooms.map((room) => (
          {
            id: room.id,
            room_number: room.name,
          }));
        setRoom(roomOptions);
        form.setFieldsValue({
          // subcategory: roomOptions[0]?.id,
          subcategory: null
        });
        console.log(roomOptions);
      })
      .catch((err) => {
      });
  };

  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    // console.log("values, values?.logo[0]?.uid", values);
    const data = {
      ...values,
      image: values?.image
        ? values.image.map((item) => {
          return item?.response ? item?.response : item;
        })
        : null,
    };
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  console.log(data, "data");

  const { data: catwishsubCat } = useQuery((`/restaurant/catwisesubcatlist/${data?.category}`), () =>
    Axios.get(`/restaurant/catwisesubcatlist/${data?.category}`).then((res) => res.data.data), { enabled: !!data?.category, refetchOnMount: true }
  );

  const initialArray = [""];

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        image:
          data?.image !== null ? data?.image : [],
        subcategory: data?.subcategory || null,
        item_type: data?.item_type || null,
      };

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  useEffect(() => {
    if (id !== undefined && data && catwishsubCat) {
      const roomOptions = catwishsubCat.map((room) => (
        {
          id: room.id,
          room_number: room.name,
        }));
      setRoom(roomOptions);
    }
  }, [id, data, catwishsubCat]);

  // useEffect(() => {
  //   if (id !== undefined) {
  //     form.setFieldsValue({
  //       custom_fields: [
  //         {
  //           name: "",
  //           value: "",
  //         },
  //       ],
  //     });
  //   }
  // }, []);

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Spin spinning={isLoading || loading || DATA == null}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="cis__form"
          form={form}
          initialValues={{ custom_fields: [""] }}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: "Category is required" },
                ]}
              >
                <Select
                  placeholder="Select an item"
                  style={{ width: "100%" }}
                  size="large"
                  allowClear
                  value={categories}
                  onChange={handleSubCatChange}
                  loading={!(categories?.length > 0)}
                >
                  {categories?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Subcategory"
                name="subcategory"
                rules={[
                  { required: false },
                ]}
              >
                {/* {room?.map((item, index) => (
                  <SelectRequestSubCat
                    placeholder="Select unit title"
                    path="restaurant/unittitlelist?is_dep=1"
                    name="unit_title"
                  />
                ))} */}
                <Select
                  placeholder="Select an item"
                  // value={item.room_id}
                  style={{ width: "100%" }}
                  allowClear
                  size="large"
                  // onChange={handleSubCatChange}
                  // onChange={(e) => handleSubCatChange(e, "room_id")}
                  // loading={!(room?.length > 0)}
                >
                  {room?.map((item, index) => (
                    <Select.Option key={index} value={item?.id}>
                      {item?.room_number}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Item Type"
                name="item_type"
                rules={[
                  { required: false },
                ]}
              >
                <SelectRequest
                  placeholder="Select item type"
                  path="restaurant/itemtypelist?is_dep=1"
                  name="item_type"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Item Name"
                name="name"
                rules={[
                  { required: true },
                ]}
              >
                <InputGroup
                  placeholder="Item Name"
                  name="name"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Base Price"
                name="base_price"
                rules={[
                  { required: true },
                ]}
              >
                <InputGroup
                  placeholder="Base Price"
                  name="base_price"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Barcode"
                name="barcode"
                rules={[
                  { required: true },
                ]}
              >
                <InputGroup
                  placeholder="Barcode"
                  name="barcode"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Unit Title"
                name="unit_id"
                rules={[
                  { required: false },
                ]}
              >
                <CustomizedSelect
                  placeholder="Select unit title"
                  path="restaurant/unittitlelist?is_dep=1"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                label="Cuisine"
                name="cuisine"
                rules={[
                  { required: false },
                ]}
              >
                <SelectRequest
                  placeholder="Select a cuisine"
                  path="sys/countries"
                  name="cuisine"
                />
              </Form.Item>
            </Col>
            <Col lg={8} md={8} span={24}>
              <Form.Item
                name="status"
                rules={[
                  { required: false },
                ]}
              >
                <SelectStatus />
              </Form.Item>
            </Col>
            <Col lg={24} md={8} span={24}>
              <Form.Item
                label="Details"
                name="details"
                rules={[
                  { required: false },
                ]}
              >
                <InputTextArea
                  placeholder="Enter details"
                  name="details"
                  rows={2}
                  required={false}
                />
              </Form.Item>
            </Col>
            <Col lg={24} md={8} span={24}>
              <Form.Item
                label="Image"
                name="image"
                rules={[
                  { required: false },
                ]}
              >
                <InputFile
                  name="image"
                  multiple
                  maxCount={5}
                  label={false}
                  accept=".png,.jpg,.jpeg"
                  listType="picture-card"
                  maxFileSize={2}
                  maxFileSizeMsg="File size must be less than 2MB"
                  maxFileCountMsg="You can only upload up to 5 images"
                  maxFileCount={5}
                  uploadMsg="Click or drag file to this area to upload"
                />
              </Form.Item>
            </Col>
            <Suspense fallback={<Loader />}>
              <FormSubmitBtns isLoading={isLoading} />
            </Suspense>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
};

export default AddMenu;
