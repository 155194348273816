import { Col, Divider } from "antd";
import { Fragment } from "react";
import { InputField } from "./InputField";

const GetModuleFields = ({ data }) => {
  return data?.map((item, index) => {
    return (
      <Fragment key={index}>
        {item?.title && item?.input && (
          <Col span={24} key={index}>
            <Divider
              orientation="left"
              orientationMargin="0"
              className="input__devider"
            >
              {item.title}
            </Divider>
          </Col>
        )}
        <InputField input={item.input} />
      </Fragment>
    );
  });
};

export default GetModuleFields;

export const GetFieldsWithoutDivider = ({ data }) => {
  return data?.map((item, index) => {
    return (
      <Fragment key={index}>
        <InputField input={item.input} />
      </Fragment>
    );
  });
};
