import { ReloadOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
// import Axios from "utils/AxiosInstance";

const SelectRoomStatus = ({
  error_msg = "",
  name = "",
  label = "",
  initialValue = null,
  placeholder = "",
  required = false,
  path = "",
  showSearch = true,
  addOn = "",
  mode = null,
  ...arg
}) => {
  const { isLoading, isError, data } = useQuery([path], () =>
    Axiosinstance.get(path).then((res) => res.data.data)
  );

  if (isError) {
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: error_msg }]}
        extra={addOn === "" ? "" : addOn}
      >
        <Select
          placeholder={placeholder}
          style={{ width: "100%" }}
          status="error"
          size="large"
          allowClear
          mode={mode}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input)
          }
          {...arg}
        >
          <Select.Option style={{ textAlign: "center" }}>
            <Button
              type="link"
              icon={<ReloadOutlined />}
              onClick={() => window.location.reload()}
              className="reload_page"
            >
              Something is wrong please reload the page
            </Button>
          </Select.Option>
        </Select>
      </Form.Item>
    );
  }

  return initialValue === null ? (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      extra={addOn === "" ? "" : addOn}
    >
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        mode={mode}
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input)
        }
        loading={isLoading}
        size="large"
        {...arg}
        options={data?.map((item, index) => ({
          key: index,
          value: item.status,
          label: item.status_name,
        }))}
      />
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      initialValue={initialValue}
      extra={addOn === "" ? "" : addOn}
      mode={mode}
    >
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input)
        }
        loading={isLoading}
        {...arg}
        size="large"
        options={data?.map((item, index) => ({
          key: index,
          value: item.status,
          label: item.status_name,
        }))}
      >
        {/* {isLoading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          data?.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))
        )} */}
      </Select>
    </Form.Item>
  );
};

export default SelectRoomStatus;
