import { Button, Card, Col, DatePicker, Input, Row, Space } from "antd";
import { openChangeRoomModal } from "features/frontDesk/frontDeskSlice";
import { useDispatch, useSelector } from "react-redux";
import ChangeRoom from "./ChangeRoom";

const SelectRoomInfoSection = () => {
  const dispatch = useDispatch();
  const { selectedRoom } = useSelector((state) => state.frontDesk);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <Col span={24}>
      <Card title="Select Room Info" className="frontdesk__sidebar">
        <Row gutter={[24, 24]}>
          <Col style={{ marginBottom: "10px" }} span={24} lg={12} xxl={6}>
            <DatePicker onChange={onChange} size="large" />
          </Col>
          <Col style={{ marginBottom: "10px" }} span={24} lg={12} xxl={6}>
            <Button type="primary" block size="large" className="roombtn">
              Find
            </Button>
          </Col>
          <Col style={{ marginBottom: "10px" }} span={24} lg={12} xxl={4}>
            <Button
              type="primary"
              block
              size="large"
              className="roombtn"
              onClick={() => dispatch(openChangeRoomModal())}
            >
              Room Change
            </Button>
          </Col>
          <Col style={{ marginBottom: "10px" }} span={24} lg={12} xxl={8}>
            <Space>
              Selected Room
              <Input
                size="large"
                placeholder="Select Room Form Below"
                disabled
                value={selectedRoom?.room_number || ""}
              />
            </Space>
          </Col>
          <ChangeRoom />
        </Row>
      </Card>
    </Col>
  );
};

export default SelectRoomInfoSection;
