import { Card, Col, Row } from "antd";
import BlockCard from "components/Cards/BlockCard";
import CardTitle from "components/Cards/CardTitle";
import SummaryCard from "components/Cards/SummaryCard";
import ActivitiesChart from "components/Chart/ActivitiesChart";
import HotelMetrix from "components/Chart/HotelMetrix";
// import ComplaintManagmentTable from "components/Tables/ComplaintManagmentTable";

const Dashboard = () => {
  return (
    <>
      <Row gutter={[24, 24]}>
        <SummaryCard />
      </Row>

      <Row gutter={[24, 24]}>
        <Col xs={24} md={24} lg={24} xl={12} xxl={15}>
          <CardTitle title="Hotel Metrix" />
          <Card className="card__paddingless card__border__radiusless">
            <HotelMetrix />
          </Card>
        </Col>

        <Col span={24} md={24} lg={24} xl={12} xxl={9}>
          <Row gutter={[24, 24]}>
            <Col span={24} md={24} lg={24} xl={24}>
              <CardTitle title="activities" />
              <Card className="card__paddingless card__border__radiusless">
                <ActivitiesChart />
              </Card>
            </Col>
            <Col span={24} md={12} lg={12} xl={12}>
              <Card className="card__border__radiusless">
                <BlockCard
                  count="82"
                  status="pending dispatach"
                  icon="warning-alt"
                  color="goldenrod"
                  total="0.00"
                />
              </Card>
            </Col>
            <Col span={24} md={12} lg={12} xl={12}>
              <Card className="card__border__radiusless">
                <BlockCard
                  count="03"
                  status="complete"
                  icon="warning-alt"
                  color="#1e8d1e"
                  total="0.00"
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[24, 24]}>
        <Col span={24} md={24} lg={24} xl={24}>
          <CardTitle title="Complaint Managment" />
          <Card className="card__border__radiusless card__paddingless">
            {/* <ComplaintManagmentTable /> */}
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
