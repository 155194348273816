/* eslint-disable no-unsafe-optional-chaining */
import { Card, Col, Divider, Form, message, Row, Spin } from "antd";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
// import Axios from "utils/AxiosInstance";
import GetModuleFields from "utils/GetModuleFields";
import Axiosinstance from "utils/AxiosInstance";
import SelectRequest from "components/Input/SelectRequest";
import InputSelect from "components/Input/InputSelect";
import DATA from "./data.json";

const NewRoom = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  // const { hotel, setHotel } = useState(null);
  // const { roomtype, setRoomtype } = useState([]);
  const [roomtype, setRoomtype] = useState([]);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axiosinstance.patch(
      DATA?.urls?.edit + id,
      values
    );
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    const data = {
      ...values,
      room_image: values?.room_image
        ? values.room_image.map((item) => {
          return item?.response ? item?.response : item;
        })
        : null,
      featured_images: values?.featured_images
        ? values.featured_images.map((item) => {
          return item?.response ? item?.response : item;
        })
        : null,
    };
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () =>
      Axiosinstance.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );
  // console.log(data, "data");

  const { data: DATA_TEMP } = useQuery(
    `rooms_ratings/roomtypedetails/${data?.room_type},
    `,
    () =>
      Axiosinstance.get(`rooms_ratings/roomtypedetails/${data?.room_type}`).then((res) => res.data.data?.name),
    console.log(data?.room_type, "roomtype"),
    { enabled: !!(id && DATA), refetchOnMount: true });
  console.log(DATA_TEMP, "DATA_TEMP");

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        room_type: DATA_TEMP,
        room_number: parseInt(data.room_number, 10),
        room_image: data?.room_image !== null ? data?.room_image : [],
        featured_images:
          data?.featured_images !== null ? data?.featured_images : [],
      };
      form.setFieldsValue(values);
      // console.log(values, "valuesvcvcv");
      // console.log(data?.room_type, "roomtype");
    } else {
      form.resetFields();
    }
  }, [id, data]);

  const handleRoomChange = (value) => {
    console.log(value, "value");
    Axiosinstance.get(`rooms_ratings/hotelroomtype/${value}`)
      .then((res) => {
        console.log(res.data.data, "roomtype");
        setRoomtype(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Spin spinning={isLoading || loading || DATA == null}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="cis__form"
          form={form}
          layout="vertical"
        >
          <Divider
            orientation="left"
            orientationMargin="0"
            className="input__devider"
          >
            Room Details
          </Divider>
          <Row gutter={[16, 0]}>
            <Col span={8}>
              <SelectRequest
                path="overview/hotellist?is_dep=1"
                name="hotel_id"
                onChange={handleRoomChange}
                placeholder="Select Hotel"
                label="Hotel"
                required
              />
            </Col>
            <Col span={8}>
              <InputSelect
                items={roomtype}
                name="room_type"
                placeholder={DATA_TEMP}
                disabled={roomtype.length === 0}
                label="Room Type"
              />
            </Col>
            <GetModuleFields data={DATA?.input_fields} />
            <Suspense fallback={<Loader />}>
              <FormSubmitBtns isLoading={isLoading} />
            </Suspense>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
};

export default NewRoom;
