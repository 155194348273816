/* eslint-disable max-len */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from "react";
import { Modal, Carousel, Image, message, Spin, Empty, Card, Row, Col, Space, Divider, Input, Skeleton, Tag } from "antd";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Axios } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Axiosinstance from "utils/AxiosInstance";
import ListIcon from "shared/ListIcon";
import Meta from "antd/es/card/Meta";
import { NumberOutlined, TagOutlined } from "@ant-design/icons";
import DATA from "./data.json";

const RoomDetails = () => {
  const { id } = useParams();
  const [room, setRoom] = useState([]);
  const [amenityDetails, setAmenityDetails] = useState([]);

  const { data, isLoading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axiosinstance.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );
  // console.log("alldata", data?.room_type);

  const roomType = data?.room_type;
  const detttails = data?.room_details;
  console.log("detttails", detttails);
  console.log("roomtype", roomType);

  useEffect(() => {
    Axiosinstance.get(DATA?.urls.typedetails + roomType)
      .then((response) => {
        console.log("typeDetails", response.data.data);
        setRoom(response.data.data);

        const amenityPromises = response.data.data.amenities.map((amenityId) => {
          return Axiosinstance.get(`/rooms_ratings/amenityopen/${amenityId}`)
            .then((amenityResponse) => amenityResponse.data.data)
            .catch((error) => {
              console.log(`Error fetching amenity details for ID ${amenityId}:`, error);
              return null;
            });
        });

        // Wait for all promises to resolve
        Promise.all(amenityPromises)
          .then((amenityDetails) => {
            console.log("Amenity details:", amenityDetails);
            setAmenityDetails(amenityDetails);
          })
          .catch((error) => console.log("Error fetching amenity details:", error));
      })
      .catch((error) => console.log(error));
  }, [roomType]);

  return (
    <Card
      title={DATA?.detailstitle}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Skeleton avatar paragraph={{ rows: 200 }} active loading={isLoading || DATA == null}>
        {data &&
          <div>
            <div style={{ height: "500px", width: "100%", overflow: "hidden", padding: "15px", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Image
                preview={false}
                style={{ maxWidth: "800px", height: "450px", justifyContent: "center", objectFit: "cover", borderRadius: "10px", alignmentBaseline: "center" }}
                src={data?.room_image[0]?.url}
              />
            </div>
            <Divider orientation="left" style={{ fontWeight: "bold", fontSize: 22 }}>
              {data?.room_name}
            </Divider>

            <Card bordered={false} style={{ width: "100%", marginBottom: "20px" }}>
              <Row>
                <Col span={12}>
                  <Meta
                    avatar={<NumberOutlined />}
                    title="Room Number"
                    description={data?.room_number}
                    style={{ marginTop: "10px" }}
                  />
                </Col>
                <Col span={12}>
                  <Meta
                    avatar={<TagOutlined />}
                    title="Room Type"
                    description={room?.name}
                    style={{ marginTop: "10px" }}
                  />
                </Col>
              </Row>
            </Card>

            <Divider orientation="left" style={{ fontWeight: "bold", fontSize: 22 }}>Room Description</Divider>
            <Card>
              <Row>
                <Col span={24}>
                  {data && typeof data.room_details === "string" ? (
                    <p>
                      {data.room_details}
                    </p>
                  ) : (
                    <Empty />
                  )}
                </Col>
              </Row>
            </Card>

            <Divider orientation="left" style={{ fontWeight: "bold", fontSize: 22 }}>Featured Images</Divider>

            <Card>
              <Row>
                {data && data?.featured_images.map((item) => (
                  <Col span={4}>
                    <Image
                      preview
                      style={{ maxWidth: "200px", height: "200px", justifyContent: "center", borderRadius: "8px" }}
                      src={item?.url}
                    />
                  </Col>
                ))}

              </Row>
            </Card>

            <Divider orientation="left" style={{ fontWeight: "bold", fontSize: 24 }}>Amenities</Divider>

            <Card>
              <Row>
                <Col span={24}>
                  <Space size={[16, 16]} wrap>
                    {amenityDetails.map((amenity, index) => (
                      <div key={index}>
                        <Tag style={{ fontSize: 14 }} color="magenta">
                          {amenity.name}
                        </Tag>
                      </div>
                    ))}
                  </Space>
                </Col>
              </Row>
            </Card>

          </div>
      }
      </Skeleton>
    </Card>
  );
};

export default RoomDetails;
