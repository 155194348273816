/* eslint-disable max-len */
import { Col, Row, Card, Button } from "antd";
import React, { useState } from "react";
import "react-calendar-timeline/lib/Timeline.css";
import { AppstoreAddOutlined, SearchOutlined } from "@ant-design/icons";
import AddBookingDrawer from "../BookingV2/AddBookingDrawer";
import V3TimeLine from "./V3TimeLine";

const index = () => {
  const [addBookingDrawer, setAddBookingDrawer] = useState(false);
  const handleClick = () => {
    setAddBookingDrawer(true);
  };
  return (
    <>
      <div className="newDiv">
        <AddBookingDrawer
          addBookingDrawer={addBookingDrawer}
          setAddBookingDrawer={setAddBookingDrawer}
        />
      </div>
      <div className="frontdeskV3">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card
              title="FRONT DESK"
              extra={
                // <Button type="link" style={{ fontWeight: "600", color: "#14527f", fontSize: "18px" }} onClick={handleClick}>ADD BOOKING</Button>
                <Button type="primary" icon={<AppstoreAddOutlined style={{ color: "#ddd" }} />} onClick={handleClick}> ADD NEW BOOKING ... </Button>
            }
              bordered={false}
              style={{
                //   width: 300,
              }}
              className="card__paddingless card__border__radiusless timeline__card__position fixed-card"
            >
              <V3TimeLine />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default index;
