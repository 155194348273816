import { Button, Popover, Input, AutoComplete } from "antd";
import { ContainerOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  addToCart,
  addToCartFromDraft,
  selectedCustomer,
  setDraftItems,
} from "features/pos/posSlice";
import localforage from "localforage";

const { Search } = Input;
const onSearch = (value) => console.log(value);
const text = <span>Draft Customer</span>;

const DraftButton = ({
  setDiscountPercentage,
  setServiceCharge,
  setAdjustAmount,
}) => {
  const { draftItems, cartData, activeCustomer } = useSelector(
    (state) => state.posData
  );
  const [draftItemSearchValue, setDraftItemSearchValue] = useState("");
  const dispatch = useDispatch();
  const [draftName, setDraftName] = useState([]);
  const [isFocusDraftInput, setIsFocusDraftInput] = useState(false);

  useEffect(() => {
    if (draftItems) {
      const newDraftItem = draftItems.map((item) => item.activeCustomer);
      setDraftName(newDraftItem);
    }
  }, [draftItems]);

  useEffect(() => {
    setDraftItemSearchValue("");
  }, [activeCustomer]);

  const onSelectItem = (data, fullData) => {
    const indexNum = draftItems.findIndex(
      (item) => item.activeCustomer.id === fullData.id
    );
    const newDraftsItems = [...draftItems];
    const deletedItemFromDraft = newDraftsItems.splice(indexNum, 1);
    localforage.setItem("pos_draft", newDraftsItems).then((res) => {
      dispatch(setDraftItems(newDraftsItems));
      dispatch(addToCartFromDraft(deletedItemFromDraft[0].cartData));
      dispatch(selectedCustomer(deletedItemFromDraft[0].activeCustomer));
      setDiscountPercentage(deletedItemFromDraft[0].discountPercentage);
      setAdjustAmount(deletedItemFromDraft[0].adjustAmount);
      setServiceCharge(deletedItemFromDraft[0].serviceCharge);
    });
  };

  const onChange = (data) => {
    setDraftItemSearchValue(data);
  };

  const onCustomerSearch = (searchText) => {
    localforage.getItem("pos_draft").then((data) => {
      if (searchText) {
        const newIncludesAry = [...data]?.filter((item) =>
          item?.activeCustomer?.label
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        );
        dispatch(setDraftItems(newIncludesAry));
      } else {
        dispatch(setDraftItems(data));
      }
    });
  };

  return (
    <Popover
      placement="bottom"
      title={text}
      content={
        <AutoComplete
          value={draftItemSearchValue || ""}
          options={draftName}
          style={{
            width: 250,
          }}
          // name="customer"
          onSelect={onSelectItem}
          onSearch={onCustomerSearch}
          onChange={onChange}
          placeholder="Search customer"
          autoFocus
        />
      }
      trigger="click"
      className="pos__top__action__btn"
    >
      <ContainerOutlined />
    </Popover>
  );
};

export default DraftButton;
