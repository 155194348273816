import { Button, Calendar, Col, Row } from "antd";
import React from "react";

const EventCalendar = () => {
  return (
    <Row>
      <Col span={6}>
        <Button type="primary" block size="large">New</Button>
      </Col>
      <Col span={18}>
        <Calendar mode="month" />
      </Col>
    </Row>
  );
};

export default EventCalendar;
