import { Card } from "antd";
import AddBooking from "./AddBooking";
import Status from "./Status";

const Sidebar = ({ data }) => {
  return (
    <>
      <Card title="Room Status" className="frontdesk__sidebar">
        <Status data={data} />
      </Card>
      <Card title="Add Booking" className="frontdesk__sidebar">
        <AddBooking />
      </Card>
    </>
  );
};

export default Sidebar;
