/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/destructuring-assignment */
import { Col } from "antd";
import FileAttachment from "components/Input/FileAttachment";
import InputCheckbox from "components/Input/InputCheckbox";
import InputDate from "components/Input/InputDate";
import InputEmail from "components/Input/InputEmail";
import InputFile from "components/Input/InputFile";
import InputGroup from "components/Input/InputGroup";
import InputMonth from "components/Input/InputMonth";
import InputNumber from "components/Input/InputNumber";
import InputNumber2 from "components/Input/InputNumber2";
import InputPhone from "components/Input/InputPhone";
import InputRating from "components/Input/InputRating";
import InputSelect from "components/Input/InputSelect";
import InputTextArea from "components/Input/InputTextArea";
import InputTime from "components/Input/InputTime";
import InputUrl from "components/Input/InputUrl";
import SelectRequestMultiple from "components/Input/SelectMultiple";
import SelectRequest from "components/Input/SelectRequest";
import Weekdays from "components/Input/WeekDays";
import SelectTree from "components/Input/TreeSelect";
import CountrySelect from "components/Input/CountrySelect";
import InputPassword from "components/Input/InputPassword";
import ReTypePassword from "components/Input/ReTypePassword";
import SelectAdd from "components/Input/SelectWithAddNew";
import SelectRoomStatus from "containers/Services/Rooms/RoomStatus";
import { PaymentType,
  CalculationMethod,
  IsFeatured,
  Priority,
  SelectBG,
  SelectGender,
  SelectMaritalStatus,
  SelectStatus,
  WeekCheckBox,
  EmailType,
} from "./CISUI";

export const CheckInputType = (input, type) => {
  switch (input.type) {
    case "text":
      return (
        <InputGroup
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "password":
      return (
        <InputPassword
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "retype_password":
      return (
        <ReTypePassword
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "tree":
      return (
        <SelectTree
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );
    case "phone":
      return (
        <InputPhone
          name={input.name}
          label={input.label}
          specialLabel={input.specialLabel}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "number":
      return (
        <InputNumber
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          initialValue={input.initialValue}
          min={input.min}
        />
      );
    case "number2":
      return (
        <InputNumber2
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          addonAfter={input.addonAfter}
          initialValue={input.initialValue}
        />
      );
    case "selectmulti":
      return (
        <SelectRequestMultiple
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );
    case "attachment":
      return (
        <FileAttachment
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          maxCount={input.maxCount}
        />
      );

    case "maritalstatus":
      return (
        <SelectMaritalStatus
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );

    case "emailtype":
      return (
        <EmailType
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );

    case "gender":
      return (
        <SelectGender
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );

    case "bloodgroup":
      return (
        <SelectBG
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );

    case "paymenttype":
      return (
        <PaymentType
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );

    case "addselect":
      return (
        <SelectAdd
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );

    case "select":
      return (
        <SelectRequest
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );
    case "roomstatus":
      return (
        <SelectRoomStatus
          path={input.path}
          name={input.name}
          mode={input?.isMultiple ? "multiple" : null}
          initialValue={input.initialValue}
          label={input.label}
          placeholder={input.placeholder}
          disabled={input.disabled}
          required={type === "adv" ? false : input.required}
        />
      );
    case "date":
      return (
        <InputDate
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "month":
      return (
        <InputMonth
          name={input.name}
          label={input.label}
          picker={input.picker}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "time":
      return (
        <InputTime
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "textarea":
      return (
        <InputTextArea
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          rows={input.rows}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "status":
      return (
        <SelectStatus
          name={input.name}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "featured":
      return (
        <IsFeatured
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "priority":
      return (
        <Priority
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "checkbox":
      return (
        <InputCheckbox
          required={type === "adv" ? false : input.required}
          name={input.name}
          label={input.label}
          label_value={input.label_value}
          disabled={input.disabled}
          checked={input.checked}
        />
      );
    case "multipleCheckbox":
      return (
        <WeekCheckBox
          name={input.name}
          label={input.label}
          required={type === "adv" ? false : input.required}
          options={input.options}
          disabled={input.disabled}
        />
      );
    case "testcheckbox":
      return (
        <Weekdays
          required={type === "adv" ? false : input.required}
          name={input.name}
          label={input.label}
          checked={input.checked}
        />
      );
    case "email":
      return (
        <InputEmail
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    case "file":
      return (
        <InputFile
          name={input.name}
          label={input.label}
          count={input.count}
          required={input.required}
          maxCount={input.maxCount}
        />
      );
    case "staticselect":
      return (
        <InputSelect
          label={input.label}
          name={input.name}
          required={type === "adv" ? false : input.required}
          extra={input.extra}
          items={input.items}
          disabled={input.disabled}
          placeholder={input.placeholder}
          initialValue={input.value}
        />
      );
    case "country":
      return (
        <CountrySelect
          label={input.label}
          name={input.name}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
          placeholder={input.placeholder}
          initialValue={input.value}
        />
      );
    case "trxtype":
      return (
        <InputSelect
          label={input.label}
          name={input.name}
          required={type === "adv" ? false : input.required}
          extra={input.extra}
          items={input.items}
          disabled={input.disabled}
          placeholder={input.placeholder}
          initialValue={input.value}
        />
      );
    case "calculation":
      return (
        <CalculationMethod
          label={input.label}
          name={input.name}
          required={type === "adv" ? false : input.required}
          extra={input.extra}
          items={input.items}
          disabled={input.disabled}
          placeholder={input.placeholder}
          initialValue={input.value}
        />
      );
    case "rating":
      return (
        <InputRating
          label={input.label}
          name={input.name}
          required={type === "adv" ? false : input.required}
          extra={input.extra}
        />
      );
    case "url":
      return (
        <InputUrl
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
    default:
      return (
        <InputGroup
          name={input.name}
          label={input.label}
          placeholder={input.placeholder}
          required={type === "adv" ? false : input.required}
          disabled={input.disabled}
        />
      );
  }
};

export const InputField = ({ input, type }) => {
  return (
    <>
      {input?.map((item, index) => {
        return (
          <Col span={24} lg={type === "adv" ? 24 : item.size} key={index}>
            {CheckInputType(item, type)}
          </Col>
        );
      })}
    </>
  );
};
