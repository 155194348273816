import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";

const StatusIdentity = ({ allStatus }) => {
  // const [allStatus, setAllStatus] = useState();
  // useEffect(() => {
  //   Axiosinstance.get("/sys/roomstatus")
  //     .then((res) => {
  //       setAllStatus(res?.data?.data);
  //     });
  // }, []);
  console.log("dataStatus====", allStatus);
  return (
    <div className="status__identity " style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", columnGap: "10px", margin: "8px 0" }}>
      {allStatus?.map((item) => (
        <div className="single__identity" style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", columnGap: "10px" }}>
          <div className="color__box" style={{ background: `${item.status_color}`, height: "12px", width: "12px", borderTopRightRadius: "11px" }} />
          <div className="identity__title" style={{ textTransform: "uppercase", fontWeight: "400", color: "rgb(9, 68, 4)" }}>{item.status_name}</div>
        </div>
      ))}
    </div>
  );
};

export default StatusIdentity;
