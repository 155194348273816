import { Col, Radio } from "antd";
import { selectedRoom } from "features/frontDesk/frontDeskSlice";
import { useDispatch } from "react-redux";
import Axiosinstance from "utils/AxiosInstance";

const RoomItem = ({ item }) => {
  const dispatch = useDispatch();
  const hadnleSlectRoom = (id) => {
    Axiosinstance.get(`/frontdesk/roomdetails/${id}`).then((res) => {
      console.log(res);
      dispatch(selectedRoom(res.data.data));
    });
  };

  return (
    <Col span={4} xs={12} md={12} lg={6} xl={6} xxl={4}>
      <Radio.Button
        className="frontdesk__blockitem"
        value={`room_${item.id}`}
        style={{ borderBottom: `5px solid ${item.status_color}` }}
        name="room"
        onChange={() => hadnleSlectRoom(item.id)}
      >
        <p className="room__number">{item.room_number}</p>
      </Radio.Button>
    </Col>
  );
};

export default RoomItem;
