import { Form } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

export default function InputPhone({ name, label, required }) {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required,
          message: "Please input your phone number!",
        },
      ]}
    >
      <ReactPhoneInput
        disableAreaCodes
        placeholder="Type your phone here"
        inputStyle={{
          width: "100%",
          minHeight: "32px",
          maxHeight: "40px",
          fontSize: "14px",
          padding: "15px 55px",
          borderRadius: "5px",
        }}
        buttonStyle={{ borderRadius: "5px 0 0 5px" }}
        dropdownStyle={{ width: "340px" }}
        country="us"
      />
    </Form.Item>
  );
}
