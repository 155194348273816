/* eslint-disable max-len */
import { CaretLeftOutlined, CheckCircleFilled, PlusCircleOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  Button,
  Input,
  Form,
  Space,
  Modal,
  Col,
  Row,
  Select,
  message,
  AutoComplete,
} from "antd";
import { customerList, selectedCustomer } from "features/pos/posSlice";
import Axiosinstance from "utils/AxiosInstance";
import CountrySelect from "components/Input/CountrySelect";
import { SelectStatus } from "utils/CISUI";

const SelectRequest = ({
  error_msg = "",
  name = "",
  label = "",
  initialValue = null,
  placeholder = "",
  required = false,
  path = "",
  showSearch = true,
  addOn = "",
  mode = null,
  ...arg
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
    // console.log("open", open);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  const { isLoading: customerLoading, isError, data } = useQuery([path], () =>
    Axiosinstance.get(path).then((res) => res.data.data)
  );
  // console.log("dataFirst", data);

  const { data: guestData, isLoading: customerLoading2 } = useQuery(
    ["/guests/guestlist_1"],
    () =>
      Axiosinstance.get("/guests/guestlist?is_dep=1").then((res) => {
        const allCustomers = res.data.data.map((item) => {
          return {
            ...item,
            value: `${item.name}`,
            label: `${item.name}`,
          };
        });
        dispatch(customerList(allCustomers));
        return allCustomers;
      })
  );

  let updatedGuests = [];

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(
      "/guests/addnewguest",
      values
    );
    updatedGuests = response;
    // console.log("updatedGuests", updatedGuests);
    return response;
  };

  const { mutate, isLoading } = useMutation(addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        // console.log("🚀 ~ file: AddSelectCustomer.jsx:92 ~ data:", data.guest_list);
        message.success(data.msg);
        form.resetFields();
        handleCancel();
        queryClient.setQueryData(path, (oldData) => {
          return data.guest_list;
        });
        dispatch(selectedCustomer(data.guest_list));
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const finishHandler = (values) => {
    const data = {
      ...values,
      city: values?.city ?? "",
      status: 1
    };

    mutate(data);
  };

  if (isError) {
    return (
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: error_msg }]}
        extra={addOn === "" ? "" : addOn}
      >
        <Select
          placeholder={placeholder}
          style={{ width: "100%" }}
          status="error"
          size="large"
          allowClear
          mode={mode}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label.toLowerCase() ?? "").includes(input)
          }
          {...arg}
        >
          <Select.Option style={{ textAlign: "center" }}>
            <Button
              type="link"
              icon={<ReloadOutlined />}
              onClick={() => window.location.reload()}
              className="reload_page"
            >
              Something is wrong please reload the page
            </Button>
          </Select.Option>
        </Select>
      </Form.Item>
    );
  }

  return initialValue === null ? (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      extra={addOn === "" ? "" : addOn}
    >
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        mode={mode}
        allowClear
        suffixIcon={<PlusCircleOutlined onClick={showModal} style={{ fontWeight: "bolder", fontSize: 20 }} />}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input)
        }
        loading={customerLoading2}
        size="large"
        {...arg}
        options={data?.map((item, index) => ({
          key: index,
          value: item.id,
          label: item.name,
        }))}
      />
      <Modal
        open={open}
        title="New Customer"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form layout="vertical" form={form} onFinish={finishHandler}>
          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter full name",
                  },
                ]}
              >
                <Input placeholder="Please enter full name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true, message: "Enter email" },
                ]}
              >
                <Input placeholder="Enter email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: "Enter phone number",
                  },
                ]}
              >
                <Input placeholder="Enter phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={12}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="text" placeholder="Enter your city" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="zip_code"
                label="ZipCode"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder="Enter your zip code" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input placeholder="Enter your state" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <CountrySelect
                name="country"
                label="Country"
                placeholder="Select country"
                size="default"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "Enter your address",
                  },
                ]}
              >
                <Input placeholder="Enter your address" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="passport"
                label="Passport"
                rules={[
                  {
                    required: true,
                    message: "Enter your passport number",
                  },
                ]}
              >
                <Input placeholder="Enter your passport number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={24}>
              <Form.Item
                name="nid"
                label="Nid"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input type="number" placeholder="Enter your nid number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={24}>
              <SelectStatus />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ padding: 3 }}>
            <Col span={24}>
              <Form.Item name="special_note" label="Special Notes">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="submit__btn">
                <Space>
                  <Button icon={<CaretLeftOutlined />} onClick={handleCancel}>
                    Back
                  </Button>
                  <Button icon={<ReloadOutlined />} htmlType="reset">
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    icon={<CheckCircleFilled />}
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={[{ required, message: error_msg }]}
      initialValue={initialValue}
      extra={addOn === "" ? "" : addOn}
      mode={mode}
    >
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        allowClear
        suffixIcon={<PlusCircleOutlined style={{ fontWeight: "bolder", fontSize: 20 }} onClick={showModal} />}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input)
        }
        loading={customerLoading2}
        {...arg}
        size="large"
        options={data?.map((item, index) => ({
          key: index,
          value: item.id,
          label: item.name,
        }))}
      >
        {/* {isLoading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          data?.map((item, index) => (
            <Select.Option key={index} value={item.id}>
              {item.name}
            </Select.Option>
          ))
        )} */}
      </Select>
      <Modal
        open={open}
        title="New Customer"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        <Form layout="vertical" form={form} onFinish={finishHandler}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter full name",
                  },
                ]}
              >
                <Input placeholder="Please enter full name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    message: " enter email ",
                  },
                ]}
              >
                <Input placeholder=" enter  email" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone"
                rules={[
                  {
                    required: true,
                    message: " enter phone number",
                  },
                ]}
              >
                <Input placeholder=" enter phone number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: " enter your city",
                  },
                ]}
              >
                <Input placeholder=" enter your city" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="zip_code"
                label="ZipCode"
                rules={[
                  {
                    required: true,
                    message: "enter your zipCode",
                  },
                ]}
              >
                <Input placeholder="enter your zipCode" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "enter your state",
                  },
                ]}
              >
                <Input placeholder=" enter your state" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <CountrySelect
                name="country"
                label="Country"
                placeholder="select country"
                size="default"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: true,
                    message: "enter your address",
                  },
                ]}
              >
                <Input placeholder="enter your address" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="passport"
                label="Passport"
                rules={[
                  {
                    required: true,
                    message: "enter your passport number",
                  },
                ]}
              >
                <Input placeholder="enter your passport number" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                name="nid"
                label="Nid"
                rules={[
                  {
                    required: true,
                    message: " enter your nid number",
                  },
                ]}
              >
                <Input placeholder="enter your nid number" />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: " select activity",
                  },
                ]}
              >
                <Select placeholder=" select activity">
                  <Option value="1">Active</Option>
                  <Option value="2">Inactive </Option>
                </Select>
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="special_note" label="Special Notes">
                <Input.TextArea />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="submit__btn">
                <Space>
                  <Button icon={<CaretLeftOutlined />} onClick={handleCancel}>
                    Back
                  </Button>
                  <Button icon={<ReloadOutlined />} htmlType="reset">
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    icon={<CheckCircleFilled />}
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Form.Item>
  );
};

export default SelectRequest;
