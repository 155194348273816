/* eslint-disable no-unsafe-optional-chaining */
import { Card, Col, Form, message, Row, Select, Spin } from "antd";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import GetModuleFields from "utils/GetModuleFields";
import SelectRequest from "components/Input/SelectRequest";
import DATA from "./data.json";

const NewHotel = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  console.log("user:", user);
  const dispatch = useDispatch();
  const [secondDropdownVisible, setSecondDropdownVisible] = useState(false);
  // const [firstDropdownValue, setFirstDropdownValue] = useState(false);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  const handleParentIdChange = (value) => {
    const secondDropdownVisibility = value === 1;
    setSecondDropdownVisible(secondDropdownVisibility);
    console.log(value, "value");
  };

  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });
  const onFinish = (values) => {
    const data = {
      ...values,
      logo: values?.logo
        ? values.logo.map((item) => {
          return item?.response ? item?.response : item;
        })
        : [],
      cover_photo: values?.cover_photo
        ? values.cover_photo.map((item) => {
          return item?.response ? item?.response : item;
        })
        : [],
      status: 1,
    };
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  useEffect(() => {
    console.log(user?.hotel_id, "area_purpose");
    if (user?.hotel_id === 0) {
      form.setFieldsValue({
        parent_id: 0,
      });
    }
  },
  [user?.hotel_id]);

  // useEffect(() => {
  //   if (user?.hotel_id !== 0) {
  //     setFirstDropdownValue(false);
  //   }
  //   console.log(firstDropdownValue, "firstDropdownValue");
  // }, [user?.hotel_id]);

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        logo: data?.logo !== null ? data?.logo : [],
        cover_photo: data?.cover_photo !== null ? data?.cover_photo : [],
      };

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  const onFieldsChange = (changedFields) => {
    if (changedFields.length > 1) return;

    if (changedFields[0]?.name[0] === "hotel_legal_name") {
      form.setFieldsValue({
        hotel_name: changedFields[0]?.value,
      });
    }
  };

  // if (DATA === null) {
  //   return <GlobalLoader />;
  // }

  // if (loading) {
  //   return <GlobalLoader />;
  // }

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Spin spinning={isLoading || loading || DATA == null}>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="cis__form"
          form={form}
          layout="vertical"
          onFieldsChange={onFieldsChange}
        >
          <Row gutter={[16, 0]}>
            {user?.hotel_id === 0 &&
            <Col span={8}>
              <Form.Item
                name="parent_id"
                label="Area"
                initialValue={null}
              >
                <Select onChange={handleParentIdChange} size="large" placeholder="Select an option" style={{ width: "100%" }}>
                  <Select.Option value={0}>Parent</Select.Option>
                  <Select.Option value={1}>Branch</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            }
            {secondDropdownVisible && (
            <Col span={8}>
              <SelectRequest
                path="overview/hotellist?is_dep=1"
                name="hotel_id"
                placeholder="Select hotel"
                label="Parent"
              />
            </Col>
            )}
            <GetModuleFields data={DATA?.input_fields} />
            <Suspense fallback={<Loader />}>
              <FormSubmitBtns isLoading={isLoading} />
            </Suspense>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
};

export default NewHotel;
