/* eslint-disable jsx-a11y/label-has-associated-control */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// import React, { useEffect, useState } from "react";
// import { Modal, Input, Select, Spin, Button, message } from "antd";
// import Axiosinstance from "utils/AxiosInstance";
// import { useMutation } from "react-query";
// import { useParams } from "react-router-dom";

// const EditModal = ({ isModalVisible, handleModalClose }) => {
//   const { id } = useParams();
//   const [securityDeposit, setSecurityDeposit] = useState(false);
//   const [radioValue, setRadioValue] = useState(2);
//   const [ref, setRef] = useState("");
//   const [amount, setAmount] = useState(0);
//   const [generalInfo, setGeneralInfo] = useState("");

//   useEffect(() => {
//     Axiosinstance.get(`booking/bookingdetails/${id}`)
//       .then((res) => res.data)
//       .then((data) => {
//         setGeneralInfo(data?.data);
//       })
//       .catch((error) => {
//         console.error("Error occurred while fetching data:", error);
//       });
//   }, [id]);

//   const addGuest = async (values) => {
//     const { data: response } = await Axiosinstance.post(`https://hotelmgtapi.cisstaging.com/api/booking/addsecurity/${generalInfo.booking_id}`, values);
//     console.log(values, "values");
//     return response;
//   };

//   const { mutate, isLoading } = useMutation(addGuest, {
//     onSuccess: (data) => {
//       if (data.status === 1) {
//         message.success(data.msg);
//         setSecurityDeposit(false);
//       } else if (data.status === 0) {
//         message.warning(data.msg);
//       } else {
//         message.error(data.msg);
//       }
//     },
//     onError: () => {
//       message.error("Server Error");
//     },
//   });

//   const onFinish = () => {
//     const data = {
//       reference_number: ref,
//       amount,
//       payment_method: radioValue,
//     };
//     mutate(data);
//   };

//   return (
//     <Modal
//       title="Edit Entry"
//       open={isModalVisible}
//       onOk={onFinish}
//       onCancel={handleModalClose}
//       footer={[
//         <Button key="back" onClick={handleModalClose}>
//           Cancel
//         </Button>,
//         <Button
//           key="submit"
//           type="primary"
//           onClick={onFinish}
//           disabled={isLoading}
//         >
//           {isLoading ? (
//             <Spin size="small" />
//           ) : (
//             "Update"
//           )}
//         </Button>,
//       ]}
//     >
//       <div className="modal-container">
//         <label htmlFor="payment_type">Payment Type</label>
//         <Select
//           name="payment_type"
//           style={{ width: "100%" }}
//           value={radioValue}
//           onChange={(value) => setRadioValue(value)}
//         >
//           <Select.Option value={1}>Cash</Select.Option>
//           <Select.Option value={2}>Card</Select.Option>
//           <Select.Option value={3}>Wallet</Select.Option>
//         </Select>
//         <label htmlFor="reference_number">Reference Number</label>
//         <Input
//           name="reference_number"
//           style={{ width: "100%" }}
//           placeholder="Reference Number"
//           value={ref}
//           onChange={(e) => setRef(e.target.value)}
//         />
//         <label htmlFor="amount">Amount</label>
//         <Input
//           style={{ width: "100%" }}
//           placeholder="Amount"
//           name="amount"
//           value={amount}
//           onChange={(e) => setAmount(e.target.value)}
//         />
//       </div>
//     </Modal>
//   );
// };

// export default EditModal;

import React, { useEffect, useState } from "react";
import { Modal, Input, Select, Spin, Button, message } from "antd";
import Axiosinstance from "utils/AxiosInstance";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";

const SecDepositModal = ({ visible, inVisible }) => {
  const { id } = useParams();
  const [generalInfo, setGeneralInfo] = useState("");
  const [radioValue, setRadioValue] = useState(2);
  const [ref, setRef] = useState("");
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    Axiosinstance.get(`booking/bookingdetails/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setGeneralInfo(data?.data);
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
      });
  }, [id]);

  const addGuest = async (values) => {
    const { data: response } = await Axiosinstance.post(`https://hotelmgtapi.cisstaging.com/api/booking/addsecurity/${generalInfo.booking_id}`, values);
    console.log(values, "values");
    return response;
  };

  const { mutate, isLoading } = useMutation(addGuest, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        inVisible(false);
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = () => {
    const data = {
      reference_number: ref,
      amount,
      payment_method: radioValue,
    };
    mutate(data);
  };

  return (
    <Modal
      title="Security Deposit"
      open={visible}
      onOk={onFinish}
      onCancel={() => inVisible(false)}
      footer={[
        <Button key="back" onClick={() => inVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onFinish}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spin size="small" />
          ) : (
            "Update"
          )}
        </Button>,
      ]}
    >
      <div className="modal-container">
        <label htmlFor="payment_type">Payment Type</label>
        <Select
          name="payment_type"
          style={{ width: "100%" }}
          value={radioValue}
          onChange={(value) => setRadioValue(value)}
        >
          <Select.Option value={1}>Cash</Select.Option>
          <Select.Option value={2}>Card</Select.Option>
          <Select.Option value={3}>Wallet</Select.Option>
        </Select>
        <label htmlFor="reference_number">Reference Number</label>
        <Input
          name="reference_number"
          style={{ width: "100%" }}
          placeholder="Reference Number"
          value={ref}
          disabled={radioValue === 1}
          onChange={(e) => setRef(e.target.value)}
        />
        <label htmlFor="amount">Amount</label>
        <Input
          style={{ width: "100%" }}
          placeholder="Amount"
          name="amount"
          type="number"
          value={amount}
          onChange={(e) => {
            setAmount(parseInt(e.target.value, 10));
          }}
        />
      </div>
    </Modal>
  );
};

export default SecDepositModal;
