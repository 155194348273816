import { Form, Select } from "antd";
import GlobalLoader from "components/Loader/GlobalLoader";
import useCountries from "utils/useCountries";

const CountrySelect = ({
  name = "",
  label = "",
  initialValue = null,
  placeholder = "",
  rules = [{ required: false }],
  showSearch = true,
  loading = false,
  ...arg
}) => {
  const { getAll } = useCountries();

  return initialValue === null ? (
    <Form.Item label={label} name={name} rules={rules}>
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        filterOption={(input, option) => {
          return (
            option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        loading={loading}
        size="large"
        {...arg}
      >
        {loading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          getAll()?.map((item, index) => {
            console.log(index, "get");
            return (
              <Select.Option key={index} value={index}>
                {item.flag} {item.label}
              </Select.Option>
            );
          })
        )}
      </Select>
    </Form.Item>
  ) : (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      initialValue={initialValue}
    >
      <Select
        showSearch={showSearch}
        placeholder={placeholder}
        style={{ width: "100%" }}
        filterOption={(input, option) => {
          return (
            option.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0
          );
        }}
        loading={loading}
        size="large"
        {...arg}
      >
        {loading ? (
          <Select.Option>
            <GlobalLoader />
          </Select.Option>
        ) : (
          getAll()?.map((item, index) => {
            return (
              <Select.Option key={index} value={item.value}>
                {item.flag} {item.label}
              </Select.Option>
            );
          })
        )}
      </Select>
    </Form.Item>
  );
};

export default CountrySelect;
