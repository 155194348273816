import axios from "axios";
import { store } from "app/store";
import config from "./Config";

const Axiosinstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Axiosinstance.interceptors.request.use(async (config) => {
  const token = await store.getState().auth.accessToken;
  config.headers.Authorization = token ? `${token}` : "";
  return config;
});

export default Axiosinstance;
