import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { IconBase } from "react-icons";

const CardInnerTitle = ({ title, actionTitle, icon, shape, size, btnText }) => {
  return (
    <div className="card__innter__title">
      <div className="inner__title">{title}</div>
      <div className="action__area">
        <div className="action__title">{actionTitle}</div>
        <div className="action__button">
          {icon ? <Button icon={icon} size={`size? ${size}: "small"`} shape={`shape? ${shape}: "default"`} />
            :
            btnText ? <Button>{btnText}</Button> : "" }
        </div>
      </div>
    </div>
  );
};

export default CardInnerTitle;
