import React from "react";
import { Dropdown, Badge } from "antd";
import { BellOutlined } from "@ant-design/icons";

const items = [
  {
    key: "1",
    label: "Notification item one",
  },
  {
    key: "2",
    label: "Notification item two",
  },
  {
    key: "3",
    label: "Notification item three",
  },
];
const NotificationItem = () => {
  return (
    <div className="notification__item item__two">
      <Dropdown menu={{ items }}>
        <a href="/" onClick={(e) => e.preventDefault()}>
          <span>
            <Badge dot>
              <BellOutlined style={{ fontSize: "20px" }} />
            </Badge>
          </span>
        </a>
      </Dropdown>
    </div>
  );
};

export default NotificationItem;
