/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unsafe-optional-chaining */
import { Card, Form, message, DatePicker, Button, Row, Col, InputNumber, Popconfirm, Select, Radio, Space } from "antd";
import { CheckCircleFilled, CloseCircleOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import Loader from "components/Loader";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ListIcon from "shared/ListIcon";
import Axiosinstance from "utils/AxiosInstance";
import dayjs from "dayjs";
import { dateFormat } from "utils/CISUI";
import SelectRequest from "./AddSelectCustomer";
import DATA from "./data.json";
import BookingModal from "./PaymentObject";
import SelectRequestAgent from "./Reference";

const { RangePicker } = DatePicker;

const NewBooking2 = () => {
  const [open, setOpen] = useState(false);
  const [prevData, setPrevData] = useState({});
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { booking_id } = useParams();
  const dispatch = useDispatch();
  const [room, setRoom] = useState([]);
  const [price, setPrice] = useState(null);
  const [nights, setNights] = useState(null);
  const [discountType, setDiscountType] = useState("amount");
  const [discount, setDiscount] = useState(null);
  const [newRoomtype, setNewRoomtype] = useState({});
  // const [selectedRoom, setSelectedRoom] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [total, setTotal] = useState(null);
  const [directSubmit, setDirectSubmit] = useState(false);
  const [selectedRoomRate, setSelectedRoomRate] = useState(2);
  const [selectedRoomTypeID, setSelectedRoomTypeID] = useState(null);
  const [status, setStatus] = useState(null);
  const [bookingItems, setBookingItems] = useState([]);

  const [state, setState] = useState([
    {
      room_type: undefined,
      room_id: undefined,
      room_rate: undefined,
      check_in: "",
      check_out: "",
      nights: 0,
      num_guest: undefined,
      price: undefined,
      discount_percentage: undefined,
      tax_percentage: undefined || 0,
      total_price: undefined,
    },
  ]);

  const [payableAmount, setPayableAmount] = useState(0.0);
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [taxAmount, setTaxAmount] = useState(0.0);
  const [adjustAmount, setAdjustAmount] = useState(0.0);
  const [exchangeAmount, setExchangeAmount] = useState(0.0);
  const [paidAmount, setPaidAmount] = useState(0.0);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);

  const addRecord = async (values) => {
    const { data: response } = await Axiosinstance.post(DATA?.urls.add, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        queryClient.refetchQueries([DATA.urls.list]);
        navigate(DATA.urls.list);
      } else if (data.status === 0) {
        message.warning(data.msg[0].msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = async (values) => {
    const bookingItems = state.map((item) => ({
      ...item,
      check_in: dayjs(item.check_in).format("YYYY-MM-DD"),
      check_out: dayjs(item.check_out).format("YYYY-MM-DD"),
      room_id: parseInt(item.room_id, 10) || 0,
    }));
    console.log(bookingItems, "bookingItems----------------------");

    const data = {
      ...values,
      referred_by: form.getFieldValue("refrred_by") || 0,
      guest_id: form.getFieldValue("guest_id") || 0,
      booking_items: bookingItems,
      booking_type: form.getFieldValue("booking_type") || 1,
      guest_type: form.getFieldValue("guest_type") || 1,
      adjust_amount: adjustAmount || 0,
      payable_amount: payableAmount || 0,
      paid_amount: paidAmount || 0,
      exchange_amount: exchangeAmount || 0,
      whole_tax_percentage: taxAmount || 0,
      payment_object: [],
      status: form.getFieldValue("status"),
      whole_discount_amount: (discountType === "amount" ? discountAmount : (payableAmount * discountAmount) / 100).toFixed(2),
      whole_discount_percentage: discountAmount || 0,
    };
    console.log(values, "values--------------$$$%--------");
    console.log(data, "bookingItems----------------------");
    if (!directSubmit) {
      setOpen(true);
      setPrevData(data);
      if (data.status === 0) {
        message.warning(data.msg[0].msg);
      }
    } else {
      mutate(data);
    }
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const handleAddRows = () => {
    const booking_items = {
      room_type: undefined,
      room_id: undefined,
      room_rate: undefined,
      check_in: "",
      check_out: "",
      nights: 0,
      num_guest: undefined,
      price: undefined,
      discount_percentage: undefined,
      tax_percentage: undefined || 0,
      total_price: 0,
    };
    setState([...state, booking_items]);
  };

  let roomTypeDetails = null;

  const handleChange = (e, id, field_name) => {
    const list = [...state];
    list[id][field_name] = e;

    if (selectedRoomRate === 2 && field_name === "room_type") {
      const filterItem = bookingItems.find((item) => item.id === e);
      list[id].price = filterItem?.base_price;
      list[id].discount_percentage = filterItem?.discount_percentage;
      console.log("selectedRoomRate", e, id, bookingItems);
    }

    // const selectedIndex = room.findIndex((item) => item.id === e);

    // // Create a new 'room' array without the selected room
    // const updatedRoom = [...room.slice(0, selectedIndex), ...room.slice(selectedIndex + 1)];
    // console.log("updatedRoom", updatedRoom);

    // setRoom(updatedRoom);

    // setSelectedRoomId(e);

    roomTypeDetails = roomrate.find((room) => room.id === list[id].room_type);
    setNewRoomtype(roomTypeDetails);

    const calculateTotal = (row) => {
      const price = row.price || 0;
      const discount_amount = (price * row.discount_percentage) / 100 || 0;
      const tax_amount = (price * row.tax_percentage) / 100 || 0;
      const total = (((price - discount_amount) + tax_amount) * row.nights).toFixed(2) || 0;
      return total;
    };

    const modifiedRow = list[id];
    const total = calculateTotal(modifiedRow);

    modifiedRow.total_price = +total;
    list[id] = modifiedRow;
    console.log("listx", list);
    setState(list);
  };

  const handleRowDelete = (id) => {
    if (state.length === 1) {
      message.warning("You cannot delete this item");
    } else {
      const newArr = state.filter((item, index) => index !== id);
      const totalAmount = newArr.reduce((total, item) => total + item.total_price, 0) - adjustAmount;
      setState(newArr);
    }
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${booking_id}`],
    () => Axiosinstance.get(DATA?.urls?.details + booking_id).then((res) => res.data.data),
    { enabled: !!(booking_id && DATA), refetchOnMount: true }
  );

  const { data: roomrate } = useQuery(("rooms_ratings/roomtypelist"), () =>
    Axiosinstance.get("rooms_ratings/roomtypelist?is_dep=1").then((res) => res.data.data)
  );

  const handleStatusChange = (value) => {
    setStatus(value);
    // console.log(value, "status_value");
  };

  const calculateNights = (e, idx) => {
    const checkIn = dayjs(e[0]).format(dateFormat);
    const checkOut = dayjs(e[1]);
    const nightsCount = checkOut.diff(checkIn, "day");

    // console.log(checkIn, "checkOut");

    setState((prevState) => {
      const newState = [...prevState];
      newState[idx] = {
        ...newState[idx],
        check_in: checkIn,
        check_out: checkOut,
      };
      newState[idx].nights = nightsCount;
      newState[idx].total_price = (newState[idx].price - (newState[idx].price * newState[idx].discount_percentage) / 100).toFixed(2) * newState[idx].nights;
      return newState;
    });

    if (nightsCount <= 0) {
      message.warning("You must book for at least one night");
      setNights(0);
    } else {
      setNights(nightsCount);
    }
    // console.log(nights, "nights");
  };

  // useEffect(() => {
  //   // const formValues = form.getFieldsValue();
  //   const roomType = roomrate && roomrate.find((roomType) => roomType.id === state.room_type);
  //   setSelectedRoomTypeID(roomType);

  //   if (roomType) {
  //     if (selectedRoomRate === 1) {
  //       setPrice(roomType.base_price);
  //     } else {
  //       setPrice(null);
  //     }
  //   } else {
  //     setNights(null);
  //     setPrice(null);
  //   }
  // }, [form.getFieldsValue(), roomrate, selectedRoomRate, nights, price]);

  useEffect(() => {
    if (roomrate !== undefined) {
      const newRoomRate = [...roomrate];

      if (state[0]?.room_rate !== "") {
        for (let i = 0; i < state.length; i++) {
          if (state[i].room_rate !== "") {
            for (let j = 0; j < newRoomRate.length; j++) {
              if (newRoomRate[j].id === state[i].room_rate) {
                newRoomRate.splice(j, 1);
              }
            }
          }
        }
      }

      setBookingItems([...newRoomRate]);
    }
  }, [roomrate, state]);

  const handleRoomChange = (value) => {
    setRoom([]);
    Axiosinstance.get(`booking/roomnumbers/${value}`)
      .then((res) => {
        const rooms = res.data.data;
        // console.log("rooms-------------", rooms[1].id);
        // console.log("rooms-------------", rooms);
        const roomOptions = rooms.map((room) => ({
          id: room.id,
          room_id: room.id,
          room_number: room.name,
        }));
        setRoom(roomOptions);
        console.log("roomOptions-------------", roomOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRoomRateChange = (value, idx) => {
    console.log("value", value);
    setSelectedRoomRate(value);
    if (value == 2) {
      setState((prevState) => {
        const newState = [...prevState];
        const roomType = roomrate.find((room) => room.id === newState[idx].room_type);
        // console.log(roomType, "roomType");

        newState[idx].nights = state[idx].nights || 0;

        const basePrice = roomType?.base_price || 0;
        const discountPercentage = roomType?.discount_percentage || 0;

        newState[idx].price = basePrice || 0;
        newState[idx].num_guest = roomType?.maximum_guests || 0;
        newState[idx].discount_percentage = discountPercentage || 0;
        const total = (basePrice - (basePrice * discountPercentage) / 100).toFixed(2) || 0;
        newState[idx].total_price = Number((total * newState[idx].nights).toFixed(2)) || 0;

        newState[idx].room_rate = value;
        return newState;
      });
    } else {
      setState((prevState) => {
        const newState = [...prevState];
        const roomType = roomrate.find((room) => room.id === newState[idx].room_type);
        // console.log(roomType, "roomType");
        newState[idx].price = null;
        newState[idx].discount_percentage = null;
        newState[idx].num_guest = roomType?.maximum_guests || 0;
        newState[idx].total_price = (newState?.price - (newState?.price * newState?.discount_percentage) / 100).toFixed(2) || 0;
        newState[idx].room_rate = value;
        return newState;
      });
    }
  };

  const handleDiscountChange = (value) => {
    // console.log(value, "discount_type");
    setDiscountType(value);
  };

  useEffect(() => {
    let totalAmount = 0;
    for (let i = 0; i < state.length; i++) {
      totalAmount += Number(state[i].total_price) || 0;
    }
    setPayableAmount(totalAmount);
    // console.log(payableAmount, "first__+++payable");
  }, [state]);

  // useEffect(() => {
  //   setState((prevState) => {
  //     const newArrayState = prevState.map((item) => {
  //       // Check if the current item's room type matches the newRoomtype
  //       if (item.room_type === newRoomtype?.id) {
  //         return {
  //           ...item,
  //           price: newRoomtype?.base_price,
  //           discount_percentage: newRoomtype?.discount_percentage,
  //           total_price: (newRoomtype?.base_price - (newRoomtype?.base_price * newRoomtype?.discount_percentage) / 100).toFixed(2) || null,
  //           room_rate: 2,
  //         };
  //       }
  //       return item; // Return unchanged for other rows
  //     });
  //     return newArrayState;
  //   });
  // }, [nights, newRoomtype]);

  useEffect(() => {
    setState((prevState) => {
      const newArrayState = prevState.map((item) => {
        if (item.room_type === newRoomtype?.id && item.id === newRoomtype?.row_id) {
          return {
            ...item,
            num_guest: newRoomtype?.maximum_guests,
            price: newRoomtype?.base_price,
            discount_percentage: newRoomtype?.discount_percentage,
            total_price: ((newRoomtype?.base_price - (newRoomtype?.base_price * newRoomtype?.discount_percentage) / 100) * item.nights).toFixed(2),
            room_rate: 2,
          };
        }
        return item;
      });
      return newArrayState;
    });
  }, []);
  // nights, newRoomtype, state
  // useEffect(() => {
  //   form.setFieldsValue({ price });
  //   form.setFieldsValue({ discount });
  //   form.setFieldsValue({ total });
  //   form.setFieldsValue({ nights });
  // }, [price, discount, total, nights]);

  useEffect(() => {
    if (booking_id !== undefined && data) {
      const values = {
        ...data,
      };

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [booking_id, data]);

  if (DATA === null) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title={booking_id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col lg={6} md={8} span={24}>
            <Form.Item
              label="Booking Type"
              name="booking_type"
              rules={[
                { required: false, message: "Booking type is required" },
              ]}
            >
              <Radio.Group defaultValue={1}>
                <Radio value={1}>Single</Radio>
                <Radio value={2}>Group</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={6} md={8} span={24}>
            <Form.Item
              label="Guest Type"
              name="guest_type"
              rules={[
                { required: false, message: "Guest type is required" },
              ]}
            >
              <Radio.Group defaultValue={1}>
                <Radio value={1}>Individual</Radio>
                <Radio value={2}>Company</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={6} md={8} span={24}>
            <SelectRequestAgent label="Reference" name="refrred_by" placeholder="Referred By" path="booking/agentinfolist?is_dep=1" />
          </Col>
          <Col lg={6} md={8} span={24}>
            <Form.Item
              label="Guest / Customer / Bill Payer"
              name="guest_id"
              rules={[
                { required: true, message: "Guest is required" },
              ]}
            >
              <SelectRequest
                placeholder="Select Guest"
                path="guests/guestlist?is_dep=1"
                name="guest_id"
              />
            </Form.Item>
          </Col>
          <Col lg={6} md={8} span={24}>
            <Form.Item
              name="status"
              label="Status"
              rules={[
                { required: true, message: "Status is required" },
              ]}
            >
              <Select
                size="large"
                placeholder="Select a status"
                style={{ width: "100%" }}
                onChange={handleStatusChange}
              >
                <Select.Option value={2}>Check In Now</Select.Option>
                <Select.Option value={7}>Reservation</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {/* <Col lg={6} md={8} span={24}>
            <Form.Item
              name="discount_type"
              label="Discount Type"
              rules={[
                { required: false, message: "Status is required" },
              ]}
            >
              <Select
                size="large"
                placeholder="Select an option"
                style={{ width: "100%" }}
                defaultValue="amount"
                onChange={handleDiscountChange}
              >
                <Select.Option value="amount">Amount($)</Select.Option>
                <Select.Option value="percentage">Percentage(%)</Select.Option>
              </Select>
            </Form.Item>
          </Col> */}
          <Col lg={24} span={24}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <p style={{ fontWeight: "450", fontSize: "16px" }}>
                Booking Items
              </p>
              <hr style={{ flexGrow: "1", margin: "0 20px" }} />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ margin: 0 }}
                onClick={() => handleAddRows()}
              >
                Add More
              </Button>
            </div>
          </Col>
          <Col lg={24} span={24}>
            <table className="tranx_table">
              <thead>
                <tr>
                  <th style={{ width: "1%", textAlign: "center" }} />
                  <th style={{ width: "9%", textAlign: "left" }}>Room Type</th>
                  {status === 7 ? null : (
                    <th style={{ width: "10%", textAlign: "left" }}>Room Number</th>
                  )}
                  <th style={{ width: "20%", textAlign: "left" }}>Check In & Check Out</th>
                  <th style={{ width: "6%", textAlign: "right" }}>Nights</th>
                  <th style={{ width: "8%", textAlign: "right" }}>Number of Guests</th>
                  <th style={{ width: "13%", textAlign: "left" }}>Room Rate</th>
                  <th style={{ width: "11%", textAlign: "right" }}>Price</th>
                  <th style={{ width: "11%", textAlign: "right" }}>
                    Discount (%)
                  </th>
                  <th style={{ width: "11%", textAlign: "right" }}>Total</th>
                </tr>
              </thead>
              <tbody>
                {/* console.log("state------", state); */}
                {state?.map((item, idx) => (
                  <tr id="addr0" key={idx}>
                    <td style={{ width: "30px" }}>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => handleRowDelete(idx)}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </td>
                    <td>
                      <Select
                        placeholder="Select an item"
                        style={{ width: "100%" }}
                        size="large"
                        value={item.room_type}
                        allowClear
                        onChange={(e) => {
                          handleRoomChange(e);
                          handleChange(e, idx, "room_type");
                        }}
                        loading={!(roomrate?.length > 0)}
                      >
                        {bookingItems?.map((item, index) => (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    {status === 7 ? null : (
                      <td>
                        <Select
                          placeholder="Select an item"
                          value={item.room_id}
                          style={{ width: "100%" }}
                          allowClear
                          onChange={(e) => handleChange(e, idx, "room_id")}
                          loading={!(room?.length > 0)}
                          disabled={item.room_type === undefined}
                        >
                          {room?.map((item, index) => (
                            <Select.Option key={index} value={item?.room_id}>
                              {item?.room_number}
                            </Select.Option>
                          ))}
                        </Select>
                      </td>
                    )}

                    <td>
                      <RangePicker
                        name="check_in"
                        className="gx-w-100 tranx_input_number"
                        disabledDate={(current) => current && current < dayjs().startOf("day")}
                        placeholder="Check In - Check Out"
                        onChange={(e) => calculateNights(e, idx, "check_in")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="nights"
                        className="gx-w-100 tranx_input_number"
                        value={item.nights}
                        placeholder="Nights"
                        onChange={(e) => handleChange(e, idx, "nights")}
                        readOnly
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="num_guest"
                        type="number"
                        className="gx-w-100 tranx_input_number"
                        value={item.num_guest}
                        placeholder="Number of Guests"
                        onChange={(e) => handleChange(e, idx, "num_guest")}
                      />
                    </td>
                    <td>
                      <Select
                        placeholder="Select an item"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(e) => handleRoomRateChange(e, idx, "room_rate")}
                        value={item.room_rate}
                      >
                        <Select.Option value={1}>Custom</Select.Option>
                        <Select.Option value={2}>Regular</Select.Option>
                      </Select>
                    </td>
                    <td>
                      <InputNumber
                        name="price"
                        type="number"
                        className="gx-w-100 tranx_input_number"
                        value={item.price}
                        placeholder="Price"
                        onChange={(e) => handleChange(e, idx, "price")}
                        readOnly={item.room_rate === 2}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="discount_percentage"
                        type="number"
                        className="gx-w-100 tranx_input_number"
                        value={item.discount_percentage}
                        placeholder="Discount"
                        onChange={(e) => handleChange(e, idx, "discount_percentage")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="Total"
                        className="gx-w-100 tranx_input_number"
                        value={Number(item.total_price) || 0}
                        placeholder="Total amount"
                        onChange={(e) => handleChange(e, idx, "total_price")}
                        readOnly={item.room_rate === 2}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                { status === 7 ? (
                  <>
                    <tr>
                      <td colSpan={6} />
                      <td
                        colSpan={2}
                      >
                        Total Amount:
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <InputNumber
                            size="small"
                            name="payable_amount"
                            className="gx-w-100 tranx_input_number"
                            value={(payableAmount).toFixed(2)}
                            disabled
                            placeholder="Payable Amount"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={6} />
                      <td colSpan={2}>
                        Discount &nbsp;
                        <Space>
                          <Select
                            size="small"
                            name="discount_type"
                            placeholder="Select an option"
                            // style={{ width: "17%" }}
                            defaultValue="amount"
                            onChange={handleDiscountChange}
                          >
                            <Select.Option value="amount">$</Select.Option>
                            <Select.Option value="percentage">%</Select.Option>
                          </Select> : &nbsp;
                          <p style={{ color: "red" }}>
                            ({discountType === "amount" ? (
                              <span>$ { discountAmount.toFixed(2) }</span>
                          ) : (
                            <span>$ { ((payableAmount * discountAmount) / 100).toFixed(2) }</span>
                          )
                            })
                          </p>
                        </Space>
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <InputNumber
                          size="small"
                          name="discount_amount"
                          className="gx-w-100 tranx_input_number"
                          value={discountAmount}
                          placeholder="Discount Amount"
                          onChange={(e) => setDiscountAmount(e)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={6} />
                      <td
                        colSpan={2}
                      >
                        Grand Total:
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <InputNumber
                            name="grand_total"
                            size="small"
                            className="gx-w-100 tranx_input_number"
                            value={discountType === "amount" ? (payableAmount - discountAmount).toFixed(2) : (payableAmount - (payableAmount * discountAmount) / 100).toFixed(2)}
                            // value={(payableAmount - discountAmount).toFixed(2)}
                            readOnly
                            placeholder="Grand total"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={6} />
                      <td
                        colSpan={2}
                      >
                        Tax (%):
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <InputNumber
                            size="small"
                            name="tax_amount"
                              // onChange={handlePaidAmountChange}
                            className="gx-w-100 tranx_input_number"
                            value={taxAmount}
                            placeholder="Tax"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={6} />
                      <td
                        colSpan={2}
                      >
                        Payable Amount:
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <InputNumber
                            size="small"
                            name="total_payable_amount"
                            className="gx-w-100 tranx_input_number"
                            value={discountType === "amount" ? (((payableAmount - discountAmount) * taxAmount / 100) + (payableAmount - discountAmount)).toFixed(2) : ((((payableAmount - (payableAmount * discountAmount) / 100) * taxAmount) / 100) + (payableAmount - (payableAmount * discountAmount) / 100)).toFixed(2)}
                            // value={(((payableAmount - discountAmount) * taxAmount / 100) + (payableAmount - discountAmount)).toFixed(2)}
                            readOnly
                            placeholder="Payable Amount"
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td colSpan={7} />
                      <td
                        colSpan={2}
                      >
                        Total Amount:
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <InputNumber
                            size="small"
                            name="payable_amount"
                            className="gx-w-100 tranx_input_number"
                            value={(typeof payableAmount === "number") ? payableAmount.toFixed(2) : ""}
                            readOnly
                            placeholder="Payable Amount"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} />
                      <td colSpan={2}>
                        Discount &nbsp;
                        <Space>
                          <Select
                            size="small"
                            name="discount_type"
                            placeholder="Select an option"
                            // style={{ width: "17%" }}
                            defaultValue="amount"
                            onChange={handleDiscountChange}
                          >
                            <Select.Option value="amount">$</Select.Option>
                            <Select.Option value="percentage">%</Select.Option>
                          </Select> : &nbsp;
                          <p style={{ color: "red" }}>
                            ({discountType === "amount" ? (
                              <span>$ { discountAmount.toFixed(2) }</span>
                          ) : (
                            <span>$ { ((payableAmount * discountAmount) / 100).toFixed(2) }</span>
                          )
                            })
                          </p>
                        </Space>
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <InputNumber
                          size="small"
                          name="discount_amount"
                          type="number"
                          className="gx-w-100 tranx_input_number"
                          value={discountAmount}
                          onChange={(e) => setDiscountAmount(e)}
                          placeholder="Discount Amount"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} />
                      <td
                        colSpan={2}
                      >
                        Grand Total:
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>

                          <InputNumber
                            name="grand_total"
                            size="small"
                            className="gx-w-100 tranx_input_number"
                            value={discountType === "amount" ? (payableAmount - discountAmount).toFixed(2) : (payableAmount - (payableAmount * discountAmount) / 100).toFixed(2)}
                            // value={(payableAmount - discountAmount).toFixed(2)}
                            readOnly
                            placeholder="Grand total amount"
                          />

                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} />
                      <td
                        colSpan={2}
                      >
                        Tax (%):
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <InputNumber
                            size="small"
                            type="number"
                            name="tax_amount"
                            onChange={(e) => setTaxAmount(e)}
                              // onChange={handlePaidAmountChange}
                            className="gx-w-100 tranx_input_number"
                            value={taxAmount}
                            placeholder="Tax"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={7} />
                      <td
                        colSpan={2}
                      >
                        Payable Amount:
                      </td>
                      <td colSpan={1} style={{ textAlign: "right", fontWeight: "bold" }}>
                        <div style={{ whiteSpace: "nowrap" }}>
                          <InputNumber
                            size="small"
                            name="total_payable_amount"
                            className="gx-w-100 tranx_input_number"
                            value={discountType === "amount" ? (((payableAmount - discountAmount) * taxAmount / 100) + (payableAmount - discountAmount)).toFixed(2) : ((((payableAmount - (payableAmount * discountAmount) / 100) * taxAmount) / 100) + (payableAmount - (payableAmount * discountAmount) / 100)).toFixed(2)}
                            // value={(((payableAmount - discountAmount) * taxAmount / 100) + (payableAmount - discountAmount)).toFixed(2)}
                            readOnly
                            placeholder="Payable Amount"
                          />
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tfoot>
            </table>
          </Col>
          <Suspense fallback={<Loader />}>
            <Space
              size="large"
              align="center"
              style={{ margin: "0 auto", display: "flex", flexDirection: "row" }}
              className="cis-form__submitbtns"
            >
              <Button
                icon={<CloseCircleOutlined />}
                onClick={() => navigate(-1)}
                size="large"
              >
                Cancel
              </Button>
              <Button icon={<ReloadOutlined />} htmlType="reset" size="large">
                Reset
              </Button>
              <Button
                type="primary"
                icon={<CheckCircleFilled />}
                // htmlType="submit"
                size="large"
                onClick={() => onFinish()
                }
              >
                Pay Now
              </Button>
              <Button
                type="primary"
                icon={<CheckCircleFilled />}
                htmlType="submit"
                size="large"
                onClick={() => setDirectSubmit(true)}
              >
                Pay Later
              </Button>
            </Space>
          </Suspense>
        </Row>
      </Form>
      <BookingModal isOpenPaymentModal={open} setIsOpenPaymentModal={setOpen} prev_data={prevData} payableAmount={discountType === "amount" ? (((payableAmount - discountAmount) * taxAmount / 100) + (payableAmount - discountAmount)).toFixed(2) : ((((payableAmount - (payableAmount * discountAmount) / 100) * taxAmount) / 100) + (payableAmount - (payableAmount * discountAmount) / 100)).toFixed(2)} />
    </Card>
  );
};

export default NewBooking2;
