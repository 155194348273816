import { Button, Space } from "antd";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <div className="error_container">
      <Space direction="vertical" align="center">
        <h2 title="title">Opps! Page not found...</h2>
        <h1 className="title__code">404</h1>
        <p className="title__subtitle">
          We can&apos;t find the page you&apos;re looking for.
        </p>
        <Link to="/">
          <Button size="large" type="primary" style={{ marginTop: "30px" }}>
            Go back home
          </Button>
        </Link>
      </Space>
    </div>
  );
};

export default Error404;
