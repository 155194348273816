/* eslint-disable max-len */
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useLoginMutation } from "features/auth/authApi";
import ReCAPTCHA from "react-google-recaptcha";
import Logo from "../assets/images/logo.svg";
import { items } from "../data/data";

const unsplashimg = {
  src: "https://source.unsplash.com/1600x900/?hotel",
  alt: "random unsplash image",
};
const image = `url(${unsplashimg.src})`;

const SignIn = () => {
  const [quotes, setQuotes] = useState("");
  const [reCaptcha, setReCaptcha] = useState(undefined);

  useEffect(() => {
    const randomNum = Math.floor(Math.random() * items.length);
    const randomQuote = items[randomNum];
    setQuotes(randomQuote);
  }, []);

  const [login, { isLoading }] = useLoginMutation();
  const handleRecaptcha = (value) => {
    setReCaptcha(value);
  };
  const onFinish = (values) => {
    // if (reCaptcha === null || reCaptcha === undefined) {
    //   message.error("Please confirm you are not a robot.");
    //   setReCaptcha(null);
    //   return;
    // }
    const data = {
      ...values,
      "g-recaptcha-response": reCaptcha,
    };
    login(data);
  };
  return (
    <main className="main__loginpage">
      <section className="home__login">
        <img
          src={unsplashimg.src}
          alt="home shape"
          className="login-shape-image"
        />
        <div className="login__container container">
          <div className="login__info">
            <div className="login__leftside__info">
              <div className="login__left__title">
                Empower your hotel business with our software to be on top of
                your competition
              </div>
              {/* <div className="login__left__des">
                A quick interactive session with our expert, to help you find
                out how to:
              </div> */}
              <div className="login__left__point">
                <ul>
                  <li>
                    Connect more guests and revenue streams with our software
                    and monitor the entire system efficiently from the front
                    desk.
                  </li>
                  <li>
                    Surveil your room rates during peak seasons or high-demand
                    periods and suggest offers based on previous customer
                    experience.
                  </li>
                  <li>
                    Manage restaurants and bars for the availability of items
                    and stock management along with room services and management
                    from the front desk.
                  </li>
                </ul>
              </div>
              <div className="owner__quote__area">
                <div className="ownwr__img">
                  <img src={Logo} alt="" />
                </div>
                <div className="owner__info">
                  <div className="owner__quote">
                    <i className="icon icon-quote-left" />
                    <span>{quotes.quote}</span>
                    <i className="icon icon-quote-right" />
                  </div>
                  <div className="owner__name">{quotes.name}</div>
                  {/* <div className="owner__address"> Mt. Jefferson View, USA</div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="login__form__area">
            <div className=" login___form">
              <div className="login__right">
                <img src={Logo} alt="brand logo" className="avatar" />
                <h3>Cosmo Hotel</h3>
              </div>
              {/* <p> Please enter your details</p> */}

              <Form
                className="login-form cis__form"
                onFinish={onFinish}
                name="basic"
                style={{ maxWidth: 400 }}
                layout="vertical"
              >
                <Form.Item
                  label="Email or Username"
                  rules={[
                    // {
                    //   type: "email",
                    //   message: "The input is not valid E-mail!",
                    // },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                  name="userid"
                  autoFocus={false}
                  className="form__item__element"
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                  name="password"
                  autoFocus={false}
                  className="form__item__element"
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    // type="password"
                    placeholder="Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item>
                  {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>Remember </Checkbox>
                  </Form.Item> */}
                  <Link to="/forgotpassword" className="login-form-forgot">
                    Forgot password?
                  </Link>
                </Form.Item>

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                  onChange={handleRecaptcha}
                  className="mb-6"
                />

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    size="large"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    Sign in
                  </Button>
                  <p className="login-form-signin">
                    Don&apos;t have an account ?
                    <Link to="/signup"> Signup</Link>
                  </p>
                </Form.Item>
              </Form>
            </div>
          </div>
          <p className="signin_copyright">
            A Product of <a href="/#">CIS Tech Ltd.</a> <br /> All Right Reserved by
            <a href="/#">CIS Tech Ltd.</a>
          </p>
        </div>
      </section>
      {/* <p className="footer__area">
        A Product of CIS Tech Ltd. <br /> All Right Reserved by CIS Tech Ltd.
      </p> */}
    </main>
  );
};

export default SignIn;
