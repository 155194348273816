import { Card } from "antd";
import { addToCart } from "features/pos/posSlice";
import { useDispatch, useSelector } from "react-redux";
import React from "react";

const { Meta } = Card;

const ProductCard = ({ data }) => {
  const { cart } = useSelector((state) => state.posData);
  const dispatch = useDispatch();
  const { id, name, sale_price, image } = data;
  console.log("pos----image-------------->>>", data);

  return (
    <Card
      hoverable
      style={{

      }}
      cover={
        JSON.parse(image).length > 0 ?
          <img
            alt="example"
            src={JSON.parse(image)[0]?.url}
            style={{ width: "100%", maxHeight: "150px", height: "100%", objectFit: "cover" }}
            className="product__image"
          />
          : ""
      }
    >
      <Meta
        title={name}
        description={`price: $${sale_price}`}
      />
    </Card>
  );
};

export default ProductCard;
