/* eslint-disable max-len */
import { Button, Col, Form, Input, Row, Select } from "antd";
import RegBg from "../assets/images/hotelbg3.jpg";
import Logo from "../assets/images/logo.svg";
import Shape from "../assets/images/shape-1.png";

const { Option } = Select;

const Registration = () => {
  return (
    <div className="registrtion__area">
      <div className="reg_wrapper">
        <img src={RegBg} alt="" className="reg_area_bg" />
        <div className="overlay" />
        <div className="reg_container container">
          <div className="reg_left_area">
            <img src={Shape} alt="" className="left_shape" />
            <img src={Shape} alt="" className="bottom_shape" />
            <div className="reg_left_info">
              <h1>PLZ REG HERE !!!</h1>
              <p>
                Reference site about Lorem Ipsum, giving information on its
                origins, as well as a random
              </p>
            </div>
          </div>

          <div className="reg_right_area">
            <div className="reg_form_area">
              <img src={Logo} alt="brand logo" className="avatar" />
              <h3>Welcome back</h3>
              <p> Please enter your details</p>
              <Form
                className="login-form cis__form space__top"
                // onFinish={onFinish}
                name="basic"
                // style={{ maxWidth: 400 }}
                layout="vertical"
              >
                <Row gutter={[16, 24]}>
                  <Col span={12}>
                    <Form.Item
                      label="Full Name"
                      rules={[
                        {
                          required: true,
                          message: "The input is not Empty!",
                        },
                      ]}
                      name="user_name"
                      autoFocus={false}
                      className="form__item__element"
                    >
                      <Input
                        // prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Your Name"
                        size="large"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: "The input is not Empty!",
                        },
                      ]}
                      name="user_name"
                      autoFocus={false}
                      className="form__item__element"
                    >
                      <Input
                        // prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="demo@gmail.com"
                        size="large"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  {/* <Divider /> */}
                  <Col span={12}>
                    <Form.Item
                      label="Company Name"
                      rules={[
                        {
                          required: true,
                          message: "The input is not Empty!",
                        },
                      ]}
                      name="user_name"
                      autoFocus={false}
                      className="form__item__element"
                    >
                      <Input
                        // prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Your Name"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "The input is not Empty!",
                        },
                      ]}
                      name="user_name"
                      autoFocus={false}
                      className="form__item__element"
                    >
                      <Input
                        // prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Your Name"
                        size="large"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="cpmpanysize"
                      label="Company Size"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a option"
                        // onChange={onGenderChange}
                        allowClear
                        className="site-form-item-icon"
                      >
                        <Option value="1">0-9 Employee</Option>
                        <Option value="2">10-20 Employee</Option>
                        <Option value="3">21-50 Employee</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="cpmpanysize"
                      label="Currence"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a option"
                        // onChange={onGenderChange}
                        allowClear
                        className="site-form-item-icon"
                      >
                        <Option value="1">0-9 Employee</Option>
                        <Option value="2">10-20 Employee</Option>
                        <Option value="3">21-50 Employee</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="cpmpanysize"
                      label="Company Size"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a option"
                        // onChange={onGenderChange}
                        allowClear
                        className="site-form-item-icon"
                      >
                        <Option value="1">0-9 Employee</Option>
                        <Option value="2">10-20 Employee</Option>
                        <Option value="3">21-50 Employee</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="cpmpanysize"
                      label="Company Size"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a option"
                        // onChange={onGenderChange}
                        allowClear
                        className="site-form-item-icon"
                      >
                        <Option value="1">0-9 Employee</Option>
                        <Option value="2">10-20 Employee</Option>
                        <Option value="3">21-50 Employee</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="space__top">
                    <Button type="primary" block>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
