import { Form, Input } from "antd";
import React from "react";

const InputTextArea = ({
  error_msg = "",
  placeholder = "",
  rows = 4,
  required = true,
  ...arg
}) => {
  return (
    <Form.Item rules={[{ required, message: error_msg }]} {...arg}>
      <Input.TextArea placeholder={placeholder} rows={rows} size="large" />
    </Form.Item>
  );
};

export default InputTextArea;
