import AppLayout from "containers/App/AppLayout";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function PrivateRoute({ children }) {
  const isLoggedIn = useAuth();

  return isLoggedIn ? (
    <AppLayout>{children}</AppLayout>
  ) : (
    <Navigate to="/signin" />
  );
}
