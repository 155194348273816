import { Button, Col, Row, Space } from "antd";
import React, { useState } from "react";
import moment from "moment";
import { FieldTimeOutlined } from "@ant-design/icons";
import TopActionButton from "./TopActionButton";

const momentZone = require("moment-timezone");

const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DetailsTop = ({ generalInfo, setGeneralInfo, booking_id, setPayInfo, onUpdateRoomInfo }) => {
  const booking_time = momentZone.tz(generalInfo.booked_time, userTimezone);
  const bookingTime = moment.tz(booking_time, "YYYY-MM-DD HH:mm").format("DD MMM Y h:mm a");
  return (

    <Row>
      <Col span={8}>
        <div>
          <div style={{ color: "#413c57", fontSize: "20px" }}>
            Booking ID: # <span style={{ color: "#1F9FD4" }}>{generalInfo.booking_id}</span>
          </div>
          <div style={{
            textTransform: "uppercase",
            color: "rgb(14, 69, 53)",
            fontWeight: "500",
            marginBottom: "10px" }}
          > Booking Time: <FieldTimeOutlined style={{ color: "#1F9FD4" }} /> <span>{bookingTime}</span>
          </div>
        </div>
      </Col>
      <Col span={10} offset={6}>
        <div>
          <TopActionButton
            generalInfo={generalInfo}
            setGeneralInfo={setGeneralInfo}
            setPayInfo={setPayInfo}
            onUpdateRoomInfo={onUpdateRoomInfo}
            booking_id={booking_id}
          />
        </div>
      </Col>

    </Row>

  );
};
export default DetailsTop;
