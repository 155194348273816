import { Button, Layout, theme } from "antd";
import NotificationItem from "shared/NotificationItem";
import SearchItem from "shared/SearchItem";
import AdvSearch from "components/AdvSearch";
import Sidebar from "components/Sidebar/index";
import TogleMenu from "components/TogleMenu";
import Topbar from "components/Topbar";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const { Header, Content } = Layout;

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const [showButton, setShowButton] = useState(true);
  const { user } = useSelector((state) => state.auth);
  // console.log("🚀 ~ file: AppLayout.js:15 ~ AppLayout ~ user__allright", user);
  const handleClick = () => {
    navigate("/frontdeskV3");
  };

  useEffect(() => {
    if (user?.hotel_id == 0) {
      setShowButton(false);
      console.log("🚀 ~allright", showButton);
    }
  }, []);

  return (
    <div className="dashboard">
      <Layout hasSider>
        <Sidebar />
        <Layout className="site-layout">
          <Header
            className="header__area"
            style={{
              position: "sticky",
              top: "0",
              width: "100%",
              zIndex: 2
            }}
          >
            <div className="hader__notification__area">
              <TogleMenu />
              <SearchItem />
              <NotificationItem />
              <Topbar />
              { showButton &&
              <Button type="primary" onClick={handleClick}>FRONT DESK</Button>
              }
            </div>
          </Header>
          <Content
            style={{
              padding: 24,
              minHeight: 280,
              backgroundColor: "#f7f7f7",
            }}
            className="main__content__area"
          >
            {children}
          </Content>
        </Layout>
      </Layout>
      <AdvSearch />
    </div>
  );
};

export default AppLayout;
