import { DatePicker, Form } from "antd";
import { monthFormat } from "utils/CISUI";

const InputMonth = ({
  error_msg = "",
  required = true,
  placeholder = "",
  name = "",
  label = "",
  picker = "",
  ...arg
}) => {
  return (
    <Form.Item
      rules={[{ required, message: error_msg }]}
      name={name}
      label={label}
    >
      <DatePicker format={monthFormat} picker={picker} size="large" placeholder={placeholder} {...arg} />
    </Form.Item>
  );
};

export default InputMonth;
