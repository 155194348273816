/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-return-assign */
/* eslint-disable no-unsafe-optional-chaining */
import { Card, Form, message, Row, Input, Popconfirm, Col, InputNumber, Select, Button } from "antd";
import GetDataFromAPI from "utils/GetDataFromAPI";
import Loader from "components/Loader";
import dayjs from "dayjs";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { jsonData } from "features/data/dataSlice";
import { Suspense, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import FormSubmitBtns from "shared/FormSubmitBtns";
import ListIcon from "shared/ListIcon";
import Axios from "utils/AxiosInstance";
import GetModuleFields from "utils/GetModuleFields";
import InputGroup from "components/Input/InputGroup";
import InputTextArea from "components/Input/InputTextArea";
import DATA from "./data.json";

const NewPurchase = () => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [purchaseitems, setPurchaseItems] = useState([]);

  const [state, setState] = useState([
    {
      barcode: "",
      item_id: undefined,
      notes: "",
      discount_percentage: undefined,
      tax_percentage: undefined,
      total_price: undefined,
      quantity: undefined,
      unit_price: undefined,
    },
  ]);
  const [wholetaxpercentage, setWholeTaxPercentage] = useState(0.0);
  const [wholediscountpercentage, setWholeDiscountPercentage] = useState(0.0);

  const handleAddRows = () => {
    const purchase_items = {
      barcode: "",
      item_id: undefined,
      notes: "",
      discount_percentage: undefined,
      tax_percentage: undefined,
      total_price: undefined,
      quantity: undefined,
      unit_price: undefined,
    };
    const errors = {
      barcode: "",
      item_id: undefined,
      notes: "",
      discount_percentage: 0,
      tax_percentage: 0,
      total_price: 0,
      quantity: 0,
      unit_price: 0,
    };
    setState([...state, purchase_items]);
  };

  const [payableAmount, setPayableAmount] = useState(0.0);

  useEffect(() => {
    let totalAmount = 0;
    for (let i = 0; i < state.length; i++) {
      totalAmount += state[i].total_price || 0;
    }
    setPayableAmount(totalAmount);
    form.setFieldsValue({ payable_amount: totalAmount });
  }, [state, payableAmount]);

  // const handleChange = (e, id, field_name) => {
  //   const list = [...state];
  //   list[id][field_name] = e;

  //   const calculateTotal = (row) => {
  //     const price = row.price || 0;
  //     const quantity = row.quantity || 0;
  //     const discount_amount = (price * row.discount_percentage) / 100;
  //     const tax_amount = (price * row.tax_percentage) / 100;
  //     const total = (discount_amount + (discount_amount * tax_amount) / 100) * quantity;
  //     console.log("total", total, price, quantity, discount_amount, tax_amount);
  //     return total;
  //   };

  //   const modifiedRow = list[id];
  //   const total = calculateTotal(modifiedRow);

  //   modifiedRow.total_price = total;
  //   list[id] = modifiedRow;
  //   console.log("list", list);
  //   setState(list);
  // };

  const handleChange = (e, id, field_name) => {
    const list = [...state];
    list[id][field_name] = e;

    const calculateTotal = (row) => {
      const price = parseFloat(row.unit_price) || 0;
      const quantity = parseFloat(row.quantity) || 0;
      const discount_amount = (price * parseFloat(row.discount_percentage)) / 100;
      const tax_amount = (price * parseFloat(row.tax_percentage)) / 100;
      const total = (price - discount_amount + (price - discount_amount) * tax_amount) * quantity;
      console.log("total", total, price, quantity, discount_amount, tax_amount);
      return total;
    };

    const modifiedRow = list[id];
    const total = calculateTotal(modifiedRow);
    modifiedRow.total_price = total || 0; // Set the default value to 0 if total is NaN or undefined

    setState(list);
  };

  const handleRowDelete = (id) => {
    if (state.length === 1) {
      message.warning("You can not delete this item");
    } else {
      const newArr = state.filter((item, index) => index !== id);
      let whole_tax_percentage = 0.0;
      let whole_discount_percentage = 0.0;
      // eslint-disable-next-line no-return-assign
      newArr.map((item, index) => (whole_tax_percentage += item.amount));
      newArr.map((item, index) => (whole_discount_percentage += item.discount_percentage));
      setWholeDiscountPercentage(whole_discount_percentage);
      setWholeTaxPercentage(whole_tax_percentage);
      setState(newArr);
    }
  };

  const products = GetDataFromAPI("purchase/purchaseitemlist?is_dep=1");

  useEffect(() => {
    if (products !== undefined) {
      // setRemainProduct([...products])
      const newProductAry = [...products];

      if (state[0]?.item_id !== "") {
        for (let i = 0; i < state.length; i++) {
          if (state[i].item_id !== "") {
            for (let j = 0; j < newProductAry.length; j++) {
              if (newProductAry[j].id === state[i].item_id) {
                newProductAry.splice(j, 1);
              }
            }
          }
        }
      }

      setPurchaseItems([...newProductAry]);
    }
  }, [products, state]);

  useEffect(() => {
    dispatch(jsonData(DATA));
  }, []);
  const addRecord = async (values) => {
    const { data: response } = await Axios.post(DATA?.urls.add, values);
    return response;
  };

  const editRecord = async (values) => {
    const { data: response } = await Axios.patch(DATA?.urls?.edit + id, values);
    return response;
  };

  const { mutate, isLoading } = useMutation(id ? editRecord : addRecord, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        queryClient.refetchQueries([DATA.urls.list]);
        if (!id) {
          form.resetFields();
          queryClient.refetchQueries([DATA?.urls?.details + id]);
          navigate(DATA.urls.list);
        }
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    const newData = (values);
    const data = {
      ...newData,
      status: 1,
      attachments: values?.attachments
        ? values.attachments.map((item) => {
          return item?.response ? item?.response : item;
        })
        : null,
      required_date: newData.required_date.format("YYYY-MM-DD"),
      order_date: newData.order_date.format("YYYY-MM-DD"),
      purchase_items: state,
    };
    console.log(data, "lol");
    mutate(data);
  };

  const onFinishFailed = () => {
    message.error("Please fill up all the required fields");
  };

  const { data, isLoading: loading } = useQuery(
    [`${DATA?.urls?.details}_${id}`],
    () => Axios.get(DATA?.urls?.details + id).then((res) => res.data.data),
    { enabled: !!(id && DATA), refetchOnMount: true }
  );

  useEffect(() => {
    if (id !== undefined && data) {
      const values = {
        ...data,
        order_date: data.order_date ? dayjs(data.order_date) : null,
        required_date: data.required_date ? dayjs(data.required_date) : null,
      };

      form.setFieldsValue(values);
    } else {
      form.resetFields();
    }
  }, [id, data]);

  if (DATA === null) {
    return <Loader />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Card
      title={id && DATA ? DATA?.edittitle : DATA ? DATA?.addtitle : ""}
      extra={<ListIcon url={DATA?.urls?.list} />}
      headStyle={{
        backgroundColor: "#23253c",
        color: "#fcfafc",
      }}
      className="chms__card"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        form={form}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <GetModuleFields data={DATA?.input_fields} />
          <Col lg={24} span={24}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            >
              <p style={{ fontWeight: "450", fontSize: "16px" }}>
                Bill Items
              </p>
              <hr style={{ flexGrow: "1", margin: "0 20px" }} />
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{ margin: 0 }}
                onClick={() => handleAddRows()}
              >
                Add More
              </Button>
            </div>
          </Col>
          <Col lg={24} span={24}>
            <table className="tranx_table">
              <thead>
                <tr>
                  <th style={{ width: "2%" }} />
                  <th style={{ width: "12%", textAlign: "center" }}>Item</th>
                  <th style={{ width: "12%", textAlign: "center" }}>Barcode</th>
                  <th style={{ width: "12%", textAlign: "center" }}>Notes</th>
                  <th style={{ width: "8%", textAlign: "center" }}>Quantity</th>
                  <th style={{ width: "8%", textAlign: "center" }}>Unit Price</th>
                  <th style={{ width: "8%", textAlign: "center" }}>Discount (%)</th>
                  <th style={{ width: "8%", textAlign: "center" }}>Tax (%)</th>
                  <th style={{ width: "8%", textAlign: "center" }}>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {state?.map((item, idx) => (
                  <tr id="addr0" key={idx}>
                    <td style={{ width: "30px" }}>
                      <Popconfirm
                        title="Sure to delete?"
                        onConfirm={() => handleRowDelete(idx)}
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    </td>
                    <td>
                      <Select
                        placeholder="Select an item"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(e) => handleChange(e, idx, "item_id")}
                        value={item.item_id}
                        loading={!(products?.length > 0)}
                      >
                        {purchaseitems?.map((item, index) => (
                          <Select.Option key={index} value={item?.id}>
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td>
                      <Input
                        name="barcode"
                        // className="gx-w-100 tranx_input_number"
                        placeholder="Barcode"
                        onChange={(e) => handleChange(e, idx, "barcode")}
                      />
                    </td>
                    <td>
                      <Input
                        name="Notes"
                        className="gx-w-100 tranx_input_number"
                        rows={1}
                        placeholder="Notes"
                        onChange={(e) => handleChange(e, idx, "notes")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="quantity"
                        className="gx-w-100 tranx_input_number"
                        value={item.quantity}
                        placeholder="Quantity"
                        onChange={(e) => handleChange(e, idx, "quantity")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="unit_price"
                        className="gx-w-100 tranx_input_number"
                        value={item.unit_price}
                        placeholder="Unit Price"
                        onChange={(e) => handleChange(e, idx, "unit_price")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="discount_percentage"
                        className="gx-w-100 tranx_input_number"
                        value={item.discount}
                        placeholder="Enter Discount"
                        onChange={(e) => handleChange(e, idx, "discount_percentage")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="tax_percentage"
                        className="gx-w-100 tranx_input_number"
                        value={item.tax}
                        placeholder="Enter Tax"
                        onChange={(e) => handleChange(e, idx, "tax_percentage")}
                      />
                    </td>
                    <td>
                      <InputNumber
                        name="total_price"
                        className="gx-w-100 tranx_input_number"
                        value={item.total_price}
                        placeholder="Total Price"
                        onChange={(e) => handleChange(e, idx, "total_price")}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} />
                  <td colSpan={2}>
                    Whole tax percentage:
                  </td>
                  <td colSpan={2} style={{ textAlign: "right", fontWeight: "bold" }}>
                    <Form.Item
                      name="whole_tax_percentage"
                      rules={[{ required: false, message: "Enter tax" }]}
                      initialValue={0}
                    >
                      <InputNumber
                        size="small"
                        className="gx-w-100 tranx_input_number"
                        value={wholetaxpercentage}
                        // onChange={setTaxPercentage}
                        readOnly
                        placeholder="Tax Percentage"
                      />
                    </Form.Item>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} />
                  <td
                    colSpan={2}
                  >
                    Whole discount percentage:
                  </td>
                  <td colSpan={2} style={{ textAlign: "right", fontWeight: "bold" }}>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <Form.Item
                        name="whole_discount_percentage"
                        rules={[{ required: false, message: "Enter discount amount" }]}
                        initialValue={0}
                      >
                        <InputNumber
                          size="small"
                          className="gx-w-100 tranx_input_number"
                          value={wholediscountpercentage || 0}
                          readOnly
                          placeholder="Discount Amount"
                        />
                      </Form.Item>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={5} />
                  <td
                    colSpan={2}
                  >
                    Payable Amount:
                  </td>
                  <td colSpan={2} style={{ textAlign: "right", fontWeight: "bold" }}>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <Form.Item
                        name="payable_amount"
                        rules={[{ required: false, message: "Enter payable amount" }]}
                      >
                        <InputNumber
                          size="small"
                          className="gx-w-100 tranx_input_number"
                          value={payableAmount.toFixed(2)}
                          readOnly
                          placeholder="Payable Amount"
                        />
                      </Form.Item>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </Col>
          <Suspense fallback={<Loader />}>
            <FormSubmitBtns isLoading={isLoading} />
          </Suspense>
        </Row>
      </Form>
    </Card>
  );
};

export default NewPurchase;
