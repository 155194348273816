import { Col, Radio } from "antd";

const SelectRoomInfoBlock = ({ data }) => {
  const { room_count } = data;

  return room_count?.map((item) => (
    <Col span={24} md={10} lg={6}>
      <Radio.Button
        className="frontdesk__blockitem room_counter"
        value={`room_count_${item.id}`}
        // style={{ backgroundColor: item.status_color }}
        name="room_count"
        style={{
          borderBottom: `5px solid ${item.status_color}`,
          cursor: "default",
        }}
      >
        <div className="room_count">
          <p className="count">{item.count}</p>
          <p className="number">{item.status_name}</p>
        </div>
      </Radio.Button>
    </Col>
  ));
};

export default SelectRoomInfoBlock;
