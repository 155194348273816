/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unsafe-optional-chaining */
import { Card, Col, Row, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { DashboardOutlined } from "@ant-design/icons";
import {
  resetCart,
  addToCart,
  addToCartFromDraft,
  categoryList,
  menuList,
  setDraftItems,
} from "features/pos/posSlice";
import localforage from "localforage";
import { useNavigate } from "react-router-dom";
import Axiosinstance from "utils/AxiosInstance";
import { values } from "lodash";
import CartItem from "./CartItem";
import DraftButton from "./DraftButton";
import PosAddSearchCustomer from "./PosAddSearchCustomer";
import PosCalculationBlock from "./PosCalculationBlock";
import ProductCard from "./ProductCard";
import SearchBoxArea from "./SearchBoxArea";

const { Meta } = Card;
const { Paragraph } = Typography;
const NewV2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const posAllData = useSelector((state) => state.posData);
  const [catWiseData, setCatWiseData] = useState([]);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [serviceCharge, setServiceCharge] = useState(0);
  const [adjustAmount, setAdjustAmount] = useState(0);
  console.log("posAllData-------------------", posAllData);

  const { data, isLoading, isError } = useQuery("posdata", () =>
    Axiosinstance.get("/restaurant/menulist?is_dep=1").then((res) => {
      setCatWiseData([...res?.data?.data]);
      dispatch(menuList(res?.data?.data));
      return res.data;
    })
  );

  useEffect(() => {
    localforage.getItem("pos_draft").then((data) => {
      if (data) {
        const allDrafts = data.map((item) => {
          return {
            ...item,
            activeCustomer: {
              ...item.activeCustomer,
              value: `${item.activeCustomer.name} (${item.activeCustomer.phone})`,
              label: `${item.activeCustomer.name} (${item.activeCustomer.phone})`,
            },
          };
        });
        dispatch(setDraftItems(allDrafts));
      }
    });
  }, []);

  const {
    data: catList,
    isLoading: catIsLoading,
    isError: catIsError,
  } = useQuery("categoryList", () =>
    Axiosinstance.get("/restaurant/menucategorylist?is_dep=1").then((res) => {
      const withoutAllMenu = res?.data?.data;
      withoutAllMenu.push({ id: 0, name: "All" });
      dispatch(categoryList(withoutAllMenu));
      return res.data;
    })
  );

  const categoryClickHandler = (id) => {
    if (id == 0) {
      setCatWiseData([...data?.data]);
      dispatch(menuList(data?.data));
    } else {
      const catWiseMenu = data?.data?.filter((itm) => itm.category_id == id);
      setCatWiseData([...catWiseMenu]);
      dispatch(menuList(catWiseMenu));
    }
  };
  const searchBoxChangeHandler = (data) => {
    if (data.target.value) {
      const searchFilterData = catWiseData?.filter((item) => {
        return (
          item.name.toUpperCase().indexOf(data.target.value.toUpperCase()) > -1
        );
      });
      dispatch(menuList(searchFilterData));
    } else {
      dispatch(menuList(catWiseData));
    }
  };

  const handleAddToCart = (c_item) => {
    dispatch(addToCart(c_item));
  };

  useEffect(() => {
    localforage.getItem("cart--add--new").then((values) => {
      if (values) {
        // console.log("🚀 ~ file: NewV2.js:154 ~ localforage.getItem ~ values:", values);
        dispatch(addToCartFromDraft(values));
      }
    });
  }, []);

  return (
    <div className="pos__wrapper">
      <div className="card__area">
        <div className="wrapper">
          <div className="box head">
            <Row>
              <Col span={24}>
                <div className="pos__top__action__area">
                  <SearchBoxArea
                    searchBoxChangeHandler={searchBoxChangeHandler}
                  />
                  <DraftButton
                    setDiscountPercentage={setDiscountPercentage}
                    setServiceCharge={setServiceCharge}
                    setAdjustAmount={setAdjustAmount}
                  />
                  {/* <Reference /> */}
                  <DashboardOutlined
                    className="pos__top__action__btn"
                    onClick={() => {
                      // if (isFull === true) {
                      //   document.exitFullscreen();
                      //   setIsFull(false);
                      // }
                      navigate("/dashboard");
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="box boxcontent">
            <div id="scroll__area" className="scroll__area">
              {isLoading ? (
                "Loading"
              ) : (
                <div>
                  <div className="tabs__menu">
                    <Row>
                      <Col span={24}>
                        <Tabs
                          tabPosition="top"
                          defaultActiveKey="1"
                          style={{ outline: "none" }}
                          items={
                                !catIsLoading &&
                                posAllData?.categoryList
                                  ?.slice()
                                  .reverse()
                                  .map((item) => {
                                    const id = String(item.id);
                                    return {
                                      label: `${item?.name}`,
                                      key: id,
                                    };
                                  })
                              }
                          onTabClick={categoryClickHandler}
                        />
                      </Col>
                    </Row>
                  </div>

                  <div className="item__area">
                    <Row gutter={[24, 24]}>
                      {!isLoading &&
                        posAllData?.menuList?.map((pos_item, key) => (
                          <Col
                            span={12}
                            md={8}
                            lg={6}
                            xxl={4}
                            key={key}
                            onClick={() => handleAddToCart(pos_item)}
                          >
                            <ProductCard data={pos_item} key={pos_item.id} />
                          </Col>
                        ))}
                    </Row>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="cart__area">
        <div className="wrapper">
          <div className="box head">
            <Row>
              <Col span={24}>
                <PosAddSearchCustomer />
              </Col>
            </Row>
          </div>
          <div className="box boxcontent">
            <div id="scroll__area" className="scroll__area">
              <div className="cart__item__area">
                <Row gutter={[16, 16]} className="cart__items__position">
                  <CartItem />
                </Row>
              </div>
            </div>
          </div>

          <div className="box foot">
            <Row>
              <Col span={24}>
                {/* {posAllData.cartData.length && (
                  <PosCalculationBlock
                    discountPercentage={discountPercentage}
                    serviceCharge={serviceCharge}
                    adjustAmount={adjustAmount}
                    setDiscountPercentage={setDiscountPercentage}
                    setServiceCharge={setServiceCharge}
                    setAdjustAmount={setAdjustAmount}
                  />
                )} */}
                {posAllData.cartData.length === 0 ?
                  ""
                  :
                  <PosCalculationBlock
                    discountPercentage={discountPercentage}
                    serviceCharge={serviceCharge}
                    adjustAmount={adjustAmount}
                    setDiscountPercentage={setDiscountPercentage}
                    setServiceCharge={setServiceCharge}
                    setAdjustAmount={setAdjustAmount}
                  />
                 }
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewV2;
