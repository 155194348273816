import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Modal, Row, Table, message } from "antd";
import SelectRequest from "components/Input/SelectRequest";
import dayjs from "dayjs";
import {
  addService,
  removeRoom,
  selectCustomer,
} from "features/frontDesk/frontDeskSlice";
import { useDispatch, useSelector } from "react-redux";
import Axiosinstance from "utils/AxiosInstance";
import { dateFormat } from "utils/CISUI";
import { Suspense, useState } from "react";
import { InputField } from "utils/InputField";
import GetModuleFields, {
  GetFieldsWithoutDivider,
} from "utils/GetModuleFields";
import FormSubmitBtns from "shared/FormSubmitBtns";
import { useMutation } from "react-query";
import Loader from "components/Loader";
import AddNewGuestJson from "../../Crm/Customer/data.json";
// import AddNewGuest from "../../Crm/Customer/New";
import ReserFooter from "./ReserFooter";

const Reservation = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { bookedRooms } = useSelector((state) => state.frontDesk);

  const handleRemove = (id) => {
    console.log("🚀 ~ file: Reservation.js:81 ~ handleRemove ~ id:", id);
    dispatch(removeRoom(id));
  };

  const dataSource = bookedRooms.map((room, index) => {
    return {
      key: index,
      type: room.room_type,
      title: room.room_name,
      price: room.base_price,
      discount: room.discount,
      tax_gst: room.vat,
      total: room.total,
      check_in: dayjs(room.check_in).format(dateFormat),
      check_out: dayjs(room.check_out).format(dateFormat),
      id: room.id,
    };
  });

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Tax/GST",
      dataIndex: "tax_gst",
      key: "tax_gst",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Check In",
      dataIndex: "check_in",
      key: "check_in",
    },
    {
      title: "Check Out",
      dataIndex: "check_out",
      key: "check_out",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (id) => {
        return <DeleteOutlined onClick={() => handleRemove(id)} />;
      },
    },
  ];

  const onFinish = (values) => {
    console.log(
      "🚀 ~ file: Reservation.js:81 ~ handleFinish ~ values:",
      values
    );
    Axiosinstance.get(
      `rooms_ratings/roomotherservicesdetails/${values.service}`
    ).then((res) => {
      console.log(
        "🚀 ~ file: Reservation.js:81 ~ handleFinish ~ res:",
        res.data.data
      );
      dispatch(addService(res.data.data));
    });
  };

  const onFieldsChange = (changedFields) => {
    if (changedFields[0].name[0] === "guest") {
      dispatch(selectCustomer(changedFields[0].value));
    }
  };

  const handleUser = () => {
    console.log("🚀 ~ file: Reservation.js:81 ~ handleUser ~ handleUser");
    setVisible(true);
  };

  // const addRecord = async (values) => {
  //   const { data: response } = await Axios.post(DATA?.urls.add, values);
  //   return response;
  // };

  const { mutate, isLoading } = useMutation(
    (values) => Axiosinstance.post(AddNewGuestJson.urls.add, values),
    {
      onSuccess: (data) => {
        if (data.status === 1) {
          message.success(data.msg);
          form.resetFields();
        } else if (data.status === 0) {
          message.warning(data.msg);
        } else {
          message.error(data.msg);
        }
      },
      onError: () => {
        message.error("Server Error");
      },
    }
  );

  const submitAddUser = (values) => {
    mutate(values);
  };

  return (
    <>
      <Card title="Reservation" style={{ marginTop: "20px" }}>
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Form
              layout="vertical"
              className="cis__form"
              form={form}
              onFinish={onFinish}
              onFieldsChange={onFieldsChange}
            >
              <Row gutter={[24, 24]}>
                <Col span={10}>
                  <SelectRequest
                    name="guest"
                    label="Customer"
                    path="guests/guestlist?is_dep=1"
                    placeholder="Select Guest"
                    required
                    suffixIcon={<UserAddOutlined onClick={handleUser} />}
                  />
                </Col>
                <Col span={10}>
                  <SelectRequest
                    name="service"
                    label="Service"
                    path="rooms_ratings/roomotherserviceslist?is_dep=1"
                    placeholder="Select Service"
                  />
                </Col>
                <Col span={4}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    block
                    style={{ marginTop: "26px" }}
                  >
                    Add Service
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
      {/* {dataSource.length !== 0 && ( */}
      <>
        <Card title="Rooms and Service" style={{ marginTop: "20px" }}>
          <Table
            dataSource={dataSource}
            columns={columns}
            // eslint-disable-next-line react/no-unstable-nested-components
            footer={() => (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button type="primary" htmlType="submit" size="large">
                  Rsrv. Complete
                </Button>
              </div>
            )}
            pagination={false}
          />
        </Card>

        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Card title="Payment Details" style={{ marginTop: "20px" }}>
              <ReserFooter />
            </Card>
          </Col>
        </Row>
      </>
      {/* )} */}
      {/* AddUser Modal */}

      <Modal
        open={visible}
        title="Add New Guest"
        footer={null}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <Form
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          className="cis__form"
          form={form}
          layout="vertical"
        >
          <Card bordered={false}>
            <Row gutter={[16, 0]}>
              <GetFieldsWithoutDivider data={AddNewGuestJson?.input_fields} />
              <Suspense fallback={<Loader />}>
                <FormSubmitBtns isLoading={isLoading} />
              </Suspense>
            </Row>
          </Card>
        </Form>
      </Modal>
    </>
  );
};

export default Reservation;
