import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Modal, Row, Space, message } from "antd";
import NumberInput from "components/Input/InputNumber";
import InputSelect from "components/Input/InputSelect";
import SelectRequest from "components/Input/SelectRequest";
import {
  closeChangeRoomModal,
  openChangeRoomModal,
} from "features/frontDesk/frontDeskSlice";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import Axiosinstance from "utils/AxiosInstance";

const ChangeRoom = () => {
  const [currentRooms, setCurrentRooms] = useState([]);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { changeRoom } = useSelector((state) => state.frontDesk);

  const checkIn = async (values) => {
    const { data: response } = await Axiosinstance.post(
      "frontdesk/roomchange",
      values
    );
    return response;
  };

  const { mutate, isLoading } = useMutation(checkIn, {
    onSuccess: (data) => {
      if (data.status === 1) {
        message.success(data.msg);
        form.resetFields();
        dispatch(closeChangeRoomModal());
        form.resetFields();
      } else if (data.status === 0) {
        message.warning(data.msg);
      } else {
        message.error(data.msg);
      }
    },
    onError: () => {
      message.error("Server Error");
    },
  });

  const onFinish = (values) => {
    const currentBookings = queryClient.getQueryData(
      "frontdesk/currentbookings"
    );
    const currentBooking = currentBookings.find(
      (item) => item.id === values.running_booking
    );
    const data = {
      ...values,
      booking_id: currentBooking.booking_id,
      running_booking: undefined,
    };

    mutate(data);
  };
  const onFinishFailed = () => {
    console.log("Failed");
  };
  const onFieldChange = (changedFields) => {
    const currentBookings = queryClient.getQueryData(
      "frontdesk/currentbookings"
    );

    if (changedFields[0].name[0] === "running_booking") {
      const currentBooking = currentBookings.find(
        (item) => item.id === changedFields[0].value
      );

      Axiosinstance.get(
        `frontdesk/currentrooms/${currentBooking.booking_id}`
      ).then((res) => {
        setCurrentRooms(res.data.data);
      });
    }

    if (changedFields[0].name[0] === "new_room_id") {
      Axiosinstance.get(
        `frontdesk/availableroomdetails/${changedFields[0].value}`
      ).then((res) => {
        console.log("res.data.data", res.data.data);
        form.setFieldsValue({
          new_room_rant: res.data.data.price,
        });
      });
    }
  };
  return (
    <Modal
      title="Room Change"
      open={changeRoom}
      onOk={() => dispatch(openChangeRoomModal())}
      onCancel={() => dispatch(closeChangeRoomModal())}
      footer={null}
      width="500px"
      maskClosable={false}
      className="input__modal"
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="cis__form"
        layout="vertical"
        width="400px"
        form={form}
        onFieldsChange={onFieldChange}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <SelectRequest
              label="Running Booking"
              name="running_booking"
              path="frontdesk/currentbookings"
              placeholder="Select Running Booking"
            />
            <InputSelect
              label="Select Current Room"
              name="current_room_id"
              items={currentRooms}
              placeholder="Select Current Room"
              disabled={currentRooms.length === 0}
            />
            <SelectRequest
              label="Select New Room"
              name="new_room_id"
              path="frontdesk/availablerooms"
              placeholder="Select New Room"
              disabled={currentRooms.length === 0}
            />
            <NumberInput
              label="New Room Rant"
              name="new_room_rant"
              placeholder="New Room Rant"
              disabled={currentRooms.length === 0}
            />
            <div className="submit__btn">
              <Space size={30}>
                <Button
                  onClick={() => dispatch(closeChangeRoomModal())}
                  icon={<CloseCircleOutlined />}
                >
                  Cancel
                </Button>
                <Button icon={<ReloadOutlined />} htmlType="reset">
                  Reset
                </Button>
                <Button
                  type="primary"
                  icon={<CheckCircleFilled />}
                  htmlType="submit"
                  loading={isLoading}
                >
                  Change Room
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChangeRoom;
