import { Avatar, Popover } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import UserContent from "./UserContent";

const Topbar = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <Popover placement="bottomRight" content={<UserContent />} action="click">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <Link to="#" className="head-example">
        <Avatar src={user?.image?.url} />
      </Link>
    </Popover>
  );
};

export default Topbar;
