import { Form, Select, Tag, Checkbox } from "antd";

export const perPage = 50;

export const dateFormat = "MM/DD/YYYY";
export const dateFormat2 = "YYYY-MM-DD";
export const monthFormat = "MMMM, YYYY";
export const timeFormat = "h:mm a";

export const GetUserInfo = (fld) => {
  const userLocal = localStorage.getItem("userData") || "";

  if (userLocal !== "") {
    return userLocal[fld];
  }
  return "";
};

export const GetUserImage = () => {
  const userLocal = localStorage.getItem("userData");
  if (userLocal?.image !== null) {
    return userLocal?.image;
  }
  return "/assets/images/placeholder.jpg";
};

export const GetStatusTag = ({ status }) => {
  switch (status) {
    case 0:
      return <Tag color="error">Inactive</Tag>;
    case 1:
      return <Tag color="success">Active</Tag>;
    case 2:
      return <Tag color="warning">Pending</Tag>;
    default:
      return <Tag color="default">Active</Tag>;
  }
};

export const GetRoomStatusTag = ({ status }) => {
  switch (status) {
    case "Close":
      return <Tag style={{ color: "#E07170", paddingRight: 3, paddingLeft: 3 }}>Close</Tag>;
    case "Available":
      return <Tag style={{ color: "#52c41a", paddingRight: 3, paddingLeft: 3 }}>Available</Tag>;
    case "Unprepared":
      return <Tag style={{ color: "#EE9E9C", paddingRight: 3, paddingLeft: 3 }}>Unprepared</Tag>;
    case "Dirty":
      return <Tag style={{ color: "#E8BE79", paddingRight: 3, paddingLeft: 3 }}>Dirty</Tag>;
    case "Occupied":
      return <Tag style={{ color: "#fa8c16", paddingRight: 3, paddingLeft: 3 }}>Occupied</Tag>;
    case "Reserved":
      return <Tag style={{ color: "#3B5B7E", paddingRight: 3, paddingLeft: 3 }}>Reserved</Tag>;
    case "Check In":
      return <Tag style={{ color: "#2a899d", paddingRight: 3, paddingLeft: 3 }}>Check In</Tag>;
    case "Check Out":
      return <Tag style={{ color: "#959595", paddingRight: 3, paddingLeft: 3 }}>Check Out</Tag>;
    default:
      return <Tag style={{ color: "#52c41a", paddingRight: 3, paddingLeft: 3 }}>Available</Tag>;
  }
};

export const WeekCheckBox = ({
  name = "",
  label = "",
  required = false,
  options = [],
  index,
}) => {
  return (
    <Form.Item
      name={index + 1 ? [index, name] : name}
      label={label}
      rules={[{ required, message: "Please select work day" }]}
    >
      <Checkbox.Group options={options} />
    </Form.Item>
  );
};

export const addCompanyIfNotExist = (data) => {
  if (
    data?.com_id === undefined &&
    GetUserInfo("user_area") !== 1 &&
    GetUserInfo("user_area") !== 2
  ) {
    data.com_id = GetUserInfo("com_id");
  }
  return data;
};

export const SelectMaritalStatus = ({
  name = "marital_status",
  label = "Marital Status",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select
        size="large"
        placeholder="Select your marital status"
        style={{ width: "100%" }}
      >
        <Select.Option value={0}>Married</Select.Option>
        <Select.Option value={1}>Unmarried</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const EmailType = ({
  name = "email_type",
  label = "Email Type",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select
        size="large"
        placeholder="Select an option"
        style={{ width: "100%" }}
      >
        <Select.Option value={1}>Check In</Select.Option>
        <Select.Option value={2}>New Guest</Select.Option>
        <Select.Option value={3}>Check Out</Select.Option>
        <Select.Option value={4}>Reservation</Select.Option>
      </Select>
    </Form.Item>
  );
};

// export const ItemType = ({
//   name = "item_type",
//   label = "Item Type",
//   required = false,
// }) => {
//   return (
//     <Form.Item
//       name={name}
//       label={label}
//       initialValue={null}
//       rules={[{ required }]}
//     >
//       <Select size="large" placeholder="Select item type" style={{ width: "100%" }}>
//         <Select.Option value={1}>Veg</Select.Option>
//         <Select.Option value={2}>Non veg</Select.Option>
//         <Select.Option value={3}>Both</Select.Option>
//       </Select>
//     </Form.Item>
//   );
// };

export const CalculationMethod = ({
  name = "calc_method",
  label = "Calculation Method",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select
        size="large"
        placeholder="Select calculation method"
        style={{ width: "100%" }}
      >
        <Select.Option value={0}>Round Up</Select.Option>
        <Select.Option value={1}>Actual</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const SelectGender = ({
  name = "gender",
  label = "Gender",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select
        size="large"
        placeholder="Select your gender"
        style={{ width: "100%" }}
      >
        <Select.Option value={0}>Male</Select.Option>
        <Select.Option value={1}>Female</Select.Option>
        <Select.Option value={2}>Others</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const SelectBG = ({
  name = "blood_group",
  label = "Blood Group",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select
        size="large"
        placeholder="Select your blood group"
        style={{ width: "100%" }}
      >
        <Select.Option value={0}>A+</Select.Option>
        <Select.Option value={1}>A-</Select.Option>
        <Select.Option value={2}>B+</Select.Option>
        <Select.Option value={3}>B-</Select.Option>
        <Select.Option value={4}>O+</Select.Option>
        <Select.Option value={5}>O-</Select.Option>
        <Select.Option value={6}>AB+</Select.Option>
        <Select.Option value={7}>AB-</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const SelectStatus = ({
  name = "status",
  label = "Status",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={1}
      rules={[{ required }]}
    >
      <Select
        size="large"
        placeholder="Select a status"
        style={{ width: "100%" }}
      >
        <Select.Option value={1}>Active</Select.Option>
        <Select.Option value={0}>Inactive</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const IsFeatured = ({
  name = "is_featured",
  label = "Is Featured",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select placeholder="Select an option" style={{ width: "100%" }}>
        <Select.Option value={1}>Yes</Select.Option>
        <Select.Option value={0}>No</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const Priority = ({
  name = "priority",
  label = "Priority",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select placeholder="Select an option" style={{ width: "100%" }}>
        <Select.Option value={1}>1</Select.Option>
        <Select.Option value={0}>2</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const PaymentType = ({
  name = "payment_type",
  label = "Payment Type",
  required = false,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={null}
      rules={[{ required }]}
    >
      <Select size="large" placeholder="Select an option" style={{ width: "100%" }}>
        <Select.Option value={1}>Cash</Select.Option>
        <Select.Option value={0}>Cheque</Select.Option>
      </Select>
    </Form.Item>
  );
};

export const getQueryStrings = (obj) => {
  return Object.entries(obj)
    .filter(([key, value]) => {
      return value !== undefined && value !== null && value !== "";
    })
    .map(([key, value]) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join("&");
};

export const AuditGetStatusTag = ({ status }) => {
  switch (status) {
    case 1:
      return <Tag color="#34495e">Logged In</Tag>;
    case 0:
      return <Tag color="#87646b">Logged Out</Tag>;
    default:
      return <Tag color="#aab5bd">None</Tag>;
  }
};
