import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Upload, message } from "antd";
import { useSelector } from "react-redux";
import { useState } from "react";
import { API_URL } from "utils/Config";

const FileAttachment = ({ name = "attachment", label = "", maxCount = 10 }) => {
  const { accessToken } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: FileAttachment.js:8 ~ FileAttachment ~ accessToken:", accessToken);
  const [ctrlPressed, setCtrlPressed] = useState(false); // State for Ctrl key

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (event) => {
    console.log(
      "🚀 ~ file: FileAttachment.js:19 ~ beforeUpload ~ event:",
      event
    );
    const isFileType =
      event.type === "image/jpg" ||
      event.type === "image/jpeg" ||
      event.type === "image/png" ||
      event.type === "image/webp" ||
      event.type === "image/HEIF" ||
      event.type === "image/HEIC" ||
      event.type === "application/pdf" ||
      event.type === "application/msword" ||
      event.type === "application/wps-office.docx";

    if (!isFileType) {
      message.error("You can only upload jpg, jpeg, png and pdf file only!");
      return false;
    }
    if (event.size / 1024 / 1024 > 2) {
      message.error("File size must be less than 2MB!");
      return false;
    }
    return true;
  };

  return (
    <Form.Item
      label={label}
      valuePropName="fileList"
      name={name}
      getValueFromEvent={normFile}
    >
      <Upload
        action={`${API_URL}sys/upload`}
        headers={{
          Authorization: accessToken,
        }}
        method="POST"
        beforeUpload={beforeUpload}
        maxCount={maxCount}
      >
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
    </Form.Item>
  );
};

export default FileAttachment;
