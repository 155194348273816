// import { COMMONURL } from "util/Routes";

export const ROUTES = {
  NEW: "/user/newrole",
  EDIT: "/user/roleedit/",
  LIST: "/user/allroles",
  DETAILS: "/user/roleopen/",
  DELETE: "/user/deleterole/",
  UPDATE: "/user/roleedit/",
};

export const AVD_INPUT = [
  {
    label: "Hotel",
    name: "hotel_id",
    placeholder: "Select an Hotel",
    type: "select",
    path: "/overview/hotellist?is_dep=1",
    size: 6,
    required: true,
  },
  {
    label: "Roles Name",
    name: "name",
    placeholder: "Enter Roles Name",
    type: "text",
    size: 6,
    required: true,
  },
  {
    label: "Description",
    name: "description",
    placeholder: "Enter Description",
    type: "textarea",
    size: 6,
    rows: 1,
    required: false,
  },
  {
    label: "Status",
    name: "status",
    value: 1,
    type: "status",
    size: 6,
    required: true,
  },
];
