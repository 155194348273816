import { Table, Card, Button, Space } from "antd";
import { useQuery } from "react-query";
import Axiosinstance from "utils/AxiosInstance";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import CheckInModal from "./NewGuestModal";

const GuestList = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [allGuests, setAllGuests] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [bookingInfo, setBookingInfo] = useState({});

  useEffect(() => {
    Axiosinstance.get(`booking/bookingdetails/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setBookingInfo(data);
        console.log(bookingInfo, "bookingInfo");
        setAllGuests(data?.data?.multiple_guests);
        console.log("data?.data?.multiple_guests", data?.data?.multiple_guests);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error occurred while fetching data:", error);
        setLoading(false);
      });
  }, [id]);

  const showCheckInModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEdit = (record) => {
    setEditingRow(record.id);
    // console.log(editingRow.id, "editingRow");
    setIsModalVisible(true);
    console.log("edit", record);
  };

  const handleDelete = (record) => {
    Axiosinstance.delete(`booking/deleteguest/${record.id}`)
      .then((response) => {
        if (response.status === 200) {
          const updatedGuests = allGuests.filter((guest) => guest.id !== record.id);
          setAllGuests(updatedGuests);
        } else {
          console.error("Failed to delete the guest");
        }
      })
      .catch((error) => {
        console.error("Error occurred while deleting the guest:", error);
      });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      editable: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Emergency Contact",
      dataIndex: "emergency_contact",
      key: "emergency_contact",
    },
    {
      title: "Photo Identification",
      dataIndex: "photo_identification",
      key: "photo_identification",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: <span className="table__title">Actions</span>,
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => {
        return (
          <Space>
            <Button type="link" onClick={() => handleEdit(record)}>
              <EditOutlined />
            </Button>
            <Button type="link" danger onClick={() => handleDelete(record)}>
              <DeleteOutlined />
            </Button>
          </Space>
        );
      }
    },
  ];

  return (
    <>
      <Card
        className="card__paddingless card__border__radiusless card__box__shadow card__custom__bg"
        title="GUEST LIST"
        extra={
          <Button
            type="primary"
            onClick={showCheckInModal}
          >
            ADD NEW GUEST
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={allGuests}
          pagination={false}
          loading={loading}
          rowKey={(record) => record.id}
        />
      </Card>
      <CheckInModal editingRow={editingRow} visible={isModalVisible} onCancel={handleCancel} />
    </>
  );
};

export default GuestList;
