// import App from "App";
import "assets/icons/styles.css";
import ReactDOM from "react-dom/client";
import "styles/index.scss";
import "./i18n";

import { store } from "app/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import Auth from "Auth";
import { ReactQueryDevtools } from "react-query/devtools";

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      refetchOnMount: false,
    },
  },
});

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Auth />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);
