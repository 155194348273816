const { createSlice } = require("@reduxjs/toolkit");

const avdSearchSlice = createSlice({
  name: "avdSearch",
  initialState: {
    isDrawerOpen: false,
  },
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },

    closeDrawer: (state) => {
      state.isDrawerOpen = false;
    },

    openDrawer: (state) => {
      state.isDrawerOpen = true;
    },
  },
});

export const { closeDrawer, openDrawer } = avdSearchSlice.actions;

export default avdSearchSlice.reducer;
