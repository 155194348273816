/* eslint-disable array-callback-return */
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, Col, Row, Table } from "antd";
import GlobalLoader from "components/Loader/GlobalLoader";
// import { jsonData } from "features/data/dataSlice";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ListPrintBtns from "shared/ListPrintBtns";
import ActionMenu from "utils/ActionMenu";
import Axios from "utils/AxiosInstance";
import { GetStatusTag } from "utils/CISUI";
import jsonData from "./data.json";
import ListField2 from "../../../utils/ListField2";

const ChartOfAccountList = () => {
  const [page, setPage] = useState(1);
  const [DATA, setDATA] = useState(jsonData);

  console.log(DATA);

  const { isLoading } = useQuery([DATA?.urls.list, page], () =>
    Axios.get(`${DATA?.urls.list}?page=${page}`).then((res) => res.data.data)
  );

  const data = [
    {
      key: 1,
      name: "John Brown sr.",
      age: 60,
      address: "New York No. 1 Lake Park",
      children: [
        {
          key: 11,
          name: "John Brown",
          age: 42,
          address: "New York No. 2 Lake Park",
        },
        {
          key: 12,
          name: "John Brown jr.",
          age: 30,
          address: "New York No. 3 Lake Park",
          children: [
            {
              key: 121,
              name: "Jimmy Brown",
              age: 16,
              address: "New York No. 3 Lake Park",
            },
          ],
        },
      ],
    },
    {
      key: 2,
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
    },
    {
      key: 3,
      name: "Jim Green sr.",
      age: 72,
      address: "London No. 1 Lake Park",
      children: [
        {
          key: 131,
          name: "Jim Green",
          age: 42,
          address: "London No. 2 Lake Park",
          children: [
            {
              key: 1311,
              name: "Jim Green jr.",
              age: 25,
              address: "London No. 3 Lake Park",
            },
            {
              key: 1312,
              name: "Jimmy Green sr.",
              age: 18,
              address: "London No. 4 Lake Park",
            },
          ],
        },
      ],
    },
  ];

  const data1 = [];
  data.map((item, index) => {
    if (item.children !== undefined) {
      if (item.children.length > 0) {
        data1.push({
          key: item.key,
          name: item.name,
          age: item.age,
          address: item.address,
          child: item.children,
        });
      }
    } else {
      data1.push({
        key: item.key,
        name: item.name,
        age: item.age,
        address: item.address,
      });
    }
  });

  if (isLoading) return <GlobalLoader />;

  return (
    <ListField2
      DATA={DATA}
      loading={{
        spinning: isLoading,
        size: "large",
        indicator: <GlobalLoader />,
      }}
      pagination={{
        position: ["bottomCenter"],
        pageSize: 10,
        defaultCurrent: page,
        total: data.total,
        onChange: (page) => {
          setPage(page);
        },
      }}
    />
  // <Table
  //   size="small"
  //   className="table"
  //   scroll={{ x: true }}
  //   columns={columns}
  //   dataSource={data}
  //   bordered
  //   pagination={{ position: ["bottomCenter"] }}
  // />

  );
};

export default ChartOfAccountList;
