import { PlusOutlined } from "@ant-design/icons";
import { Form, Modal, Upload, message } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API_URL } from "utils/Config";

const InputFile = ({ name = "files", label = "Upload", maxCount = 1, source = "cis_hotel" }) => {
  const [isModal, setIsModal] = useState(false);
  const [prevImg, setPrevImg] = useState(null);
  const [file, setFile] = useState(null);
  const { accessToken } = useSelector((state) => state.auth);
  console.log("🚀 ~ file: FileAttachment.js:8 ~ FileAttachment ~ accessToken:", accessToken);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setFile(e.file);
    return e?.fileList;
  };

  const handlePreview = async (file) => {
    setPrevImg(file?.url);
    setIsModal(true);
    console.log("🚀 ~ file: FileAttachment.js:27 ~ handlePreview ~ file", file);
    setPrevImg(file?.response?.url);
    setIsModal(true);
    console.log("🚀 ~ file: FileAttachment.js:27 ~ handlePreview ~ file", file.response?.url);
  };

  const beforeUpload = (event) => {
    const isJpgOrPng =
      event.type === "image/jpg" ||
      event.type === "image/jpeg" ||
      event.type === "image/png" ||
      event.type === "application/pdf";

    if (!isJpgOrPng) {
      message.error("You can only upload jpg, jpeg, png and pdf file only!");
      return Upload.LIST_IGNORE;
    }
    if (event.size / 1024 / 1024 > 2) {
      message.error("File size must be less than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  return (
    <>
      <Form.Item
        label={label}
        valuePropName="fileList"
        name={name}
        getValueFromEvent={normFile}
      >
        <Upload
          action={`${API_URL}sys/upload`}
          headers={{
            Authorization: accessToken,
          }}
          method="POST"
          listType="picture-card"
          beforeUpload={beforeUpload}
          onPreview={handlePreview}
          className="avatar-uploader"
          maxCount={maxCount}
          data={{
            file,
            source,
            file_id: file?.uid,
          }}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
          </div>
        </Upload>
      </Form.Item>
      {prevImg ? (
      // console.log("🚀 ~ file: FileAttachment.js:78 ~ FileAttachment ~ prevImg", prevImg),
        <Modal
          open={isModal}
            // title={prevImg?.name}
          footer={null}
          onCancel={() => {
            setIsModal(false);
            setPrevImg(null);
          }}
        >
          <img alt="example" style={{ width: "100%" }} src={prevImg} />
        </Modal>
      ) : null}
    </>
  );
};

export default InputFile;
