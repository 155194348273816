/* eslint-disable max-len */
/* eslint linebreak-style: ["error", "windows"] */

export const items = [
  {
    name: "Kate Nowlan",
    quote: "Hospitality is simply an opportunity to show love and care"
  },
  {
    name: "Stanley Turkel",
    quote: "Success in the hotel business is all about customer service"
  },
  {
    name: "Francesca Gallo",
    quote: "Good hospitality is about making guests feel like they are at home"
  },
  {
    name: "Kemmons Wilson",
    quote: "The key to success in the hotel business is to treat your employees like family"
  },
  {
    name: "Oscar de la Renta",
    quote: "The best hotels are not only luxurious, but also make their guests feel comfortable"
  },
  {
    name: "Brijesh Thakur",
    quote: "Hospitality is about creating an experience that will be remembered forever"
  },
  {
    name: "Richard Branson",
    quote: "Great hotels make their guests feel like they are part of the family"
  },
  {
    name: "Jeff Zidell",
    quote: "The goal of hospitality is to create a home away from home for guests"
  },
  {
    name: "Anonymous",
    quote: "Customer service is not a department, it's an attitude"
  },
  {
    name: "Laura Ashley",
    quote: "Hospitality is about putting the guest first, no matter what"
  },
  {
    name: "Sam Shank",
    quote: "The customer experience is the ultimate differentiator in the hotel industry"
  },
  {
    name: "Anonymous",
    quote: "In hospitality, we don't sell products, we sell experiences"
  },
  {
    name: "Harald Mootz",
    quote: "The hotel industry is all about creating lasting memories"
  },
  {
    name: "Charles E. Smith",
    quote: "The key to a successful hotel is to exceed guest expectations"
  },
  {
    name: "Anonymous",
    quote: "In hospitality, there is no such thing as a small detail"
  },
  {
    name: "Unknown",
    quote: "Hospitality is all about making people feel good"
  },
  {
    name: "Unknown",
    quote: "Hotels are not just buildings, they are living organisms"
  },
  {
    name: "Unknown",
    quote: "The foundation of great hospitality is a sincere desire to please"
  },
  {
    name: "Unknown",
    quote: "A hotel is not just a place to stay, it's an experience"
  },
  {
    name: "Unknown",
    quote: "Hospitality is about creating a warm and welcoming environment"
  },
  {
    name: "Anonymous",
    quote: "Customer service is not a department, it's an attitude"
  },
  {
    name: "Laura Ashley",
    quote: "Hospitality is about putting the guest first, no matter what"
  },
  {
    name: "Sam Shank",
    quote: "The customer experience is the ultimate differentiator in the hotel industry"
  },
  {
    name: "Anonymous",
    quote: "In hospitality, we don't sell products, we sell experiences"
  },
  {
    name: "Harald Mootz",
    quote: "The hotel industry is all about creating lasting memories"
  },
  {
    name: "Charles E. Smith",
    quote: "The key to a successful hotel is to exceed guest expectations"
  },
  {
    name: "Anonymous",
    quote: "In hospitality, there is no such thing as a small detail"
  },
  {
    name: "Anonymous",
    quote: "Hospitality is all about making people feel good"
  },
  {
    name: "Anonymous",
    quote: "Hotels are not just buildings, they are living organisms"
  },
  {
    name: "Anonymous",
    quote: "The foundation of great hospitality is a sincere desire to please"
  },
  {
    name: "Anonymous",
    quote: "A hotel is not just a place to stay, it's an experience"
  },
  {
    name: "Anonymous",
    quote: "Hospitality is about creating a warm and welcoming environment"
  },
  {
    name: "Anonymous",
    quote: "In the hotel business, there are no shortcuts to success"
  }
];
