/* eslint-disable max-len */
/* eslint-disable max-len */
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { Drawer, Button, Input, Select, Table, DatePicker, notification, message, InputNumber, Space } from "antd";
import React, { useEffect, useState } from "react";
import Axiosinstance from "utils/AxiosInstance";
import moment from "moment";

const AddAccommodation = ({
  addRoomDrawer,
  setAddRoomDrawer,
  booking_id,
  onUpdateRoomInfo,
  setGeneralInfo,
  info
}) => {
  console.log("info------------------", info);
  const [roomTypes, setRoomTypes] = useState([]);
  // console.log("roomTypes-----------------now-", roomTypes);
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [isRoomTypeSelected, setIsRoomTypeSelected] = useState(false);
  // console.log("roomNumbers-----------------now-", roomNumbers);
  const [tableData, setTableData] = useState([
    // Initial table data
    {
      id: 1,
      roomType: "",
      roomNumber: 0,
      checkInDate: null, // Initialize check-in date as null
      checkOutDate: null, // Initialize check-out date as null
      nightsCount: 0,
      guestCount: 0,
      roomRate: "",
      price: 0,
      discount: 0,
      tax: 0,
      total: 0,
    },
  ]);
  // console.log("tableData-------------------------->>>>>>", tableData);
  useEffect(() => {
    const fetchRoomTypes = async () => {
      try {
        const response = await Axiosinstance.get("rooms_ratings/roomtypelist?is_dep=1");
        const roomTypesData = response.data.data;
        // console.log("tableData--tableData===tableData--roomTypesData--000-->", roomTypesData);
        setRoomTypes(roomTypesData);
      } catch (error) {
        console.error("Error fetching room types:", error);
      }
    };
    fetchRoomTypes();
  }, []);

  const handleBaseRoomChange = (id, value) => {
    const newData = [...tableData];
    const targetRow = newData.find((row) => row.id === id);
    targetRow.roomNumber = value;
    // console.log("value------------------------------", targetRow);
    setTableData(newData);
  };
  const calculateTotal = (price, discount, tax) => {
    const discountedPrice = price - (price * discount) / 100;
    const taxAmount = (discountedPrice * tax) / 100;
    return discountedPrice + taxAmount;
  };
  const handleRoomTypeChange = async (id, value) => {
    const newData = [...tableData];
    const targetRow = newData.find((row) => row.id === id);
    // console.log("targetRow---------->", targetRow);
    targetRow.roomType = roomTypes.find((roomType) => roomType.id === value);
    if (targetRow.roomType) {
      targetRow.isRoomTypeSelected = true;
      targetRow.roomType = roomTypes.find((roomType) => roomType.id === value);
    } else {
      targetRow.isRoomTypeSelected = false;
      targetRow.roomType = null;
    }
    if (targetRow.roomType) {
      targetRow.guestCount = targetRow.roomType.maximum_guests;
      if (targetRow.roomType.base_price) {
        targetRow.roomRate = "Regular";
        targetRow.price = targetRow.roomType.base_price;
        targetRow.discount = targetRow.roomType.discount_percentage;
        targetRow.tax = targetRow.roomType.tax_percentage;
        targetRow.total = calculateTotal(
          targetRow.roomType.base_price,
          targetRow.roomType.discount_percentage,
          targetRow.roomType.tax_percentage,
          targetRow.nightsCount,

        );
      }
    }
    targetRow.roomNumber = "";
    setTableData(newData);

    try {
      const response = await Axiosinstance.get(`booking/roomnumbers/${value}`);
      const roomNumbersData = response.data.data;
      // how to filter room number based on room_type
      const filteredRoomNumbers = roomNumbersData.filter((room) => {
        return !info.some((item) => item.room_number === room.name);
      });
      console.log("filteredRoomNumbers----", filteredRoomNumbers);
      setRoomNumbers(filteredRoomNumbers || []);
      if (filteredRoomNumbers && filteredRoomNumbers.length > 0) {
        const selectedRoomNumbers = tableData.map((row) => row.roomNumber);
        const unselectedRoomNumbers = filteredRoomNumbers.filter((room) => !selectedRoomNumbers.includes(room.id));
        setRoomNumbers(unselectedRoomNumbers || []);
      } else {
        targetRow.roomNumber = "";
      }
    } catch (error) {
      console.error("Error fetching room numbers:", error);
    }
  };
  const handlePriceChange = (id, value) => {
    const newData = [...tableData];
    const targetRow = newData.find((row) => row.id === id);
    targetRow.roomRate = value;

    if (value === "Regular") {
      const selectedRoomType = targetRow.roomType;
      if (selectedRoomType) {
        const roomTypeData = roomTypes.find((roomType) => roomType.id === selectedRoomType.id);
        // console.log("roomTypeData------------------roomTypeData-----123>", roomTypeData);

        if (roomTypeData) {
          targetRow.price = roomTypeData.base_price;
          targetRow.discount = roomTypeData.discount_percentage;
          targetRow.tax = roomTypeData.tax_percentage;
          targetRow.total = calculateTotal(
            roomTypeData.base_price,
            roomTypeData.discount_percentage,
            roomTypeData.tax_percentage,
          ) * targetRow.nightsCount;
        }
      }
    } else if (value === "Custom") {
      if (targetRow.roomType) {
        targetRow.price = targetRow.roomType.base_price || 0;
        targetRow.discount = targetRow.roomType.discount_percentage || 0;
        targetRow.tax = targetRow.roomType.tax_percentage || 0;
        targetRow.total = calculateTotal(
          targetRow.price,
          targetRow.discount,
          targetRow.tax,
          targetRow.nightsCount
        );
      } else {
        targetRow.price = 0;
        targetRow.discount = 0;
        targetRow.tax = 0;
        targetRow.total = 0;
      }
    }
    setTableData(newData);
  };

  const handleCancel = (id) => {
    const newData = tableData.filter((row) => row.id !== id);
    setTableData(newData);
    // console.log("-------------------------------------------------------------newdata", newData);
  };

  const handleSave = async () => {
    const isAnyFieldEmpty = tableData.some((item) => (
      !item.roomType || !item.roomNumber || !item.checkInDate || !item.checkOutDate
    ));

    if (isAnyFieldEmpty) {
      message.error("Please fill in all fields for each row before saving.");
      return;
    }

    try {
      const postData = tableData.map((item) => ({
        room_id: item.roomNumber,
        room_type: item.roomType.id,
        num_guest: item.guestCount,
        check_in: item.checkInDate.format("YYYY-MM-DD"),
        check_out: item.checkOutDate.format("YYYY-MM-DD"),
        nights: item.nightsCount,
        room_rate: item.roomRate === "Regular" ? 2 : 1,
        price: item.price,
        discount_percentage: item.discount,
        tax_percentage: item.tax,
        total_price: item.total,
      }));

      const response = await Axiosinstance.post(`booking/addbookingitem/${booking_id}`, {
        booking_items: postData,
      });

      if (response.data.status === 1) {
        Axiosinstance.get(`booking/bookingdetails/${booking_id}`)
          .then((res) => res.data)
          .then((data) => {
            onUpdateRoomInfo(data?.data?.booking_items);
            setGeneralInfo(data?.data);
          });
        setTableData([
          {
            id: 1,
            roomType: "",
            baseRoom: "",
            nightsCount: 0,
            guestCount: 0,
            roomRate: "",
            price: 0,
            discount: 0,
            tax: 0,
            total: 0,
          },
        ]);
        setAddRoomDrawer(false);
        message.success(response.data.msg);
      } else if (response.data.status === 0) {
        message.error(response.data.msg.map((item) => (
          <span>{item.msg}</span>
        )));
      } else {
        throw new Error(response.data.msg);
      }
    } catch (error) {
      console.error("Error while saving:", error);
      message.error({ message: "Failed to save data" });
    }
  };

  const handleAddRow = () => {
    const isCurrentRowFilled = tableData.every((row) => (
      row.roomType && row.roomNumber && row.checkInDate && row.checkOutDate
    ));

    if (isCurrentRowFilled) {
      const newRow = {
        id: tableData.length + 1,
        roomType: "",
        baseRoom: "",
        nightsCount: 0,
        guestCount: 0,
        roomRate: "",
        price: 0,
        discount: 0,
        tax: 0,
        total: 0,
      };
      setTableData([...tableData, newRow]);
    } else {
      message.error("Please fill in all fields in the current row before adding a new row.");
    }
  };

  const handleGuestCountChange = (id, value) => {
    const newData = tableData.map((row) => {
      if (row.id === id) {
        const guestCount = parseInt(value, 10);
        const maxGuests = row.roomType.maximum_guests;

        if (Number.isNaN(guestCount) || guestCount < 0) {
          message.error("Invalid guest count");
        }

        return { ...row, guestCount };
      }
      return row;
    });
    setTableData(newData);
  };

  const handleDateRangeChange = (dates, record) => {
    const [checkInDate, checkOutDate] = dates;
    const nightsCount = checkOutDate.diff(checkInDate, "days");

    const newData = tableData.map((row) => {
      if (row.id === record.id) {
        const totalPrice = row.price - (row.price * row.discount) / 100;
        const taxAmount = (totalPrice * row.tax) / 100;
        const total = totalPrice + taxAmount;

        return {
          ...row,
          checkInDate, // Update check-in date
          checkOutDate, // Update check-out date
          nightsCount,
          total,
        };
      }
      return row;
    });

    setTableData(newData);
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf("day");
  };

  const handleCustomPriceChange = (id, value) => {
    const newData = [...tableData];
    const targetRow = newData.find((row) => row.id === id);
    targetRow.price = parseFloat(value) || 0;
    targetRow.total = calculateTotal(
      targetRow.price,
      targetRow.discount,
      targetRow.tax,
      targetRow.nightsCount
    );
    setTableData(newData);
  };

  const handleCustomDiscountChange = (id, value) => {
    const newData = [...tableData];
    const targetRow = newData.find((row) => row.id === id);
    targetRow.discount = parseFloat(value) || 0;
    targetRow.total = calculateTotal(
      targetRow.price,
      targetRow.discount,
      targetRow.tax,
      targetRow.nightsCount
    );
    setTableData(newData);
  };

  const handleCustomTaxChange = (id, value) => {
    const newData = [...tableData];
    const targetRow = newData.find((row) => row.id === id);
    targetRow.tax = parseFloat(value) || 0;
    targetRow.total = calculateTotal(
      targetRow.price,
      targetRow.discount,
      targetRow.tax,
      targetRow.nightsCount
    );
    setTableData(newData);
  };

  const columns = [
    {
      title: <span className="table__title"> room Type</span>,
      dataIndex: "roomType",
      render: (text, record) => (
        <Select
          value={text.id}
          style={{ width: 150 }}
          onChange={(value) => handleRoomTypeChange(record.id, value)}
          placeholder="Room Type"
        >
          <Select.Option value="">Select type</Select.Option>
          {roomTypes.map((roomType) => (
            <Select.Option key={roomType.id} value={roomType.id}>
              {roomType.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: <span className="table__title"> room Number</span>,
      dataIndex: "roomNumber",
      render: (text, record) => (
        <Select
          value={record.roomNumber || undefined}
          style={{ width: 150 }}
          onChange={(value) => handleBaseRoomChange(record.id, value)}
          placeholder="Select Room Number"
          disabled={!record.isRoomTypeSelected}
        >
          {roomNumbers.map((room) => (
            <Select.Option key={room.id} value={room.id}>
              {room.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: <span className="table__title"> Check In & Out</span>,
      render: (text, record) => (
        <DatePicker.RangePicker
          style={{ width: 200 }}
          value={[
            record.checkInDate ? moment(record.checkInDate) : null,
            record.checkOutDate ? moment(record.checkOutDate) : null,
          ]}
          onChange={(dates) => handleDateRangeChange(dates, record)}
          disabledDate={disabledDate}
        />
      ),
    },
    {
      title: <span className="table__title"> nights</span>,
      dataIndex: "nightsCount",
      id: "nightsCount",
      render: (text) => <span>{text}</span>,
    },

    {
      title: <span className="table__title"> guest</span>,
      dataIndex: "guestCount",
      render: (text, record) => (
        <InputNumber
          className="accomodation_numbar"
          value={record.guestCount}
          defaultValue={record.guestCount}
          onChange={(value) => handleGuestCountChange(record.id, value)}
          max={1000}
          min={1}
        />
      ),
    },
    {
      title: <span className="table__title"> room Rate</span>,
      dataIndex: "roomRate",
      render: (text, record) => (
        <Select
          value={text || undefined}
          style={{ width: 150 }}
          onChange={(value) => handlePriceChange(record.id, value)}
          placeholder="Select Price Type"
        >
          <Select.Option value="Regular">Regular</Select.Option>
          <Select.Option value="Custom">Custom</Select.Option>
        </Select>
      ),
    },
    {
      title: <span className="table__title"> price($)</span>,
      dataIndex: "price",
      align: "right",
      render: (text, record) => (
        <Input
          value={record.roomRate === "Custom" ? record.price : text}
          onChange={(e) => handleCustomPriceChange(record.id, e.target.value)}
          disabled={record.roomRate === "Regular"}
          style={{ textAlign: "right" }}
        />
      ),
    },
    {
      title: <span className="table__title"> discount (%)</span>,
      dataIndex: "discount",
      align: "right",
      render: (text, record) => (
        <Input
          value={record.roomRate === "Custom" ? record.discount : text}
          onChange={(e) => handleCustomDiscountChange(record.id, e.target.value)}
          disabled={record.roomRate === "Regular"}
          style={{ textAlign: "right" }}
        />
      ),
    },
    {
      title: <span className="table__title"> tax (%)</span>,
      dataIndex: "tax",
      align: "right",
      render: (text, record) => (
        <Input
          value={record.roomRate === "Custom" ? record.tax : text}
          onChange={(e) => handleCustomTaxChange(record.id, e.target.value)}
          disabled={record.roomRate === "Regular"}
          style={{ textAlign: "right" }}
        />
      ),
    },

    {
      title: <span className="table__title">total</span>,
      dataIndex: "total",
      align: "right",
      id: "total",
      render: (text, record) => {
        // console.log("=------------------------------------------------------record", record.text);
        if (record.nightsCount > 0) {
          return <span>{text * record.nightsCount}</span>;
        }
        return <span>{text}</span>;
      },
    },
    {
      title: <span className="table__title"> action</span>,
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return (
          <Button type="primary" onClick={() => handleCancel(record.id)}>
            <CloseOutlined />
          </Button>
        );
      }
    },
  ];
  // console.log("tableData--tableData===tableData--tableData--555-->", tableData);
  return (
    <Drawer
      title="ADD NEW ROOM"
      width="90%"
      placement="right"
      closable={false}
      open={addRoomDrawer}
      onClose={() => setAddRoomDrawer(false)}
      style={{ zIndex: "2001" }}
      footer={
        <div style={{ display: "flex", justifyContent: "flex-end" }}>

          <Space>
            <Button type="default" onClick={() => setAddRoomDrawer(false)} icon={<CloseCircleOutlined />} style={{ marginTop: "20px", width: "250px" }}>
              Close
            </Button>

            <Button type="primary" onClick={handleSave} icon={<CheckCircleOutlined />} style={{ marginTop: "20px", width: "250px" }}>
              Submit
            </Button>
          </Space>
        </div>
      }
    >
      <div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleAddRow}
          style={{ marginBottom: 16, float: "right" }}
        >
          Add Room
        </Button>
        <Table
          dataSource={tableData}
          columns={columns}
          pagination={false}
          bordered
        />
      </div>
    </Drawer>
  );
};

export default AddAccommodation;
