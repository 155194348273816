/**
 * @description This NumberInput For If you want to use InputNumber without keyboard and controls
 * @example Routing Number / Account Number
 */
import { Form, InputNumber } from "antd";
import { useState } from "react";

const InputNumber2 = ({ name, label, placeholder = "", disabled, addonAfter = "", required, ...arg }) => {
  const [cleanedValue, setCleanedValue] = useState("");

  const handleInputChange = (value) => {
    const cleanedInput = value.toString().replace(/^0+/, "");
    setCleanedValue(cleanedInput);
  };
  return (
    <Form.Item
      rules={[
        {
          required,
          message: `Please input your ${label}!`,
        },
      ]}
      name={name}
      label={label}
      {...arg}
    >
      <InputNumber
        placeholder={placeholder}
        size="large"
        bordered
        addonAfter={addonAfter}
        keyboard={false}
        controls={false}
        disabled={disabled}
        onChange={handleInputChange}
        value={cleanedValue}
      />
    </Form.Item>
  );
};

export default InputNumber2;
