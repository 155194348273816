import Dashboard from "containers/Overview/Dashboard";

import SalesReport2 from "containers/Reports/Sales/List";

// import ReservationList from "containers/Frontdesk/Reservations/List";
// import NewReservation from "containers/Frontdesk/Reservations/New";

import GroupList from "containers/Frontdesk/Groups/List";
import NewGroup from "containers/Frontdesk/Groups/New";

import SalaryLogs from "containers/Hrm/Salarysheet/List";
import GenerateSalSheet from "containers/Hrm/Salarysheet/New";

import CGList from "containers/Frontdesk/CustomerGuest/List";
import NewCG from "containers/Frontdesk/CustomerGuest/New";

import HkAdminList from "containers/HouseKeeping/List";
import NewHkAdmin from "containers/HouseKeeping/New";

import RoomList from "containers/Services/Rooms/List";
import NewRoom from "containers/Services/Rooms/New";

import RoomTypes from "containers/Services/RoomTypes/List";
import NewRoomTypes from "containers/Services/RoomTypes/New";

import ServicesList from "containers/Services/Other/List";
import NewServices from "containers/Services/Other/New";

import RatesList from "containers/Settings/Rates/List";
import NewRates from "containers/Settings/Rates/New";

// import CloseoutList from "containers/CloseoutsOverrides/List";
// import NewCloseout from "containers/CloseoutsOverrides/New";

import SetupList from "containers/Settings/Setup/List";
import NewSetup from "containers/Settings/Setup/New";

import UpdatePendingOrder from "containers/Purchase/PendingOrder/New";

// import Other from "containers/OtherHelp/List";

import TaskList from "containers/Tasks/List";
import NewTask from "containers/Tasks/New";

import ActivityList from "containers/Activities/Info/List";
import NewActivity from "containers/Activities/Info/New";

import ActivityTypeList from "containers/Activities/Type/List";
import NewActivityType from "containers/Activities/Type/New";

import VacationList from "containers/Hrm/Vacation/List";
import NewVacation from "containers/Hrm/Vacation/New";

import ScheduleList from "containers/Activities/Schedule/List";
import NewSchedule from "containers/Activities/Schedule/New";

import CMList from "containers/ChannelManager/List";
import NewCM from "containers/ChannelManager/New";

import EquipmentList from "containers/Assets/Equipments/List";
import NewEquipment from "containers/Assets/Equipments/New";

import CashChequePay from "containers/Accounts/CashPay/New";
import CashChequeRc from "containers/Accounts/CashReceive/New";

import TransactionHistory from "containers/Accounts/Transaction/List";
import SalReport from "containers/Restaurant/SalesReport/List";

import AssetList from "containers/Assets/Info/List";
import NewAsset from "containers/Assets/Info/New";

import AssetTypes from "containers/Assets/Type/List";
import NewAssetType from "containers/Assets/Type/New";

import BankinfoList from "containers/Banking/List";
import NewBankInfo from "containers/Banking/New";

import BookingList from "containers/Booking/Info/List";
import NewBooking from "containers/Booking/Info/New";

import CheckoutHistory from "containers/Booking/History/List";
import CheckoutInfo from "containers/Booking/History/New";

import EmpList from "containers/Hrm/Employee/List";
import NewEmp from "containers/Hrm/Employee/New";

import EmpTypeList from "containers/Hrm/EmployeeType/List";
import NewEmpType from "containers/Hrm/EmployeeType/New";

import DesignationList from "containers/Hrm/Designation/List";
import NewDesignation from "containers/Hrm/Designation/New";

import LeaveList from "containers/Hrm/Leave/List";
import NewLeave from "containers/Hrm/Leave/New";

import LeaveTypes from "containers/Hrm/LeaveType/List";
import NewLeaveType from "containers/Hrm/LeaveType/New";

import AllShift from "containers/Hrm/Shift/List";
import NewShift from "containers/Hrm/Shift/New";

import DepList from "containers/Hrm/Department/List";
import NewDepartment from "containers/Hrm/Department/New";

import ItemTypes from "containers/Restaurant/ItemType/List";
import NewItemType from "containers/Restaurant/ItemType/New";

import SalHistory from "containers/Restaurant/SalesHistory/List";

import Menu from "containers/Restaurant/Menu/List";
import AddMenu from "containers/Restaurant/Menu/New";

import MenuCats from "containers/Restaurant/MenuCategory/List";
import AddMenuCat from "containers/Restaurant/MenuCategory/New";

import UnitTitleList from "containers/Restaurant/UnitTitle/List";
import AddUnitTitle from "containers/Restaurant/UnitTitle/New";

import MenuSubCats from "containers/Restaurant/MenuSubcat/List";
import AddMenuSubCat from "containers/Restaurant/MenuSubcat/New";

import Calendar from "containers/Calendar";
import GuestList from "containers/Crm/Customer/List";
import NewGuest from "containers/Crm/Customer/New";
import GuestStatementList from "containers/Crm/Statement/List";
import Error404 from "containers/Error404";
import SalStructureList from "containers/Hrm/EmpSalaryStructure/List";
import NewSalStructure from "containers/Hrm/EmpSalaryStructure/New";
import HotelList from "containers/Overview/Hotels/List";
import NewHotel from "containers/Overview/Hotels/New";
import ItemList from "containers/Purchase/Items/List";
import AddItem from "containers/Purchase/Items/New";
import PurchaseList from "containers/Purchase/Orders/List";
import NewPurchase from "containers/Purchase/Orders/New";
import PendingOrderList from "containers/Purchase/PendingOrder/List";
import SupplierList from "containers/Purchase/Supplier/List";
import NewSupplier from "containers/Purchase/Supplier/New";
import BalanceSheet from "containers/Reports/BalanceSheet/List";
import Cashflow from "containers/Reports/Cashflow/List";
import ExpensesReport from "containers/Reports/Expense/List";
import Ledger from "containers/Reports/GenLedger/List";
import IncomeStatement from "containers/Reports/Income/List";
import ProfitAndLoss from "containers/Reports/ProfitAndLoss/List";
import PurchaseReport from "containers/Reports/Purchase/List";

import NewPos from "containers/Restaurant/POS/New";

import TrialBalance from "containers/Reports/TrialBalance/List";

import AllAmenity from "containers/Services/Amenities/List";
import AddAmenity from "containers/Services/Amenities/New";
import AllPolicy from "containers/Services/Policy/List";
import AddPolicy from "containers/Services/Policy/New";
import SourceList from "containers/Settings/Sources/List";
import NewSource from "containers/Settings/Sources/New";
import UserList from "containers/User/Info/List";
import NewUser from "containers/User/Info/New";
import { Route, Routes } from "react-router-dom";

import Registration from "containers/Registration";

import ResServiceList from "containers/Restaurant/Inventory/List";
import NewResService from "containers/Restaurant/Inventory/New";
import NewVendorBill from "containers/VendorBill/New";
import NewChartOfAccount from "containers/Accounts/ChartOfAccounts/New";
import ChartOfAccountList from "containers/Accounts/ChartOfAccounts/List";
import BillHistory from "containers/VendorBill/List";
import NewTransaction from "containers/Accounts/Transaction/New";
import NewJournal from "containers/Accounts/Journal/New";
import Journals from "containers/Accounts/Journal/List";
import ProductDetails from "containers/Overview/Hotels/Details";
import UserRoles from "containers/User/Roles/List";
import NewRoles from "containers/User/Roles/New";
import Audit from "containers/User/Audit";
import SalesHistoryDetails from "containers/Restaurant/SalesHistory/Details";
import NewBooking2 from "containers/Booking2/New";
import BookingHistory from "containers/Booking2/List";
import CurrentGuests from "containers/Booking2/Guest/List";
import RoomRevenueList from "containers/Reports/RoomRevenue/List";
import NewBookingV2 from "containers/BookingV3/NewV3";
import RoomDetails from "containers/Services/Rooms/Details";
import AgentList from "containers/Agent/List";
import NewAgent from "containers/Agent/New";
import NewEmailConfig from "containers/Email/New";
import NewEmailSubject from "containers/Email/EmailSubject/NewSub";
import Booking from "../containers/Frontdesk/Booking";
import BookingV2 from "../containers/Frontdesk/BookingV2";
import BookingV3 from "../containers/Frontdesk/BookingV3";
import BookingDetails from "../containers/Frontdesk/BookingDetails";

const AppRoutes = () => {
  return (
    // <AppLayout>
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/overview/dashboard" element={<Dashboard />} />
      <Route path="/calendar" element={<Calendar />} />
      <Route path="/frontdesk" element={<Booking />} />
      <Route path="/booking/bookingdetails/:id" element={<BookingDetails />} />
      <Route path="/frontdeskV3" element={<BookingV3 />} />
      <Route path="/registration" element={<Registration />} />
      <Route path="/overview/hotels" element={<HotelList />} />

      <Route path="overview">
        <Route path="addnewhotel" element={<NewHotel />} />
        <Route path="updatehotel/:id" element={<NewHotel />} />
        <Route path="hoteldetails/:id" element={<ProductDetails />} />
        <Route path="hotellist" element={<HotelList />} />
      </Route>

      <Route path="restaurant">
        <Route path="addnewmenu" element={<AddMenu />} />
        <Route path="updatemenu/:id" element={<AddMenu />} />
        <Route path="menulist" element={<Menu />} />

        <Route path="addnewmenucategory" element={<AddMenuCat />} />
        <Route path="updatemenucategory/:id" element={<AddMenuCat />} />
        <Route path="menucategorylist" element={<MenuCats />} />

        <Route path="addnewmenusubcat" element={<AddMenuSubCat />} />
        <Route path="updatemenusubcat/:id" element={<AddMenuSubCat />} />
        <Route path="menusubcatlist" element={<MenuSubCats />} />

        <Route path="addnewunittitle" element={<AddUnitTitle />} />
        <Route path="updateunittitle/:id" element={<AddUnitTitle />} />
        <Route path="unittitlelist" element={<UnitTitleList />} />

        <Route path="addnewitemtype" element={<NewItemType />} />
        <Route path="updateitemtype/:id" element={<NewItemType />} />
        <Route path="itemtypelist" element={<ItemTypes />} />

        <Route path="addnewresservicetype" element={<NewResService />} />
        <Route path="updateresservicetype/:id" element={<NewResService />} />
        <Route path="resservicetypelist" element={<ResServiceList />} />

        <Route path="addnewpos" element={<NewPos />} />

        <Route path="saleshistory" element={<SalHistory />} />
        <Route path="salesitemdetails/:order_id" element={<SalesHistoryDetails />} />

        <Route path="salesreport" element={<SalReport />} />
      </Route>

      <Route path="rooms_ratings">
        <Route path="addnewroominfo" element={<NewRoom />} />
        <Route path="updateroominfo/:id" element={<NewRoom />} />
        <Route path="roominfodetails/:id" element={<RoomDetails />} />
        <Route path="roominfolist" element={<RoomList />} />

        <Route path="addnewroomtype" element={<NewRoomTypes />} />
        <Route path="updateroomtype/:id" element={<NewRoomTypes />} />
        <Route path="roomtypelist" element={<RoomTypes />} />

        <Route path="addnewroomotherservices" element={<NewServices />} />
        <Route path="updateroomotherservices/:id" element={<NewServices />} />
        <Route path="roomotherserviceslist" element={<ServicesList />} />

        <Route path="newpolicy" element={<AddPolicy />} />
        <Route path="editpolicy/:id" element={<AddPolicy />} />
        <Route path="policies" element={<AllPolicy />} />

        <Route path="newamenity" element={<AddAmenity />} />
        <Route path="editamenity/:id" element={<AddAmenity />} />
        <Route path="amenities" element={<AllAmenity />} />
      </Route>

      {/* <Route path="agent">
        <Route path="agentinfolist" element={<AgentList />} />
      </Route> */}

      <Route path="user">
        <Route path="newuser" element={<NewUser />} />
        <Route path="useredit/:id" element={<NewUser />} />
        <Route path="userlist" element={<UserList />} />

        <Route path="allroles" element={<UserRoles />} />
        <Route path="newrole" element={<NewRoles />} />
        <Route path="roleopen/:id" element={<NewRoles />} />

        <Route path="audit" element={<Audit />} />
      </Route>

      <Route path="booking">
        <Route path="addnewbooking" element={<NewBooking2 />} />
        <Route path="bookinglist" element={<BookingHistory />} />
        {/* <Route path="bookingdetails/:id" element={<NewBooking2 />} /> */}

        {/* <Route path="new" element={<CurrentGuestInfo />} /> */}
        <Route path="currentguests" element={<CurrentGuests />} />

        <Route path="agentinfolist" element={<AgentList />} />
        <Route path="addnewagentinfo" element={<NewAgent />} />
        <Route path="updateagentinfo/:id" element={<NewAgent />} />

        <Route path="newemailconfig" element={<NewEmailConfig />} />
        {/* <Route path="emailconfigdetails/:id" element={<NewEmailConfig />} /> */}
        <Route path="updateemailconfig/:id" element={<NewEmailConfig />} />

        <Route path="newemailsubject" element={<NewEmailSubject />} />

        <Route path="checkouthistory" element={<CheckoutHistory />} />

      </Route>

      <Route path="accounts">
        <Route path="addnewchartofacc" element={<NewChartOfAccount />} />
        <Route path="updatechartofacc/:id" element={<NewChartOfAccount />} />
        <Route path="chartofacc" element={<ChartOfAccountList />} />

        <Route path="newtransaction" element={<NewTransaction />} />
        <Route path="transactionhistory" element={<TransactionHistory />} />

        <Route path="ledger" element={<Ledger />} />
        {/* <Route path="newtransaction" element={<NewTransaction />} /> */}

        <Route path="newjournal" element={<NewJournal />} />
        <Route path="accountsjournal" element={<Journals />} />
        <Route path="updatejournal/:id" element={<NewJournal />} />
      </Route>

      <Route path="settings">
        <Route path="newsource" element={<NewSource />} />
        <Route path="editsource/:id" element={<NewSource />} />
        <Route path="sources" element={<SourceList />} />
      </Route>

      <Route path="guests">
        <Route path="addnewguest" element={<NewGuest />} />
        <Route path="updateguest/:id" element={<NewGuest />} />
        <Route path="guestlist" element={<GuestList />} />

        <Route path="gueststatement" element={<GuestStatementList />} />
      </Route>

      <Route path="assets">
        <Route path="addnewassetinfos" element={<NewAsset />} />
        <Route path="updateassetinfos/:id" element={<NewAsset />} />
        <Route path="assetinfoslist" element={<AssetList />} />

        <Route path="addnewassettypes" element={<NewAssetType />} />
        <Route path="updateassettypes/:id" element={<NewAssetType />} />
        <Route path="assettypeslist" element={<AssetTypes />} />
      </Route>

      <Route path="hrm">
        <Route path="addnewemployeeinfos" element={<NewEmp />} />
        <Route path="updateemployeeinfos/:id" element={<NewEmp />} />
        <Route path="employeeinfoslist" element={<EmpList />} />

        <Route path="addnewemployeetypes" element={<NewEmpType />} />
        <Route path="updateemployeetypes/:id" element={<NewEmpType />} />
        <Route path="employeetypeslist" element={<EmpTypeList />} />

        <Route path="addnewdepartment" element={<NewDepartment />} />
        <Route path="updatedepartment/:id" element={<NewDepartment />} />
        <Route path="departmentlist" element={<DepList />} />

        <Route path="addnewdesignation" element={<NewDesignation />} />
        <Route path="updatedesignation/:id" element={<NewDesignation />} />
        <Route path="designationlist" element={<DesignationList />} />

        <Route path="addnewworkingshift" element={<NewShift />} />
        <Route path="updateworkingshift/:id" element={<NewShift />} />
        <Route path="workingshiftlist" element={<AllShift />} />

        <Route path="addnewleaveinfo" element={<NewLeave />} />
        <Route path="updateleaveinfo/:id" element={<NewLeave />} />
        <Route path="leaveinfolist" element={<LeaveList />} />

        <Route path="addnewleavetype" element={<NewLeaveType />} />
        <Route path="updateleavetype/:id" element={<NewLeaveType />} />
        <Route path="leavetypelist" element={<LeaveTypes />} />

        <Route path="addnewholiday" element={<NewVacation />} />
        <Route path="updateholiday/:id" element={<NewVacation />} />
        <Route path="holidaylist" element={<VacationList />} />

        <Route path="addnewsalarysheet" element={<GenerateSalSheet />} />
        <Route path="updatesalarysheet/:id" element={<GenerateSalSheet />} />
        <Route path="salarysheetlist" element={<SalaryLogs />} />

        <Route path="addnewsalarystructure" element={<NewSalStructure />} />
        <Route path="updatesalarystructure/:id" element={<NewSalStructure />} />
        <Route path="salarystructurelist" element={<SalStructureList />} />
      </Route>

      <Route path="purchase">
        <Route path="addnewpurchaseitem" element={<AddItem />} />
        <Route path="updatepurchaseitem/:id" element={<AddItem />} />
        <Route path="purchaseitemlist" element={<ItemList />} />

        <Route path="addnewsupplierinfo" element={<NewSupplier />} />
        <Route path="updatesupplierinfo/:id" element={<NewSupplier />} />
        <Route path="supplierinfo" element={<SupplierList />} />

        <Route path="addnewpurchaseorder" element={<NewPurchase />} />
        <Route path="updatepurchaseorder/:id" element={<NewPurchase />} />
        <Route path="purchaseorderlist" element={<PurchaseList />} />

        <Route path="pendingorders" element={<PendingOrderList />} />
        <Route path="approvedirectorder/:id" element={<UpdatePendingOrder />} />

        <Route path="newsupplierbill" element={<NewVendorBill />} />
        <Route path="billhistory" element={<BillHistory />} />
      </Route>

      <Route path="banking">
        <Route path="addnewbankinfos" element={<NewBankInfo />} />
        <Route path="updatebankinfos/:id" element={<NewBankInfo />} />
        <Route path="bankinfoslist" element={<BankinfoList />} />
      </Route>

      <Route path="assets_management">
        <Route path="addnewassetinfos" element={<NewAsset />} />
        <Route path="updateassetinfos/:id" element={<NewAsset />} />
        <Route path="assetinfoslist" element={<AssetList />} />
      </Route>

      <Route path="reports">
        <Route path="roomrevenue" element={<RoomRevenueList />} />
      </Route>

      <Route path="user">
        <Route path="newuser" element={<NewUser />} />
        <Route path="useredit/:id" element={<NewUser />} />
        <Route path="userlist" element={<UserList />} />
      </Route>
      {/* /booking/newbooking */}

      <Route path="booking">
        <Route path="newbooking" element={<NewBooking />} />
      </Route>
      {/* <Route path="/reservation/list" element={<ReservationList />} /> */}

      <Route path="/salesreport2/list" element={<SalesReport2 />} />

      <Route path="/groups/new" element={<NewGroup />} />
      <Route path="/groups/list" element={<GroupList />} />

      <Route path="/cusgue/new" element={<NewCG />} />
      <Route path="/cusgue/list" element={<CGList />} />

      <Route path="/hkadmin/new" element={<NewHkAdmin />} />
      <Route path="/hkadmin/list" element={<HkAdminList />} />

      <Route path="/shift/new" element={<NewShift />} />
      <Route path="/shift/list" element={<AllShift />} />

      <Route path="/leave/list" element={<LeaveList />} />
      <Route path="/leave/new" element={<NewLeave />} />

      <Route path="/leavetypes/list" element={<LeaveTypes />} />
      <Route path="/leavetypes/new" element={<NewLeaveType />} />

      <Route path="/designation/list" element={<DesignationList />} />
      <Route path="/designation/new" element={<NewDesignation />} />

      <Route path="/department/list" element={<DepList />} />
      <Route path="/department/new" element={<NewDepartment />} />

      <Route path="/salarysheet/list" element={<SalaryLogs />} />
      <Route path="/salarysheet/new" element={<GenerateSalSheet />} />

      <Route path="/rates/list" element={<RatesList />} />
      <Route path="/rates/new" element={<NewRates />} />

      {/* <Route path="restaurant_management/menulist" element={<Menu />} />
      <Route path="restaurant_management/addnewmenu" element={<AddMenu />} />
      <Route
        path="restaurant_management/updatemenu/:id"
        element={<AddMenu />} */}
      {/* /> */}

      {/* <Route path="/closeoutoverrides/list" element={<CloseoutList />} />
      <Route path="/closeoutoverrides/new" element={<NewCloseout />} /> */}

      <Route path="/setup/list" element={<SetupList />} />
      <Route path="/setup/list" element={<NewSetup />} />

      <Route path="/tasks/list" element={<TaskList />} />
      <Route path="/tasks/new" element={<NewTask />} />

      <Route path="/activity/list" element={<ActivityList />} />
      <Route path="/activity/new" element={<NewActivity />} />

      <Route path="/acttype/list" element={<ActivityTypeList />} />
      <Route path="/acttype/new" element={<NewActivityType />} />

      <Route path="/vacations/list" element={<VacationList />} />
      <Route path="/vacations/new" element={<NewVacation />} />

      <Route path="/schedules/list" element={<ScheduleList />} />
      <Route path="/schedules/new" element={<NewSchedule />} />

      {/* <Route path="/aeres/list" element={<AeResList />} /> */}

      <Route path="/saleshistory/list" element={<SalHistory />} />

      <Route path="/salesreport/list" element={<SalReport />} />

      {/* <Route path="/aeadmin/list" element={<AeAdminList />} /> */}

      <Route path="/channelmanager/list" element={<CMList />} />
      <Route path="/channelmanager/new" element={<NewCM />} />

      <Route path="/equipment/list" element={<EquipmentList />} />
      <Route path="/equipment/new" element={<NewEquipment />} />

      <Route path="/cashchequepay/list" element={<CashChequePay />} />
      <Route path="/cashchequerc/list" element={<CashChequeRc />} />

      <Route path="/trx/list" element={<TransactionHistory />} />

      <Route path="/bankinfo/list" element={<BankinfoList />} />
      <Route path="/bankinfo/new" element={<NewBankInfo />} />

      <Route path="/booking/list" element={<BookingList />} />
      <Route path="/booking/new" element={<NewBooking />} />

      <Route path="/checkout/list" element={<CheckoutHistory />} />
      <Route path="/checkout/new" element={<CheckoutInfo />} />

      {/* <Route path="crntguest">
        <Route path="new" element={<CurrentGuestInfo />} />
        <Route path="list" element={<CurrentGuests />} />
      </Route> */}

      <Route path="/purchasereport/list" element={<PurchaseReport />} />

      <Route path="/trialbalance/list" element={<TrialBalance />} />

      <Route path="/balancesheet/list" element={<BalanceSheet />} />

      <Route path="/incomestatement/list" element={<IncomeStatement />} />

      <Route path="/profitlossacc/list" element={<ProfitAndLoss />} />

      <Route path="/ledger/list" element={<Ledger />} />

      <Route path="/cashflow/list" element={<Cashflow />} />

      <Route path="/expensesreport/list" element={<ExpensesReport />} />

      <Route path="/pendingorder/list" element={<PendingOrderList />} />

      <Route path="/unittitle/list" element={<UnitTitleList />} />
      <Route path="/unittitle/new" element={<AddUnitTitle />} />

      <Route path="/purchaseorder/list" element={<PurchaseList />} />
      <Route path="/purchaseorder/new" element={<NewPurchase />} />

      <Route path="/item/list" element={<ItemList />} />
      <Route path="/item/new" element={<AddItem />} />

      <Route path="/supplier/list" element={<SupplierList />} />
      <Route path="/supplier/new" element={<NewSupplier />} />

      {/* <Route path="/otherhelp/list" element={<Other />} /> */}
      {/* <Route path="/reports/list" element={<ReportList />} /> */}
      <Route path="*" element={<Error404 />} />
    </Routes>
    // </AppLayout>
  );
};

export default AppRoutes;
