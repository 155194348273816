import { Table } from "antd";
import { useState } from "react";
import { useQuery } from "react-query";
// import axiosinstance from "util/Api";

import Axiosinstance from "utils/AxiosInstance";
import { AVD_INPUT, ROUTES } from "./Data";

const DeletdLog = () => {
  const [reqURL] = useState(ROUTES.DeletdLog);

  const columns = [
    {
      title: "SL NO",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Deleted By",
      dataIndex: "deleted_by",
      key: "deleted_by",
    },
    {
      title: "Table Name",
      dataIndex: "table_name",
      key: "table_name",
    },
    {
      title: "Row ID",
      dataIndex: "row_id",
      key: "row_id",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "IP Address",
      dataIndex: "ip",
      key: "ip",
    },
  ];

  const { data, isError, isLoading } = useQuery(
    [ROUTES.DeletdLog, reqURL],
    () => Axiosinstance.get(reqURL).then((res) => res.data.data)
  );

  return (
    <Table
      size="small"
      className="table"
      scroll={{ x: true }}
      columns={columns}
      dataSource={data}
      bordered
      pagination={{ position: ["bottomCenter"], pageSize: 50 }}
      loading={isLoading}
    />
  );
};
export default DeletdLog;
