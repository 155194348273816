import {
  CheckCircleFilled,
  CloseCircleOutlined,
  ReloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Form, Row, Space } from "antd";
import { closeDrawer } from "features/avdSearch/avdSearchSlice";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getQueryStrings } from "utils/CISUI";
import { InputField } from "utils/InputField";

const AdvSearch = () => {
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector((state) => state.avdSearch?.isDrawerOpen);
  const json = useSelector((state) => state.json?.data);

  const formRef = React.useRef();
  const navigate = useNavigate();

  const onFinish = (values) => {
    const qsup = getQueryStrings(values);
    navigate(`?${qsup}`);
    console.log(values);
    dispatch(closeDrawer());
    // console.log(closeDrawer, "closeDrawer");
  };

  useEffect(() => {
    if (isDrawerOpen) {
      formRef.current?.resetFields();
    }
  }, [isDrawerOpen]);

  return (
    <Drawer
      title="Advance Search"
      placement="right"
      onClose={() => {
        dispatch(closeDrawer());
        // formRef.current?.resetFields();
      }}
      open={isDrawerOpen}
      width={380}
    >
      <Form layout="vertical" ref={formRef} onFinish={onFinish}>
        <Row gutter={[24, 24]}>
          {json?.input_fields?.map((item, index) => {
            const inputs = item.input.filter((item) => item.isFilterable);
            return <InputField key={index} input={inputs} type="adv" />;
          })}
        </Row>
        <div className="adv_submit_area">
          <Space size="large" align="center" style={{ margin: "0 auto" }}>
            <Button icon={<CloseCircleOutlined />} onClick={() => dispatch(closeDrawer())}>
              Cancel
            </Button>
            <Button
              icon={<ReloadOutlined />}
              htmlType="reset"
              onClick={() => {
                navigate(window.location.pathname);
                formRef.current?.resetFields();
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              icon={<SearchOutlined />}
              htmlType="submit"
              loading={false}
              disabled={false}
            >
              Search
            </Button>
          </Space>
        </div>
      </Form>
    </Drawer>
  );
};

export default AdvSearch;
