import { message } from "antd";
import { apiSlice } from "../api/apiSlice";
import { userLoggedIn } from "./authSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: data,
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          if (result?.data?.status === 200) {
            message.success(result.data.message);

            dispatch(
              userLoggedIn({
                accessToken: result?.data?.access_token,
                user: result?.data?.user,
              })
            );
          } else {
            message.error(result?.data?.error);
          }
        } catch (res) {
          // do nothing
          // message.error(JSON.stringify(res));
          message.error(res?.error?.data?.error);
        }
      },
    }),
  }),
});

export const { useLoginMutation } = authApi;
