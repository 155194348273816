/* eslint-disable max-len */
import React, { useEffect, useState, useRef } from "react";
import "react-calendar-timeline/lib/Timeline.css";
import Axiosinstance from "utils/AxiosInstance";
// import Timeline, { DateHeader, SidebarHeader, TimelineHeaders } from "react-calendar-timeline";
import Timeline, {
  TimelineMarkers,
  TimelineHeaders,
  TodayMarker,
  CustomMarker,
  CursorMarker,
  CustomHeader,
  SidebarHeader,
  DateHeader
} from "react-calendar-timeline";
import moment from "moment";
import { Button, Image, Popover, Spin } from "antd";
import { EllipsisOutlined, DashOutlined, ArrowLeftOutlined, ArrowRightOutlined, CaretRightOutlined, CaretLeftOutlined, MergeCellsOutlined, PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { MdOutlineCenterFocusStrong } from "react-icons/md";
import RoomStatus from "./RoomStatus";
import CalenderDetailsDrawer from "./CalenderDetailsDrawer";
import RoomDetailsDrawer from "./RoomDetailsDrawer";
import FallbackImage from "../../../assets/images/j.webp";
import StatusBox from "./StatusBox";
import StatusIdentity from "./StatusIdentity";
import HoverStatus from "./HoverStatus";

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start_time",
  itemTimeEndKey: "end_time",
  // groupLabelKey: "title"
};

const V3TimeLine = () => {
  const [gdata, setGdata] = useState([]);
  const [groups, setGroups] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [calendarData, setCalendarData] = useState([]);
  const [openCalenderData, setOpenCalenderData] = useState(false);
  const [hotelInfo, setHotelInfo] = useState();
  const [roomDetailsDrawer, setRoomdetailsDrawer] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [visibleTimeStart, setVisibleTimeStart] = useState(moment().startOf("month").valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment().endOf("month").valueOf());
  const [loading, setLoading] = useState(true);
  // console.log("groups=====groups=-------------robin123----==>", hotelInfo);
  const handleClick = () => {
    setOpenCalenderData(true);
  };

  const onPrevClick = () => {
    const zoom = visibleTimeEnd - visibleTimeStart;
    setVisibleTimeStart((prevStart) => prevStart - zoom);
    setVisibleTimeEnd((prevEnd) => prevEnd - zoom);
  };

  const onNextClick = () => {
    const zoom = visibleTimeEnd - visibleTimeStart;
    setVisibleTimeStart((prevStart) => prevStart + zoom);
    setVisibleTimeEnd((prevEnd) => prevEnd + zoom);
  };
  const onMonthClick = () => {
    setVisibleTimeStart(moment().startOf("month").valueOf());
    setVisibleTimeEnd(moment().endOf("month").valueOf());
  };

  useEffect(() => {
    setLoading(true); // Set loading state to true initially
    Axiosinstance.get("frontdesk/frontdeskv2")
      .then((res) => {
        setAllStatus(res?.data?.status_list);
        setGdata(res?.data?.data);
        setCalendarData(res?.data?.calender_data);
        setHotelInfo(res?.data?.hotel_info);
        // console.log("res data-------111-----", res.data);
        setLoading(false); // Set loading state to false when data is loaded
        console.log(calendarData, "calendarData");
      });
  }, []);

  const { data, isLoading, isError } = useQuery("frontdesk", () =>
    Axiosinstance.get("/sys/roomstatus?is_dep=1").then((res) => res.data)
  );
  // console.log("new RoomStatus 111111111111=====>", data);
  const content = (id) => {
    return (
      <div className="room__status__button">
        <RoomStatus data={data?.data} id={id} setGroups={setGroups} />
      </div>
    );
  };
  // Create an array to hold the updated 'groups' state---------------------------------------->>

  useEffect(() => {
    const updatedGroups = [];

    gdata.forEach((item, index) => {
      const newGroup = { id: index * 100, title: item.type_name, isSectionHeader: true };
      const roomGroups = item.rooms.map((room) => ({
        id: room.id,
        room_name: room.room_name,
        title: room.room_number,
        room_size: room.room_size,
        maximum_guests: room.maximum_guests,
        num_of_bed: room.num_of_bed,
        num_of_bathroom: room.num_of_bathroom,
        num_of_kitchen: room.num_of_kitchen,
        num_of_balcony: room.num_of_balcony,
        num_of_sofa_bed: room.num_of_sofa_bed,
        base_price: room.base_price,
        check_in: room.check_in,
        check_out: room.check_out,
        room_image: room.room_image,
        status_color: room.status_color,
        status: room.status,
      }));

      updatedGroups.push(newGroup, ...roomGroups);
    });
    setGroups(updatedGroups);
  }, [gdata]);

  const newGroups = groups
    .map((group) => {
      return {
        ...group,
        title: group.isSectionHeader ? (
          <div
            style={{ color: "#f9f9f9", fontWeight: 600, paddingLeft: "10px" }}
            className={`${
              group.isSectionHeader ? "esl-section-header" : "esl-equipment"
            }`}
          >
            {group.title}
          </div>
        ) : (
          <div
            className="hotel__name__status"
            style={{ paddingLeft: 10, color: " #23253c", fontWeight: 800 }}
          >
            <div className="hotel__status__area">
              <StatusBox roomStatus={group.status} statusColor={group.status_color} />
              <span role="presentation" onClick={handleClick} style={{ cursor: "pointer", color: " #5b5b78", fontSize: "13px", fontWeight: "600" }}>
                <Popover
                  trigger="hover"
                  content={
                    <div className="room__details__hover__area">
                      <div className="room__details__image">
                        <Image
                          width={250}
                          height={140.63}
                          src={group.room_image}
                          fallback={FallbackImage}
                        />
                      </div>
                      <div className="room__details__info">
                        <div className="room__details__no"><span className="room__details__heading">room no :</span>{group.title} </div>
                        <div className="room__details__name"><span className="room__details__heading">room name :</span>{group.room_name}</div>
                        <div className="room__details__bed"><span className="room__details__heading">room bed :</span>{group.num_of_bed}</div>
                        <div className="room__details__area"><span className="room__details__heading">room area :</span>{group.room_size}</div>
                        <div className="room__details__capacity"><span className="room__details__heading">room capacity :</span>{group.maximum_guests}</div>
                        <div className="room__details__price"><span className="room__details__heading">room price :</span>$ {group.base_price}</div>
                        <div className="room__details__status"><span className="room__details__heading">Cleaning required:</span> No</div>
                      </div>
                    </div>
                  }
                >
                  Room  {group.title}
                </Popover>
              </span>
            </div>
            <span className="room__status">
              <Popover placement="right" content={content(group.id)} trigger="click" className="hotel__pop">
                <EllipsisOutlined />
              </Popover>
            </span>
            {/* <CalenderDetailsDrawer
              openCalenderData={openCalenderData}
              setOpenCalenderData={setOpenCalenderData}
            /> */}
          </div>
        )
      };
    });

  // problrm area End----------------------------------------->>
  const handleItemClick = (itemId) => {
    const newdata = calendarData.find((event) => event.id === itemId);
    setSelectedEvent(newdata);
    setRoomdetailsDrawer(true);
  };
  // console.log("allStatus=====allStatus===>", allStatus);
  const getBackgroundColor = (status) => {
    switch (status) {
      case 0:
        return {
          status_name: "Inactive",
          color: "#fff",
          backgroundColor: "#C6D2D5",
        };
      case 1:
        return {
          status_name: "Active",
          color: "#fff",
          backgroundColor: "#52c41a",
        };
      case 2:
        return {
          status_name: "Check In",
          color: "#fff",
          backgroundColor: "#2a899d",
        };
      case 3:
        return {
          status_name: "Check Out",
          color: "#fff",
          backgroundColor: "#959595",

        };
      case 4:
        return {
          status_name: "Abandoned",
          color: "#fff",
          backgroundColor: "#EE9E9C",
        };
      case 5:
        return {
          status_name: "Cancelled",
          color: "#fff",
          backgroundColor: "#E07170",
        };
      case 6:
        return {
          status_name: "Occupied",
          color: "#fff",
          backgroundColor: "#fa8c16",
        };
      case 7:
        return {
          status_name: "Reserved",
          color: "#fff",
          backgroundColor: "#3B5B7E",
        };
      default:
        return {
          status_name: "",
          color: "",
          backgroundColor: "",
          border: ""
        };
    }
  };

  const itemRenderer = ({ item, itemContext, getItemProps }) => {
    const colors = getBackgroundColor(item.status);
    const backgroundColor = itemContext.selected ? "#23253C" : colors.backgroundColor;
    // const { border } = colors;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: colors.color,
            border: "1px solid transparent",
            overflow: "hidden",
            // backgroundImage: "url(\"https://www.transparenttextures.com/patterns/bright-squares.png\")"
            // backgroundImage: "url(\"https://www.transparenttextures.com/patterns/escheresque.png\")"
            // backgroundImage: "url(\"https://www.transparenttextures.com/patterns/maze-white.png\")"
            backgroundImage: "url(\"https://www.transparenttextures.com/patterns/gplay.png\")"
          }
        })}
      >
        <div className="esl-schedule-item" style={{ textTransform: "capitalize", borderRadius: "10%", textAlign: "center", fontWeight: "500", fontSize: "14px" }}>
          <span>
            <span style={{ height: "10px", width: "10px", backgroundColor: `${colors.color}`, display: "inline-block", margin: "0 10px" }} />{item.title}
          </span>
        </div>
      </div>
    );
  };

  const buttonStyle = {
    background: "#23253c",
    color: "rgb(255, 255, 255)",
    lineHeight: "14px",
    fontWeight: "400",
    fontSize: ".94rem"
  };
  return (
    <>
      <div className="timeline__topProps" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <span><StatusIdentity allStatus={allStatus} /></span>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", columnGap: "10px" }}>
          <Button style={buttonStyle} size="default" onClick={onPrevClick}><CaretLeftOutlined /></Button>
          <Button style={buttonStyle} size="default" onClick={onMonthClick}><MdOutlineCenterFocusStrong style={{ fontSize: "20px" }} /></Button>
          <Button style={buttonStyle} size="default" onClick={onNextClick}><CaretRightOutlined /></Button>
        </div>
      </div>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <Spin size="large" />
        </div>
      ) : (
        <Timeline
          groups={newGroups}
        // groupRenderer={groupRenderer}
          sidebarWidth={250}
          sidebarContent={<div style={{ color: "#fff" }}>Above The Left</div>}
          keys={keys}
          canMove={false}
          canResize={false}
          canSelect
          itemsSorted
          itemTouchSendsClick={false}
          stackItems
          itemHeightRatio={0.95}
          useResizeHandle={false}
          showCursorLine
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          itemRenderer={itemRenderer}
          lineHeight={30}
          fullUpdate
          items={
          calendarData.map((event) => ({
            id: event.id,
            group: event.room_id,
            start_time: moment(event.start_date),
            end_time: moment(event.end_date),
            status: event.status,
            title:
          <Popover
            trigger="hover"
            placement="right"
            content={
              <div className="customer__details__hover__area">
                <div className="main__guest__name">
                  <span className="guest__title">Main guest</span>
                  <p style={{ textTransform: "capitalize", color: "#089cd9" }}> {event.guest_name}</p>
                </div>
                <div className="customer__info">
                  <div className="customer__single__info">
                    <span className="info__title">Nights</span>
                    <span className="info__details">{event.nights}</span>
                  </div>
                  <div className="customer__single__info">
                    <span className="info__title">guests</span>
                    <span className="info__details">{event.guests}</span>
                  </div>
                  <div className="customer__single__info">
                    <span className="info__title">Children</span>
                    <span className="info__details">0</span>
                  </div>
                </div>
                <div className="status__area">
                  <div className="single__status">
                    <span className="status__title">Status</span>
                    <HoverStatus status={event.status} />
                  </div>
                  <div className="single__status">
                    <Link to={`/booking/bookingdetails/${event.booking_id}`}>
                      <Button type="link">Booking Details</Button>
                    </Link>
                  </div>
                </div>
              </div>
                }
          >
            {event.guest_name}
          </Popover>,
          }))
        }
          onItemClick={handleItemClick}
        >
          <TimelineHeaders className="sticky">
            <SidebarHeader>
              {({ getRootProps }) => {
                // return <div {...getRootProps()} className="animate-charcter">{hotelInfo.hotel_legal_name}</div>;
                return <div {...getRootProps()} className="frontdesk__header__title">{hotelInfo.hotel_legal_name}</div>;
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" />
            <DateHeader />
            <TimelineMarkers>
              <TodayMarker />
              <CustomMarker
                date={moment().startOf("month").valueOf() + 1000 * 60 * 60 * 2}
              />
              <CustomMarker date={moment().add(1, "month").valueOf()}>
                {({ styles }) => {
                  const newStyles = { ...styles, backgroundColor: "transparent !importent" };
                  return <div style={newStyles} />;
                }}
              </CustomMarker>
              <CursorMarker />
            </TimelineMarkers>
          </TimelineHeaders>
        </Timeline>
      )}
      <RoomDetailsDrawer
        roomDetailsDrawer={roomDetailsDrawer}
        setRoomdetailsDrawer={setRoomdetailsDrawer}
        selectedEvent={selectedEvent}
      />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", columnGap: "10px", margin: "20px 0" }}>
        <Button style={buttonStyle} size="default" onClick={onPrevClick}><CaretLeftOutlined /></Button>
        <Button style={buttonStyle} size="default" onClick={onMonthClick}><MdOutlineCenterFocusStrong style={{ fontSize: "20px" }} /></Button>
        <Button style={buttonStyle} size="default" onClick={onNextClick}><CaretRightOutlined /></Button>
      </div>
    </>
  );
};

export default V3TimeLine;
